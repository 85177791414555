import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { AlertWrapper } from 'Shared/Alert'

const AutopayFailedAlert = ({ isAccountEngine, linkLabel, showLink, url }) => (
  <AlertWrapper className="shadow-sm d-flex align-items-center justify-content-between" color="danger">
    <div className="d-flex align-items-center">
      <FontAwesomeIcon className="me-3 text-danger" icon="fa-triangle-exclamation" size="lg" />

      <p className="text-grey-dark mb-0">
        {isAccountEngine ? (
          <>
            Autopay has failed for the payment method saved on file for this reservation.
            {showLink ? <br /> : ' '}
            Please contact your bank or add a new payment method in the top right corner.
          </>
        ) : (
          <>
            The payment method saved on file for this invoice could not successfully run on autopay.
            {showLink ? <br /> : ' '}
            Please have the guest contact their bank or add a different payment method on file.
          </>
        )}
      </p>
    </div>

    {showLink && linkLabel && <a className="btn btn-danger" href={url}>{linkLabel}</a>}
  </AlertWrapper>
)

AutopayFailedAlert.defaultProps = {
  isAccountEngine: false,
  linkLabel: 'Review Payment Method',
  showLink: false,
  url: '',
}

AutopayFailedAlert.propTypes = {
  isAccountEngine: PropTypes.bool,
  linkLabel: PropTypes.string,
  showLink: PropTypes.bool,
  url: PropTypes.string,
}

export default AutopayFailedAlert
