import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import NavElement from './NavElement'

const RecurrentStopped = ({ billigDay, billigEndDay, billigStartDay }) => (
  <>
    <div className="element d-flex px-4 mt-2">
      <FontAwesomeIcon className="danger mt-1 me-2" icon="circle-stop" />

      <span> Recurring invoice schedule stopped on <b>{billigEndDay}</b></span>
    </div>

    <NavElement text="Billing Start Date" value={billigStartDay} />

    <NavElement text="Billing End Date" value={billigEndDay} />

    <NavElement text="Billing Day" value={billigDay} />
  </>
)

export default RecurrentStopped
