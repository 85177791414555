import cname from 'classnames'
import { Table, TableBody, TableHeader } from './Table'
import Body from './Invoice.Body'
import Card from 'Shared/Admin/Card/Card'
import Header from './Invoice.Header'
import ItemForm from './Item/Form/Invoice.ItemForm'
import ItemModal from './Item/Invoice.ItemModal'
import MinPricingAlert from './Invoice.MinPricingAlert'
import PricingAdjustmentAlert from './Item/Invoice.PricingAdjustmentAlert'
import RowAlert from './Invoice.RowAlert'
import './Invoice.scss'

const Invoice = ({ className, children }) => {
  const classNameWrapper = cname('invoice d-flex flex-column mb-3 mb-lg-4 p-0', className)

  return (
    <Card className={classNameWrapper}>
      {children}
    </Card>
  )
}

Invoice.Body = Body
Invoice.Header = Header
Invoice.ItemForm = ItemForm
Invoice.ItemModal = ItemModal
Invoice.MinPricingAlert = MinPricingAlert
Invoice.PricingAdjustmentAlert = PricingAdjustmentAlert
Invoice.RowAlert = RowAlert
Invoice.Table = Table
Invoice.TableBody = TableBody
Invoice.TableHeader = TableHeader

export default Invoice
