import cnames from 'classnames'
import 'Styles/Link'

const Anchor = props => {
  const { className, children, disabled, onClick } = props
  const classes = cnames(className, { 'cursor-pointer': !disabled },
                         { 'text-muted text-decoration-none': disabled })

  const handleOnClick = event => {
    event.preventDefault()
    if (disabled) return

    onClick(event)
  }

  const handleKeyEvent = event => {
    if (event.keyCode === 13) onClick(event)
  }

  return (
    <a {...props} className={classes} onClick={handleOnClick} onKeyDown={handleKeyEvent} role="link"
       tabIndex={0}>
      {children}
    </a>
  )
}

export default Anchor
