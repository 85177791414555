import { gql } from 'Utils/apolloClient'

const alertQuery = gql`
  query AlertData($filters: ValidationsFiltersAttributes, $from: ISO8601Date!, $slug: String!,
                  $to: ISO8601Date!) {
    campground(filters: $filters, from: $from, slug: $slug, to: $to) {
      alerts
    }
  }
`

export default alertQuery
