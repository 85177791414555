import { useEffect, useRef } from 'react'
import './ActivationNeeded.scss'

const BASE_CLASS = 'activation-needed bg-black opacity-75 h-100 w-100 align-items-center d-flex justify-content-center top-0 position-absolute'

const ActivationNeeded = ({ onClick }) => {
  const elementRef = useRef(null)

  useEffect(() => {
    if (!elementRef.current) return

    elementRef.current.addEventListener('click', onClick)

    return () => {
      if (!elementRef.current) return

      elementRef.current.removeEventListener('click', onClick)
    }
  }, [elementRef.current])

  return (
    <div className={BASE_CLASS} ref={elementRef}>
      <span className="position-sticky text text-white">
        To zoom with scroll you need to click/touch into the map
      </span>
    </div>
  )
}

export default ActivationNeeded
