import storeConnect from 'Checkout/Redux/Connect'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import Header from 'Checkout/Components/Shared/Header/Header'
import Footer from 'Checkout/Components/Shared/Footer/Footer'
import MainColumn from 'Checkout/Components/Shared/MainColumn/MainColumn'
import Section from 'Checkout/Components/Shared/Section/Section'
import ReservationsDisabledPageImage from './reservations_disabled.png'

const ReservationsDisabledPage = ({ campgroundName, helpCenter, roverpassHome }) => (
  <Section name="reservations-disabled">
    <MainColumn>
      <Header className="p-4" />

      <div className="text-center d-flex flex-column mt-5 pb-5 flex-fill align-items-center">
        <div className="mb-5">
          <img src={ReservationsDisabledPageImage} alt="reservations disabled" width="240" height="240" />
        </div>

        <h3 className="fw-bold">{campgroundName}</h3>

        <h3 className="fw-bold mb-4">Online reservations are inactive</h3>

        <h5 className="fw-bold">Are you the campground? Activate reservations by contacting support</h5>

        <div className="btn-wrapper mt-5">
          <a className="btn btn-theme-primary me-2" href={helpCenter}>
            <FontAwesomeIcon icon="envelope" className="me-1" />
            Email Support
          </a>

          <a className="btn btn-outline-theme-primary ms-2" href={roverpassHome}>Take me home</a>
        </div>
      </div>

      <Footer />
    </MainColumn>
  </Section>
)

const mapStateToProps = state => ({
  campgroundName: state.campground.name,
  helpCenter: state.options.externalLinks.helpCenter,
  roverpassHome: state.options.externalLinks.roverpassHome,
})

export default storeConnect({ mapStateToProps })(ReservationsDisabledPage)
