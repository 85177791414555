import { createContext, useState } from 'react'

export const ItemFormContext = createContext()

export const ItemFormProvider = ({ children }) => {
  const [item, setItem] = useState({})

  return (
    <ItemFormContext.Provider value={{ item, setItem }}>
      {children}
    </ItemFormContext.Provider>
  )
}
