import { useState } from 'react'
import PropTypes from 'prop-types'
import { Content, Header } from './Components'
import { StateProvider } from './useStateContext'
import { classes } from 'Shared/Cart/Constants'

const CollapseWrapper = ({ children, className, handleOnClick, isOpenByDefault }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const handleState = () => {
    setIsOpen(!isOpen)
    if (handleOnClick) handleOnClick()
  }

  return (
    <StateProvider value={{ isOpen, handleState }}>
      <div className={`collapse-wrapper ${className}`}>
        {children}
      </div>
    </StateProvider>
  )
}

CollapseWrapper.defaultProps = {
  className: classes.collapseContainer,
  isOpenByDefault: false,
  handleOnClick: null,
}

CollapseWrapper.propTypes = {
  className: PropTypes.string,
  isOpenByDefault: PropTypes.bool,
  handleOnClick: PropTypes.func,
}

CollapseWrapper.Header = Header
CollapseWrapper.Content = Content

export default CollapseWrapper
