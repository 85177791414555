import PropTypes from 'prop-types'
import Link from 'Shared/Link/Link'

const TdLink = ({ children, className, contentClassName, isExternalLink, link, linkClassName, target }) => (
  <td className={className}>
    {link ? (
      <Link className={linkClassName} to={link} isExternal={isExternalLink} target={target}>
        <span className={contentClassName}>
          {children}
        </span>
      </Link>
    ) : (
      <span className={contentClassName}>
        {children}
      </span>
    )}
  </td>
)

TdLink.defaultProps = {
  contentClassName: null,
  isExternalLink: false,
  target: '_self',
  link: null,
  linkClassName: null,
}

TdLink.propTypes = {
  contentClassName: PropTypes.string,
  isExternalLink: PropTypes.bool,
  target: PropTypes.string,
  link: PropTypes.string,
  linkClassName: PropTypes.string,
}

export default TdLink
