import * as Yup from 'yup'

const SignInViewSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid.')
    .required('Please enter an email.'),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .required('Please enter a password'),
})

export default SignInViewSchema
