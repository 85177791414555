import cnames from 'classnames'

const InvoiceData = ({ amount, installmentId, installmentNumber, itemName, name }) => {
  const eventsDecrement = ['due', 'itemDeleted', 'refund', 'refundCreated', 'overdue']
  const eventsIncrement = ['itemCreated', 'paid']
  const totalClass = cnames({ 'amount-decrement text-danger': eventsDecrement.includes(name),
                              'amount-increment text-success': eventsIncrement.includes(name) })

  return (
    <div className="invoice-data d-flex justify-content-between mt-2">
      <span>{installmentNumber || itemName}</span>

      <span>{installmentId}</span>

      <span className={totalClass}>{amount?.display}</span>
    </div>
  )
}

export default InvoiceData
