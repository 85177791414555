import { useContext, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { PaymentMethodActions, PaymentMethodsContext } from 'Shared/PaymentMethods'
import CashPaymentFormSchema from './CashPaymentFormSchema'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import FormCurrencyInput from 'Shared/Formik/FormCurrencyInput'
import RefundedLabel from './RefundedLabel/RefundedLabel'

const initalFormValues = { amount: 0, source: 'cash' }

const CashPayment = ({ children }) => {
  const { clearMutationErrors, displayAmount, displayOptions, mutation, onChangePaymentCash, onSubmit,
          paymentChangeOwed, toggle, totalBeforeProcessing } = useContext(PaymentMethodsContext)
  const { amount, display } = totalBeforeProcessing
  const amountText = displayAmount ? `: ${display}` : ''


  const validateSchema = () => CashPaymentFormSchema(amount)

  return (
    <FormWithValidations className="payment-form" formId="payment-form-cash" initialValues={initalFormValues}
                         onChangeValues={clearMutationErrors} onSubmit={onSubmit} scrollToError
                         validationSchema={validateSchema}>
      {({ values }) => {
        useEffect(() => {
          onChangePaymentCash(values.amount)
        }, [values.amount])

        return (
          <>
            <h5>Amount{amountText}</h5>

            <span className="text-muted">
              Enter in amount received in cash to see change owed.
            </span>

            <Row className="mt-2">
              <Col className="d-flex align-items-center">
                <FormCurrencyInput className="mb-0" name="amount" placeholder="$0.00" showErrors />
              </Col>

              <RefundedLabel changeOwed={paymentChangeOwed} tagColor="light-blue" tagFitContent />
            </Row>

            {children}

            {displayOptions && (
              <PaymentMethodActions confirmText="Pay Invoice Now" loading={mutation.loading} onCancel={toggle} />
            )}
          </>
        )
      }}
    </FormWithValidations>
  )
}

export default CashPayment
