import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Dropdown from 'src/Shared/NavbarDropdown/Dropdown'

const NavbarDropdown = () => {
  const container = document.getElementById('nav-react-data-container')
  window.roverData = { ...window?.roverData, ...container.dataset }

  return (
    <ApolloProvider client={apolloClient}>
      <Dropdown />
    </ApolloProvider>
  )
}

export default NavbarDropdown
