import { gql } from 'Utils/apolloClient'

const websiteQuery = gql`
  query WebsiteQuery($domain: String!) {
    website(domain: $domain) {
      isSecure
    }
  }
`

export default websiteQuery
