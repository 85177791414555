import { gql } from 'Utils/apolloClient'

const UserRegistrationMutation = gql`
  mutation UserRegistrationCreate($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
    userRegistrationCreate(input: { email: $email, firstName: $firstName, lastName: $lastName,
                                    password: $password}) {
      currentUser {
        authenticationToken
      }
    }
  }
`

export default UserRegistrationMutation
