import AlertWrapper from 'Shared/Alert/AlertWrapper/AlertWrapper'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const PRICE_INCREASE_ALERT = 'If the price increases, the camper will be notified and must approve all changes before payment is processed.'

const PricingAdjustmentAlert = () => (
  <AlertWrapper className="shadow-sm fw-bold" color="warning-outline-text-light">
    <p className="mb-1">
      <FontAwesomeIcon className="color-warning" icon="triangle-exclamation" size="lg" />

      <span className="ms-2 fw-bold">Pricing Adjustments</span>
    </p>

    <p className="mb-0 fw-normal">{PRICE_INCREASE_ALERT}</p>
  </AlertWrapper>
)

export default PricingAdjustmentAlert
