import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

const useParamsHandler = () => {
  const history = useHistory()
  const location = useLocation()

  /**
   * Replaces the query parameters in the same path
   * @param {Object} search - query params to replace
   */
  const replaceQuery = (search, optionals = {}) => {
    const currentParams = getSearchParams()

    Object.entries(optionals).forEach(([key, value]) => {
      if (key in currentParams) currentParams[key] = value
    })

    const params = { ...currentParams, ...search }
    const query = _mergeQueryParams(params)

    history.replace({ search: query })
  }

  const getSearchParams = () => queryString.parse(location.search)

  // private
  const _mergeQueryParams = params => queryString.stringify(params, { skipEmptyString: true })

  return useMemo(() => ({
    getSearchParams,
    replaceQuery,
  }), [history, location])
}

export default useParamsHandler
