import { gql } from 'Utils/apolloClient'

const sitesAvailableDuringQuery = gql`
  query SitesAvailableDuring($from: ISO8601Date!, $rateId: ID, $slug: String!, $to: ISO8601Date!) {
    campground(slug: $slug, from: $from, to: $to) {
      rate(id: $rateId) {
        sitesAvailableDuring {
          id
          name
        }
      }
      sitesAvailable {
        id
        name
      }
    }
  }
`

export default sitesAvailableDuringQuery
