import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap'

const PaymentMethodActions = ({ cancelText, confirmText, loading, onCancel }) => (
  <Row className="mt-4">
    <Col className="d-flex justify-content-between" md={{ size: 7, offset: 5 }}>
      <Button color="secondary" onClick={onCancel} size="md">{cancelText}</Button>

      <Button color="salmon" disabled={loading} size="md" type="submit">
        {confirmText}
      </Button>
    </Col>
  </Row>
)

PaymentMethodActions.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Save',
  loading: false,
  onCancel: () => {},
}

PaymentMethodActions.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default PaymentMethodActions
