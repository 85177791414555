import { firstWordCharacterRegex, symbolsRegex } from 'Shared/RegExpressions'

const removeSymbols = text => text.replace(symbolsRegex, ' ')

export const titleize = text => {
  if (typeof text !== 'string' || !text) return ''

  return text.toLowerCase().replace(firstWordCharacterRegex, x => removeSymbols(x).toUpperCase())
}

export const toPositive = value => (typeof value === 'string' ? value.replace('-', '') : value)
