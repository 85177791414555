import { useControls } from 'react-zoom-pan-pinch'
import { scrollToQueryElement } from 'Utils/Elements'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './MapControls.scss'

const MapControls = () => {
  const { resetTransform, zoomIn, zoomOut } = useControls()

  const handleZoomDecrease = () => zoomOut()

  const handleZoomIncrease = () => zoomIn()

  const handleResetTransform = () => resetTransform()

  const scrollToSites = () => {
    resetTransform()
    const elementScrolled = scrollToQueryElement('.sites-list--header')
    if (elementScrolled) return

    scrollToQueryElement('.no-availability-sites')
  }

  return (
    <div className="zoom-controls rounded mt-4 me-4 position-absolute top-0 end-0">
      <div className="d-flex flex-column">
        <button className="zoom-controls__button rounded-top" onClick={handleZoomIncrease} type="button">
          <FontAwesomeIcon className="zoom-controls__icons" icon="plus" size="lg" />
        </button>

        <button className="zoom-controls__button" onClick={handleZoomDecrease} type="button">
          <FontAwesomeIcon className="zoom-controls__icons" icon="minus" size="lg" />
        </button>

        <button className="zoom-controls__button" onClick={handleResetTransform} type="button">
          <FontAwesomeIcon className="zoom-controls__icons" icon="rotate-right" size="lg" />
        </button>

        <button className="zoom-controls__button rounded-bottom" onClick={scrollToSites} type="button">
          <FontAwesomeIcon className="zoom-controls__icons" icon="list" size="lg" />
        </button>
      </div>
    </div>
  )
}

export default MapControls
