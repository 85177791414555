export const snakecaseKeys = (object = {}) => {
  const keys = Object.keys(object)
  const newObject = keys.reduce((acc, curr) => {
    const newKey = curr.replace(/[A-Z]/g, chr => `_${chr.toLowerCase()}`)

    return { ...acc, [newKey]: object[curr] }
  }, {})

  return newObject
}

export const cloneWithoutKeys = (object = {}, except = []) => {
  const newObject = except.reduce((acc, key) => {
    const { [key]: _, ...rest } = acc
    return rest
  }, object)

  return newObject
}

export const compact = object => Object.fromEntries(Object.entries(object).filter(([, value]) => value != null))

export const copyObject = object => JSON.parse(JSON.stringify(object))

export const getValue = (object, path) => path.split('.').reduce((objectAcc, path) => objectAcc?.[path], object)

export const isEmpty = object => Object.keys(object).length === 0
