import ReservationRow from './ReservationRow'
import TableList from 'Shared/TableList/TableList'

const ReservationsList = ({ isAllChecked, isIndeterminate, onCheckHeader, onCheckRow, reservations }) => {
  const headers = [
    {
      cellName: 'reservation-data',
      cols: 8,
      label: 'Select All',
      name: 'resData',
    },
    {
      cols: 3,
      label: '',
      name: 'amount',
    },
  ]

  return (
    <div className="cancellable-reservations-table text-muted">
      <TableList classHeader="m-0" CustomRow={ReservationRow} data={reservations} headers={headers}
                 isAllChecked={isAllChecked} isIndeterminate={isIndeterminate} onCheckHeader={onCheckHeader}
                 onCheckRow={onCheckRow} withCheckSelection />
    </div>
  )
}

export default ReservationsList
