import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { operationWithDecimals } from 'Utils/Math'
import { roundWithDecimals } from 'Utils/Parse'
import CurrencyInput from 'Shared/CurrencyInput/CurrencyInput'
import FormGroupField from 'Shared/Formik/FormGroupField'

const FormCurrencyPayment = ({ className, disabled, id, inputName, name, number, totalPayments }) => (
  <FormGroupField className={className} name={name} showErrors>
    {({ field, form: { setStatus, setValues, values }, hasErrorClass }) => {
      const [maxValue, setMaxValue] = useState()

      const getPaymentTotal = ({ amountSum, newTotal, payment, splitTotal }) => {
        const { number: paymentNumber } = payment
        if (paymentNumber === totalPayments) return operationWithDecimals(values.total, amountSum, false)
        if (paymentNumber === number) return newTotal
        if (paymentNumber > number) return splitTotal

        return payment.total
      }

      const updateValue = value => {
        const recalculatedPayments = recalculateTotals(value)
        setValues({ ...values, payments: recalculatedPayments })
        setStatus(true)
      }

      const calculateRemainingTotal = () => {
        const totalBeforePayment = values.payments.reduce((sum, item) => (
          item.id < id ? sum + item.total : sum
        ), 0)

        return roundWithDecimals(values.total - totalBeforePayment)
      }

      const recalculateTotals = paymentTotal => {
        const paymentsToRecalculate = values.payments.length - id
        const remainingTotal = calculateRemainingTotal()
        let splitTotal = 0
        let newTotal = paymentTotal

        if (remainingTotal <= 0) {
          setMaxValue(0)
          newTotal = 0
        } else if (paymentTotal > remainingTotal) {
          setMaxValue(remainingTotal)
          newTotal = remainingTotal
        } else
          splitTotal = roundWithDecimals((remainingTotal - paymentTotal) / paymentsToRecalculate)

        let amountSum = 0
        return values.payments.map(item => {
          const amount = getPaymentTotal({ amountSum, newTotal, payment: item, splitTotal })

          amountSum += amount
          return { ...item, total: amount }
        })
      }

      const isDisabled = useMemo(() => {
        if (disabled) return disabled

        return calculateRemainingTotal() === 0
      }, [disabled, values])

      useEffect(() => {
        setMaxValue(values.total)
      }, [values.total])

      return (
        <CurrencyInput classTextGroup="px-3" disabled={isDisabled} inputSize="" inputClass={hasErrorClass}
                       name={inputName} max={maxValue} onValueChange={updateValue} value={field.value} />
      )
    }}
  </FormGroupField>
)

FormCurrencyPayment.defaultProps = {
  disabled: false,
}

FormCurrencyPayment.propTypes = {
  disabled: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default FormCurrencyPayment
