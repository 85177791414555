import { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import paymentStateQuery from './PaymentStateQuery'
import Loading from 'Shared/Loading'
import ViewModal from 'Shared/ViewModal/ViewModal'

const ProcessPaymentModal = ({ closeModal, isOpen, onCompletePayment, uuid }) => {
  const [getInstallment, { data }] = useLazyQueryNoCache(paymentStateQuery)
  const [payment, setPayment] = useState({ attempts: 0, isFailed: false })

  useEffect(() => {
    if (isOpen) {
      const paymentState = data?.installment?.payment?.state
      const isCompleted = paymentState === 'completed'
      const isFailed = paymentState === 'failed'

      if (isCompleted) onCompletePayment()
      else if (isFailed && payment.attempts > 1) setPayment({ attempts: 0, isFailed: true })
      else {
        const interval = setInterval(() => {
          getInstallment({ variables: { uuid } })
          setPayment(prevState => ({ ...prevState, attempts: prevState.attempts + 1, isFailed: false }))
        }, 2000)

        return () => clearInterval(interval)
      }
      return () => {}
    }
  }, [data])

  useEffect(() => {
    if (isOpen) {
      if (!uuid) return setPayment({ attempts: 0, isFailed: true })

      setPayment({ attempts: 0, isFailed: false })
      getInstallment({ variables: { uuid } })
    }
  }, [isOpen])

  return (
    <ViewModal isOpen={isOpen} showOkButton={false}>
      <div className="d-flex flex-column align-items-center justify-content-center py-5">
        {payment.isFailed ? (
          <>
            <FontAwesomeIcon icon="triangle-exclamation" className="text-danger h1" />
            <h3 className="text-danger">Payment Failed</h3>

            <div className="py-2">
              <p>This credit card was not able to be processed.</p>
              <p>Please verify the card details, or try another card.</p>
            </div>

            <Button className="w-100" color="salmon" type="button" onClick={closeModal}>
              Close and review
            </Button>
          </>
        ) : (
          <>
            <Loading className="text-center mb-2" iconClass="mt-5" iconColor="#2B84BF" size="3x" />

            <h3 className="mb-5">Processing Payment</h3>
          </>
        )}
      </div>
    </ViewModal>
  )
}

export default ProcessPaymentModal
