import PropTypes from 'prop-types'

const DiscountOptions = ({ discounts, showRate }) =>
discounts.map(discount => {
  const rate = discount.ratePercent || discount.rate
  const ratePercentage = (showRate || discount.name === 'Non-Refundable') ? `(${rate}%)` : ''

  return (
    <option key={discount.id} value={discount.id}>{discount.clubName || discount.name} {ratePercentage}</option>
  )
})

DiscountOptions.defaultProps = {
  discounts: [],
  showRate: false,
}

DiscountOptions.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  showRate: PropTypes.bool,
}

export default DiscountOptions
