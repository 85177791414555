import cnames from 'classnames'
import PropTypes from 'prop-types'
import CheckboxTable from 'Shared/Admin/Table/CheckboxTable'

const RowCell = ({ header, item, onCheckRow }) => {
  const { cellName, cols, getValue, name } = header
  const className = cnames('row-cell text-break', `row-cell--${cellName || name}`, `col-${cols || 1}`)
  const value = getValue ? getValue(item[name], item) : item[name]
  const isCheckCell = name === 'select'
  const nameCheckBox = 'select-current-check'
  const idCheckbox = `${nameCheckBox}-${item.id}`

  return (
    <div className={className}>
      {isCheckCell ? (
        <CheckboxTable handleClick={onCheckRow} isChecked={value} id={idCheckbox} message="Select Row"
                       name={nameCheckBox} />
      ) : (
        value
      )}
    </div>
  )
}

RowCell.defaultProps = {
  onCheckRow: () => {},
}

RowCell.propTypes = {
  header: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({}).isRequired,
  onCheckRow: PropTypes.func,
}

export default RowCell
