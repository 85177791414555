import cnames from 'classnames'
import RefundsPending from 'Shared/RefundsPending/RefundsPending'

const TotalPrice = ({ hasPendingRefunds, id, pricing, refunds, state, textSize, totalClassName, totalHelperText,
                      total, totalName }) => {
  const totalClasses = cnames(textSize, totalClassName, {
    [state]: !hasPendingRefunds,
  })
  const { isTaxIncluded, taxConfig } = pricing || {}
  const taxRenamed = taxConfig?.taxName || 'Tax'
  const totalInfoName = totalHelperText || (isTaxIncluded && `(Fees and ${taxRenamed} included)`)

  return (
    <div className="d-flex justify-content-between mb-1">
      <div>
        <span className={totalClasses}>{totalName}</span>

        <span className="fst-italic ms-2 smaller text-muted">{totalInfoName}</span>
      </div>

      <span className={totalClasses}>
        {hasPendingRefunds && <RefundsPending id={id} onlyIconTrigger refunds={refunds} />}

        {total?.display || '--'}
      </span>
    </div>
  )
}

export default TotalPrice
