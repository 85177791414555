import { connect } from 'react-redux'

let storeConnect

(() => {
  const reduxConnect = (mapStateToProps, mapDispatchToProps, mergeProps, options) => {
    const storeConnector = connect(mapStateToProps, mapDispatchToProps, mergeProps, options)
    return Component => storeConnector(Component)
  }

  storeConnect = ({ mapStateToProps, mapDispatchToProps, mergeProps }) => reduxConnect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )
})()

export default storeConnect
