import { Alert } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import RichText from 'Shared/RichText/RichText'

const TripInsuranceCoverageAlert = ({ alertMessage }) => (
  <Alert className="mt-2 text-hyphens trip-insurance-coverage-alert" color="warning">
    <div className="d-flex flex-column justify-content-between px-0">
      <div className="align-items-start d-flex px-0 py-1">
        <FontAwesomeIcon className="fa-md me-2 pt-1" icon="triangle-exclamation" />

        <div className="d-flex d-sm-block flex-column flex-sm-row mb-0 text-justify">
          <div className="banner--warning__title mb-2 mb-sm-0 ms-4 ms-sm-0 ps-1 ps-sm-0">
            <RichText html={alertMessage} />
          </div>
        </div>
      </div>
    </div>
  </Alert>
)

export default TripInsuranceCoverageAlert
