import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { dnsResolverApi } from 'Shared/VerifyWebsite/Utils/Utils'
import { roverDomainsRegex } from 'Shared/RegExpressions'

const INITIAL_STATE = { errors: [], isError: false, isLoading: true, isCnameCorrect: false }

export const useCnameValidation = domain => {
  const [state, setState] = useState(INITIAL_STATE)
  const debouncedApiCall = useDebouncedCallback(() => apiCall(), 350)

  const addRequestError = () => setState({ errors: [], isError: true, isLoading: false, isCnameCorrect: false })
  const resetState = () => setState(INITIAL_STATE)
  const successDns = () => setState({ errors: [], isError: false, isLoading: false, isCnameCorrect: true })

  const apiCall = async () => {
    const data = await dnsResolverApi(domain)
    if (!data) return addRequestError()

    const isCnameCorrect = data.Answer?.some(item => item.data.match(roverDomainsRegex))
    if (isCnameCorrect) return successDns()

    setState(prev => ({ ...prev, errors: data.Answer, isError: true, isLoading: false }))
  }

  const refetch = () => {
    resetState()
    debouncedApiCall()
  }

  useEffect(() => {
    apiCall()
  }, [domain])

  return { ...state, refetch }
}
