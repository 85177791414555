import { gql } from 'Utils/apolloClient'

const pricingQuery = gql`
  query Pricing($reservationsData: [ReservationPricingAttributes!], $slug: String!) {
    campground(isPos: false, slug: $slug) {
      id
      purchasePricing(reservationsData: $reservationsData) {
        isTaxIncluded
        dueNow {
          amount
          display
        }
        dueAtCheckIn {
          amount
          display
        }
        dynamicPrices
        platformFeeTotal {
          display
        }
        processingFeeTotal {
          amount
          display
        }
        reservations {
          autoAssign
          from
          guests {
            adults
            children
            pets
            useGuests
            vehicles
          }
          isDayUse
          inProgress
          isSiteLock
          nanoId
          pricing {
            camperSubtotal {
              amount
            }
            id
            nonRefundableAmount {
              amount
              display
            }
            nonRefundableDisclaimer
            nonRefundableDiscount
            subtotal {
              display
              isTaxIncluded
            }
            items {
              base {
                amount
                display
              }
              id
              isFee
              name
              position
              priceDescription
              units
              itemableType
            }
            tripInsurancePrice
          }
          rate {
            id
            name
          }
          site {
            id
            name
          }
          to
        }
        subtotal {
          amount
          display
        }
        taxName
        taxTotal {
          amount
          display
        }
        total {
          cents
          amount
          display
          displayWithCode
        }
        totalNonRefundableAmount {
          amount
          display
        }
        totalRevenue {
          amount
        }
        totalSavings {
          display
        }
      }
    }
  }
`

export default pricingQuery
