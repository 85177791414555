import { Label, Collapse } from 'reactstrap'
import { DatePickerSingle } from 'Shared/DatePicker'
import { today } from 'Utils/Dates'

const DueDatePicker = ({ dueOn, isOpen, updateDate }) => {
  const blockPastDays = day => day.isBefore(dueOn || today())

  return (
    <Collapse className="col-12" id="DueDatePicker" isOpen={isOpen}>
      <div className="rounded bg-gray-light my-2 pb-2 pt-2 px-4">
        <small className="fst-italic text-muted">Select the due date for the draft invoice.</small>

        <div>
          <Label htmlFor="date-dueOn">Due Date</Label>

          <DatePickerSingle block date={dueOn} displayFormat="MM/DD/YYYY" id="date-dueOn"
                            isOutsideRange={blockPastDays} onDateChange={updateDate}
                            placeholder="--/--/----" showDefaultInputIcon textToLeft />
        </div>
      </div>
    </Collapse>
  )
}

export default DueDatePicker
