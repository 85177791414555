import './CustomTooltip.scss'

const createBodyEl = (balanceDisplay, parentEl, tooltip) => {
  const { body, labelColors, title } = tooltip
  const amount = balanceDisplay[title[0]]?.display
  const bodyLines = body.map(b => b.lines)
  const bodyEl = document.createElement('div')
  bodyEl.classList.add('tooltip-body')

  bodyLines.forEach((_, index) => {
    const colors = labelColors[index]
    createBulletEl(bodyEl, colors)
    createTextEl(bodyEl, amount)
  })

  parentEl.appendChild(bodyEl)
}

const createBulletEl = (parentEl, colors) => {
  const bulletEl = document.createElement('span')
  bulletEl.style.background = colors.backgroundColor
  bulletEl.classList.add('tooltip-bullet', 'd-inline-block', 'rounded-circle', 'me-2')

  parentEl.appendChild(bulletEl)
}

const createTextEl = (parentEl, amount) => {
  const textEl = document.createElement('span')
  textEl.classList.add('tooltip-text', 'text-muted')
  textEl.appendChild(document.createTextNode(amount))

  parentEl.appendChild(textEl)
}

const createTitleEl = (parentEl, titleLines = []) => {
  titleLines.forEach(title => {
    const titleEl = document.createElement('strong')
    titleEl.classList.add('tooltip-title', 'd-block', 'text-center')
    titleEl.appendChild(document.createTextNode(title))

    parentEl.appendChild(titleEl)
  })
}

const createTooltipEl = () => {
  const tooltipEl = document.createElement('div')
  tooltipEl.id = 'chartjs-tooltip'
  tooltipEl.classList.add('bg-white', 'rounded', 'position-absolute', 'shadow-sm', 'border', 'pe-none')

  return tooltipEl
}

const getOrCreateTooltip = chart => {
  const tooltip = chart.canvas.parentNode.querySelector('div')
  if (tooltip) return tooltip

  const tooltipCreated = createTooltipEl()
  chart.canvas.parentNode.appendChild(tooltipCreated)

  return tooltipCreated
}

export const externalTooltipHandler = (balanceDisplay, context) => {
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  tooltipEl.style.opacity = tooltip.opacity

  tooltipEl.innerHTML = ''
  createTitleEl(tooltipEl, tooltip.title)
  createBodyEl(balanceDisplay, tooltipEl, tooltip)

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  tooltipEl.style.left = `${positionX + tooltip.caretX}px`
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`
  tooltipEl.style.padding = `${tooltip.options.padding}px`
}
