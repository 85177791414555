import * as Yup from 'yup'

const CheckPaymentFormSchema = Yup.object().shape({
  // TODO: Add payment schema
  checkNumber: Yup.string()
    .required(),

  memo: Yup.string(),
})

export default CheckPaymentFormSchema
