import LeftComponent from './LeftComponent'
import RightComponent from './RightComponent'

const RowContainer = ({ children }) => (
  <div className="d-flex justify-content-between">
    {children}
  </div>
)

RowContainer.LeftComponent = LeftComponent
RowContainer.RightComponent = RightComponent

export default RowContainer
