import { Button, Modal, ModalBody } from 'reactstrap'
import RefundSubmit from './DoubleCheckRefundModal/RefundSubmit'

const DoubleCheckRefundModal = ({ isOpen, number, refundsPending, toggle }) => (
  <Modal className="double-check--refund" centered isOpen={isOpen} toggle={toggle}>
    <ModalBody>
      <p className="mb-1">
        There are currently pending refunds(s) on Payment

        <span className="fw-bold ms-1">#{number}</span>
      </p>

      <ul className="mb-1">
        {refundsPending?.map(refund => <RefundSubmit key={refund.id} refund={refund} />)}
      </ul>

      <div className="d-flex flex-column gap-3">
        <span className="text-muted mt-1">
          You are unable to process another refund until the pending refund above has completed processing.
        </span>

        <div className="d-flex justify-content-end gap-2">
          <Button color="blue-dark" onClick={toggle}>Got it</Button>
        </div>
      </div>
    </ModalBody>
  </Modal>
)

export default DoubleCheckRefundModal
