import { useState } from 'react'
import 'Shared/timemachine'
import 'react-dates/initialize'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ModalWrapper from 'Shared/CreateInvoiceModal/Modal/ModalWrapper'

const CreateInvoiceModal = () => {
  const container = document.getElementById('react-data-container')
  const { slug: campgroundSlug, engineName, timezone, token, uuid } = container.dataset
  const isAdmin = engineName === 'admin'
  window.roverData = { campgroundSlug, isAdmin, timezone, token, uuid }

  const toggle = () => setModal(prev => !prev)
  const [modal, setModal] = useState(false)

  return (
    <ApolloProvider client={apolloClient}>
      <Button className="btn-icon" color="primary" onClick={toggle}>
        <FontAwesomeIcon className="py-2" icon="plus" />

        <span className="align-middle ps-3">Create Invoice</span>
      </Button>

      <ModalWrapper isOpen={modal} toggle={toggle} />
    </ApolloProvider>
  )
}

export default CreateInvoiceModal
