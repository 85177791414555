import PropTypes from 'prop-types'
import Anchor from 'Shared/Anchor/Anchor'

const TdAction = ({ children, className, linkClassName, onClick }) => (
  <td className={className}>
    <Anchor className={linkClassName} href="#cell-action" onClick={onClick}>
      {children}
    </Anchor>
  </td>
)

TdAction.defaultProps = {
  linkClassName: null,
}

TdAction.propTypes = {
  linkClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default TdAction
