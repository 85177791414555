import { compose } from 'redux'
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'

const storeConfig = ({ reducers, middlewares = [] }) => {
  const rootReducer = combineReducers(reducers)

  const composeEnhancers = (!['production', 'staging'].includes(process.env.NODE_ENV) &&
                            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const store = configureStore({
    devTools: composeEnhancers,
    middleware: [...getDefaultMiddleware({
      serializableCheck: false,
    }), ...middlewares],
    reducer: rootReducer,
  })

  return store
}

export default storeConfig
