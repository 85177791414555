import { memo } from 'react'
import PropTypes from 'prop-types'
import FormCurrencyPayment from './FormCurrencyPayment'
import PaymentWrapper from 'Shared/PaymentWrapper/PaymentWrapper'
import { daysDifferenceFromToday, isPastDate } from 'Utils/Dates'

const Payment = ({ id, name, nextPayment, number, previousPayment, resetNextDates, totalPayments }) => {
  const isFirstPayment = id === 1
  const isLastPayment = id === parseInt(totalPayments)
  const isSingleDateDisabled = !previousPayment?.dueOn && !isFirstPayment
  const daysToAdd = previousPayment?.dueOn && daysDifferenceFromToday(previousPayment.dueOn) + 1

  const onDateChanged = date => {
    if (!isPastDate(date, nextPayment?.dueOn)) return resetNextDates(id, date)
  }

  return (
    <PaymentWrapper id={id} totalPayments={totalPayments}>
      <PaymentWrapper.CurrencyInput>
        <FormCurrencyPayment className="mb-0" disabled={isLastPayment} id={id} inputName={`total${id}`}
                             name={`${name}.total`} number={number} totalPayments={totalPayments} />
      </PaymentWrapper.CurrencyInput>

      <PaymentWrapper.SingleDate daysToAdd={daysToAdd} disabled={isSingleDateDisabled} id={id}
                                 isFirstPayment={isFirstPayment} isUnpaid name={`${name}.dueOn`}
                                 onDateChanged={onDateChanged} placeholder={`Due Date ${id}`} />
    </PaymentWrapper>
  )
}

Payment.defaultProps = {
  previousPayment: null,
  resetNextDates: () => {},
}

Payment.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  previousPayment: PropTypes.shape({}),
  resetNextDates: PropTypes.func,
}

export default memo(Payment)
