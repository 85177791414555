import { useDispatch, useSelector } from 'react-redux'
import { useRedirects } from 'Checkout/Hooks/useRedirects'
import { cloneWithoutKeys } from 'Utils/objects'
import { addReservation, removeReservation as removeReservationRedux, setEditingReservationId,
         updateReservation as updateReservationRedux } from 'Checkout/Redux/Slices/CartSlice'
import { clearSearch, searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { detailsClear, detailsSetData } from 'Checkout/Redux/Actions/Details'
import { paymentSetData } from 'Checkout/Redux/Actions/Payment'
import { useCurrentReservation } from 'Checkout/Hooks/useCurrentReservation'

export const useCart = () => {
  const dispatch = useDispatch()
  const reservation = useCurrentReservation()
  const reservations = useSelector(state => state.cart.reservations)
  const { cartRedirect, detailsRedirect, searchRedirect, sitesRedirect } = useRedirects()

  const attachReservation = formValues => {
    const newReservation = buildReservation(formValues)

    dispatch(addReservation(newReservation))
    clearStore()
  }

  const buildReservation = values => {
    if (!values) return reservation

    const { customFields, productsPurchased, specialRequests, vehicle } = values
    const reservationCopy = {
      ...reservation,
      extras: {
        ...reservation.extras,
        productsPurchased,
      },
    }

    return {
      ...reservationCopy,
      customFields: customFields?.map(({ id, value, ...rest }) => ({
        answer: value || rest.answer || '',
        customFieldId: id || rest.customFieldId,
      })),
      inProgress: false,
      specialRequests,
      vehicle,
    }
  }

  const clearStore = () => {
    dispatch(clearSearch())
    dispatch(detailsClear())
    dispatch(paymentSetData({ isSiteLock: false, siteLockFee: null }))
  }

  const clearWithCartRedirect = () => {
    clearStore()
    dispatch(setEditingReservationId(''))
    cartRedirect()
  }

  const fillCurrentReservationFromCart = (nanoId, { exceptSearchFilter = [], withRedirectTo = 'search' }) => {
    if (withRedirectTo === 'cart') return clearWithCartRedirect()

    const reservation = reservations.find(reservation => reservation.nanoId === nanoId)
    const { customFields, data, dayUse, extras, specialRequests, vehicle } = reservation
    const { rate, site } = data
    const { filters: { maxLength, maxWidth }, guests, isSiteLock, productsPurchased } = extras
    const accommodation = dayUse ? 'daily' : 'overnight'
    const searchFilterData = { accommodation, dayUse, rate, rigLength: maxLength, rigWidth: maxWidth, site }

    dispatch(setEditingReservationId(nanoId))
    dispatch(paymentSetData({ isSiteLock }))
    dispatch(searchFilterUpdate(cloneWithoutKeys(searchFilterData, exceptSearchFilter)))
    dispatch(detailsSetData({
      customFields: customFields.map(({ answer, customFieldId }) => ({ id: customFieldId, value: answer })),
      guests,
      productsPurchased,
      specialRequests,
      vehicle,
    }))

    const redirects = { details: detailsRedirect, search: searchRedirect, sites: sitesRedirect }
    if (redirects[withRedirectTo]) redirects[withRedirectTo](reservation)
  }

  const removeReservation = nanoId => {
    dispatch(removeReservationRedux({ nanoId }))
  }

  const updateReservation = (formValues, options = { keepStore: false }) => {
    const reservation = buildReservation(formValues)

    dispatch(updateReservationRedux({ reservation, keepStore: options.keepStore }))
    if (!options.keepStore) clearStore()
  }

  return { attachReservation, clearStore, fillCurrentReservationFromCart, removeReservation, updateReservation }
}
