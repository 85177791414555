import { useState } from 'react'
import cnames from 'classnames'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import { AlertWrapper } from 'Shared/Alert'

const AlertDismiss = ({ children, className, onDismiss, ...props }) => {
  const [visible, setVisible] = useState(true)

  const onDismissHandler = () => {
    setVisible(false)
    if (onDismiss) onDismiss()
  }

  const classes = cnames('custom', className)

  useEffectAfterMount(() => {
    setVisible(true)
  }, [children])

  return (
    <AlertWrapper {...props} className={classes} isOpen={visible} toggle={onDismissHandler}>
      {children}
    </AlertWrapper>
  )
}

export default AlertDismiss
