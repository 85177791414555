import { Input, Label } from 'reactstrap'
import FormErrorMessage from 'Shared/Formik/FormErrorMessage'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import FloatingLabelTextOnly from 'Shared/FloatingLabelTextOnly/FloatingLabelTextOnly'
import './FloatingLabelInput.scss'

const FloatingLabelInput = ({ className, inputClasses, inputRef, label, max, name, rows, showErrors, textOnly,
                              type, value }) => {
  const classes = {
    formGroup: cnames(className),
    input: cnames('control-input', inputClasses),
  }

  if (textOnly) return <FloatingLabelTextOnly className={classes.formGroup} label={label} value={value} />

  return (
    <FormGroupField className={classes.formGroup} name={name} isFloating>
      {({ field, form, invalid }) => {
        const handleOnChange = event => {
          const newValue = event.target.value

          if (newValue.length <= max) form.setFieldValue(name, newValue)
        }

        return (
          <>
            <Input {...field} className={classes.input} innerRef={inputRef} name={name} onChange={handleOnChange}
                   placeholder={label} rows={rows} type={type} invalid={invalid} />

            <Label for={name}>{label}</Label>

            {showErrors && <FormErrorMessage name={name} />}
          </>
        )
      }}
    </FormGroupField>
  )
}

FloatingLabelInput.defaultProps = {
  max: Number.MAX_SAFE_INTEGER,
}

export default FloatingLabelInput
