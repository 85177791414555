export const INITIAL_STATE_QUERY = {
  called: false,
  data: {},
  error: null,
  loading: false,
}

export const calendarQuickEditInitialState = {
  campground: {},
  inventoryHoldQuery: INITIAL_STATE_QUERY,
  formValues: {},
  reservation: {},
  reservationQuery: INITIAL_STATE_QUERY,
  sitesAvailableDuringQuery: INITIAL_STATE_QUERY,
  translates: {},
}
