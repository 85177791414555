const NavElement = ({ text, value }) => (
  <div className="element flex-row d-flex px-4">
    <div className="d-flex flex-column px-4">
      {text}

      <span className="fw-bold">{value}</span>
    </div>
  </div>
)

export default NavElement
