import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Overview from './Overview'

const Wrapper = props => {
  window.roverData = { ...window?.roverData, ...props }

  return (
    <ApolloProvider client={apolloClient}>
      <Overview {...props} />
    </ApolloProvider>
  )
}

export default Wrapper
