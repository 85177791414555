import PropTypes from 'prop-types'
import { addDateTime, today } from 'Utils/Dates'
import FormSingleDate from 'Shared/Formik/FormSingleDate'

const SingleDate = ({ children, daysToAdd, disabled, id, isFirstPayment, name, onDateChanged,
                      placeholder }) => {
  const addDaysFromToday = () => {
    const todayDate = today()
    return isFirstPayment ? todayDate : addDateTime(todayDate, { day: daysToAdd })
  }

  const isOutsideRange = day => {
    const date = addDaysFromToday()

    return day.isBefore(date)
  }

  const onDateChange = day => onDateChanged(day)

  return (
    children || (
      <FormSingleDate className="mb-0" disabled={disabled} id={`dueDate-${id}`} initialDate={addDaysFromToday()}
                      isOutsideRange={isOutsideRange} name={name} onDateChange={onDateChange}
                      placeholder={placeholder} showDefaultInputIcon showErrors small />
    )
  )
}

SingleDate.defaultProps = {
  daysToAdd: 1,
  disabled: false,
  id: null,
  isFirstPayment: false,
  name: 'dueDate',
  onDateChanged: () => {},
  placeholder: 'Due Date',
}

SingleDate.propTypes = {
  daysToAdd: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isFirstPayment: PropTypes.bool,
  name: PropTypes.string,
  onDateChanged: PropTypes.func,
  placeholder: PropTypes.string,
}

export default SingleDate
