import { Button, Modal, ModalBody } from 'reactstrap'
import PropTypes from 'prop-types'
import EmailInput from 'Shared/Formik/EmailInput'
import EmailRequiredSchema from './EmailRequiredSchema'
import FormWithValidations from 'Shared/Formik/FormWithValidations'

const IN_FULL_TITLE = 'An email address is required to send this to the guest.'
const MULTIPLE_PAYMENT_TITLE = 'Email Required for Multiple Payments'

const EmailRequiredModal = ({ handleOnSubmit, inputStyles, isInFull, isOpen }) => {
  const title = isInFull ? IN_FULL_TITLE : MULTIPLE_PAYMENT_TITLE

  return (
    <Modal className="modal-dialog-centered" id="sign-up-modal" isOpen={isOpen}>
      <ModalBody className="p-4">
        <h4 className="mt-3 mb-3 text-center">{title}</h4>

        <FormWithValidations disableRenderProps initialValues={{ email: '' }} onSubmit={handleOnSubmit}
                             validationSchema={EmailRequiredSchema}>
          <EmailInput {...inputStyles} showErrors validateWithSchema />

          <Button block className="my-3" color="salmon" size="lg" type="submit">
            Add email
          </Button>
        </FormWithValidations>

        <Button block color="secondary" size="lg" onClick={handleOnSubmit}>
          Go back
        </Button>
      </ModalBody>
    </Modal>
  )
}

EmailRequiredModal.defaultProps = {
  isInFull: true,
  inputStyles: {
    labelClasses: 'h6 text-uppercase fw-bold opacity-75',
    sizeInput: 'lg',
  },
}

EmailRequiredModal.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  inputStyles: PropTypes.shape({
    labelClasses: PropTypes.string,
    sizeInput: PropTypes.string,
  }),
  isInFull: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
}

export default EmailRequiredModal
