import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Toast as ToastReactstrap, ToastBody, ToastHeader } from 'reactstrap'
import RichText from 'Shared/RichText/RichText'
import CloseToastButton from 'Shared/CloseToastButton'

const Toast = ({ children, content, duration, icon, isOpen, title, toggle }) => {
  const [isVisible, setIsVisible] = useState(isOpen)

  useEffect(() => {
    let timeoutId

    setIsVisible(isOpen)

    if (isOpen)
      timeoutId = setTimeout(() => {
        setIsVisible(false)
        toggle()
      }, duration)

    return () => clearTimeout(timeoutId)
  }, [isOpen, toggle, duration])

  return (
    <div className="toast-container position-fixed bottom-0 start-0 p-3">
      <ToastReactstrap className="bg-light" isOpen={isVisible}>
        <ToastHeader close={<CloseToastButton onClick={toggle} />} icon={icon}>
          {title}
        </ToastHeader>

        <ToastBody>
          <RichText html={content} />
          {children}
        </ToastBody>
      </ToastReactstrap>
    </div>
  )
}

Toast.defaultProps = {
  duration: 5000,
}

Toast.propTypes = {
  duration: PropTypes.number,
}

export default Toast
