import { gql } from 'Utils/apolloClient'

const UserPaymentMethodsDataFragment = gql`
  fragment userPaymentMethodsData on User {
    bankAccountsReusable {
      accountHolder
      brand
      id
      isVerified
      lastFour
      microdepositsUrl
      routingNumber
    }
    stripeCardsReusable {
      id
      brand
      brandIconKey
      expDate
      isDefaultSource
      lastFour
    }
    name
  }`

export default UserPaymentMethodsDataFragment
