import PropTypes from 'prop-types'
import FormTextInput from './FormTextInput'

const EmailInput = ({ className, isRequired, name, onBlur, placeholder, showErrors, sizeInput, tooltip, tooltipId,
                      validate, validateWithSchema, ...props }) => {
  const validateEmail = value => {
    if (!value && !isRequired) return
    if (!value && isRequired) return 'Required'

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
      return 'Email is invalid.'

    return null
  }

  const validateToInput = validateWithSchema ? null : validate || validateEmail

  const handleBlur = event => {
    const { value } = event.target
    if (validateEmail) return onBlur(value)
  }

  return (
    <FormTextInput {...props} className={className} icon="circle-question" name={name} onBlur={handleBlur}
                              placeholder={placeholder} sizeInput={sizeInput} showErrors={showErrors}
                              tooltip={tooltip} tooltipId={tooltipId} type="email" validate={validateToInput} />
  )
}

EmailInput.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  sizeInput: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipId: PropTypes.string,
  validate: PropTypes.func,
  validateWithSchema: PropTypes.bool,
}

EmailInput.defaultProps = {
  className: '',
  isRequired: false,
  name: 'email',
  onBlur: () => {},
  placeholder: 'Email',
  showErrors: false,
  sizeInput: 'lg',
  tooltip: '',
  tooltipId: '',
  validateWithSchema: false,
  validate: null,
}

export default EmailInput
