import RecurrentActive from './RecurrentActive'
import RecurrentStopped from './RecurrentStopped'
import PropTypes from 'prop-types'
import './FlexibleNavBar.scss'

const RecurringPaymentTab = ({ billigDay, billigEndDay, billigStartDay, editSchedule, isStopped }) => (
  <div className="payments-flexible-nav-bar d-flex flex-wrap justify-content-between py-3">
    {isStopped ? (
      <RecurrentStopped {...{ billigDay, billigEndDay, billigStartDay }} />
    ) : (
      <RecurrentActive {...{ billigDay, billigStartDay, editSchedule }} />
    )}
  </div>
)

RecurringPaymentTab.defaultProps = {
  billigEndDay: '',
}


RecurringPaymentTab.propTypes = {
  billigDay: PropTypes.string.isRequired,
  billigEndDay: PropTypes.string,
  billigStartDay: PropTypes.string.isRequired,
  isStopped: PropTypes.bool.isRequired,
}

export default RecurringPaymentTab
