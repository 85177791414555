export const reportState = {
  filters: {
    from: '',
    resource: '',
    to: '',
  },
  isParamsReviewed: false,
  pagination: {
    currentPage: 1,
    perPage: 25,
  },
  report: {
    data: null,
    error: null,
    loading: false,
  },
  reportName: '',
  reportPath: '',
  slug: '',
}
