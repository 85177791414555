import cname from 'classnames'
import PropTypes from 'prop-types'
import { AlertWrapper } from 'Shared/Alert'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const MinPricingAlert = ({ className, classNameAlert, colSpan, isInvoiceTemplate, minPricing }) => {
  const classNameAlertWrapper = cname('mb-2 bg-transparent p-2', classNameAlert)
  const invoiceText = isInvoiceTemplate ? 'recurring invoice template' : 'invoice'

  return (
    <tr>
      <td className={className} colSpan={colSpan}>
        <AlertWrapper className={classNameAlertWrapper} color="info-outline-filled">
          <FontAwesomeIcon className="text-blue me-2" icon="circle-exclamation" size="lg" />

          Add more than {minPricing} in order to schedule this {invoiceText} for payment.
        </AlertWrapper>
      </td>
    </tr>
  )
}

MinPricingAlert.defaultProps = {
  className: '',
  classNameAlert: '',
  colSpan: 3,
  isInvoiceTemplate: false,
}

MinPricingAlert.propTypes = {
  className: PropTypes.string,
  classNameAlert: PropTypes.string,
  colSpan: PropTypes.string,
  isInvoiceTemplate: PropTypes.bool,
  minPricing: PropTypes.string.isRequired,
}

export default MinPricingAlert
