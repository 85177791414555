import { useEffect } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import AlertWrapper from 'Shared/Alert/AlertWrapper/AlertWrapper'
import BalanceQuery from './Query/BalanceQuery'
import Balance from './Cards/Balance'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Loading from 'Shared/Loading'
import NextPayment from './Cards/NextPayment'
import Payments from './Cards/Payments'
import Flexible from './Cards/Flexible'

const Wrapper = ({ isAccount, isAdmin, isCalendarView, icon, helpText, reservationId, slug }) => {
  const { isDesktopView } = useWindowDimensions(768)
  const [getBalance, { called, data, error, loading }] = useLazyQueryNoCache(BalanceQuery)
  const balanceData = data?.campground?.reservation
  const refunds = balanceData?.refundsInstallments
  const hasRefundsPending = balanceData?.hasRefundsPending
  const { balance, installmentPendingToPay, ...payments } = balanceData || {}
  const isActive = balanceData?.schedule?.isActive
  const showRecurringSchedule = payments.installmentsCount > 0 && isActive && !isCalendarView && isDesktopView

  useEffect(() => {
    getBalance({ variables: { isEngineAdmin: isAdmin, slug, uuid: reservationId } })
  }, [])

  if (!called || loading) return <Loading className="d-flex justify-content-center py-5" />

  if (error) return <AlertWrapper color="danger text-danger">{error.message}</AlertWrapper>

  return (
    <>
      <div className="row g-2 g-md-3">
        <Balance {...{ balance, hasRefundsPending, isCalendarView, refunds }} />

        {installmentPendingToPay && (
          <NextPayment isAccount={isAccount} isCalendarView={isCalendarView} isDesktopView={isDesktopView}
                       nextPayment={installmentPendingToPay} />
        )}

        {showRecurringSchedule ? (
          <Flexible isAccount={isAccount} payment={payments} />
        ) : (
          <Payments payments={payments} />
        )}
      </div>

      {helpText && (
        <div className="text-muted mt-2">
          <FontAwesomeIcon icon={icon} className="me-2" />

          <span>{helpText}</span>
        </div>
        )
      }

    </>
  )
}

Wrapper.defaultProps = {
  isAccount: false,
  isAdmin: false,
  isCalendarView: false,
  icon: 'circle-info',
  helpText: '',
  reservationId: '',
  slug: '',
}

export default Wrapper
