import cnames from 'classnames'
import PropTypes from 'prop-types'
import CollapseWrapper from 'Shared/Collapse/CollapseWrapper'
import RichText from './RichText/RichText'

const InvoiceTermsAndConditions = ({ className, isOpenByDefault, showIcon, text, title }) => {
  const classes = cnames('mb-3 p-3 border shadow-sm rounded', className)

  return (
    <CollapseWrapper className={classes} isOpenByDefault={isOpenByDefault}>
      <CollapseWrapper.Header {...{ showIcon, title }} />

      <CollapseWrapper.Content className="mt-2 invoice-tos-content">
        <RichText html={text} tag="p" />
      </CollapseWrapper.Content>
    </CollapseWrapper>
  )
}

InvoiceTermsAndConditions.defaultProps = {
  isOpenByDefault: false,
  showIcon: true,
  text: '',
  title: 'Invoice Terms & Conditions',
}

InvoiceTermsAndConditions.propTypes = {
  isOpenByDefault: PropTypes.bool,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default InvoiceTermsAndConditions
