import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from 'Utils/Dates'
import { useStayDates } from 'Checkout/Hooks/useStayDates'

export const useCurrentReservation = () => {
  const { dayUse, rate, rigLength, rigWidth, site, siteType } = useSelector(state => state.search)
  const { isSiteLock } = useSelector(state => state.payment)
  const { customFields, guests, productsPurchased } = useSelector(state => state.details)
  const marketplace = useSelector(state => state.campground.marketplace.isMarketplace)
  const { checkIn, checkOut } = useStayDates()
  const from = useMemo(() => formatDate(checkIn), [checkIn])
  const to = useMemo(() => formatDate(checkOut), [checkOut])

  const data = { rate, site, siteType }
  const filters = { maxLength: rigLength, maxWidth: rigWidth }
  const extras = {
    filters,
    guests,
    isSiteLock,
    marketplace,
    productsPurchased,
    siteId: site?.id,
  }

  return { customFields, dayUse, extras, data, from, inProgress: true, rateId: rate?.id, to }
}
