import RefundModalRow from '../RefundModalRow'

const ModalContentTotals = ({ amount, camperProcessingFeeMaxToRefund, items, platformFee, subtotal, tax,
                             total }) => (
  <div className="modal-totals p-2">
    <div className="modal-items">
      { items?.map((item, index) => {
        const title = index === 0 ? 'Reservation Payment' : item?.name

        return (
          <RefundModalRow displayValue={item?.subtotal?.display} title={title}
                          textStyle="text-muted text-sm text-italic" key={item.id} />
          )
        })
      }
    </div>

    <hr className="m-2 text-muted" />

    <RefundModalRow displayValue={subtotal?.display} title="Subtotal" textStyle="text-sm text-bold" />

    <RefundModalRow displayValue={tax?.display} title="Tax" textStyle="text-sm" />

    {amount?.camperProcessingFee > 0 && (
      <RefundModalRow displayValue={camperProcessingFeeMaxToRefund?.display} title="Processing Fee"
                      textStyle="text-sm" />
    )}

    {platformFee?.amount > 0 && (
      <RefundModalRow displayValue={platformFee?.display} title="Platform Fee" textStyle="text-sm" />
    )}

    <RefundModalRow displayValue={total?.display} title="Total" textStyle="text-md text-bold" />
  </div>
)

export default ModalContentTotals
