import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Toast, ToastBody } from 'reactstrap'
import CloseToastButton from 'Shared/CloseToastButton'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import RichText from 'Shared/RichText/RichText'

const SuccessErrorToast = ({ children, className, content, duration, handleClose, isError, isOpen,
                             isSuccess, toastAlingment }) => {
  const [isOpenToast, setIsOpenToast] = useState(isOpen)
  const errorRef = useRef(null)
  if (!isError && !isSuccess) return null

  const icon = isSuccess ? 'circle-check' : 'circle-exclamation'
  const id = isSuccess ? 'success-toast' : 'error-toast'

  const classes = {
    container: cnames('toast-container w-100', toastAlingment),
    body: cnames('toast-body d-flex align-items-center justify-content-between shadow-sm border rounded p-3',
                 { 'border-danger': isError }, { 'border-success': isSuccess }, className),
    icon: cnames('me-2', { 'text-danger': isError }, { 'text-success': isSuccess }),
  }

  const toggle = () => setIsOpenToast(prevState => !prevState)

  useEffect(() => {
    let timeoutId

    if (isOpen)
      timeoutId = setTimeout(() => {
        handleClose()
        toggle()
      }, duration)

    return () => clearTimeout(timeoutId)
  }, [isOpenToast, toggle, duration])

  if (!isError && !isSuccess) return null

  useEffect(() => {
    if (isOpen) errorRef.current.scrollIntoView()
  }, [isOpen])

  return (
    <div className={classes.container} ref={errorRef}>
      <Toast className="bg-light w-100" id={id} isOpen={isOpenToast}>
        <ToastBody className={classes.body}>
          <FontAwesomeIcon className={classes.icon} icon={icon} size="lg" />

          <div className="flex-grow-1 text-start">
            {content && <RichText html={content} />}

            {children}
          </div>

          <CloseToastButton className="ms-2" onClick={toggle} />
        </ToastBody>
      </Toast>
    </div>
  )
}

SuccessErrorToast.defaultProps = {
  children: null,
  className: '',
  content: '',
  duration: 5000,
  handleClose: () => {},
  isError: false,
  isOpen: false,
  isSuccess: false,
  toastAlingment: 'start-0 end-0',
}

SuccessErrorToast.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
  duration: PropTypes.number,
  handleClose: PropTypes.func,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSuccess: PropTypes.bool,
  toastAlingment: PropTypes.string,
}

export default SuccessErrorToast
