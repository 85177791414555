import { moment, today } from 'Utils/Dates'

export const getButtonText = date => {
  if (moment(date).isAfter(today())) return 'Early Check-In'

  return 'Check-In'
}

export const getLeftButtonText = (balance, date) => {
  const hasBalanceDue = balance.due?.amount > 0
  if (hasBalanceDue) return 'Check-In & Collect'

  return getButtonText(date)
}

export const getRightButtonText = (balance, date) => {
  const hasBalanceDue = balance.due?.amount > 0
  if (hasBalanceDue) return getButtonText(date)

  return 'No, Go Back'
}
