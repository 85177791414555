import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { fillArrayWithMinToMax } from 'Utils/Arrays'
import FormSelect from 'Shared/Formik/FormSelect'
import Payment from 'Shared/MultiplePayments/Payment/Payment'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'
import TooltipWithTarget from 'Shared/TooltipWithTarget'
import { TotalPayment } from 'Shared/MultiplePayments/'

const AMOUNT_INFO = "To edit this amount, click the 'Edit Amount' button to the right in the cart."
const DUE_DATE = 'To set reservation payment as Due Upon Check-In, adjust the date here, then click Proceed to First Payment.'

const PaymentsSchedule = ({ maxPayments, minPayments, onChangeNumberPayments, payments, resetNextDates,
                            TitleComponent, totalDisabled, totalPayments }) => (
  <>
    <TitleComponent />

    <FormSelect className="col-sm-6 p-0 mb-4" defaultValue="1" isNormalSelect isNumeric
                label="How many payments?" name="numberOfPayments" onChange={onChangeNumberPayments}>
      <SelectOptions data={fillArrayWithMinToMax({ max: maxPayments, min: minPayments })} />
    </FormSelect>

    <hr />

    {totalPayments > 0 && (
      <>
        <div className="d-none d-md-block mt-md-4">
          <div className="row">
            <div className="col-md-2" />

            <div className="col-md-5">
              <span>Amount</span>

              <TooltipWithTarget icon="circle-question" iconClass="text-muted ms-1" iconSize="sm"
                                 id="amount-info-tooltip">
                {AMOUNT_INFO}
              </TooltipWithTarget>
            </div>

            <div className="col-md-5">
              <span>Due Date</span>

              <TooltipWithTarget icon="circle-question" iconClass="text-muted ms-1" iconSize="sm"
                                 id="due-date-info-tooltip">
                {DUE_DATE}
              </TooltipWithTarget>
            </div>
          </div>
        </div>

        <FieldArray className="mt-4 mt-md-0" name="payments" render={() => (
          <>
            {payments.map((payment, i) => (
              <div className="split-payments" key={`payment_${payment.id}`}>
                <Payment {...payment} name={`payments.${i}`} nextPayment={payments[i + 1]}
                          previousPayment={payments[i - 1]} resetNextDates={resetNextDates}
                          totalPayments={totalPayments} />
              </div>
            ))}
          </>
        )} />
      </>
    )}

    <TotalPayment isDisabled={(totalPayments < minPayments) || totalDisabled} />
  </>
)

PaymentsSchedule.defaultProps = {
  maxPayments: 12,
  minPayments: 1,
  totalDisabled: false,
  TitleComponent: null,
}

PaymentsSchedule.propTypes = {
  maxPayments: PropTypes.number,
  minPayments: PropTypes.number,
  onChangeNumberPayments: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  TitleComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.any),
  ]),
  totalDisabled: PropTypes.bool,
  totalPayments: PropTypes.number.isRequired,
}

export default PaymentsSchedule
