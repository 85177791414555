import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'reactstrap'
import { PaymentMethodActions, PaymentMethodsContext } from 'Shared/PaymentMethods'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import CheckPaymentFormSchema from './CheckPaymentFormSchema'
import CheckPaymentLabel from './CheckPaymentLabel'
import FormTextArea from 'Shared/Formik/FormTextArea'
import FormTextInput from 'Shared/Formik/FormTextInput'

const Label = <div>
  MEMO{' '}
  <small>(OPTIONAL)</small>
</div>

const initalFormValues = { checkNumber: '', memo: '', source: 'check' }

const CheckPayment = ({ children, date, seller }) => {
  const { clearMutationErrors, displayAmount, displayOptions, mutation, onSubmit, toggle,
          totalBeforeProcessing } = useContext(PaymentMethodsContext)

  return (
    <FormWithValidations className="payment-form" disableRenderProps formId="payment-form-check"
                         initialValues={initalFormValues} onChangeValues={clearMutationErrors} onSubmit={onSubmit}
                         scrollToError validationSchema={CheckPaymentFormSchema}>
      {displayAmount && <h5>Amount {totalBeforeProcessing.display}</h5>}

      <h4>Check Information</h4>

      <Row>
        <CheckPaymentLabel label="DATED ON:" text={date} />

        <CheckPaymentLabel label="PAY TO THE ORDER OF:" text={seller} />
      </Row>

      <FormTextInput label="CHECK NUMBER" name="checkNumber"
                     placeholder="Find this number at the top right of the check." />

      <FormTextArea label={Label} name="memo" />

      {children}

      {displayOptions && (
        <PaymentMethodActions confirmText="Pay Invoice Now" loading={mutation.loading} onCancel={toggle} />
      )}
    </FormWithValidations>
  )
}

CheckPayment.defaultProps = {
  seller: '',
}

CheckPayment.propTypes = {
  date: PropTypes.string.isRequired,
  seller: PropTypes.string,
}

export default CheckPayment
