import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cnames from 'classnames'
import { detailsToggleValidation } from 'Checkout/Redux/Actions/Details'
import { searchToggleDiscardAlert, searchToggleModifyNotSaved } from 'Checkout/Redux/Actions/Search'
import { useCart } from 'Checkout/Hooks/useCart'
import { routesHandler } from 'Checkout/Routes/routesHandler'
import { Badge } from 'reactstrap'
import AlertModal from 'Shared/Alert/AlertModal/AlertModal'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CartButton = () => {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [showAlert, setShowAlert] = useState(false)
  const { clearStore, updateReservation } = useCart()
  const { editingReservationId, paymentProcessing } = useSelector(state => state.cart)
  const { newRateNeeded, newSiteNeeded } = useSelector(state => state.search)
  const isDetailError = useSelector(state => state.details.isError)
  const reservationsCount = useSelector(state => state.cart.reservations.length)
  const disabled = reservationsCount === 0 || paymentProcessing
  const iconClassName = cnames({ 'text-theme-primary': !disabled })
  const isDetailsPage = location.pathname === routesHandler.details.path
  const titleAnchor = disabled ? 'Add a reservation to visit your Cart.' : ''

  const toggle = () => setShowAlert(prevState => !prevState)

  const openAlert = () => {
    if (!reservationsCount) return
    if (editingReservationId && isDetailError) return dispatch(searchToggleModifyNotSaved())
    if (newRateNeeded || newSiteNeeded) return dispatch(searchToggleDiscardAlert())
    if (editingReservationId && isDetailsPage) return dispatch(detailsToggleValidation())

    toggle()
  }

  const handleClick = () => {
    if (editingReservationId) updateReservation()

    clearStore()
    toggle()
    push(routesHandler.cart.path)
  }

  return (
    <>
      <Anchor className="cart-button ms-4 position-relative text-muted" disabled={disabled} id="cart-button"
              onClick={openAlert} title={titleAnchor}>
        <FontAwesomeIcon className={iconClassName} icon="cart-shopping" size="lg" />

        {!disabled && (
          <Badge className="position-absolute top-0 start-100 translate-middle rounded-pill" color="theme-primary">
            {reservationsCount}
          </Badge>
        )}
      </Anchor>

      <AlertModal show={showAlert} size="md">
        <p className="fw-bold">Discard changes?</p>

        <p>This reservation has not been added to the cart. Any changes you have made may not be saved.</p>

        <div className="d-flex justify-content-end">
          <Anchor className="btn btn-outline-secondary me-2" onClick={toggle}>Cancel</Anchor>

          <Anchor className="btn btn-theme-primary" onClick={handleClick}>Go to cart</Anchor>
        </div>
      </AlertModal>
    </>
  )
}

export default CartButton
