import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { resetLastCard } from 'Admin/Store/Slices/paymentMethodsSlice'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import AlertDismiss from './AlertDismiss/AlertDismiss'
import RichText from 'Shared/RichText/RichText'

const SuccessErrorAlert = ({ className, error, outline, success }) => {
  const dispatch = useDispatch()
  const lastAdded = useSelector(state => state.paymentMethods?.stripeCardsReusable?.lastAdded)

  if (!error && !success) return null

  const icon = success ? 'circle-check' : 'circle-exclamation'
  const id = success ? 'success-alert' : 'error-alert'

  const classes = {
    color: cnames({ danger: error && !outline }, { success: success && !outline },
                  { 'danger-outline': error && outline }, { 'success-outline': success && outline }),
    container: cnames('shadow-sm fw-bold', className),
    icon: cnames('me-2', { 'text-danger': error }, { 'text-success': success }),
  }

  const onDismiss = () => {
    if (lastAdded) return dispatch(resetLastCard())
  }

  return (
    <AlertDismiss className={classes.container} color={classes.color} id={id} onDismiss={onDismiss}>
      <FontAwesomeIcon className={classes.icon} icon={icon} size="lg" />

      <RichText tag="span" html={success || error} />
    </AlertDismiss>
  )
}

SuccessErrorAlert.defaultProps = {
  outline: true,
}

SuccessErrorAlert.propTypes = {
  outline: PropTypes.bool,
}

export default SuccessErrorAlert
