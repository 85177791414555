import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'Shared/Checkbox/Checkbox'

const FilterOption = ({ isChecked, option, optionsDropdown, selectFilter, setDefaultOptions }) => {
  const { name, value } = option

  useEffect(() => {
    if (!optionsDropdown.length) setDefaultOptions(value)
  }, [])

  return (
    <Checkbox className="fw-bold mb-3 mb-md-2" handleClick={selectFilter} isChecked={isChecked}
              message={name} name={value} />
  )
}

FilterOption.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  selectFilter: PropTypes.func.isRequired,
  setDefaultOptions: PropTypes.func.isRequired,
}

export default FilterOption
