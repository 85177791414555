import cnames from 'classnames'
import PropTypes from 'prop-types'

const PricingItem = ({ className, infoText, itemName, rowClassName, value }) => {
  const classes = cnames('d-flex justify-content-between mb-1', className)

  return (
    <div className={classes}>
      <div>
        <span className={rowClassName}>{itemName}</span>

        {infoText && (<span className="fst-italic ms-2 smaller text-muted">{infoText}</span>)}
      </div>

      {value && <span className={rowClassName}>{value}</span>}
    </div>
  )
}

PricingItem.defaultProps = {
  className: '',
  rowClassName: 'h5',
  value: '',
}

PricingItem.propTypes = {
  className: PropTypes.string,
  itemName: PropTypes.string.isRequired,
  rowClassName: PropTypes.string,
  value: PropTypes.string,
}

export default PricingItem
