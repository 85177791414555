import { FieldArray } from 'formik'
import Checkbox from 'Shared/Checkbox/Checkbox'

const FormArrayCheckboxes = ({ checkboxClassName, className, labelClassname, name, options }) => (
  <FieldArray className={className} name={name}>
    {({ form, push, remove }) => {
      const { values } = form

      const setValue = target => {
        const { checked, name: value } = target
        const index = values[name].indexOf(value)

        if (checked && index < 0)
          push(value)
        else if (index >= 0)
          remove(index)
      }

      return (
        options.map(({ label, value }) => {
          const isChecked = values[name].includes(value)

          return (
            <div className={checkboxClassName} key={value}>
              <Checkbox handleClick={e => setValue(e.target)} isChecked={isChecked}
                        labelClassname={labelClassname} name={value} message={label} />
            </div>
          )
        })
      )
    }}
  </FieldArray>
)

export default FormArrayCheckboxes
