import { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import CollapseHeader from 'Shared/Cart/Components/CollapseHeader'

const CollapseItem = ({ children, title, value }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <CollapseHeader className="px-0" handleToggle={toggle} isOpen={isOpen} title={title}
                      value={value} />

      <Collapse isOpen={isOpen}>{children}</Collapse>
    </>
  )
}

CollapseItem.defaultProps = {
  title: '',
  value: '',
}

CollapseItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
}

export default CollapseItem
