import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './EmptyData.scss'

const EmptyData = ({ actionIcon, actionText, onClick, subtitle, title, titleIcon }) => (
  <div className="empty-data bg-light flex-centered flex-column py-4">
    {titleIcon && <FontAwesomeIcon className="empty-data--icon mb-2" icon={titleIcon} size="lg" />}

    <h5 className="empty-data--title mb-2">{title}</h5>

    <span className="empty-data--subtitle mb-3 fw-normal fst-italic">
      {subtitle}
    </span>

    <Button className="col-12 col-md-auto my-3 my-md-0" color="blue-dark" onClick={onClick}>
      {actionIcon && <FontAwesomeIcon icon={actionIcon} className="me-2" />}

      {actionText}
    </Button>
  </div>
)

EmptyData.defaultProps = {
  actionIcon: 'plus',
  titleIcon: 'circle-info',
}

EmptyData.propTypes = {
  actionIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actionText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default EmptyData
