import * as Yup from 'yup'
import { isPastDate } from 'Utils/Dates'

const messageRequired = 'Required field.'
const messageMin = 'Min value is 1'

Yup.addMethod(Yup.array, 'isDateLessThanNextDate', function ({ errorMessage, isRequired, useInvoices }) {
  return this.test('test-is-date-less-than-next-date', errorMessage, function () {
    if (!isRequired || !useInvoices) return true

    const { createError, parent: { payments }, path } = this

    const isCorrect = payments.reduce((acc, payment, index) => {
      if (!acc || !payment?.dueOn) return false

      const nextPayment = payments[index + 1]
      if (!isPastDate(payment.dueOn, nextPayment?.dueOn)) return false

      return true
    }, true)

    return isCorrect || createError({ message: errorMessage, path })
  })
})

const reservationSchema = ({ isTotalRequired, isChronologicalOrderRequired = false, useInvoices = true }) => (
  Yup.object().shape({
    numberOfPayments: Yup.number()
      .min(1, messageMin)
      .required(messageRequired),

    payments: Yup.array().of(
      Yup.object().shape({
        dueOn: Yup.string()
          .when({
            is: () => useInvoices,
            then: Yup.string()
              .required('Required Due Date')
              .nullable(),
          }),

        total: Yup.number()
          .when({
            is: () => isTotalRequired && useInvoices,
            then: Yup.number()
              .min(1, messageMin)
              .required(messageRequired),
          }),
      })
    ).isDateLessThanNextDate({ errorMessage: 'Invoice schedule must be in chronological order',
                               isRequired: isChronologicalOrderRequired, useInvoices }),

    total: Yup.number()
      .when({
        is: () => isTotalRequired,
        then: Yup.number()
          .min(0, messageMin)
          .required(messageRequired),
      }),
  })
)

export default reservationSchema
