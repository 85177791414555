import { gql } from 'Utils/apolloClient'
import translatesFragment from 'Shared/Queries/TranslateFragment'

const reservationQuery = gql`
  query ReservationQuery($slug: String!, $uuid: ID!, $keys: [TranslatesKeysAttributes!]!) {
    campground(slug: $slug) {
      closeSeasonDates
      reservation(uuid: $uuid) {
        createdAt
        from
        hasTripInsurance
        rate {
          id
        }
        site {
          id
          name
        }
        to
        tripInsurancePolicy {
          cannotCancel
          tripInsuranceNightLimit
        }
      }
    }

    translates {
      ...translateFragment
    }
  }

  ${translatesFragment}
`

export default reservationQuery
