import { forwardRef } from 'react'
import cnames from 'classnames'
import { Input, Label } from 'reactstrap'
import FormErrorMessage from 'Shared/Formik/FormErrorMessage'
import FormGroupField from 'Shared/Formik/FormGroupField'
import './FloatingLabelInput.scss'

const FloatingLabelInputRef = forwardRef(({ className, inputClasses, label, max, name, onChangeInput, showErrors,
                                            type, value }, ref) => {
  const classes = {
    formGroup: cnames(className),
    input: cnames('control-input', inputClasses),
  }

  return (
    <FormGroupField className={classes.formGroup} name={name} isFloating>
      {({ form, invalid }) => {
        const handleOnChange = event => {
          const newValue = event.target.value
          if (newValue.length <= max) {
            if (onChangeInput) return onChangeInput(newValue)

            form.setFieldValue(name, newValue)
          }
        }

        return (
          <>
            <Input className={classes.input} name={name} onChange={handleOnChange} placeholder={label}
                   ref={ref} type={type} value={value} invalid={invalid} />

            <Label for={name}>{label}</Label>

            {showErrors && <FormErrorMessage name={name} />}
          </>
        )
      }}
    </FormGroupField>
  )
})

FloatingLabelInputRef.defaultProps = {
  max: Number.MAX_SAFE_INTEGER,
}

FloatingLabelInputRef.displayName = FloatingLabelInputRef

export default FloatingLabelInputRef
