import { useEffect } from 'react'
import cnames from 'classnames'
import connect from 'Admin/Store/connect'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import { getSitesAvailableDuring } from 'Shared/CalendarQuickEdit/Store/CalendarQuickEditSlice'
import Select from 'react-select'

const SitesSelector = ({ called, data, error, from, getSitesAvailableDuring, loading, reservation, to }) => {
  const { isHold, slug } = window.roverData
  const INPUT_NAME = isHold ? 'inventory_hold_form[site_id]' : 'reservation[site_id]'
  const isLoading = !called || loading
  const { site } = reservation
  const selectClassname = cnames('mt-3', { 'loading-sites': isLoading })
  const { sitesAvailableDuring, rateSitesAvailable } = data
  const defaultOption = { label: site.name, value: site.id }
  const rateOptions = (rateSitesAvailable || []).map(({ name, id }) => ({ label: name, value: id }))
  const siteOptions = (sitesAvailableDuring || []).map(({ name, id }) => ({ label: name, value: id }))
  const optionsGrouped = [{ label: 'Rate Available Sites', options: rateOptions },
                          { label: 'All Available Sites', options: siteOptions }]
  siteOptions.push(defaultOption)

  useEffect(() => {
    const { from, to } = reservation || {}
    getSites(from, to)
  }, [])

  useEffectAfterMount(() => {
    if (from && to) getSites(from, to)
  }, [from, to])

  const getSites = (from, to) => {
    const { rate } = reservation || {}

    getSitesAvailableDuring({ variables: { from, rateId: rate?.id || '', slug, to } })
  }

  if (error) return <p className="mt-3 text-center text-danger">Error</p>

  return (
    <Select className={selectClassname} defaultValue={defaultOption} id={INPUT_NAME} isDisabled={isLoading}
            isLoading={isLoading} name={INPUT_NAME} options={optionsGrouped} classNamePrefix="react-select" />
  )
}

const mapDispatchToProps = { getSitesAvailableDuring }

const mapStateToProps = state => {
  const { calendarQuickEdit } = state
  const { formValues, reservation, sitesAvailableDuringQuery } = calendarQuickEdit
  const { called, data, error, loading } = sitesAvailableDuringQuery
  const { from, to } = formValues

  return { called, data, error, from, loading, reservation, to }
}

export default connect({ mapDispatchToProps, mapStateToProps })(SitesSelector)
