import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from '../Queries/userPaymentMethodsDataFragment'

const paymentMethodUpdateMutation = gql`
  mutation PaymentMethodUpdate($paymentMethodId: ID!) {
    paymentMethodUpdate(input: { paymentMethodId: $paymentMethodId }) {
      paymentMethod {
        brand
        lastFour
      }
      user {
        id
        ...userPaymentMethodsData
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default paymentMethodUpdateMutation
