import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'
import NavElement from './NavElement'

const RecurrentActive = ({ billigDay, billigStartDay, editSchedule }) => (
  <>
    <div className="element d-block px-4 mt-3">
      <FontAwesomeIcon className="fb-color me-2" icon="info-circle" />

      <span> This Reservation is part of a recurring schedule</span>
    </div>

    <NavElement text="Billing Start Date" value={billigStartDay} />

    <NavElement text="Billing Day" value={billigDay} />

    <div className="element flex-row d-flex px-4">
      <Link className="edit-link fw-bold mt-2" to={editSchedule} isExternal>
        Edit Template

        <FontAwesomeIcon className="ms-2" icon="pen-to-square" />
      </Link>
    </div>
  </>
)

export default RecurrentActive
