import PropTypes from 'prop-types'
import './MapLegend.scss'

const MapLegend = ({ showUnavailableOnline }) => (
  <div className="map-legend flex-wrapp d-flex aligin-items-center px-4 py-2 rounded-top">
    <div className="d-flex align-items-center fw-bold me-4">
      <span className="d-lg-block d-none">Color Key</span>
      <span className="d-lg-none">KEY</span>
    </div>

    <div className="d-flex align-items-center me-4">
      <span className="d-lg-block d-none">Available</span>
      <span className="d-lg-none">Avail</span>
      <div className="map-legend--square available ms-1 rounded my-1" />
    </div>

    {showUnavailableOnline && (
      <div className=" d-flex align-items-center me-4">
        <span className="d-lg-block d-none">Unavailable online</span>
        <span className="d-lg-none">UO</span>
        <div className="map-legend--square unavailable-online ms-1 rounded my-1" />
      </div>
    )}

    <div className="d-flex align-items-center">
      <span className="d-lg-block d-none">Unavailable</span>
      <span className="d-lg-none">Unavail</span>
      <div className="map-legend--square unavailable ms-1 rounded my-1" />
    </div>
  </div>
)

MapLegend.defaultProps = {
  showUnavailableOnline: false,
}

MapLegend.propTypes = {
  showUnavailableOnline: PropTypes.bool,
}

export default MapLegend
