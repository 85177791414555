import { ContentFooter, RowPricingElement } from 'Shared/Cart/Components'
import { classes } from 'Shared/Cart/Constants'

const TotalsContainer = ({ helpTextTotal, isTaxIncluded, taxName, total, totalItems, totalRefunded }) => (
  <div className={`mt-3 ${classes.container}`}>
    <div className="d-flex flex-column gap-1 mb-3">
      {totalItems.map(({ helpText, key, value }) => value && (
        !(isTaxIncluded && key === taxName) && (
          <RowPricingElement helpText={helpText} key={key} description={key} price={value} />
        )
      ))}
    </div>

    <hr className="m-0 mb-3 text-dark bg-dark" />

    <ContentFooter helpText={helpTextTotal} name="TOTAL" value={total?.displayWithCode} />

    {totalRefunded?.amount > 0 && (
      <ContentFooter name="Refunded" value={totalRefunded?.displayWithCode} />
    )}
  </div>
)

export default TotalsContainer
