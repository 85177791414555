import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './Balance.scss'

const Flexible = ({ isAccount, payment }) => {
  const date = payment.schedule?.dayOrdinalized
  const editLink = payment.links?.scheduleEditUrl
  const amount = payment.schedule?.amount?.display

  return (
  <div className="balance col-sm-6 col-lg-4 col-xl-3">
    <div className="recurring-card border rounded shadow-sm p-2 h-100">
      <div className="d-flex justify-content-between">
        <p className="fw-bold text-uppercase mb-1 ms-1">
          <FontAwesomeIcon className="me-1 text-muted" icon="arrows-rotate" />
          Recurring Schedule
        </p>

        {!isAccount && (
          <a className="installment-link text-decoration-none" href={editLink}>
            <FontAwesomeIcon className="text-muted" icon="up-right-from-square" />
          </a>
        )}
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <small className="text-muted fst-italic">Amount</small>
          <p className="m-0">{amount}</p>
        </div>

        <div className="me-3">
          <small className="text-muted fst-italic">Billing Day</small>
          <p className="m-0 fst-italic">{date}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Flexible
