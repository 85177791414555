import cnames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { Row, Col } from 'reactstrap'
import './Banner.scss'

const Banner = ({ children, color, href, icon, textButton, title }) => {
  const bannerColor = `banner--${color}`
  const buttonColor = `${bannerColor}__button`
  const titleColor = `${bannerColor}__title`
  const classes = {
    baseStyles: cnames('alert banner mt-2', bannerColor),
    buttonStyles: cnames('btn mt-2 my-sm-auto text-bold', buttonColor),
    titleStyles: cnames('mb-2 mb-sm-0 ms-sm-0 ms-4 me-1 ps-sm-0 ps-2 text-bold', titleColor),
  }

  return (
    <div className={classes.baseStyles}>
      <Row xs="1" sm="2">
        <Col className="align-items-sm-center align-items-start d-flex" xs="11" sm="9">
          {icon && <FontAwesomeIcon className="me-3" icon={icon} size="lg" />}

          <div className="d-flex d-sm-block flex-column flex-sm-row mb-0">
            {title && (
              <span className={classes.titleStyles}>
                {title}
              </span>
            )}

            <span>
              {children}
            </span>
          </div>
        </Col>

        <Col sm="3" className="d-flex justify-content-end col-sm-3 mt-2 mt-sm-0">
          <a className={classes.buttonStyles} href={href}>
            {textButton}
          </a>
        </Col>
      </Row>
    </div>
  )
}

Banner.defaultProps = {
  color: 'info',
  icon: '',
  title: '',
}

Banner.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default Banner
