import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { isEmpty } from 'Utils/objects'
import CustomFieldsForm from './CustomFieldsForm/CustomFieldsForm'
import PageSubtitle from 'Shared/PageSubtitle'

const MoreInformation = ({ customFieldsData, isPos, loading, setFieldValue, showDivider }) => {
  const [customFields, setCustomFields] = useState([])

  const classNameContainer = cnames('more-information mb-4', { 'skeleton-loading': loading })

  useEffect(() => {
    setCustomFields(customFieldsData)
    setFieldValue('customFields', customFieldsData)
  }, [customFieldsData])

  if (isEmpty(customFields)) return null

  return (
    <>
      <div className={classNameContainer}>
        <PageSubtitle text="More Information" />
        <CustomFieldsForm customFields={customFields} isPos={isPos} />
      </div>

      {showDivider && <hr />}
    </>
  )
}

MoreInformation.defaultProps = {
  customFieldsData: [],
  setFieldValue: () => {},
  showDivider: false,
}

MoreInformation.propTypes = {
  customFieldsData: PropTypes.arrayOf(PropTypes.shape({})),
  setFieldValue: PropTypes.func,
  showDivider: PropTypes.bool,
}

export default memo(MoreInformation)
