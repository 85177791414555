import { useEffect, useState } from 'react'

const MILLISECONDS = 1000

const MsCountDown = ({ eachMs, refetch, starts }) => {
  const [ms, setMs] = useState(starts)

  useEffect(() => {
    const interval = setInterval(() => {
      setMs(prev => prev - MILLISECONDS)
    }, eachMs)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (ms === 0) refetch()
    if (ms < 0) setMs(starts)
  }, [ms])

  return <span className="ms-2">Trying again in: {ms / MILLISECONDS} seconds...</span>
}

MsCountDown.defaultProps = {
  isDsc: false,
  eachMs: 1000,
  starts: 30000,
}

export default MsCountDown
