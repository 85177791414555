// Please, add any icon you need in the list below (keep it alphabetically sorted)
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaBrandsIcons, FaIcons, FaKit, FaRegularIcons } from 'Shared/FontAwesomeIcon/Icons'

library.add(
  FaKit.faSolidBuildingColumnsClock,
  FaBrandsIcons.faCcAmex,
  FaBrandsIcons.faCcDinersClub,
  FaBrandsIcons.faCcDiscover,
  FaBrandsIcons.faCcMastercard,
  FaBrandsIcons.faCcVisa,
  FaBrandsIcons.faFacebookF,
  FaBrandsIcons.faFirstdraft,
  FaIcons.faAngleDown,
  FaIcons.faAnglesDown,
  FaIcons.faAngleUp,
  FaIcons.faArrowDown,
  FaIcons.faArrowLeft,
  FaIcons.faArrowRight,
  FaIcons.faArrowRotateLeft,
  FaIcons.faArrowUp,
  FaIcons.faArrowUpRightFromSquare,
  FaIcons.faArrowsRotate,
  FaIcons.faBan,
  FaIcons.faBars,
  FaIcons.faBell,
  FaIcons.faBook,
  FaIcons.faBolt,
  FaIcons.faBoxArchive,
  FaIcons.faBuildingColumns,
  FaIcons.faCalendarCheck,
  FaIcons.faCalendarDays,
  FaIcons.faCalendarPlus,
  FaIcons.faCalendarXmark,
  FaIcons.faCamera,
  FaIcons.faCar,
  FaIcons.faCartPlus,
  FaIcons.faCartShopping,
  FaIcons.faCertificate,
  FaIcons.faCheck,
  FaIcons.faChevronDown,
  FaIcons.faChevronLeft,
  FaIcons.faChevronRight,
  FaIcons.faChevronUp,
  FaIcons.faCircle,
  FaIcons.faCircleCheck,
  FaIcons.faCircleExclamation,
  FaIcons.faCircleInfo,
  FaIcons.faCircleMinus,
  FaIcons.faCircleQuestion,
  FaIcons.faCirclePlus,
  FaIcons.faCircleStop,
  FaIcons.faCircleXmark,
  FaIcons.faClock,
  FaIcons.faCloud,
  FaIcons.faCloudArrowDown,
  FaIcons.faCloudArrowUp,
  FaIcons.faCloudMoon,
  FaIcons.faCreditCard,
  FaIcons.faDog,
  FaIcons.faDollarSign,
  FaIcons.faDoorOpen,
  FaIcons.faDroplet,
  FaIcons.faEllipsisVertical,
  FaIcons.faEnvelope,
  FaIcons.faExclamation,
  FaIcons.faFile,
  FaIcons.faFileInvoice,
  FaIcons.faFileInvoiceDollar,
  FaIcons.faFireFlameCurved,
  FaIcons.faGauge,
  FaIcons.faHouseChimney,
  FaIcons.faHourglassEnd,
  FaIcons.faHourglassHalf,
  FaIcons.faKey,
  FaIcons.faList,
  FaIcons.faLocationDot,
  FaIcons.faLock,
  FaIcons.faMagnifyingGlass,
  FaIcons.faMinus,
  FaIcons.faMoneyBill,
  FaIcons.faMoneyBillTransfer,
  FaIcons.faMoneyBillWave,
  FaIcons.faMoneyCheck,
  FaIcons.faPaperPlane,
  FaIcons.faPen,
  FaIcons.faPencil,
  FaIcons.faPenToSquare,
  FaIcons.faPhone,
  FaIcons.faPlug,
  FaIcons.faPlus,
  FaIcons.faPrint,
  FaIcons.faQuestion,
  FaIcons.faReply,
  FaIcons.faRightLeft,
  FaIcons.faRotate,
  FaIcons.faRotateLeft,
  FaIcons.faRotateRight,
  FaIcons.faRulerCombined,
  FaIcons.faShare,
  FaIcons.faSort,
  FaIcons.faSortDown,
  FaIcons.faSortUp,
  FaIcons.faSpinner,
  FaIcons.faStore,
  FaIcons.faSun,
  FaIcons.faTableCellsLarge,
  FaIcons.faTag,
  FaIcons.faTags,
  FaIcons.faTimes,
  FaIcons.faTrash,
  FaIcons.faTrashCan,
  FaIcons.faTriangleExclamation,
  FaIcons.faTruck,
  FaIcons.faThumbsUp,
  FaIcons.faTimesCircle,
  FaIcons.faUndo,
  FaIcons.faUpRightFromSquare,
  FaIcons.faUserGroup,
  FaIcons.faUserCheck,
  FaIcons.faUserPlus,
  FaIcons.faUserSlash,
  FaIcons.faUserXmark,
  FaIcons.faUsers,
  FaIcons.faUserSlash,
  FaIcons.faWifi,
  FaIcons.faXmark,
  FaKit.faSolidMoneyBillTransferSlash,
  FaRegularIcons.faCalendarDays,
  FaRegularIcons.faCircleCheck,
  FaRegularIcons.faCircleQuestion,
  FaRegularIcons.faCircleXmark,
  FaRegularIcons.faClock,
  FaRegularIcons.faCopy,
  FaRegularIcons.faFlag,
  FaRegularIcons.faHandHoldingCircleDollar,
  FaRegularIcons.faMoneyBill1,
  FaRegularIcons.faMoon,
  FaRegularIcons.faPenToSquare,
  FaRegularIcons.faTimesCircle,
  FaRegularIcons.faTrashCan,
)

export default FontAwesomeIcon
