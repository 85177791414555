import EventTitle from './EventTitle'
import { revisionMapping } from './eventMaping'

const EMPTY_VALUE = '--'

const GroupedChanges = ({ changes, eventConfig, id }) => (
  <div className="grouped-changes">
    {changes.map(change => {
      const config = revisionMapping[change.name] || eventConfig

      return (
        <div className="change" key={`${id}-${change.name}`}>
          <EventTitle icon={config?.icon} label={config?.label || change.name} />

          <div className="d-flex justify-content-between">
            <div>
              <span className="text-muted d-block">From:</span>
              {change.from?.display || change.from || EMPTY_VALUE}
            </div>

            <div className="w-50">
              <span className="text-muted d-block">To:</span>
              {change.to?.display || change.to || EMPTY_VALUE}
            </div>
          </div>
        </div>
      )
    })}
  </div>
)

export default GroupedChanges
