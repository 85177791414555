import { useEffect } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'

export const useLazyQueryWithPolling = (query, options) => {
  const [queryFn, result] = useLazyQueryNoCache(query)
  const { data, startPolling, stopPolling } = result
  const { milliseconds, onPolling } = options

  useEffect(() => {
    if (!startPolling || !data) return

    startPolling(milliseconds)

    if (!onPolling(data)) stopPolling()

    return () => stopPolling()
  }, [data])

  return [queryFn, result]
}
