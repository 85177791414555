import 'Shared/timemachine'
import 'react-dates/initialize'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'

const App = props => {
  window.roverData = { ...window?.roverData, ...props }

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper {...props} />
    </ApolloProvider>
  )
}

export default App
