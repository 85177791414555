import 'Shared/timemachine'
import { getElementDataSet } from 'Utils/Elements'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'
import 'Styles/Text.scss'
import 'Styles/Button.scss'

const CampgroundPictures = ({ pictureAlt, pictureId, pictureUrl }) => {
  const { slug: campgroundSlug, token } = getElementDataSet('campground-pictures-data-container')
  window.roverData = { campgroundSlug, token }

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper {...{ pictureAlt, pictureId, pictureUrl }} />
    </ApolloProvider>
  )
}

export default CampgroundPictures
