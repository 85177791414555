import { parseMoment } from 'Utils/Dates'

const RefundSubmit = ({ refund }) => {
  const { createdAt, total } = refund || {}
  const submittedOn = parseMoment(createdAt, {
    format: 'YYYY/MM/DD',
    formatReturned: 'MM/DD/YYYY',
    isFormatNeeded: true,
  })

  return (
    <li>
      <span>
        {total?.display}
      </span>

      <span className="ms-1 text-muted">
        submitted on {submittedOn}
      </span>
    </li>
  )
}

export default RefundSubmit
