import { gql } from 'Utils/apolloClient'

const reservationsQuery = gql`
  query ReservationsQuery($engineName: String, $slug: String!, $uuid: ID!) {
    campground(slug: $slug) {
      campgroundCancellationPolicy
      campgroundCancellationPolicyNote
      reservation(uuid: $uuid) {
        canRefundPayment
        id
        isGroup
        links {
          refundsParentUrl(engine: $engineName)
          refundsUrl(engine: $engineName)
        }
        parentId
        reservationsGroupNotCancelled {
          canRefundPayment
          from
          hasRefundsPending
          id
          installmentFirst {
            total {
              display
            }
          }
          isDayUse
          isFlexible
          isParent
          isRequest
          links {
            refundsUrl(engine: $engineName)
          }
          name
          pricing {
            maxRefundableAmount {
              display
              amount
            }
            totalRefunded {
              amount
              display
            }
          }
          rate {
            name
          }
          refundsInstallments {
            id
            state
          }
          site {
            id
            name
          }
          to
          uuid
        }
      }
      roverpassCancellationPolicy
    }
  }`

export default reservationsQuery
