import PricingItem from 'Shared/Invoices/Pricing/PricingItem'

const Items = ({ items }) => (
  <div className="cart-recurring-items my-3">
    <h6>Reservation</h6>

    {items?.map(({ name, subtotal }, index) => {
      const key = `${name}-${index}`

      return (
        <PricingItem itemName={name} key={key} rowClassName="text-muted" value={subtotal.display} />
      )
    })}
  </div>
)

export default Items
