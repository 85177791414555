import PropTypes from 'prop-types'
import ReservationBasketItem from './ReservationBasketItem'

const CartPayment = ({ classTitle, classBreakdown, breakDown, titleLabel, titleValue, processingFeeTotal,
                       taxTotal }) => (
  <>
    <ReservationBasketItem className={classTitle} label={titleLabel} value={titleValue} />
    {breakDown && (
      <>
        <ReservationBasketItem className={classBreakdown} label="Processing Fee"
                                value={processingFeeTotal} />

        <ReservationBasketItem className={classBreakdown} label="Tax" value={taxTotal} />
      </>
    )}
  </>
)

CartPayment.defaultProps = {
  breakDown: false,
  classBreakdown: 'ps-3 px-2',
  classTitle: 'ps-3 px-2',
  processingFeeTotal: '',
  taxTotal: '',
}

CartPayment.propTypes = {
  breakDown: PropTypes.bool,
  classBreakdown: PropTypes.string,
  classTitle: PropTypes.string,
  processingFeeTotal: PropTypes.string,
  taxTotal: PropTypes.string,
  titleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  titleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default CartPayment
