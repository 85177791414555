import { useMemo } from 'react'
import { Label } from 'reactstrap'
import cnames from 'classnames'
import Select from 'react-select'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'

const FormSelect = ({ className, formatGroupLabel, formatOptionLabel, customHeight, defaultMessage, defaultValue,
                      helpText, id, isArrayObejct, isClearable, isDisabled, isLoading, isMulti, isOptional,
                      isRequired, isSearchable, label, labelClasses, name, options, showErrors }) => (
  <FormGroupField className={className} name={name} showErrors={showErrors}>
    {({ field, form, hasErrorClass }) => {
      const classes = {
        select: cnames('control-select react-select-container', hasErrorClass,
                       { 'with-custom-height': customHeight }),
        label: cnames('main-label', labelClasses),
      }

      const handleBlur = () => form.setFieldTouched(name, true)

      const handleChange = items => {
        if (Array.isArray(items) && isArrayObejct) return form.setFieldValue(name, items)

        let value = ''
        if (items) value = items.length ? transformToArray(items) : items.value

        form.setFieldValue(name, value)
      }

      const transformToArray = items => {
        if (!items.length) return []

        return items.map(item => item.value || item)
      }

      const getFieldValue = value => {
        if (Array.isArray(value))
          if (isArrayObejct) return value
          else return value.map(item => ({ label: item, value: item }))

        const itemObject = options.find(option => option.value === value)
        return value ? itemObject : null
      }

      const fieldValue = useMemo(() => getFieldValue(field.value), [field.value])

      return (
        <>
          {label && (
            <Label className={classes.label} for={name}>
              {label}

              {isOptional && <span className="text-muted fst-italic">Optional</span>}

              {isRequired && <span className="text-danger ms-1">*</span>}
            </Label>
          )}

          {helpText && <RichText className="help-text w-100" html={helpText} />}

          <Select {...field} className={classes.select} classNamePrefix="react-select"
                  defaultValue={defaultValue} formatGroupLabel={formatGroupLabel}
                  formatOptionLabel={formatOptionLabel} id={id || name} isClearable={isClearable}
                  isDisabled={isDisabled} isLoading={isLoading} isMulti={isMulti}
                  isSearchable={isSearchable} name={name} onBlur={handleBlur} onChange={handleChange}
                  options={options} placeholder={defaultMessage} value={fieldValue} />
        </>
      )
    }}
  </FormGroupField>
)

FormSelect.defaultProps = {
  components: {},
  customHeight: true,
  isArrayObejct: false,
  isClearable: true,
  isLoading: false,
  isOptional: false,
  isRequired: false,
  isSearchable: true,
  labelClasses: 'main-label',
}

export default FormSelect
