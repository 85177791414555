import { ArcElement, Chart, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { externalTooltipHandler } from './CustomTooltip'

Chart.register(ArcElement, Tooltip)

const DoughnutChart = ({ balance }) => {
  const { due, paidNoRefunded, refunded, remainingNoDue } = balance || {}
  const balanceDisplay = { Due: due, Paid: paidNoRefunded, Refunded: refunded, Remaining: remainingNoDue }
  const data = {
    labels: ['Remaining', 'Due', 'Paid', 'Refunded'],
    datasets: [
      {
        data: [remainingNoDue?.amount, due?.amount, paidNoRefunded?.amount, refunded?.amount],
        backgroundColor: ['#D8D8D8', '#BD2031', '#24741F', '#F19B2F'],
        hoverBackgroundColor: ['#D8D8D8', '#BD2031', '#24741F', '#F19B2F'],
      },
    ],
  }

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        external: context => externalTooltipHandler(balanceDisplay, context),
      },
    },
  }

  return (
    <div className="d-flex align-items-center">
      <Doughnut data={data} height={79} options={options} width={79} />
    </div>
  )
}

export default DoughnutChart
