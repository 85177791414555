import { useEffect, useState } from 'react'
import { useLazyQuery } from '../Hooks/useApolloClient'
import countriesQuery from 'Shared/Queries/CountriesQuery'

const StatesOptions = () => {
  const [countries, setCountries] = useState()
  const [getCountries, { data, loading }] = useLazyQuery(countriesQuery)

  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (data) setCountries(data.countries)
  }, [data])

  if (loading || !countries?.length) return <option disabled>Loading</option>

  return (
    <>
      {countries?.map(({ name, states }) => (
        <optgroup key={name} label={name}>
          {states?.map(({ code, name }) => (
            <option key={code} value={code}>{name}</option>
          ))}
        </optgroup>
      ))}

      <optgroup key="International" label="International">
        <option key="Intl" code="Intl" value="International">International</option>
      </optgroup>
    </>
  )
}

export default StatesOptions
