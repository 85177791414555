import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from '../Queries/userPaymentMethodsDataFragment'

const bankAccountCreateMutation = gql`
  mutation UserBankAccount ($input: UserBankAccountCreateInput!) {
    userBankAccountCreate(input: $input) {
      user {
        ...userPaymentMethodsData
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default bankAccountCreateMutation
