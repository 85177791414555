import { Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'

const FormPercentageInput = ({ className, classTextGroup, disabled, helpTextClass, helpText, inputSize, id,
                               inputClasses, isIconOnStart, isRequired, label, labelClasses, name, max, min,
                               showErrors }) => {
  const classes = {
    groupText: cnames('fw-bolder', classTextGroup),
    input: cnames('control-textarea', inputClasses),
    label: cnames('main-label m-0', labelClasses),
    helpText: cnames('help-text w-100 text-muted', helpTextClass),
  }

  const icon = <InputGroupText htmlFor={name} tag="label" className={classes.groupText}>%</InputGroupText>

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field }) => (
        <>
          {label && (
            <Label className={classes.label} for={name}>
              {label}

              {isRequired && <span className="text-danger ms-1">*</span>}
            </Label>
          )}

          {helpText && <RichText className={classes.helpText} html={helpText} />}

          <InputGroup className="flex-nowrap">
            {isIconOnStart && icon}

            <Input {...field} autoComplete="off" bsSize={inputSize} className={classes.input}
                   disabled={disabled} id={id || name} max={max} min={min} name={id || name} step="any"
                   type="number" />

            {!isIconOnStart && icon}
          </InputGroup>
        </>
      )}
    </FormGroupField>
  )
}

FormPercentageInput.defaultProps = {
  helpTextClass: '',
  inputSize: 'lg',
  isIconOnStart: false,
  isRequired: false,
  max: Number.MAX_SAFE_INTEGER,
}

export default FormPercentageInput
