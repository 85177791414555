import cnames from 'classnames'
import RowCell from './RowCell'

const BASE_CLASSNAME = 'align-items-center border border-1 d-flex justify-content-between mb-3 py-3 px-2 rounded row shadow-sm'

const Row = ({ headers, item }) => {
  const className = cnames('table-list--row', BASE_CLASSNAME)

  return (
    <div className={className}>
      {headers.map(header => <RowCell header={header} item={item} key={`row-cell--${item.id}-${header.name}`} />)}
    </div>
  )
}

export default Row
