import { gql } from 'Utils/apolloClient'

const addBlackoutDatesMutation = gql`
  mutation BlackoutDatesBulkCreate($campgroundId: ID!, $days: [Int!] = [], $from: ISO8601Date!, $sites: [ID!]!,
                                   $to: ISO8601Date! ) {
    blackoutDatesBulkCreate(input: { campgroundId: $campgroundId, days: $days, from: $from, siteIds: $sites,
                                     to: $to }) {
      blackoutDatesBulkSummary {
        alreadyBlockedSites {
          id
          name
        }
        daysOfWeek
        from
        id
        partiallyBlockedSites {
          id
          name
        }
        succesfullyBlockedSites {
          id
          name
        }
        to
      }
    }
  }`

export default addBlackoutDatesMutation
