import { useContext, useEffect, useState } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import { FormCardElement, useStripeToken } from 'Shared/Stripe'
import { initalFormValues } from './InitialFormValues'
import { PaymentMethodActions, PaymentMethodsContext } from 'Shared/PaymentMethods'
import FormTextInput from 'Shared/Formik/FormTextInput'
import BillingAddress from 'Shared/BillingAddress'
import ProcessPaymentModal from 'Shared/ProcessPaymentModal/ProcessPaymentModal'
import CardOnFile from './CardOnFile'
import CreditCardPaymentFormSchema from './CreditCardPaymentFormSchema'
import './CreditCardPayment.scss'

const CreditCardPayment = ({ cardOnFileLabel, children, onProcessingPayment, showCardOnFile, uuid }) => {
  const { clearMutationErrors, stripeCardOnFile, displayOptions, mutation, onCompletePayment, onSubmit,
          toggle, total } = useContext(PaymentMethodsContext)
  const [formData, setFormData] = useState({})
  const [paymentErrors, setPaymentErrors] = useState()
  const [saveCardOnFile, setSaveCardOnFile] = useState(false)
  const [processingPayment, setProcessingPayment] = useState({ modal: false, start: false })
  const { clearErrors, getToken, token, tokenErrors,
          tokenLoading } = useStripeToken({ stripeElement: CardElement })

  const { lastFour: cardOnFileLastFour } = stripeCardOnFile ?? {}
  const isProcessingPayment = mutation.loading || tokenLoading

  const closeModal = () => setProcessingPayment(prevState => ({ ...prevState, modal: false }))

  const resetPaymentErrors = () => paymentErrors && setPaymentErrors(null)

  const onChangeCardElement = () => {
    if (tokenErrors.length > 0) clearErrors()
    resetPaymentErrors()
    clearMutationErrors()
  }

  const handleOnSubmit = values => {
    if (values.cardOnFile) {
      onStartModal()
      onSubmit(values)
      return false
    }

    const { address, city, address2, state, zip } = values
    const additionalData = { address_city: city, address_line1: address, address_line2: address2,
                             address_state: state, zip }
    setFormData(values)
    getToken(additionalData)
  }

  const onClickSaveCardOnFile = event => setSaveCardOnFile(event.target.checked)

  const onStartModal = () => setProcessingPayment(prevState => ({ ...prevState, start: true }))

  useEffect(() => {
    if (token) {
      onStartModal()
      onSubmit({ ...formData, saveCardOnFile, stripeToken: token })
    }
  }, [token])

  useEffect(() => {
    if (processingPayment.start) setProcessingPayment({ modal: true, start: false })
  }, [mutation.data])

  useEffect(() => {
    onProcessingPayment(!isProcessingPayment)
  }, [isProcessingPayment])

  return (
    <FormWithValidations className="payment-form" formId="payment-form-card" initialValues={initalFormValues}
                         onChangeValues={clearMutationErrors} onSubmit={handleOnSubmit} scrollToError
                         validationSchema={CreditCardPaymentFormSchema}>
      {
        ({ setTouchFormFields, values }) => {
          const { cardOnFile } = values

          useEffect(() => {
            setTouchFormFields(false)
          }, [cardOnFile])

          return (
            <>
              {showCardOnFile && <CardOnFile cardOnFileLastFour={cardOnFileLastFour} />}

              <Collapse isOpen={!cardOnFile}>
                <h5 className="fw-bold text-lg">Amount: {total.display}</h5>

                <FormCardElement {...{ cardOnFileLabel, onClickSaveCardOnFile, saveCardOnFile }} className="mb-3"
                                  errors={tokenErrors} graphQLErrors={paymentErrors} hidePostalCode
                                  label="Card Details" onChange={onChangeCardElement} showDefaultPayment
                                  showIcons />

                <FormTextInput label="Name On Card" name="cardholderName" />

                <BillingAddress layoutColumn={{ first: 7, second: 5 }}
                                stateDropDownProps={{ defaultMessage: 'Select' }} showZipCode />

                {children}

                {displayOptions && (
                  <PaymentMethodActions confirmText="Pay Invoice Now" loading={isProcessingPayment}
                                        onCancel={toggle} />
                )}
              </Collapse>

              <ProcessPaymentModal {...{ closeModal, onCompletePayment, uuid }} isOpen={processingPayment.modal} />
            </>
          )
        }
      }
    </FormWithValidations>
  )
}

CreditCardPayment.defaultProps = {
  cardOnFileLabel: 'Save card on file for future use?',
  onProcessingPayment: () => {},
  showCardOnFile: false,
}

CreditCardPayment.propTypes = {
  cardOnFileLabel: PropTypes.string,
  onProcessingPayment: PropTypes.func,
  showCardOnFile: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
}

export default CreditCardPayment
