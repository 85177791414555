import { gql } from 'Utils/apolloClient'

const sitesBlackoutDates = gql`
  query Sites($bodFilters: BlackoutDatesFiltersAttributes, $isAdmin: Boolean!, $perPage: Int!,
              $siteFilters: SiteFiltersAttributes, $slug: String!) {
    campground(slug: $slug) {
      id
      sites(filters: $siteFilters) {
        id
        name
        blackoutDates(filters: $bodFilters, page: 1, perPage: $perPage) {
          hasNextPage
          nodes {
            dateRange
            id
            nights
          }
          totalCount
        }
      }
    }
    options {
      externalLinks {
        campgroundCalendar(isAdmin: $isAdmin, slug: $slug)
      }
    }
  }`

export default sitesBlackoutDates
