import { memo, useEffect, useMemo } from 'react'
import { useLazyQuery } from 'Shared/Hooks/useApolloClient'
import { usePaginationData } from 'Shared/Hooks/usePaginationData'
import { getValue } from 'Utils/objects'

export const withPagination = (WrappedComponent, query, pathData) => {
  const withPaginationComponent = ({ extraVariables, ...props }) => {
    const [get, { data, loading }] = useLazyQuery(query)
    const pagination = usePaginationData()
    const { nodes, pagesCount, totalCount } = useMemo(() => {
      if (!data || loading) return {}

      return getValue(data, pathData)
    }, [data])
    const { currentPage, paramsReviwed, perPage } = pagination
    const paginationData = { ...pagination, pagesCount, totalCount }

    useEffect(() => {
      if (paramsReviwed) get({ variables: { ...extraVariables, page: currentPage, perPage } })
    }, [paramsReviwed, currentPage, perPage])

    return <WrappedComponent {...props} data={nodes} loading={loading} pagination={paginationData} />
  }

  return memo(withPaginationComponent)
}
