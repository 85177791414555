import { memo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import CustomField from 'Shared/CustomFields/CustomField/CustomField'
import './CustomFieldsForm.scss'

const CustomFieldsForm = ({ customFields, isPos }) => (
  <Row xs="1" lg="2" className="custom-fields-form mt-3">
    {customFields.map((field, index) => {
      const { id } = field

      return (
        <Col key={id}>
          <CustomField dataField={field} index={index} isPos={isPos} />
        </Col>
      )
    })}
  </Row>
)

CustomFieldsForm.defaultProps = {
  customFields: [],
}

CustomFieldsForm.propTypes = {
  customFields: PropTypes.instanceOf(Array),
}

export default memo(CustomFieldsForm)
