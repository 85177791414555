import { useEffect, useMemo } from 'react'
import cnames from 'classnames'
import { isNeighbor, getNeighbors } from 'Shared/NightlyConfigurationLegacy/Utils/utils'
import Checkbox from 'Shared/Checkbox/Checkbox'
import CurrencyInput from 'Shared/CurrencyInput/CurrencyInput'

const BASE_WRAPPER_CLASS = 'check-day-group d-flex justify-content-between col-12 col-sm-8 col-md-7 col-lg-6'

const CheckDay = ({ dailyPrices, handleSelected, id, label, position, selecteds, updateNightsBookTogether,
                    value }) => {
  const { isNeighborNotActive, isNextActive, isPreviousNotActive } = useMemo(() => {
    const [previous, next] = getNeighbors(position)

    return {
      isNeighborNotActive: !isNeighbor(position, selecteds),
      isNextActive: selecteds.includes(next),
      isPreviousNotActive: !selecteds.includes(previous),
    }
  }, [selecteds])
  const isChecked = selecteds.includes(position)
  const checkboxLabel = `${label} checkbox`
  const classNameWrapper = cnames(BASE_WRAPPER_CLASS, {
    'check-next-day-active': isNextActive || isChecked,
    'check-day-available': selecteds.length > 0 && !isNeighborNotActive && !isChecked,
  })
  const dayPrice = parseInt(dailyPrices[value] || 0)
  const disabled = !isChecked && isNeighborNotActive
  const inputId = `period_${value}`
  const inputName = `period[${value}]`

  const handleClick = e => handleSelected(e.target.checked, position)

  useEffect(() => {
    if (!selecteds.length) updateNightsBookTogether({ endsOn: '', startsOn: '' })
    if (isChecked && isPreviousNotActive) updateNightsBookTogether({ startsOn: value })
    if (isChecked && !isNextActive) updateNightsBookTogether({ endsOn: value })
  }, [selecteds])

  return (
    <div className={classNameWrapper}>
      <div className="d-flex">
        <Checkbox classNameWrapper="col-3 check-day position-relative" color="salmon" disabled={disabled}
                  handleClick={handleClick} isChecked={isChecked} labelClassname="visually-hidden"
                  message={checkboxLabel} name={id} />

        <label className="fw-normal" htmlFor={inputId}>{label}</label>
      </div>

      <CurrencyInput classInputGroup="mb-3 w-50 input-group-sm" id={inputId} inputSize="sm" name={inputName}
                     value={dayPrice} />
    </div>
  )
}

export default CheckDay
