import PropTypes from 'prop-types'
import cnames from 'classnames'

const CloseToastButton = ({ className, onClick, isColorWhite }) => {
  const classname = cnames('btn-close', { 'btn-close-white': isColorWhite }, className)

  return (
    <button aria-label="Close" className={classname} name="Close" onClick={onClick} type="button" value="Close" />
  )
}

CloseToastButton.defaultProps = {
  isColorWhite: false,
}

CloseToastButton.propTypes = {
  isColorWhite: PropTypes.bool,
}

export default CloseToastButton
