import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const EventTitle = ({ icon, label, link }) => {
  const color = icon?.color || '#1F4965'
  const name = icon?.name || 'exclamation'

  return (
    <div className="d-flex justify-content-between aligin-items-center">
      <div>
        <FontAwesomeIcon className="me-2" color={color} icon={name} />

        <span className="fw-bold">{label}</span>
      </div>

      {link && (
        <a className="text-decoration-none text-gray" href={link} rel="noreferrer" target="_blank">
          <FontAwesomeIcon color="#91A4B1" icon="up-right-from-square" />
        </a>
      )}
    </div>
  )
}

export default EventTitle
