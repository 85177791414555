import { createContext, useContext, useState } from 'react'

export const InvoiceContext = createContext()

export const InvoiceProvider = ({ id, children }) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const toggleCreateModal = () => setIsCreateModalOpen(prev => !prev)

  const toggleLinkModal = () => setIsLinkModalOpen(prev => !prev)

  const value = { id, isCreateModalOpen, isLinkModalOpen, toggleCreateModal, toggleLinkModal }

  return (
    <InvoiceContext.Provider value={value}>
      {children}
    </InvoiceContext.Provider>
  )
}

export const useInvoice = () => useContext(InvoiceContext)
