import { useMemo } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import CheckboxTable from './CheckboxTable'
import ControlsTable from './ControlsTable'
import HeaderCell from './HeaderCell'
import TableRow from './Row/TableRow'
import './Table.scss'

const Table = ({ className, contentClasses, data, displayPagination, headers, isStriped, onCheckSelection,
                 pagination, RowComponent, sortData, tableName, totals, TotalsComponent, withCheckSelection }) => {
  const classes = cnames('table-responsive mt-3 mb-4', className)
  const tableClassName = cnames('table m-0', { 'table-striped': isStriped })
  const onCheckHeader = () => onCheckSelection({ type: 'TABLE_HEADER' })

  const memoHeaders = useMemo(() => {
    if (!withCheckSelection) return headers

    const availableData = data.filter(item => !item.isArchived)
    const isAllCheckboxsChecked = availableData.every(item => item.selected)
    const isIndeterminate = !isAllCheckboxsChecked && availableData.some(item => item.selected)
    const checkbox = <CheckboxTable handleClick={onCheckHeader} isChecked={isAllCheckboxsChecked}
                                    isIndeterminate={isIndeterminate} message="Select all" name="select-all" />
    const checkboxHeader = { columnHeaderClassname: 'col-1', label: '', name: 'select', component: checkbox }

    return [checkboxHeader, ...headers]
  }, [headers, data])

  return (
    <>
      <div className={classes}>
        <table className={tableClassName}>
          <thead>
            <tr>
              {memoHeaders.map(header => <HeaderCell key={header.name} sortData={sortData} {...header} />)}
            </tr>
          </thead>

          <tbody>
            {data.map(item => {
              const key = item.id || nanoid()
              if (RowComponent) return <RowComponent headers={memoHeaders} key={key} {...item} />

              return <TableRow contentClasses={contentClasses} headers={memoHeaders} item={item} key={key}
                               onRowCheckSelected={onCheckSelection} tableName={tableName} />
            })}
          </tbody>

          {totals && TotalsComponent && (
            <tfoot>
              <TotalsComponent headers={headers} totals={totals} />
            </tfoot>
          )}

        </table>
      </div>

      {displayPagination && <ControlsTable {...pagination} />}
    </>
  )
}

Table.defaultProps = {
  displayPagination: true,
  isStriped: false,
  pagination: {},
  RowComponent: null,
  sortData: {},
  tableName: '',
  totals: {},
  TotalsComponent: null,
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayPagination: PropTypes.bool,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isStriped: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pagesCount: PropTypes.number,
    perPage: PropTypes.number,
    totalCount: PropTypes.number,
    updateCurrentPage: PropTypes.func,
    updateFilter: PropTypes.func,
  }),
  RowComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  sortData: PropTypes.shape({
    sortedField: PropTypes.shape({}),
    updateSort: PropTypes.func,
  }),
  tableName: PropTypes.string,
  totals: PropTypes.shape({}),
  TotalsComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]),
}

export default Table
