import classnames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const Loading = ({ className, iconClass, iconColor, message, messageClass, size }) => {
  const wrapperClasses = classnames('loading', className)
  const iconClasses = classnames('fa-spin', iconClass)

  return (
    <div className={wrapperClasses}>
      {message && <p className={messageClass}>{message}</p>}

      <FontAwesomeIcon icon="spinner" className={iconClasses} color={iconColor} size={size} />
    </div>
  )
}

Loading.defaultProps = {
  className: null,
  iconClass: 'text-gray',
  iconColor: '',
  message: null,
  messageClass: null,
  size: '2x',
}

Loading.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string,
  iconColor: PropTypes.string,
  message: PropTypes.string,
  messageClass: PropTypes.string,
  size: PropTypes.string,
}

export default Loading
