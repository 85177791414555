import 'react-dates/initialize'
import LogInButton from './Components/LogInButton'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'

const Wrapper = ({ stripeKey, token }) => {
  window.roverData = { stripeKey, token }

  return (
    <ApolloProvider client={apolloClient}>
      <LogInButton />
    </ApolloProvider>
  )
}

export default Wrapper
