import { useMemo } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import CheckboxTable from 'Shared/Admin/Table/CheckboxTable'
import HeaderCell from 'Shared/TableList/HeaderCell'
import Row from 'Shared/TableList/Row'

const BASE_STYLES = 'align-items-center d-flex justify-content-between not-loading pt-3 pb-2 px-2 row'

const TableList = ({ classHeader, CustomRow, data, headers, isAllChecked, isIndeterminate, onCheckHeader,
                     onCheckRow, withCheckSelection }) => {
  const classesHeader = cnames('table-list--header', BASE_STYLES, classHeader)

  const memoHeaders = useMemo(() => {
    if (!withCheckSelection) return headers

    const isAllCheckboxChecked = isAllChecked || data.every(item => item.selected)
    const isIndeterminateChecked = isIndeterminate || (!isAllCheckboxChecked && data.some(item => item.selected))
    const checkbox = <CheckboxTable handleClick={onCheckHeader} isChecked={isAllCheckboxChecked}
                                    isIndeterminate={isIndeterminateChecked} message="Select all"
                                    name="select-all" />
    const checkboxHeader = { cols: 1, component: checkbox, label: '', name: 'select' }

    return [checkboxHeader, ...headers]
  }, [headers, data])

  return (
    <div className="table-list">
      <div className={classesHeader}>
        {memoHeaders.map(header => <HeaderCell key={header.name} header={header} />)}
      </div>

      <div className="table-list--body">
        {data.map(item => (CustomRow ? (
          <CustomRow headers={headers} item={item} key={item.id} onCheckRow={onCheckRow} />
        ) : (
          <Row headers={headers} item={item} key={item.id} onCheckRow={onCheckRow} />)
        ))}
      </div>
    </div>
  )
}

TableList.defaultProps = {
  data: [],
  onCheckHeader: () => {},
  withCheckSelection: false,
}

TableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCheckHeader: PropTypes.func,
  withCheckSelection: PropTypes.bool,
}

export default TableList
