import { gql } from 'Utils/apolloClient'

const paymentStateQuery = gql`
  query Installment($uuid: ID!) {
    installment(uuid: $uuid) {
      id
      payment {
        id
        state
      }
    }
  }`

export default paymentStateQuery
