import PropTypes from 'prop-types'
import FilterOption from '../FilterOption/FilterOption'

const FilterOptions = ({ options, selectFilter, selectedOptions, optionsDropdown, setDefaultOptions, title }) => (
  <div>
    {title && <div className="fw-bold mb-3">{title}</div>}

    {options.map(option => {
      const isChecked = selectedOptions.includes(option.value)

      return <FilterOption isChecked={isChecked} key={option.value} option={option}
                           optionsDropdown={optionsDropdown} selectFilter={selectFilter}
                           setDefaultOptions={setDefaultOptions} />
    })}
  </div>
)

FilterOptions.defaultProps = {
  title: '',
}

FilterOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })).isRequired,
  selectFilter: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDefaultOptions: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default FilterOptions
