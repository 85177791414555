import EmptyActionAlert from './EmptyActionAlert'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const EmptyTable = ({ actionLink, actionLinkText, icon, message, secondaryMessage, useActionAlert,
                      useEmptyComponent }) => (
  <>
    {(useEmptyComponent) ? (
      <div className="bg-light d-flex flex-column align-items-center text-muted pb-4 pt-5 mb-4">
        <FontAwesomeIcon className="my-2 d-block" icon={icon} size="3x" />

        <h5 className="mb-2">{message}</h5>
        <h6 className="mb-3 fw-normal">{secondaryMessage}</h6>
      </div>
    ) : (
      <EmptyActionAlert actionLink={actionLink} actionLinkText={actionLinkText} icon={icon} message={message}
                        secondaryMessage={secondaryMessage} useActionAlert={useActionAlert} />
    )}
  </>
)

EmptyTable.defaultProps = {
  icon: ['far', 'flag'],
}

export default EmptyTable
