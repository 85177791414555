import { useEffect, useState } from 'react'
import ModalContent from './ModalContent/ModalContent'
import ViewModal from 'Shared/ViewModal/ViewModal'

const RefundFormModal = ({ canReversePayout, installment, installmentsCount, installmentRefunded, isRefundPage,
                           isAdmin, isOpen, resetRefund, onChangeCurrencies, onSubmit, refundError, refundFinished,
                           refundLoading, reservation, resourcesData, toggleFormModal }) => {
  const [showHeader, setShowHeader] = useState()
  const modalClasses = {
    content: refundLoading ? 'h-50 flex-row align-items-center' : '',
    header: 'new-refund-modal',
    modal: refundLoading ? 'h-50' : 'confirmation-modal refund-modal',
  }

  useEffect(() => {
    setShowHeader(!refundLoading)
  }, [refundLoading])

  return (
    <ViewModal classes={modalClasses} isCloseColorWhite isOpen={isOpen} onClosed={resetRefund}
               showHeader={showHeader} showOkButton={false} size="lg" title="Refund Invoice"
               toggle={toggleFormModal}>
      <ModalContent {...{ canReversePayout, installment, installmentsCount, installmentRefunded, isAdmin,
                          isRefundPage, onChangeCurrencies, onSubmit, refundLoading, refundError, refundFinished,
                          reservation, resourcesData, toggleFormModal }} />
    </ViewModal>
  )
}

RefundFormModal.defaultProps = {
  isRefundPage: false,
}

export default RefundFormModal
