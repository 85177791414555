import FormCheckInputGroup from 'Shared/Formik/FormCheckInputGroup'
import FormTextArea from 'Shared/Formik/FormTextInput'

const OPTIONS = [
  { label: 'Guest made another reservation.', value: 'made_another_reservation' },
  { label: 'Guest reserved with campground directly.', value: 'reserved_with_campground_directly' },
  { label: 'Guest can no longer make this reservation.', value: 'refund_can_no_longer_make_it' },
  { label: 'Other.', value: 'other' },
]

const CancellationOptions = ({ optionName, placeholder, showRadioButton, textLabel, textName }) => (
  <>
    {showRadioButton && (
      <FormCheckInputGroup className="ps-0" classNameLabel="d-flex" name={optionName} options={OPTIONS}
                           type="radio" />
    )}

    <FormTextArea label={textLabel} name={textName} placeholder={placeholder} rows={2} showErrors
                  type="textarea" />
  </>
)

CancellationOptions.defaultProps = {
  optionLabel: null,
  showRadioButton: true,
}

export default CancellationOptions
