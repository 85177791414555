import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import PropTypes from 'prop-types'
import Tooltip from 'Shared/Tooltip'

const TooltipWithTarget = ({ children, icon, iconClass, iconSize, id, placement, value }) => (
  <>
    <FontAwesomeIcon className={iconClass} id={id} icon={icon} size={iconSize} />

    <Tooltip idTarget={id} placement={placement} value={value}>
      {children}
    </Tooltip>
  </>
)

TooltipWithTarget.defaultProps = {
  icon: 'circle-exclamation',
  iconClass: null,
  iconSize: 'lg',
  placement: 'right',
  value: null,
}

TooltipWithTarget.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  iconClass: PropTypes.string,
  iconSize: PropTypes.string,
  placement: PropTypes.string,
  value: PropTypes.string,
}

export default TooltipWithTarget
