import { gql } from 'Utils/apolloClient'

const siteBlackoutDates = gql`
  query Site($bodFilters: BlackoutDatesFiltersAttributes, $page: Int, $perPage: Int, $slug: String!,
             $siteId: ID!) {
    campground(slug: $slug) {
      id
      site(id: $siteId) {
        id
        blackoutDates(filters: $bodFilters, page: $page, perPage: $perPage) {
          hasNextPage
          nodes {
            dateRange
            id
            nights
          }
        }
      }
    }
  }`

export default siteBlackoutDates
