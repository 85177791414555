import classNames from 'classnames'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const FormErrorMessages = ({ errors, className, style }) => {
  const formErrorMessagesClasses = classNames('alert alert-danger text-center', { [className]: !!className })

  return (
    <div className={formErrorMessagesClasses} style={style}>
      <FontAwesomeIcon icon="circle-exclamation" className="me-3" />
      <span>{errors.join(', ')}</span>
    </div>
  )
}

export default FormErrorMessages
