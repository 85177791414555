import { cloneElement, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ConfirmDialog = ({ children, message, onConfirm, title }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const confirm = () => {
    onConfirm()
    toggle()
  }

  return (
    <>
      {cloneElement(children, { onClick: toggle })}

      <Modal className="confirm-dialog" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>

        <ModalBody>{message}</ModalBody>

        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={toggle}>Cancel</Button>

          <Button color="salmon" onClick={confirm} data-test="cb-confirm-dialog-button">Confirm</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmDialog
