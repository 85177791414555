import { useEffect } from 'react'
import Select from 'react-select'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import CampgroundsQuery from 'Shared/NavbarDropdown/Queries/CampgroundsQuery'

const Dropdown = () => {
  const { campgroundSlug, userToken } = window.roverData
  const [getCampgrounds, { data, loading }] = useLazyQueryNoCache(CampgroundsQuery)

  useEffect(() => {
    getCampgrounds({ variables: { userToken } })
  }, [])

  const handleSelectChange = selectedOption => {
    const { campgroundUrl } = selectedOption
    window.location.replace(campgroundUrl)
  }

  const campgrounds = data?.currentUser?.campgroundsAvailable?.nodes
  const campgroundsUrl = data?.options?.externalLinks?.campgroundsUrl
  const options = campgrounds?.map(option => ({ label: option.name, value: option.slug,
                                                campgroundUrl: option.links.dashboardUrl }))
  options?.push({ label: '...View All Campgrounds', value: 'view-all-campgrounds', campgroundUrl: campgroundsUrl })

  const optionSelected = options?.find(option => option.value === campgroundSlug)
  const styles = {
    placeholder: base => ({ ...base, color: 'text-dark' }),
  }

  return (
    <Select className="react-select text-dark" classNamePrefix="react-select" isLoading={loading} options={options}
            onChange={handleSelectChange} placeholder={optionSelected?.label || 'loading...'} styles={styles} />
  )
}

export default Dropdown
