import { Alert } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const InfoAlert = ({ linkText, message, reservationParentLink }) => (
  <Alert className="group-bookings-bar-alert align-items-center d-flex mb-3 py-1 px-3 mx-4 border-0 rounded-0
                    rounded-bottom-4">
    <FontAwesomeIcon className="me-2 text-facebook" icon="circle-info" />

    <small>
      {message}

      {reservationParentLink && (
        <a className="alert-info ms-1 text-decoration-underline" href={reservationParentLink}>
          {linkText}
        </a>
      )}
    </small>
  </Alert>
)

export default InfoAlert
