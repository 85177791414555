import { Button, ButtonGroup } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'

const FormYesOrNoButtons = ({ className, label, labelClasses, name }) => (
  <FormGroupField {...{ className, name }} showErrors>
    {({ field, form }) => (
      <div className="d-flex flex-column">
       <label className={labelClasses} htmlFor={name}>
          {label}
        </label>

        <ButtonGroup>
          <Button active={field.value} color="light" onClick={() => form.setFieldValue(name, true)} size="lg">
            Yes
          </Button>

          <Button active={!field.value} color="light" onClick={() => form.setFieldValue(name, false)} size="lg">
            No
          </Button>
        </ButtonGroup>
      </div>
    )}
  </FormGroupField>
)

export default FormYesOrNoButtons
