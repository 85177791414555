import cnames from 'classnames'
import PropTypes from 'prop-types'
import CheckboxTable from '../CheckboxTable'
import TdAction from 'Admin/Components/TdAction'
import TdLink from 'Admin/Components/TdLink/TdLink'

const ROW_CELL_TYPE = 'TABLE_ROW_CELL'

const RowCell = ({ contentClasses, item, header, onRowCheckSelected }) => {
  const { attrs, getAttrs, getValue, name, onClick } = header
  const currentItem = item[name]
  const attrsToTdLink = getAttrs ? getAttrs(currentItem) : attrs
  const isCheckCell = name === 'select'
  const checkboxContainerClassname = cnames(contentClasses,
                                            { 'align-items-center d-flex justify-content-center': isCheckCell })
  const keyTdLink = `${item.id}-${name}`
  const nameCheckBox = 'select-current_check'
  const idCheckbox = `${nameCheckBox}-${item.id}`

  const onCheckRow = () => onRowCheckSelected({ type: ROW_CELL_TYPE, value: item.id })

  const getValueToShow = () => {
    if (isCheckCell) return item.selected
    if (getValue) return getValue(currentItem, item)

    return currentItem
  }

  const value = getValueToShow()

  if (onClick instanceof Function)
    return <TdAction {...attrsToTdLink} key={keyTdLink} onClick={() => onClick(item)}>{value}</TdAction>

  return (
    <TdLink contentClassName={checkboxContainerClassname} {...attrsToTdLink} key={keyTdLink}>
      {isCheckCell ? (
        <CheckboxTable handleClick={onCheckRow} isChecked={value} id={idCheckbox} isDisabled={item.isArchived}
                       message="Select Row" name={nameCheckBox} />
        ) : value}
    </TdLink>
  )
}

RowCell.defaultProps = {
  contentClasses: '',
  header: {
    attrs: {},
    getAttrs: null,
    getValue: null,
  },
  item: {},
  onRowCheckSelected: () => {},
}

RowCell.propTypes = {
  contentClasses: PropTypes.string,
  header: PropTypes.shape({
    attrs: PropTypes.PropTypes.shape({}), // Any possible prop to TdLink (className, contentClassName, isExternalLink, etc)
    getAttrs: PropTypes.func, // Callback to get the attrs in case we want create them with the current item
    getValue: PropTypes.func, // Callback to get the current item to return another stuff with the item
    name: PropTypes.string.isRequired, // Current header name to get the value from the item
  }),
  item: PropTypes.shape({}),
  onRowCheckSelected: PropTypes.func,
}

export default RowCell
