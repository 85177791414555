import CartWrapper from 'Shared/Cart/CartWrapper'
import Empty from './Empty'
import Header from './Header'
import PricingTotals from './PricingTotals'

const RecurringSection = ({ data, error, loading, localItems, showItems }) => (
  <CartWrapper className="cart-recurring mb-3 px-2 py-3">
    <Header />

    {(localItems.length > 0 || loading) ? (
      <PricingTotals data={data} error={error} loading={loading} showItems={showItems} />
    ) : (
      <Empty />
    )}
  </CartWrapper>
)

export default RecurringSection
