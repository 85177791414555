import cnames from 'classnames'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

const FormErrorMessage = ({ className, name }) => {
  const classes = cnames('alert alert-danger formik-error-message', className)

  return (
    <ErrorMessage name={name} render={msg => (
      <div className={classes}>
      <div className="mb-0">{msg}</div>
    </div>
    )} />
  )
}

FormErrorMessage.defaultProps = {
  className: 'mt-2 mb-0',
}

FormErrorMessage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default FormErrorMessage
