import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const RefundSuccess = ({ purchaseRefunded, toggle }) => {
  const { paymentFirst: payment, refund } = purchaseRefunded || {}
  const { total, source } = refund || {}
  const successMessage = payment?.isExternal ? 'Refund Recorded Successfully' : 'Refunded Successfully'

  return (
    <div className="d-flex flex-column m-xs-3 mx-auto w-75 px-md-3 my-3">
      <FontAwesomeIcon className="text-success my-1" icon={['far', 'circle-check']} size="2x" />

      <h5 className="mt-2 text-center fw-bold">
        <p><span className="text-primary">{total?.display}</span> {successMessage}</p>
      </h5>

      <p className="text-center text-muted">
        <span>From Payment 1 of 1 #{payment?.id}</span>
      </p>

      <p className="text-center text-muted">
        <span>Refund Method: {source}</span>
      </p>

      <Button block className="my-2" color="salmon" onClick={toggle}>Close</Button>
    </div>
  )
}

export default RefundSuccess
