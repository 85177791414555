import { memo } from 'react'
import cnames from 'classnames'
import Step from './Step'
import 'Shared/ProgressNav/ProgressNav.scss'

const ProgressNav = ({ currentRoute, goToRoute, hideStepNumber, isClickable, isEditing, progressNavClass, size,
                       steps, stepsToHide, theme }) => {
  const totalSteps = steps.length

  const currentStep = steps.find(item => item?.routes.includes(currentRoute))

  const isLastStep = totalSteps === currentStep?.index

  const isActive = step => (step?.index <= currentStep?.index)

  const isCurrent = step => (step?.index === currentStep?.index)

  const isOverlay = step => isEditing && step?.index > currentStep?.index

  const stepsToShow = steps.filter(({ index }) => !stepsToHide.includes(index))

  const classes = {
    progressNav: cnames('progress-nav d-print-none', progressNavClass, theme),
    step: step => cnames('align-items-center flex-column d-flex step', size, {
      active: isActive(step),
      current: isCurrent(step),
      overlay: isOverlay(step),
    }),
  }

  return (
    <div className={classes.progressNav}>
      {stepsToShow.map((step, index) => {
        const route = step.routes[0]
        const navigateToRoute = () => goToRoute(route)
        if (steps.length > stepsToShow.length) step.index = index + 1

        return (
          <div className={classes.step(step)} key={step.index}>
            <Step {...{ navigateToRoute, hideStepNumber, isClickable, isLastStep, size, step }}
                  isActive={isActive(step)} isOverlay={isOverlay(step)} />
          </div>
        )
      })}
    </div>
  )
}

ProgressNav.defaultProps = {
  hideStepNumber: false,
  stepsToHide: [],
  isClickable: true,
  progressNavClass: '',
  size: '',
  theme: '',
}

export default memo(ProgressNav)
