import cnames from 'classnames'
import { Card as ReactstrapCard } from 'reactstrap'

const Card = ({ children, className }) => {
  const classes = cnames('rounded border shadow-sm', className)

  return (
    <ReactstrapCard className={classes}>
      {children}
    </ReactstrapCard>
  )
}

export default Card
