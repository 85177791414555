import { gql } from 'Utils/apolloClient'
import ValidationDataFragment from 'Shared/Queries/ValidationDataFragment'

const resValidationsQuery = gql`
  query GuestRigValidations ($checkArchived: Boolean, $filters: ValidationsFiltersAttributes, $from: ISO8601Date!,
                             $isPos: Boolean, $rateId: ID, $reservationUuid: ID, $siteId: ID,
                             $skipAvailability: Boolean, $slug: String!, $to: ISO8601Date!) {
    campground(from: $from, isPos: $isPos, slug: $slug, to: $to) {
      validations(checkArchived: $checkArchived, filters: $filters, reservationUuid: $reservationUuid,
                  rateId: $rateId, siteId: $siteId, skipAvailability: $skipAvailability) {
          ...validationData
        }
      }
    }
  ${ValidationDataFragment}
`

export default resValidationsQuery
