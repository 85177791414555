import { useEffect } from 'react'
import useThrottle from 'Shared/Hooks/useThrottle'
import { getSessionStorage, setSessionStorage } from 'Utils/SessionStorage'

const useSessionStorageData = ({ extraData, formik, keySessionStorage, ignoreStorageItems }) => {
  const { campgroundSlug: slug } = window.roverData || {}
  const { setValues, values } = formik

  const saveSessionStorageData = useThrottle((values, slug) => {
    setSessionStorage(`${slug}-${keySessionStorage}`, { ...values, ...ignoreStorageItems })
  }, 3000)

  useEffect(() => {
   if (slug) {
     const formData = getSessionStorage(`${slug}-${keySessionStorage}`)
     if (formData) setValues(formData)
   }
  }, [slug])

  useEffect(() => {
    saveSessionStorageData({ ...values, ...extraData }, slug)
  }, [extraData, values])
}

export default useSessionStorageData
