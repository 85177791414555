import cname from 'classnames'
import { CardHeader } from 'reactstrap'

const Header = ({ children, className }) => {
  const classNameWrapper = cname('px-4 py-3', className)

  return <CardHeader className={classNameWrapper}>{children}</CardHeader>
}

export default Header
