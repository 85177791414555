import { Modal, ModalHeader } from 'reactstrap'
import CloseModalButton from 'Shared/CloseModalButton'
import 'Styles/SalmonHeaderModal.scss'

const ModalCustomClose = ({ children, className, isOpen, title, toggle, size }) => {
  const closeButton = (<CloseModalButton isColorWhite onClick={toggle} />)

  return (
    <Modal centered className={className} isOpen={isOpen} toggle={toggle} size={size}>
      <ModalHeader close={closeButton} toggle={toggle}>{title}</ModalHeader>

      {children}
    </Modal>
  )
}

ModalCustomClose.defaultProps = {
  size: 'md',
}

export default ModalCustomClose
