import { Col } from 'reactstrap'
import classNames from 'classnames'
import './VehicleCard.scss'

const VehicleCard = ({ handleSelect, isSelected, length, name, width }) => {
  const colClasses = classNames('card border rounded bg-light text-gray shadow-sm p-2 mb-2 me-2 h-100',
                                { 'selected-card': isSelected })

  return (
    <Col className="vehicle-card g-0 mb-3 pe-2" md="4" onClick={handleSelect} xs="6">
      <div className={colClasses}>
        <strong className="d-block">{name}</strong>
        <span className="d-block">{`${length} ft. x ${width} ft.`}</span>
      </div>
    </Col>
  )
}

export default VehicleCard
