import { Input, Label } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormGroupField from 'Shared/Formik/FormGroupField'
import Tooltip from 'Shared/Tooltip'

const FormCheckbox = ({ children, className, disabled, id, inputClassName, labelClassName, message, name,
                        tooltipIcon, tooltipMessage }) => (
  <FormGroupField check className={className} name={name}>
    {({ field, form, invalid }) => (
      <>
        <Input {...field} checked={form.values[name]} className={inputClassName} disabled={disabled}
               id={id || name} invalid={invalid} name={name} type="checkbox" />

        <Label check className={labelClassName} for={id || name}>
          {children || message}
        </Label>

        {tooltipMessage && tooltipIcon && (
          <>
            <FontAwesomeIcon className="ms-1" color="grey" icon={tooltipIcon} id={`checkbox-tooltip-${name}`} />

            <Tooltip idTarget={`checkbox-tooltip-${name}`} placement="right" value={tooltipMessage} />
          </>
        )}
      </>
    )}
  </FormGroupField>
)

FormCheckbox.defaultProps = {
  inputClassName: 'me-2',
}

export default FormCheckbox
