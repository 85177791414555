export const initialFormValues = {
  amount: {
    grandTotal: 0,
    camperFee: 0,
    camperProcessingFee: 0,
  },
  number: '',
  refundMethod: '',
  reason: '',
  reasonText: '',
  reversePayout: {
    total: 0,
  },
}
