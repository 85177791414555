import PropTypes from 'prop-types'
import Addon from './Addon'
import QuantityButtons from 'Shared/QuantityButtons/QuantityButtons'

const AddonWraper = ({ addProduct, inStore, product, quantity, quantityKey, removeProduct, updateProduct }) => {
  const onChange = value => {
    if (!inStore)
      return addProduct({ ...product, quantity: value })

    if (value === 0)
      return removeProduct({ id: product.id })

    const params = { id: product.id }
    params[quantityKey] = value
    updateProduct(params)
  }

  const minimumProductValue = product.builderRequired ? 1 : 0

  return (
    <Addon className="addons-card" product={product}>
      <QuantityButtons count={inStore ? quantity : minimumProductValue} min={minimumProductValue}
                       name={`addons[${product.id}]`} onChange={onChange} realName={product.name} />
    </Addon>
  )
}

AddonWraper.defaultProps = {
  addProduct: () => {},
  inStore: true,
  quantity: 0,
  quantityKey: 'quantity',
  removeProduct: () => {},
  // TODO: Validate if update will be required
  // updateProduct: () => {},
}

AddonWraper.propTypes = {
  addProduct: PropTypes.func,
  inStore: PropTypes.bool,
  quantity: PropTypes.number,
  quantityKey: PropTypes.string,
  removeProduct: PropTypes.func,
  updateProduct: PropTypes.func.isRequired,
}

export default AddonWraper
