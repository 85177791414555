import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import LogInModal from 'Shared/LogInModal/LogInModal'

const LogInButton = ({ alertClasses, buttonText, color, outline, showHelpText }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(prevState => !prevState)

  return (
    <>
      <div className={alertClasses}>
        { showHelpText && (
          <>
            <FontAwesomeIcon className="me-1 fa-xl" icon="circle-info" />

            <p className="m-3">Sign in to see or save a payment method for future use.</p>
          </>
        )}

        <Button color={color} onClick={openModal} outline={outline}>{buttonText}</Button>
      </div>

      <LogInModal isOpen={isModalOpen} toggle={openModal} />
    </>
  )
}

LogInButton.propTypes = {
  alertClasses: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  showHelpText: PropTypes.bool,
}

LogInButton.defaultProps = {
  alertClasses: '',
  buttonText: 'Traveler Log In',
  color: 'light',
  outline: true,
  showHelpText: false,
}

export default LogInButton
