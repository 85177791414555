import { useEffect, useMemo } from 'react'
import { useCnameValidation } from 'Shared/VerifyWebsite/Hooks/useCnameValidation'
import CnameError from 'Shared/VerifyWebsite/Components/CnameStatus/CnameError'
import IconStatus from 'Shared/VerifyWebsite/Components/IconStatus/IconStatus'

const CnameStatus = ({ canSecure, domain, onCnameCorrect }) => {
  const { errors, isError, isLoading, isCnameCorrect, refetch } = useCnameValidation(domain)
  const cnameStatusTitle = useMemo(() => {
    if (isLoading) return 'Checking CNAME...'
    if (isError) return 'CNAME ERROR!'

    return 'CNAME setup'
  }, [isError, isLoading])

  useEffect(() => {
    if (isCnameCorrect) onCnameCorrect()
  }, [isError, isCnameCorrect])

  return (
    <div className="mb-2">
      <span className="card-subtitle mb-2 fw-bold">
        <IconStatus isCorrect={isCnameCorrect} isError={isError} isLoading={isLoading} />

        {cnameStatusTitle}
      </span>

      {!isLoading && isError && <CnameError canSecure={canSecure} errors={errors} refetch={refetch} />}
    </div>
  )
}

export default CnameStatus
