import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const RefundReservation = ({ hasRefundsPending, links, pricing }) => {
  if (hasRefundsPending)
    return <a className="text-gray-600" href={links?.refundsUrl}>
      <FontAwesomeIcon className="refund-icon fa-flip-horizontal me-1"
                       color="#f19a2f" icon="share" />

      <span className="text-decoration-underline">Refund(s) Pending</span>
    </a>

  return (
    <span className="text-gray-600">{pricing.totalRefunded?.display} Refunded</span>
  )
}

export default RefundReservation
