import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const PaymentMethodIcons = () => (
  <div className="d-flex mb-2">
    <FontAwesomeIcon className="me-2" size="lg" icon={['fab', 'cc-visa']} />
    <FontAwesomeIcon className="me-2" size="lg" icon={['fab', 'cc-diners-club']} />
    <FontAwesomeIcon className="me-2" size="lg" icon={['fab', 'cc-amex']} />
    <FontAwesomeIcon className="me-2" size="lg" icon={['fab', 'cc-mastercard']} />
  </div>
)

export default PaymentMethodIcons
