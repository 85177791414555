import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { useInvoice } from './InvoiceContext'
import installmentStripeChargeMutation from 'Admin/Mutations/installmentStripeChargeMutation'
import { CreateStripeCharge, LinkStripeCharge } from './Actions'
import AlertShowError from 'Shared/Alert/AlertShowError/AlertShowError'
import CloseModalButton from 'Shared/CloseModalButton'
import Loading from 'Shared/Loading'
import 'Styles/Button.scss'
import 'Styles/SalmonHeaderModal.scss'

const ModalWrapper = () => {
  const [linkStripeCharge, { data, error, loading }] = useMutation(installmentStripeChargeMutation)
  const { id, isCreateModalOpen, toggleCreateModal, isLinkModalOpen, toggleLinkModal } = useInvoice()
  const isOpen = isCreateModalOpen || isLinkModalOpen
  const modalId = isCreateModalOpen ? 'create-stripe-charge-modal' : 'link-stripe-charge-modal'
  const title = isCreateModalOpen ? 'Create Stripe Charge' : 'Link to existing Stripe Charge'

  const link = values => {
    const variables = { installmentId: id, stripeChargeKey: values?.stripeChargeKey }
    linkStripeCharge({ variables })
  }

  const modalBody = props => {
    if (loading) return <Loading className="text-center" iconColor="salmon" />
    if (error) return <AlertShowError className="p-2" error={error.message} />
    if (isLinkModalOpen) return <LinkStripeCharge {...props} />
    if (isCreateModalOpen) return <CreateStripeCharge {...props} />
  }

  const toggle = () => {
    if (isCreateModalOpen) toggleCreateModal()
    if (isLinkModalOpen) toggleLinkModal()
  }

  useEffect(() => {
    if (data?.installmentStripeCharge?.success) {
      toggle()
      window.location.reload()
    }
  }, [data])

  return (
    <Modal centered className="salmon-header-modal" id={modalId} isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="modal-header" close={<CloseModalButton isColorWhite onClick={toggle} />}
                   toggle={toggle}>
        <span className="text-white">{title}</span>
      </ModalHeader>

      <ModalBody>
        {modalBody({ link, toggle })}
      </ModalBody>
    </Modal>
  )
}
export default ModalWrapper
