import { gql } from 'Utils/apolloClient'

const websiteLinksQuery = gql`
  query WebsiteLinksQuery {
    options {
      externalLinks {
        websitesManageUrl
      }
    }
  }
`

export default websiteLinksQuery
