import { useRef } from 'react'
import cnames from 'classnames'
import { Input, Label, FormGroup } from 'reactstrap'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import './Checkbox.scss'

const Checkbox = ({ className, classNameInput, classNameWrapper, color, disabled, handleClick, id, isChecked,
                    isCircle, isIndeterminate, labelClassname, labelChildren, message, name }) => {
  const checkRef = useRef()
  const classnames = cnames(className, labelClassname)
  const classnamesForInput = cnames(classNameInput, {
    'checkbox--circle': isCircle,
    [`checkbox--${color}-color`]: !!color,
  })

  useEffectAfterMount(() => {
    if (checkRef?.current)
      checkRef.current.indeterminate = isIndeterminate
  }, [isIndeterminate])

  return (
    <FormGroup check className={classNameWrapper}>
      <Input checked={isChecked} className={classnamesForInput} disabled={disabled} id={id || name}
             innerRef={checkRef} name={name || id} onChange={handleClick} type="checkbox" />

      <Label check className={classnames} for={id || name}>
        {labelChildren || message}
      </Label>
    </FormGroup>
  )
}

Checkbox.defaultProps = {
  isChecked: false,
  isCircle: false,
  isIndeterminate: false,
}

export default Checkbox
