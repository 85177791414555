import PropTypes from 'prop-types'
import { fillArrayWithMinToMax } from 'Utils/Arrays'
import { formatToday } from 'Utils/Dates'
import { operationWithDecimals } from 'Utils/Math'
import { roundWithDecimals } from 'Utils/Parse'
import multiplePaymentsSchema from './multiplePaymentsSchema'
import useThrottle from 'Shared/Hooks/useThrottle'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import PaymentsSchedule from 'Shared/MultiplePayments/Form/PaymentsSchedule'
import PaymentsTitle from './PaymentsTitle'
import { initialPaymentsValues } from './InitialPaymentsValues'

const MultiplePaymentsForm = ({ children, formId, isChronologicalOrderRequired, isTotalRequired,
                                onChangeValues, onFormikError, onSubmit, savedValues, TitleComponent,
                                totalDisabled, useInvoices, withClearDates }) => {
  const getSinglePayment = total => [{ id: 1, dueOn: formatToday(), total }]
  const setToday = id => (id === 1 ? formatToday() : null)
  const schema = multiplePaymentsSchema({ isChronologicalOrderRequired, isTotalRequired, useInvoices })

  const saveDataInStore = useThrottle((values, status) => {
    if (status) onChangeValues(values)
  }, 1000)

  return (
    <FormWithValidations formId={formId} initialValues={initialPaymentsValues}
                         onChangeValues={saveDataInStore} onFormikError={onFormikError} onSubmit={onSubmit}
                         savedValues={savedValues} scrollToError validationSchema={schema}>
        {props => {
          const { setFieldValue, setTouchFormFields, setStatus, setValues, values } = props
          const { numberOfPayments, payments, total } = values

          const onChangeNumberPayments = ({ target }) => {
            const { value } = target
            const number = parseInt(value)
            const isDifferentCount = number !== payments.length

            if (total > 0 && isDifferentCount && number > 0) {
              const splitTotal = roundWithDecimals(total / number)
              const paymentIds = fillArrayWithMinToMax({ max: number, min: 1 })

              const payments = paymentIds.map(id => {
                let totalForPayment = splitTotal
                if (id === number) {
                  const totalSumWithoutLastInstallment = splitTotal * (number - 1)
                  totalForPayment = operationWithDecimals(total, totalSumWithoutLastInstallment, false)
                }

                return { dueOn: setToday(id), id, total: totalForPayment, number: id }
              })

              setValues({ ...values, payments, numberOfPayments: number })
              setStatus(true)
              setTouchFormFields(false)
            }
          }

          const toProps = { ...props, getSinglePayment }

          const resetNextDates = (fromPayment, dueOn) => {
            if (!withClearDates) return

            const paymentsWithoutDueOn = payments.map(payment => {
              if (payment.id > fromPayment) return { ...payment, dueOn: null }
              if (payment.id === fromPayment) return { ...payment, dueOn }

              return payment
            })

            setFieldValue('payments', paymentsWithoutDueOn)
          }

          return (
            <>
              {children && children(toProps)}

              <PaymentsSchedule onChangeNumberPayments={onChangeNumberPayments} payments={payments}
                                resetNextDates={resetNextDates} TitleComponent={TitleComponent}
                                totalDisabled={totalDisabled} totalPayments={numberOfPayments} />
            </>
          )
        }}
    </FormWithValidations>
  )
}

MultiplePaymentsForm.defaultProps = {
  formId: 'multiple-payments-form',
  isTotalRequired: true,
  onChangeValues: () => {},
  onFormikError: () => {},
  savedValues: initialPaymentsValues,
  TitleComponent: PaymentsTitle,
  totalDisabled: false,
  useInvoices: true,
  withClearDates: false,
}

MultiplePaymentsForm.propTypes = {
  formId: PropTypes.string,
  isTotalRequired: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onChangeValues: PropTypes.func,
  onFormikError: PropTypes.func,
  savedValues: PropTypes.shape({
    numberOfPayments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    payments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    total: PropTypes.number,
  }),
  TitleComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.any),
  ]),
  totalDisabled: PropTypes.bool,
  useInvoices: PropTypes.bool,
  withClearDates: PropTypes.bool,
}

export default MultiplePaymentsForm
