import { gql } from 'Utils/apolloClient'

const batchProcessMutation = gql`
  mutation BatchProcess($file: Upload!, $name: String!, $userId: ID!) {
    batchProcess(input: { file: $file, name: $name, userId: $userId }) {
      wasQueued
    }
  }
`

export default batchProcessMutation
