import { Label } from 'reactstrap'
import PropTypes from 'prop-types'
import { getSplitPaymentTotals } from 'Utils/Arrays'
import CurrencyInput from 'Shared/CurrencyInput/CurrencyInput'
import FormGroupField from 'Shared/Formik/FormGroupField'

const TotalPayment = ({ isDisabled, name, text }) => (
  <div className="align-middle border-top mt-4 pt-4 row total">
    <Label className="col-md-2 fw-bold mb-0" for={name}>{text}</Label>

    <FormGroupField className="col-md-5" name={name} showErrors>
      {({ field, form: { setStatus, setValues, values } }) => {
        const updateValue = value => {
          const recalculatedPayments = getSplitPaymentTotals({ payments: values.payments, total: value })
          setValues({ ...values, payments: recalculatedPayments, total: value })
          setStatus(true)
        }

        // TODO: Create a new PR to edit the styles
        return (
          <CurrencyInput classTextGroup="px-3" disabled={isDisabled} name="nightlyTotal" inputSize=""
                         onValueChange={updateValue} value={field.value} />
        )
      }}
    </FormGroupField>
  </div>
)

TotalPayment.defaultProps = {
  isDisabled: false,
  name: 'total',
  text: 'TOTAL',
}

TotalPayment.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  text: PropTypes.string,
}

export default TotalPayment
