import { gql } from 'Utils/apolloClient'

const Campgrounds = gql`
  query Campgrounds($userToken: String) {
    currentUser(token: $userToken) {
      campgroundsAvailable {
        nodes {
          id
          name
          slug
          links {
            dashboardUrl
          }
        }
      }
    }
    options {
      externalLinks {
        campgroundsUrl
      }
    }
  }`

export default Campgrounds
