import { useEffect } from 'react'
import { setCookie } from 'Utils/Cookies'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import userSessionCreateMutation from 'Shared/LogInModal/Mutations/CurrentUserMutation'
import { Button } from 'reactstrap'
import EmailInput from 'Shared/Formik/EmailInput'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import Loading from 'Shared/Loading'
import MessageError from 'Shared/MessageError/MessageError'
import ValidateSchema from 'Shared/LogInModal/Components/LogInForm/ValidateSchema'

const LogInForm = ({ closeModal, formToggle }) => {
  const [userSessionCreate, { error: errorSignInUser, data, loading }] = useMutation(userSessionCreateMutation)

  const initialFormValues = { email: '', password: '' }

  const handleOnSubmit = values => {
    userSessionCreate({ variables: values })
  }

  useEffect(() => {
    if (!errorSignInUser && data) {
      const { currentUser } = data.createUserSession
      setCookie('user_token', currentUser.authenticationToken, { expires: 6 * 60 })
      closeModal()
      window.location.reload()
    }
  }, [data])

  return (
    <>
      <p className="text-center text-muted">
        Log in to submit requests or
        <Button className="text-decoration-none p-1" color="link" onClick={formToggle}>
          create a free account
        </Button>
        to quickly check availability at campgrounds across the country.
      </p>

      {(errorSignInUser) && <MessageError error={errorSignInUser} /> }

      <FormWithValidations className="payment-method-form" disableRenderProps initialValues={initialFormValues}
                           onSubmit={handleOnSubmit} validationSchema={ValidateSchema}>
        <EmailInput label="Email" name="email" placeholder="youremail@andyadress.com" sizeInput="md" />

        <FormTextInput label="Password" name="password" placeholder="Your Password" type="password" />

        <Button className="text-white w-100 mt-2" color="salmon" type="submit">
          {loading ? <Loading /> : 'Sign In'}
        </Button>
      </FormWithValidations>
    </>
  )
}

export default LogInForm
