import { gql } from 'Utils/apolloClient'

const PricingDataFragment = gql`
  fragment pricingData on Pricing {
    camperFee {
      display
      displayWithCode
    }
    camperProcessingFee {
      amount
      display
      displayWithCode
    }
    camperTotal {
      amount
      cents
      display
      displayWithCode
    }
    dueNow {
      amount
      display
    }
    dueUponCheckIn {
      amount
      display
    }
    installments {
      dueOn
      id
      number
      processingFeeTotal {
        amount
        display
        displayWithCode
      }
      state
      taxTotal {
        amount
        display
        displayWithCode
      }
      total {
        display
        displayWithCode
      }
    }
    isTaxIncluded
    items {
      base {
        amount
        display
        displayWithCode
      }
      id
      isFee
      itemableId
      itemableType
      name
      units
    }
    nightlyTotal {
      amount
      display
      displayWithCode
    }
    totalRemaining {
      amount
      display
      displayWithCode
    }
    savings {
      amount
      display
      displayWithCode
    }
    subtotal {
      amount
      display
      displayWithCode
    }
    tax {
      amount
      display
      displayWithCode
    }
    taxConfig {
      isIncluded
      rate
      taxName
    }
    taxesAndFees
    totalPaid {
      amount
      display
      displayWithCode
    }
    totalRefunded {
      amount
      display
      displayWithCode
    }
  }`

export default PricingDataFragment
