import { useEffect } from 'react'
import PropTypes from 'prop-types'
import reservationQuery from './reservationQuery'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient/useLazyQueryNoCache'
import GroupBookingsBar from '../GroupBookingsBar'
import Loading from 'Shared/Loading'

const Overview = ({ displayAlert, engineName, linkText, message, redirectUrl, slug, uuid }) => {
  const [getReservation, { data, loading }] = useLazyQueryNoCache(reservationQuery)
  const reservationParentLink = data?.campground?.reservation?.links?.reservationParentUrl

  useEffect(() => {
    getReservation({ variables: { engineName, slug, uuid } })
  }, [])

  if (loading) return <Loading className="d-flex justify-content-center py-5" />

  return (
    <GroupBookingsBar className="mt-3" displayAlert={displayAlert} linkText={linkText} message={message}
                      redirectUrl={redirectUrl} reservationParentLink={reservationParentLink}
                      reservations={data?.campground?.reservation?.reservationsWithParent} selected={uuid} />
  )
}

Overview.propTypes = {
  displayAlert: PropTypes.bool,
  engineName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
}

Overview.defaultProps = {
  displayAlert: false,
}

export default Overview
