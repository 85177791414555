import { Button, Input, Label } from 'reactstrap'
import BankAccountFormSchema from './BankAccountFormSchema'
import FormGroupField from 'Shared/Formik/FormGroupField'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import Loading from 'Shared/Loading'
import './BankAccountForm.scss'

const MANDATE_COPY = "By adding a bank account for ACH, you authorize RoverPass to debit the bank account specified for any amount owed for charges arising from your use of RoverPass's services and/or services provided by campgrounds using RoverPass to collect payments, pursuant to RoverPass's website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time in your RoverPass account portal. If you use RoverPass's services or pay invoices to campgrounds using RoverPass periodically pursuant to RoverPass's and the campground's terms, you authorize RoverPass to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained."

const BankAccountForm = ({ handleOnSubmit, loading }) => {
  const initialFormValues = { accountHolder: '', mandateApproved: false }

  return (
    <FormWithValidations className="bank-account-form" disableRenderProps initialValues={initialFormValues}
                         onSubmit={handleOnSubmit} scrollToError validationSchema={BankAccountFormSchema}>
      <>
        <div className="m-auto mb-2 p-2 pt-1">
          <p className="fs-5 fw-bold">Bank Details</p>

          <FormTextInput label="Account Holder" name="accountHolder" placeholder="Name" />

          <div className="background background-white-ghost rounded px-3 py-2">
            <FormGroupField check className="mt-2" name="mandateApproved">
              {({ field, form, invalid }) => (
                <>
                  <Input {...field} checked={form.mandateApproved} className="me-2" id="mandateApproved"
                        invalid={invalid} name="mandateApproved" type="checkbox" />

                  <Label check className="col-11 text-muted text-sm text-italic" for="mandateApproved">
                    {MANDATE_COPY}
                  </Label>
                </>
              )}
            </FormGroupField>
          </div>
        </div>

        <div className="d-flex justify-content-end mb-3">
          <Button className="col-12 col-md-3 fw-bold" color="blue-dark" type="submit">
            {loading ? <Loading iconClass="text-white" /> : 'Continue' }
          </Button>
        </div>
      </>
    </FormWithValidations>
  )
}

export default BankAccountForm
