import { Label } from 'reactstrap'
import { moment, today } from 'Utils/Dates'
import { tripInsuranceCoverageAlertText } from 'Utils/Translates'
import DateRangePicker from 'Shared/Revision/DateRangePicker'

const DatePicker = ({ campground, handleDateChange, reservation, translates }) => {
  const tripInsuranceCoverageAlert = translates?.[tripInsuranceCoverageAlertText]
  const { createdAt, isFlexible, to, tripInsurancePolicy } = reservation || {}
  const { closeSeasonDates } = campground || {}
  const { cannotCancel, tripInsuranceNightLimit } = tripInsurancePolicy || {}
  const checkOutPlaceholder = isFlexible ? 'TBD' : 'Check Out'
  const disabledField = isFlexible || 'startDate'
  const minYear = moment().subtract(3, 'years').year()
  const todayDate = today()

  const onChangedDates = dates => handleDateChange(dates?.to?.format('YYYY-MM-DD'))

  return (
    <div className="border-bottom-1 justify-content-between mb-3 pb-3 px-0">
      <Label className="control-label text-grey-800" name="from">
        *Stay (check-in & check-out):
      </Label>

      <DateRangePicker alertMessage={tripInsuranceCoverageAlert} canNotCancelInsurance={cannotCancel}
                       checkOutPlaceholder={checkOutPlaceholder} closureDates={closeSeasonDates}
                       disabled={disabledField} endDateId="check_in_form_dates_to" endDateValue={to}
                       isLegacyReservation minYear={minYear} onChangedDates={onChangedDates}
                       reservationCreatedAt={createdAt} startDateValue={todayDate}
                       tripInsuranceNightsLimit={tripInsuranceNightLimit} />

      <Label className="text-cardinal text-sm mt-1">
        <span className="me-1 ">*</span>
        Reservation's start date is different than the current date.
      </Label>
    </div>
  )
}

export default DatePicker
