import RichText from 'Shared/RichText/RichText'

const CancellationPolicies = ({ cancellationPolicies }) => (
  <>
    {Object.entries(cancellationPolicies).map(([name, content]) => {
      const { description, title } = content

      return (
        <div className="mb-3" key={name}>
          <h5 className="fw-bold" id={name}>{title}</h5>
          <RichText className="text-smaller-1 mb-0" html={description || ''} tag="span" />
        </div>
      )
    })}
  </>
)

export default CancellationPolicies
