import { gql } from 'Utils/apolloClient'
import translatesFragment from 'Shared/Queries/TranslateFragment'

const reservationQuery = gql`
  query ReservationQuery ($slug: String!, $uuid: ID!, $isEngineAdmin: Boolean,
                          $keys: [TranslatesKeysAttributes!]!) {
    campground(slug: $slug, isEngineAdmin: $isEngineAdmin) {
      id
      closeSeasonDates
      reservation(uuid: $uuid) {
        balance {
          remaining {
            amount
            display
          }
          due {
            amount
            display
          }
        }
        createdAt
        from
        hasTripInsurance
        id
        installmentPendingToPay {
          links {
            installmentPath
          }
        }
        isFlexible
        to
        tripInsurancePolicy {
          cannotCancel
          tripInsuranceNightLimit
        }
      }
    }

    translates {
      ...translateFragment
    }
  }
  ${translatesFragment}
`

export default reservationQuery
