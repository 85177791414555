import cnames from 'classnames'
import { Collapse } from 'reactstrap'
import { useStateContext } from '../useStateContext'

const Content = ({ children, className }) => {
  const { isOpen } = useStateContext()
  const classes = cnames('collapse-content', className)

  return (
    <Collapse className={classes} isOpen={isOpen}>
      {children}
    </Collapse>
  )
}

export default Content
