import PropTypes from 'prop-types'

const InstallmentPaid = ({ children, title }) => (
  <div className="installment-paid bg-light p-2">
    <p className="fw-bold">{title}</p>

    {children}
  </div>
)

InstallmentPaid.propTypes = {
  title: PropTypes.string.isRequired,
}

export default InstallmentPaid
