import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from '../Queries/userPaymentMethodsDataFragment'

const paymentMethodCreateMutation = gql`
  mutation PaymentMethodCreate($paymentMethod: PaymentMethodAttributes!) {
    paymentMethodCreate(input: { paymentMethod: $paymentMethod }){
      paymentMethod {
        brand
        lastFour
      }
      user {
        id
        ...userPaymentMethodsData
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default paymentMethodCreateMutation
