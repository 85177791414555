import { useEffect } from 'react'
import { getElementDataSet } from 'Utils/Elements'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import eventsQuery from 'Shared/ReservationHistory/Queries/eventsQuery'
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import CloseCanvasBtn from './CloseCanvasBtn'
import EventsWithSkeleton from './EventsWithSkeleton'
import './HistoryCanvas.scss'

const HistoryCanvas = ({ isOpen, toggle }) => {
  const [getEvents, { data, error, loading }] = useLazyQueryNoCache(eventsQuery)
  const { engineName, slug, uuid } = getElementDataSet('react-data-container-history')
  const close = <CloseCanvasBtn toggle={toggle} />
  const events = data?.campground?.reservation?.eventsHistory
  const isAdmin = engineName === 'admin'

  useEffect(() => {
    if (isOpen) {
      const variables = { isAdmin, slug, uuid }
      getEvents({ variables })
    }
  }, [isOpen])

  return (
    <Offcanvas className="history-canvas" direction="end" isOpen={isOpen} scrollable toggle={toggle}>
      <OffcanvasHeader className="pb-0" close={close} toggle={toggle}>
        History
      </OffcanvasHeader>

      <OffcanvasBody className="events-data">
        <EventsWithSkeleton error={error} events={events} loading={loading} />
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default HistoryCanvas
