import { gql } from 'Utils/apolloClient'

const userSessionCreateMutation = gql`
  mutation CreateUserSession($email: String!, $password: String!) {
    createUserSession(input: { email: $email, password: $password}) {
      currentUser {
        authenticationToken
      }
    }
  }
`

export default userSessionCreateMutation
