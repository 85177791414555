import PropTypes from 'prop-types'

const RefundModalRow = ({ displayValue, textStyle, title }) => (
    <div className="d-flex justify-content-between ps-2 pe-2 pt-1">
      <span className={textStyle}>{title}</span>

      <span className={textStyle}>{displayValue}</span>
    </div>
)

RefundModalRow.defaultProps = {
  displayValue: '',
  textStyle: '',
  title: '',
}

RefundModalRow.propTypes = {
  displayValue: PropTypes.string,
  textStyle: PropTypes.string,
  title: PropTypes.string,
}

export default RefundModalRow
