import { createConsumer } from '@rails/actioncable'
import { getMainDefinition } from '@apollo/client/utilities'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { roverPassWSApi } from 'Utils/Apollo/roverPassAPI'

const cable = createConsumer(roverPassWSApi())
const actionCableLink = new ActionCableLink({ cable })

const hasSubscriptions = ({ query }) => {
  const definition = getMainDefinition(query)

  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
}

export { actionCableLink, hasSubscriptions }
