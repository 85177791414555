import cnames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const TitledElement = ({ children, className, icon, title, titleClass, value, valueClass }) => {
  const classes = {
    title: cnames('fw-bold text-dark', titleClass),
    value: cnames('text-brownie', valueClass),
  }

  return (
    <div className={className}>
      <p className={classes.title}>
        {icon && <FontAwesomeIcon className="me-2" icon={icon} />}

        {title}
      </p>

      <div className={classes.value}>{children || value}</div>
    </div>
  )
}

TitledElement.defaultProps = {
  icon: null,
  titleClass: null,
  value: null,
  valueClass: null,
}

TitledElement.prototypes = {
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  value: PropTypes.string,
  valueClass: PropTypes.string,
}

export { TitledElement }
