const baseContainer = 'border p-2 rounded shadow-sm'

export const classes = {
  collapseContainer: baseContainer,
  container: `${baseContainer} py-3 mb-0`,
  headerTitle: 'm-0 text-blue',
}

export const defaulValue = '--'

export const tooltipValues = {
  balance: 'Amount the traveler still owes.',
  difference: 'Difference between the Previous total and the New total once the reservation has been modified.',
  refund: 'Amount You owe to the Traveler',
}

export const cartInfoAlerts = {
  applicable: {
    message: 'Based on the $POLICY_NAME Cancellation Policy',
    title: 'Refund Calculated',
  },
  none: {
    title: 'No Refund Needed',
    message: 'This Booking is Non-Refundable',
  },
  noPolicy: {
    title: 'Optional Refund',
    message: 'The Campground should determine if the refund applies',
  },
}
