import { Input, Label } from 'reactstrap'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'
import { textareaMaxLength } from 'Shared/Formik/Utils'

const FormTextArea = ({ className, helpText, id, inputClasses, isRequired, label, labelClasses, maxLength, name,
                        placeholder, showErrors, rows }) => {
  const classes = {
    input: cnames('control-textarea', inputClasses),
    label: cnames('main-label', labelClasses),
  }

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, hasErrorClass }) => {
        const initValue = field.value ?? ''
        const classesInput = cnames(classes.input, hasErrorClass)

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}
                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}
            {helpText && <RichText className="help-text w-100" html={helpText} />}

            <Input {...field} className={classesInput} id={id || name} maxLength={maxLength} name={name}
                   placeholder={placeholder} rows={parseInt(rows)} type="textarea" value={initValue} />
          </>
        )
      }}
    </FormGroupField>
  )
}

FormTextArea.defaultProps = {
  isRequired: false,
  maxLength: textareaMaxLength,
  rows: 3,
  showErrors: false,
}

export default FormTextArea
