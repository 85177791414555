import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import SingleDoughnutChart from 'Shared/BalanceTracker/DoughnutChart/SingleDoughnutChart'

const BalanceSimple = ({ balance, classes, mainText }) => {
  const { allVoided, total, voided } = balance || {}
  const value = allVoided ? voided : total
  // #TODO: When group booking uses this, implement value and label logic for anything other than allVoided

  return (
    <div className={classes.balanceColStyles}>
      <div className="balance-card d-flex border rounded shadow-sm">
        <div className={classes.lineColor} />

        <div className="balance-content d-flex flex-fill justify-content-between p-2">
          <div>
            <p className="fw-bold text-uppercase">
              <FontAwesomeIcon className="me-1 icon-color" icon="dollar-sign" />
              Balance
            </p>

            <p className={classes.mainTextValue}>
              {value.display}
              <small className={classes.mainText}> {mainText.text}</small>
            </p>

            <div className={classes.balanceDetails}>
              <small>
                {value.display}
                <span className="fw-normal"> Total</span>
              </small>
            </div>
          </div>

          <SingleDoughnutChart label="Paid" color="green" value={value} />
        </div>
      </div>
    </div>
  )
}

export default BalanceSimple
