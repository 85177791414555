import { useState } from 'react'
import CheckDays from './CheckDays'

const CheckDaysWrapper = ({ dailyPrices, nigthsBookTogether }) => {
  const [rangeDays, setRangeDays] = useState({ endsOn: '', startsOn: '' })

  const updateNightsBookTogether = data => setRangeDays(prev => ({ ...prev, ...data }))

  return (
    <div className="nightly-configuration--checkdays">
      <CheckDays {...{ dailyPrices, nigthsBookTogether, updateNightsBookTogether }} />

      <input hidden name="period[nbt_ends_on]" readOnly type="text" value={rangeDays.endsOn} />
      <input hidden name="period[nbt_starts_on]" readOnly type="text" value={rangeDays.startsOn} />
    </div>
  )
}

export default CheckDaysWrapper
