import { gql } from 'Utils/apolloClient'

const customFieldsQuery = gql`
  query CustomFields($isPos: Boolean, $slug: String!) {
    campground(isPos: $isPos, slug: $slug) {
      id
      customFields {
        id
        data
        requiredInCheckout
        requiredInBuilder
        subtitle
        title
        type
      }
    }
  }`

export default customFieldsQuery
