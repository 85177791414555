import { useEffect, useRef, useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { addDateTime, formatDate, moment, parseMoment, today } from 'Utils/Dates'
import { callEventToUncontrolledElement } from 'Utils/Elements'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import DateRangePicker from 'Shared/DatePicker/DateRangePicker/DateRangePicker'
import './GeneralDateRangePicker.scss'
import 'react-dates/initialize'

const initialDateState = { formattedFrom: '', formattedTo: '', initialFrom: '', initialTo: '' }
const initialRangeState = { maxDate: null, minDate: null }

const GeneralDateRangePicker = ({ areDatesRequired, changeFormat, closureDates, disabled, endDateHiddenName,
                                  endDateId, endDatePlaceholder, endDateValue, format, formatReturned, getDates,
                                  isDatesError, maxRange, minimumNights, minRange, minYear, numberOfMonths,
                                  startDateHiddenName, startDateId, startDatePlaceholder, startDateValue,
                                  updateDates, withIds }) => {
  const [dates, setDates] = useState(initialDateState)
  const [range, setRange] = useState(initialRangeState)
  const hiddenFromInput = useRef()
  const hiddenToInput = useRef()
  const classNameWrapper = cnames('general-date-range-picker', { 'date-error': isDatesError })
  const fromPickerName = changeFormat ? `date-${startDateId}` : startDateId
  const toPickerName = changeFormat ? `date-${endDateId}` : endDateId

  const updateFormatDates = (from, to) => {
    const formattedFrom = getFormatted(from)
    const formattedTo = getFormatted(to)

    setDates(prevState => ({ ...prevState, formattedFrom, formattedTo, from, to }))
  }

  const getFormatted = date => formatDate(date, { format: formatReturned })

  const handleOnDateSet = ({ startDate, endDate }) => {
    if (startDate && maxRange) {
      const maxDayAllowed = addDateTime(startDate, maxRange)
      setRange(prevState => ({ ...prevState, maxDate: maxDayAllowed }))
    }

    if (startDate && endDate) {
      updateFormatDates(startDate, endDate)
      updateDates({ startDate, endDate })
    }
  }

  const updateRangeDate = from => {
    let minDate = moment(minRange) || today()
    let maxDate = addDateTime(minDate, maxRange)

    if (minYear) minDate = moment().year(minYear).startOf('year')

    if (from && from < minDate) minDate = from

    if (from && maxRange) maxDate = addDateTime(from, maxRange)

    setRange({ minDate, maxDate })
  }

  useEffect(() => {
    const initialFrom = parseMoment(startDateValue)
    const initialTo = parseMoment(endDateValue)

    if (initialFrom && initialTo) {
      const formattedFrom = getFormatted(startDateValue)
      const formattedTo = getFormatted(endDateValue)

      setDates({ initialFrom, initialTo, formattedFrom, formattedTo })
    }

    updateRangeDate(initialFrom)

    return () => {
      setDates(initialDateState)
      setRange(initialRangeState)
    }
  }, [])

  useEffect(() => {
    if (getDates) getDates(dates)
  }, [dates])

  useEffectAfterMount(() => {
    if (dates.formattedFrom && hiddenFromInput.current)
      callEventToUncontrolledElement({ element: hiddenFromInput.current, value: dates.formattedFrom })
  }, [dates.formattedFrom])

  useEffectAfterMount(() => {
    if (dates.formattedTo && hiddenToInput.current)
      callEventToUncontrolledElement({ element: hiddenToInput.current, value: dates.formattedTo })
  }, [dates.formattedTo])

  return (
    <div className={classNameWrapper} id="general-date-range-picker-container">
      {changeFormat && (
        <>
          <input id={startDateId || startDateHiddenName} name={startDateHiddenName} ref={hiddenFromInput}
                 required={areDatesRequired} type="hidden" />

          <input id={endDateId || endDateHiddenName} name={endDateHiddenName} ref={hiddenToInput}
                 required={areDatesRequired} type="hidden" />
        </>
      )}

      <DateRangePicker checkInId={fromPickerName} checkOutId={toPickerName}
                       checkInPlaceholder={startDatePlaceholder} checkInValue={dates.initialFrom}
                       checkOutPlaceholder={endDatePlaceholder} checkOutValue={dates.initialTo}
                       closureDates={closureDates} disabled={disabled} format={format} isDateError={isDatesError}
                       isPos maxDate={range.maxDate} minDate={range.minDate} minimumNights={minimumNights}
                       numberOfMonths={numberOfMonths} onDateSet={handleOnDateSet} showDefaultInputIcon
                       updateDates={() => {}} withIds={withIds} />
    </div>

  )
}

GeneralDateRangePicker.defaultProps = {
  areDatesRequired: false,
  changeFormat: false,
  closureDates: [],
  disabled: false,
  endDateHiddenName: null,
  endDateId: 'to_date',
  endDatePlaceholder: 'To',
  endDateValue: null,
  format: 'MM/DD/YYYY',
  formatReturned: 'YYYY-MM-DD',
  maxRange: null,
  minimumNights: 1,
  minYear: null,
  numberOfMonths: null,
  startDateHiddenName: null,
  startDateId: 'from_date',
  startDatePlaceholder: 'From',
  startDateValue: null,
  updateDates: () => {},
  withIds: false,
}

GeneralDateRangePicker.propTypes = {
  areDatesRequired: PropTypes.bool,
  changeFormat: PropTypes.bool,
  closureDates: PropTypes.arrayOf(PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  })),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  endDateHiddenName: PropTypes.string,
  endDateId: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  endDateValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]), // NOTE: moment object or string
  format: PropTypes.string,
  formatReturned: PropTypes.string,
  maxRange: PropTypes.shape({}), // Note: send { unit: value } like { months: 3 }
  minimumNights: PropTypes.number,
  minYear: PropTypes.number,
  numberOfMonths: PropTypes.number,
  startDateHiddenName: PropTypes.string,
  startDateId: PropTypes.string,
  startDatePlaceholder: PropTypes.string,
  startDateValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]), // NOTE: moment object or string
  updateDates: PropTypes.func,
  withIds: PropTypes.bool,
}

export default GeneralDateRangePicker
