import { gql } from 'Utils/apolloClient'

const InstallmentScheduleAndCreateMutation = gql`
  mutation InstallmentScheduleAndCreate($dueOn: ISO8601Date, $installmentId: ID!, $slug: ID!) {
    installmentScheduleAndCreate(input: { campgroundId: $slug, dueOn: $dueOn, installmentId: $installmentId }) {
      installment {
        id
        links {
          installmentPath
        }
      }
    }
  }`

export default InstallmentScheduleAndCreateMutation
