import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'
import useParamsHandler from 'Shared/Hooks/useParamsHandler'

const DEFAULT_OPTIONS = [10, 25, 50]

const ItemsPerPage = ({ itemsName, needsQueryUrl, pageName, perPage, updatePerPage }) => {
  const params = useParamsHandler()

  const handleOnChange = event => {
    const { value } = event.target
    const itemsPerPage = parseInt(value)
    if (needsQueryUrl) {
      const optionals = { [pageName]: 1 }
      params.replaceQuery({ [itemsName]: itemsPerPage }, optionals)
    }
    updatePerPage(itemsPerPage)
  }

  const getPerPageToShow = () => {
    if (perPage > 100) return 100
    if (perPage < 1) return 10

    return perPage
  }

  const perPageToShow = getPerPageToShow()
  const options = DEFAULT_OPTIONS.includes(perPageToShow) ? DEFAULT_OPTIONS : [perPageToShow, ...DEFAULT_OPTIONS]

  return (
    <div className="d-flex justify-content-end align-items-center">
      <Label for="per-page" className="p-0 me-2 mb-0">Per page</Label>

      <Input type="select" id="per-page" name={pageName} onChange={handleOnChange} value={perPage}
             className="w-auto">
        {options.map(option => (<option key={option}>{option}</option>))}
      </Input>
    </div>
  )
}

ItemsPerPage.propTypes = {
  itemsName: PropTypes.string.isRequired,
  needsQueryUrl: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  updatePerPage: PropTypes.func.isRequired,
}

export default ItemsPerPage
