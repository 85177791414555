import { useEffect } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient/useLazyQueryNoCache'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import CloseModalButton from 'Shared/CloseModalButton'
import DraftBreakDown from 'Shared/CreateInvoiceModal/Modal/DraftBreakDown'
import Loading from 'Shared/Loading'
import Tabs from 'Shared/CreateInvoiceModal/Modal/Tabs'
import reservationQuery from 'Shared/Queries/ReservationQuery'
import 'Styles/Button.scss'
import 'Styles/SalmonHeaderModal.scss'

const ModalWrapper = ({ isOpen, toggle }) => {
  const [getReservation, { data, loading }] = useLazyQueryNoCache(reservationQuery)
  const closeButton = (<CloseModalButton isColorWhite onClick={toggle} />)
  const installmentDraft = data?.campground?.reservation?.installmentDraft
  const { campgroundSlug, isAdmin, uuid } = window.roverData

  useEffect(() => {
    if (isOpen)
      getReservation({ variables: { isEngineAdmin: isAdmin, slug: campgroundSlug, uuid } })
  }, [isOpen])

  return (
    <Modal centered className="salmon-header-modal" id="check_in_form" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="modal-header" close={closeButton} toggle={toggle}>
        <span className="text-white">Create Invoice</span>
      </ModalHeader>

      <ModalBody>
        { loading ? <Loading className="d-flex justify-content-center py-5" /> :
          data &&
            <>
              <DraftBreakDown installmentDraft={installmentDraft} />

              <Tabs closeModal={toggle} installmentDraft={installmentDraft} slug={campgroundSlug} />
            </> }
      </ModalBody>
    </Modal>
  )
}

export default ModalWrapper
