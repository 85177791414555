import Loading from 'Shared/Loading'
import Table from 'Shared/Admin/Table/Table'
import EmptyTable from 'Shared/Admin/Table/EmptyTable/EmptyTable'

const WrapperTable = props => {
  const { actionLink, actionLinkText, children, data, emptyMessage, emptyIcon, loading,
          secondaryMessage, useActionAlert, useEmptyComponent } = props

  if (loading) return <Loading className="d-flex justify-content-center py-5" />

  return (
    <>
      {children}

      {(!data?.length) ? (
        <EmptyTable actionLink={actionLink} actionLinkText={actionLinkText} icon={emptyIcon} message={emptyMessage}
                    secondaryMessage={secondaryMessage} useActionAlert={useActionAlert}
                    useEmptyComponent={useEmptyComponent} />
      ) : (
        <Table {...props} />
      )}
    </>
  )
}

export default WrapperTable
