import { useRef } from 'react'
import { useInvoice } from './InvoiceContext'
import { Input } from 'reactstrap'

const ActionSelect = () => {
  const lastSelected = useRef(null)
  const select = useRef(null)
  const { toggleCreateModal, toggleLinkModal } = useInvoice()

  const forceChange = event => {
    if (select.current.value === lastSelected.current)
      handleActionChange(event)

    lastSelected.current = select.current.value
  }

  const handleActionChange = event => {
    const selectedAction = event.target.value
    if (selectedAction === 'create-stripe-charge') toggleCreateModal()
    if (selectedAction === 'link-stripe-charge') toggleLinkModal()
    lastSelected.current = selectedAction
  }

  return (
    <Input className="w-50 mx-auto" id="invoice-actions" onChange={handleActionChange} onClick={forceChange}
           ref={select} type="select">
      <option value="">Select an action</option>
      <option value="link-stripe-charge">Link to Stripe Charge</option>
      <option value="create-stripe-charge">Create Stripe Charge</option>
    </Input>
  )
}

export default ActionSelect
