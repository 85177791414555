import { useEffect, useState } from 'react'
import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import AlertErrors from 'Shared/Alert/AlertErrors'
import SitesList from '../Components/List/SitesList'

const Summary = ({ calendarUrl, error, loading, mutationData }) => {
  const [sites, setSites] = useState()
  const loadingClass = cnames({ 'skeleton-loading': loading })
  const { summaryErrors } = mutationData?.blackoutDatesBulkRemove || {}

  useEffect(() => {
    if (mutationData?.blackoutDatesBulkRemove) setSites(mutationData.blackoutDatesBulkRemove.summary)
  }, [mutationData])

  if (error) return (
    <>
      <p>Please refresh the page and try again.</p>

      <AlertErrors graphQLErrors={error} />
    </>
  )

  return (
    <div className={loadingClass}>
      {summaryErrors?.length > 0 && (
        <AlertErrors errors={summaryErrors} withScroll />
      )}

      <p className="d-inline-block">
        <FontAwesomeIcon className="fw-bold me-2 text-success" icon={['far', 'circle-check']} size="lg" />
        <span>Successfully Unblocked Sites</span>
      </p>

      <SitesList loading={loading} name="summary" setSites={setSites} sites={sites} />

      <div className="position-sticky sticky-bottom bg-white mt-5 pb-3">
          <hr />

          <a href={calendarUrl} className="btn btn-salmon">Back to the Calendar</a>
        </div>
    </div>
  )
}

export default Summary
