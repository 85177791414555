import cnames from 'classnames'
import { Label } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'
import PropTypes from 'prop-types'
import Switch from 'react-switch'

const FormSwitch = ({ checked, classContainer, classLabel, className, id, label, name, onChange, onColor }) => {
  const classNameLabel = cnames('mb-0 h6', classLabel)
  const switchId = `switch-${id || name}`

  return (
    <FormGroupField className={className} name={name}>
      {({ field, form }) => {
        const handleChange = checked => form.setFieldValue(name, checked)

        const isChecked = checked === undefined ? form.values[name] : checked

        return (
          <div className={classContainer}>
            <Switch {...field} checked={isChecked} checkedIcon={false} className="react-switch" height={16}
                    id={switchId} name={name} onChange={onChange || handleChange} onColor={onColor}
                    uncheckedIcon={false} width={32} />

            <Label for={switchId} className={classNameLabel}>
              {label}
            </Label>
          </div>
        )
      }}
    </FormGroupField>
  )
}

FormSwitch.defaultProps = {
  classContainer: 'd-flex align-items-center mb-0',
}

FormSwitch.propTypes = {
  classContainer: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default FormSwitch
