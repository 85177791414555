import { numberSystem } from 'Shared/RegExpressions'

export const absoluteValue = value => Math.abs(value)

export const escapeBlank = (value, options = { defaultValue: '' }) => {
  const { defaultValue } = options

  return value || defaultValue
}

export const numberToMoney = (amount = 0, currency = 'USD') => {
  const money = parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency })
  return money
}

export const numberToMoneyWithCurrency = (amount = 0, currency = 'USD') => {
  const money = numberToMoney(amount)

  return `${money} ${currency}`
}

export const parseObject = object => JSON.parse(JSON.stringify(object))

export const parsePrice = price => {
  if (!price) return 0

  const amount = price.toString()
  const number = parseFloat(amount.replace(numberSystem, ''))

  return number
}

export const roundWithDecimals = (value, decimals = 2) => {
  // NOTE: JavaScript doesn't support rounding decimals by default
  const factor = 10 ** decimals

  return Math.round((value + Number.EPSILON) * factor) / factor
}

export const toNumber = (value, options = { float: false, defaultValue: 0 }) => {
  const { float, defaultValue } = options
  if (float) return parseFloat(value) || defaultValue

  return parseInt(value) || defaultValue
}

export const toKebabCase = string => {
  if (!string) return false

  return string.split(' ').map(x => x.toLowerCase()).join('-')
}

export const valuesToNumber = (obj, options = { float: false, defaultValue: 0, except: [] }) => {
  const { except } = options
  const object = { ...obj }

  Object.entries(object).forEach(([key, value]) => {
    if (!except.includes(key))
      object[key] = toNumber(value, options)
  })

  return object
}

// TODO: Remove when apollo-client decides to blacklist `__typename` from variables/results
// https://github.com/apollographql/apollo-feature-requests/issues/6
export const withoutTypeName = object => {
  if (object.__typename)
    delete object.__typename

  return object
}

export const jsonToArray = object => Object.keys(object).map(key => ({ key, value: object[key] }))
