import { gql } from 'Utils/apolloClient'

const externalLinksQuery = gql`
  query ExternalLinksQuery {
    options {
      externalLinks {
        forgotPassword
      }
    }
  }`

export default externalLinksQuery
