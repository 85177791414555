import { Popover, PopoverBody } from 'reactstrap'
import Refund from 'Shared/RefundsPending/Refund'
import 'Shared/RefundsPending/RefundsPopover.scss'

const RefundsPopover = ({ isOpen, refunds, targetId, text, toggle }) => {
  const refundsToShow = refunds.filter(refund => refund.state === 'pending')
  const refundsCount = (refundsToShow?.length || 1) - 1

  return (
    <Popover className="refunds-popover" isOpen={isOpen} placement="bottom" target={targetId} toggle={toggle}>
      <PopoverBody>
        <div className="refunds-popover--content">
          {refundsToShow.map((refund, index) => (
            <Refund {...{ index, refund, refundsCount, text }} key={refund.id} />
          ))}

          <hr className="my-2" />

          <span className="fst-italic text-muted">
            May take up to 3 business days
          </span>
        </div>
      </PopoverBody>
    </Popover>
  )
}

export default RefundsPopover
