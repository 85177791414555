import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const FilterButton = ({ className, color, filterSelected, label, onClick }) => (
  <Button active={filterSelected} className={className} color={color} onClick={onClick} type="button">
    <span>{label}</span>
  </Button>
)

FilterButton.defaultProps = {
  color: 'outline-primary',
  filterSelected: false,
}

FilterButton.propTypes = {
  color: PropTypes.string,
  filterSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FilterButton
