import { gql } from 'Utils/apolloClient'

const eventsQuery = gql`
  query eventsHistoryQuery ($isAdmin: Boolean, $slug: String!, $uuid: ID!) {
    campground(slug: $slug) {
      id
      reservation(uuid: $uuid) {
        eventsHistory(filters: { admin: $isAdmin }) {
          amount {
            display
          }
          changesHumanized
          dataChanges
          id
          installmentId
          installmentNumber
          installmentLink
          itemName
          itemTotal {
            display
          }
          name
          occurredAt
          changesMoney {
            from {
              display
            }
            name
            to {
              display
            }
          }
          type
          userEmail
        }
      }
    }
  }`

export default eventsQuery
