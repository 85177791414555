import { ApolloLink } from '@apollo/client'
import { getCSRFToken } from '../Security'
import { createUploadLink } from 'apollo-upload-client'
import { SentryLink } from 'apollo-link-sentry'
import { roverPassApi } from 'Utils/Apollo/roverPassAPI'

const uploadLink = createUploadLink({
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
  uri: roverPassApi,
})

const sentryLink = new SentryLink({
  setTransaction: false,
  setFingerprint: false,
  attachBreadcrumbs: {
    includeError: true,
  },
})

const apolloLink = new ApolloLink((operation, forward) => {
  const { token } = window.roverData
  const csrfToken = getCSRFToken()
  const headers = token ? { authorization: `Bearer ${token}`, 'X-CSRF-Token': csrfToken } : ''
  operation.setContext({ headers, credentials: 'same-origin' })

  return forward(operation)
})

export const apolloAuthLink = apolloLink.concat(uploadLink, sentryLink)
