import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'
import { useDebouncedCallback } from 'use-debounce'
import { addClass, getAttributeValue, getElementQuery, getElements, removeClass } from 'Utils/Elements'
import { initialState, mapReducer } from './interactiveMapReducer'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import MapControls from './MapControls/MapControls'
import MapInteraction from './MapInteraction'
import MapLegend from './MapLegend/MapLegend'
import MapLoading from './MapLoading'
import MapSitesPopover from './MapSitesPopover'
import MobileModal from './MobileModal'
import './InteractiveMap.scss'

const InteractiveMap = ({ children, resetSiteSelected, showMapLegend, showUnavailableOnline, siteId, sites, src,
                          selectedSite }) => {
  const [mapState, setMapState] = useReducer(mapReducer, initialState)
  const debouncedUpdateMap = useDebouncedCallback(data => updateMapState(data), 180)
  const { isDesktopView } = useWindowDimensions()
  const { isPopoverOpen, siteSelected, siteSvgId } = mapState

  const MapSitesWrapper = isDesktopView ? MapSitesPopover : MobileModal

  const updateMapState = data => setMapState({ payload: data })

  const resetMapState = () => updateMapState(initialState)

  const describeSite = ({ siteAssigned, svgId }) => {
    const previousSite = mapState.siteSvgId
    resetMapState()

    if (previousSite && previousSite === svgId) return false

    const previousElement = getElementQuery('.site.selected')
    if (previousElement) previousElement.classList.toggle('selected')

    const dataSite = sites.find(site => siteAssigned === site.id)
    const data = { isPopoverOpen: true, siteSelected: dataSite, siteSvgId: svgId }

    debouncedUpdateMap(data)
  }

  const mapId = site => site.id

  const bindSitesEvents = svg => {
    const siteNodes = getElements('.site[data-id]', { by: 'queryAll', base: svg })
    const arrSiteNodes = Array.from(siteNodes)
    const siteIds = sites.map(mapId)
    const siteIdsUO = showUnavailableOnline ? sites.filter(site => !site.reservableByGuest).map(mapId) : []

    arrSiteNodes.forEach((site, index) => {
      const dataId = getAttributeValue(site, 'data-id')
      const siteClass = siteIdsUO.includes(dataId) ? 'unavailable-online' : 'available'

      if (siteIds.includes(dataId)) {
        addClass(site, siteClass)
        site.setAttribute('id', `site_${index}`)
        site.addEventListener('click', () => describeSite({ siteAssigned: dataId, svgId: dataId }))

        if (siteSelected?.id === dataId || siteId === dataId)
          addClass(site, 'selected')
      } else
        removeClass(site, siteClass)
    })
  }

  const getSiteNodes = () => {
    const siteNodes = getElements('.site[data-id]', { by: 'queryAll' })
    const arrSiteNodes = Array.from(siteNodes)
    const index = arrSiteNodes.findIndex(site => selectedSite.id === getAttributeValue(site, 'data-id'))

      if (index >= 0) return describeSite({ siteAssigned: selectedSite.id, svgId: selectedSite.id })

      resetMapState()
  }

  const afterInjectSvg = (error, svg) => {
    if (!svg || error) return false

    return bindSitesEvents(svg)
  }

  useEffect(() => {
    if (selectedSite)
      getSiteNodes()
  }, [selectedSite?.id])

  useEffect(() => {
    if (!isPopoverOpen && selectedSite?.id) resetSiteSelected()
  }, [isPopoverOpen])

  return (
    <>
      <div className="my-1">
        <p className="fw-bold">Campground Map</p>
      </div>

      {showMapLegend && <MapLegend showUnavailableOnline={showUnavailableOnline} />}

      <div id="interactive-map" className="interactive-map mb-5 p-0 position-relative user-select-none">
        <MapInteraction controls={MapControls}>
          <ReactSVG afterInjection={afterInjectSvg} className="not-loading w-100" src={src} loading={MapLoading} />
        </MapInteraction>

        <MapSitesWrapper isOpen={isPopoverOpen} svgSelectedSiteId={siteSvgId} parentId="interactive-map">
          {children && children({ resetMapState, siteSelected })}
        </MapSitesWrapper>
      </div>
    </>
  )
}

InteractiveMap.defaultProps = {
  resetSiteSelected: () => {},
  showMapLegend: false,
  showUnavailableOnline: false,
  siteId: '',
  sites: [],
}

InteractiveMap.propTypes = {
  resetSiteSelected: PropTypes.func,
  showMapLegend: PropTypes.bool,
  showUnavailableOnline: PropTypes.bool,
  siteId: PropTypes.string,
  sites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  src: PropTypes.string.isRequired,
}

export default InteractiveMap
