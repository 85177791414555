const BASE_MONEY_FIELD = { display: '$0.00' }

export const PRICING_FOR_SKELETON = {
  platformFee: BASE_MONEY_FIELD,
  platformFeeName: 'Loading',
  processingFeeTotal: BASE_MONEY_FIELD,
  subtotal: BASE_MONEY_FIELD.display,
  tax: BASE_MONEY_FIELD,
  total: BASE_MONEY_FIELD,
 }

export const getPricingInstallment = pricing => {
  const { camperFee, camperProcessingFee, camperTotal, subtotal, tax } = pricing || {}

  return { platformFee: camperFee, platformFeeName: 'Platform Fee', processingFeeTotal: camperProcessingFee,
           subtotal: subtotal?.display, tax, total: camperTotal }
}
