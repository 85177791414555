import { useApolloClient } from '@apollo/client'

const useAsyncQuery = query => {
  const client = useApolloClient()

  const queryFn = async options => {
    try {
      const queryArgs = { query, ...options }
      const response = await client.query(queryArgs)
      return response
    } catch (error) {
      return error
    }
  }

  return queryFn
}

export { useAsyncQuery }
