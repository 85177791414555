import cnames from 'classnames'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import FormCurrencyInput from 'Shared/Formik/FormCurrencyInput'
import FormPercentageInput from 'Shared/Formik/FormPercentageInput'
import FormQuantityButtons from 'Shared/Formik/FormQuantityButtons'
import FormTextInput from 'Shared/Formik/FormTextInput'

const BasicFields = ({ children }) => {
  const { meterRead } = useSelector(state => state.invoices?.item) || {}
  const { values } = useFormikContext()
  const { category } = values
  const displayQty = category === 'custom' || category === 'utility'
  const disableName = category === 'late_payment'
  const firstInputsClasses = cnames({ 'col-md-6': displayQty })
  const firstRowClasses = cnames({ row: displayQty })
  const quantityLabel = category === 'utility' && meterRead?.unit ? meterRead?.unit : 'Quantity'

  return (
    <div className="bg-light p-2 mb-4">
      <div className={firstRowClasses}>
        <FormTextInput className={firstInputsClasses} disabled={disableName} isRequired label="Description"
                       name="name" placeholder="Payment" showErrors sizeInput="lg" />

        {displayQty && (
          <FormQuantityButtons className={firstInputsClasses} isRequired label={quantityLabel} labelClasses="mb-2"
                               labelWrapper="justify-content-start" name="units" />
        )}
      </div>

      <div className="row">
        <FormCurrencyInput className="col-md-6" isRequired label="Amount" name="price" showErrors />

        <FormPercentageInput className="col-md-6" label="Tax Rate" labelClasses="mb-2" max={100} min={0}
                             name="tax" showErrors />
      </div>

      {children}
    </div>
  )
}

export default BasicFields
