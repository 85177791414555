import { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { useLazyQuery, useMutation } from 'Shared/Hooks/useApolloClient'
import { INITIAL_MUTATION, UNDO_SUCCESS_MSG } from './constants'
import { removeItemSessionStorage } from 'Utils/SessionStorage'
import { setCookie } from 'Utils/Cookies'
import addBlackoutDatesMutation from './Queries/addBlackoutDatesMutation'
import undoBlackoutDatesMutation from './Queries/undoBlackoutDatesMutation'
import sitesQuery from './Queries/sitesQuery'
import AddBlackoutDatesModal from './AddBlackoutDatesModal'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Toast from './Toast'

const Wrapper = ({ isAdmin, slug }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [geSitesData, { called, data: sitesApi, loading: loadingSites,
                        error: errorSites }] = useLazyQuery(sitesQuery, { fetchPolicy: 'no-cache' })
  const [mutationData, setMutationData] = useState(INITIAL_MUTATION)
  const [saveBlackouts] = useMutation(addBlackoutDatesMutation, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      const { alreadyBlockedSites: error, daysOfWeek, from, id, partiallyBlockedSites: partially, to,
              succesfullyBlockedSites: success } = response.blackoutDatesBulkCreate.blackoutDatesBulkSummary || {}
      const responseObject = { daysOfWeek, from, id, response: { success, partially, error }, to }
      updateMutationData({ data: responseObject, error: null, loading: false })
    },
    onError: error => {
      updateMutationData({ data: null, error: error.graphQLErrors[0].message, loading: false })
    },
  })
  const [undoBlackouts, { data: dataUndo, loading: loadingUndo }] = useMutation(undoBlackoutDatesMutation)
  const cookieName = `${slug}-undo-successful`
  const removeUrl = sitesApi?.options?.externalLinks?.blackoutDatesRemove

  const clearSessionData = () => removeItemSessionStorage(`${slug}-add-blackout-dates`)

  const handleSaveBlackouts = values => {
    updateMutationData({ loading: true })
    saveBlackouts({ variables: { ...values, campgroundId: slug } })
  }

  const handleUndoBlackouts = () => {
    const blackoutId = mutationData.data?.id
    undoBlackouts({ variables: { blackoutId } })
  }

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  const toggleModal = () => {
    setIsOpen(prevState => !prevState)
    if (mutationData?.data || mutationData?.error) {
      setMutationData(INITIAL_MUTATION)
      if (mutationData?.data) {
        clearSessionData()
        window.location.reload(false)
      }
    }
  }

  const updateMutationData = values => setMutationData(prevState => ({ ...prevState, ...values }))

  useEffect(() => {
    geSitesData({ variables: { isAdmin, slug } })
    clearSessionData()
  }, [])

  useEffect(() => {
    if (dataUndo) {
      setCookie(cookieName, true, { expires: 1 })
      toggleModal()
    }
  }, [dataUndo])

  return (
    <>
      <Dropdown direction="down" disabled={loadingSites} isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret className="text-bold" color="salmon">
          {!called || loadingSites ? <Spinner className="ms-1" size="sm" /> : 'Bulk Actions'}
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem className="text-decoration-none text-black" color="link" onClick={toggleModal}>
            <FontAwesomeIcon className="text-bold me-2" icon="plus" size="lg" />
            Add Blackout Dates
          </DropdownItem>

          <DropdownItem className="text-decoration-none text-black" color="link" href={removeUrl} tag="a">
            <FontAwesomeIcon className="text-bold me-2" icon={['far', 'trash-can']} size="lg" />
            Remove Blackout Dates
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <AddBlackoutDatesModal errorSites={errorSites} isOpen={isOpen} loadingSites={loadingSites}
                             loadingUndo={loadingUndo} mutation={mutationData} saveBlackouts={handleSaveBlackouts}
                             sitesApi={sitesApi} toggle={toggleModal} undoBlackouts={handleUndoBlackouts} />

      <Toast cookieName={cookieName} message={UNDO_SUCCESS_MSG} />
    </>
  )
}

export default Wrapper
