import cnames from 'classnames'
import PropTypes from 'prop-types'
import useParamsHandler from 'Shared/Hooks/useParamsHandler'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/Admin/FontAwesomeIcon/FontAwesomeIcon'

const HeaderCell = ({ columnHeaderClassname, component, label, name, sortable, sortData, table }) => {
  if (!sortable) return <th className={columnHeaderClassname}><span>{component || label}</span></th>

  const params = useParamsHandler()
  const { sortedField, updateSort } = sortData
  const iconClasses = cnames('ms-1', { 'text-grey': sortedField.name !== name })
  let icon = 'sort'

  if (sortedField.name === name) icon = sortedField.direction === 'asc' ? 'sort-up' : 'sort-down'

  const handleUpdateSort = () => {
    const direction = (sortedField.direction === 'asc' && sortedField.name === name) ? 'desc' : 'asc'
    const optionals = { page: 1 }
    const selectedTable = table && { table }
    params.replaceQuery({ sortDirection: direction, sortName: name, sortTable: table }, optionals)

    updateSort({ direction, name, ...selectedTable })
  }

  return (
    <th className={columnHeaderClassname}>
      <Anchor className="text-decoration-none link-inherit" href="#sort" onClick={handleUpdateSort}>
        <span className="me-2">{label}</span>
        <FontAwesomeIcon icon={icon} className={iconClasses} />
      </Anchor>
    </th>
  )
}

HeaderCell.defaultProps = {
  columnHeaderClassname: '',
  sortable: false,
  sortData: {},
  table: null,
}

HeaderCell.propTypes = {
  columnHeaderClassname: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  sortData: PropTypes.shape({
    sortedField: PropTypes.shape({
      name: PropTypes.string,
      direction: PropTypes.string,
    }),
    updateSort: PropTypes.func,
  }),
  table: PropTypes.string,
}

export default HeaderCell
