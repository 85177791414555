import PropTypes from 'prop-types'
import { Field } from 'formik'
import cnames from 'classnames'
import FormErrorMessage from './FormErrorMessage'

const FormGroupField = ({ children, className, isFloating, name, showErrors, validate }) => {
  const classes = {
    base: cnames('form-group', { 'form-floating': isFloating }, className),
    group: ({ error, touched }) => cnames(classes.base, { 'has-error': error && touched && !showErrors }),
  }

  return (
    <Field name={name} validate={validate}>
      {({ field, form, meta, meta: { error, touched } }) => {
        const invalid = error && touched
        const hasErrorClass = cnames({ 'has-error': invalid && showErrors })
        const isInvalidClass = invalid && !showErrors

        return (
          <div className={classes.group({ error, touched })}>
            {children({ field, form, hasErrorClass, invalid, isInvalidClass, meta })}

            {showErrors && <FormErrorMessage name={name} />}
          </div>
        )
      }}
    </Field>
  )
}

FormGroupField.defaultProps = {
  className: 'mb-3',
  isFloating: false,
  showErrors: false,
}

FormGroupField.propTypes = {
  className: PropTypes.string,
  isFloating: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showErrors: PropTypes.bool,
}

export default FormGroupField
