import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { AlertWrapper } from 'Shared/Alert'

const PaymentFailedAlert = ({ url }) => (
  <AlertWrapper className="shadow-sm d-flex align-items-center justify-content-between py-2" color="danger">
    <div className="d-flex align-items-center">
      <FontAwesomeIcon className="me-3 text-danger" icon="fa-triangle-exclamation" size="lg" />

      <p className="text-grey-dark mb-0">
        Payment method failed, please try again or use a different payment method.
      </p>
    </div>

    {url && <a className="btn btn-danger" href={url}>View Invoice</a>}
  </AlertWrapper>
)

export default PaymentFailedAlert
