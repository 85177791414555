import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import useParamsHandler from 'Shared/Hooks/useParamsHandler'

const StatusSelect = ({ filters, onChange, promptText, value }) => {
  const params = useParamsHandler()

  const handleStatusOnChange = ({ target }) => {
    const { value: status } = target
    const optionals = { page: 1 }

    params.replaceQuery({ paymentStatus: status }, optionals)
    onChange(status)
  }

  return (
    <Input name="status" onChange={handleStatusOnChange} type="select" value={value}>
      {promptText && <option value="">{promptText}</option>}

      {filters && (
        Object.keys(filters).map(status => (
          <option key={status} value={status}>{filters[status]}</option>
        ))
      )}
    </Input>
  )
}

StatusSelect.defaultProps = {
  promptText: 'All',
  filters: {},
}

StatusSelect.propTypes = {
  filters: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  promptText: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default StatusSelect
