const clearSearch = (data = {}) => ({
  type: 'SEARCH_CLEAR', payload: data,
})

const searchFilterUpdate = data => ({
  type: 'SEARCH_FILTER_UPDATE', payload: data,
})

const searchToggleDiscardAlert = () => ({ type: 'SEARCH_TOGGLE_DISCARD_ALERT' })
const searchToggleModifyNotSaved = () => ({ type: 'SEARCH_TOGGLE_MODIFY_NOT_SAVED' })

export { clearSearch, searchFilterUpdate, searchToggleDiscardAlert, searchToggleModifyNotSaved }
