import cname from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const IconStatus = ({ isCorrect, isError, isLoading }) => {
  const iconClassname = cname('me-2', { 'fa-spin': isLoading, 'text-success': isCorrect, 'text-danger': isError })

  const getIconToShow = () => {
    if (isLoading) return 'spinner'
    if (isError) return 'xmark'

    return ['far', 'circle-check']
  }

  return <FontAwesomeIcon className={iconClassname} icon={getIconToShow()} size="lg" />
}

export default IconStatus
