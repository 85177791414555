import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Col, Label, Badge } from 'reactstrap'

const CheckPaymentLabel = ({ className, label, text }) => {
  const classes = {
    badge: cnames('mb-4'),
    label: cnames('h6 mb-1 opacity-75 text-uppercase fw-bold d-block', className),
  }

  return (
    <Col>
      <Label className={classes.label}>{label}</Label>

      <Badge className={classes.badge} color="primary">{text}</Badge>
    </Col>
  )
}

CheckPaymentLabel.defaultProps = {
  label: '',
  text: '',
}

CheckPaymentLabel.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
}

export default CheckPaymentLabel
