import * as Yup from 'yup'

const CreditCardFormSchema = () => Yup.object().shape({
  address: Yup.string().required(),

  address2: Yup.string(),

  cardholderName: Yup.string().required(),

  city: Yup.string().required(),

  state: Yup.string().required(),
})

export default CreditCardFormSchema
