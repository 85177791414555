import { Label } from 'reactstrap'
import Select from 'react-select'

const SelectPicker = ({ defaultOptions, isClearable, isMultiple, label, name, options, placeholder }) => {
  const optionsWithValues = options.map(([label, value]) => ({ label, value }))
  const optionSelected = optionsWithValues.filter(option => defaultOptions.includes(option.value))

  return (
    <>
      <Label for={name}>{label}</Label>

      <Select className="control-select" classNamePrefix="react-select" defaultValue={optionSelected}
              isClearable={isClearable} id={name} isMulti={isMultiple} name={name} options={optionsWithValues}
              placeholder={placeholder} />
    </>
  )
}

export default SelectPicker
