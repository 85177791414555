import { useEffect, useState } from 'react'

const DESKTOP_BREAKPOINT = 992

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

const useWindowDimensions = (desktopBreakpoint = DESKTOP_BREAKPOINT) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const isDesktopView = windowDimensions.width >= desktopBreakpoint
  const notDesktopView = !isDesktopView

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { windowDimensions, notDesktopView, isDesktopView }
}

export default useWindowDimensions
