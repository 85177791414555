import classname from 'classnames'
import PropTypes from 'prop-types'

const Addon = ({ children, className, product }) => {
  const { name, price } = product
  const baseClasses = classname('product-card', className)
  const priceText = price?.amount > 0 ? price.display : 'Free'

  return (
    <article className={baseClasses}>
      <div className="product-card__info">
        <div className="d-flex flex-column">
          <span className="product-card__price">{priceText}</span>

          <label className="product-card__name" htmlFor={`addons[${product.id}]`}>{name}</label>
        </div>
      </div>

      { children }
    </article>
  )
}

Addon.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.number,
      display: PropTypes.string,
    }),
  }).isRequired,
}

export default Addon
