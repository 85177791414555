import { gql } from 'Utils/apolloClient'
import translatesFragment from 'Shared/Queries/TranslateFragment'

const inventoryHoldQuery = gql`
  query InventoryHoldQuery($slug: String!, $id: ID!, $keys: [TranslatesKeysAttributes!]!) {
    campground(slug: $slug) {
      closeSeasonDates
      inventoryHold(id: $id) {
        createdAt
        from
        site {
          id
          name
        }
        to
      }
    }

    translates {
      ...translateFragment
    }
  }

  ${translatesFragment}
`

export default inventoryHoldQuery
