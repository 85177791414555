import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'
import { numberSystem } from 'Shared/RegExpressions'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'

const FormSelect = ({ children, className, defaultMessage, defaultValue, disabled, helpText, id, inputClasses,
                      inputWrapperClass, isNumeric, isRequired, label, labelClasses, name, onChange, showErrors,
                      sizeInput }) => {
  const classes = {
    input: cnames('control-select form-select', inputClasses),
    label: cnames('main-label', labelClasses),
  }

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, form, hasErrorClass, isInvalidClass }) => {
        const inputClasses = cnames(classes.input, hasErrorClass)

        const handleOnChange = event => {
          if (onChange) return onChange(event, form.setFieldValue)

          const { value } = event.target
          const parsedValue = isNumeric ? (parseInt(value.replace(numberSystem, '')) || 0) : value
          form.setFieldValue(name, parsedValue)
        }

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}

                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}

            {helpText && <RichText className="help-text w-100" html={helpText} />}

            <div className={inputWrapperClass}>
              <Input {...field} bsSize={sizeInput} className={inputClasses} disabled={disabled} id={id || name}
                                invalid={isInvalidClass} name={name} onChange={handleOnChange} type="select">
                {defaultMessage && <option value={defaultValue} label={defaultMessage} />}
                {children}
              </Input>
            </div>
          </>
        )
      }}
    </FormGroupField>
  )
}

FormSelect.defaultProps = {
  defaultMessage: '',
  defaultValue: '',
  disabled: false,
  helpText: '',
  id: '',
  inputClasses: '',
  inputWrapperClass: null,
  isNormalSelect: false,
  isNumeric: false,
  label: '',
  labelClasses: '',
  onChange: null,
  showErrors: false,
  sizeInput: '',
}

FormSelect.propTypes = {
  defaultMessage: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string,
  inputClasses: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  isNormalSelect: PropTypes.bool,
  isNumeric: PropTypes.bool,
  label: PropTypes.string,
  labelClasses: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showErrors: PropTypes.bool,
  sizeInput: PropTypes.string,
}

export default FormSelect
