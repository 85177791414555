import cnames from 'classnames'
import { Container } from './Components'
import './CartWrapper.scss'

const CartWrapper = ({ children, className, withMaxHeight, ...rest }) => {
  const classes = cnames('cart-wrapper d-flex flex-column justify-content-between border shadow-sm bg-white',
                         'rounded', className, { 'max-height': withMaxHeight })

  return (
    <aside className={classes} {...rest}>
      {children}
    </aside>
  )
}

CartWrapper.defaultProps = {
  withMaxHeight: true,
}

CartWrapper.Container = Container

export default CartWrapper
