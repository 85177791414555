import cnames from 'classnames'
import PropTypes from 'prop-types'

const LeftComponent = ({ children, className, value }) => {
  const classes = cnames(className, { 'me-2': children })

  return (
    <div className="flex-none">
      <span className={classes}>{value}</span>

      {children}
    </div>
  )
}

LeftComponent.propTypes = {
  value: PropTypes.string.isRequired,
}

export default LeftComponent
