import { useEffect } from 'react'
import { Form } from 'reactstrap'
import { Formik } from 'formik'
import getTouchedObj from './getTouchedObj'
import useSessionStorageData from './useSessionStorageData'
import useScrollToError from './useScrollToError'

const FormWithValidations = ({ children, className, disableRenderProps, enableReinitialize, formId, initialValues,
                               ignoreStorageItems, keySessionStorage, onChangeValues, onFormikError, onSubmit,
                               saveSessionStorage, savedValues, savedValuesAreReady, saveSessionStorageExtraData,
                               scrollToError, validationSchema }) => (
  <Formik enableReinitialize={enableReinitialize} initialValues={initialValues} onSubmit={onSubmit}
          validationSchema={validationSchema}>
    {props => {
      const { errors, handleSubmit, setTouched, setValues, status, values } = props
      const isFormikError = Object.keys(errors).length > 0

      if (saveSessionStorage) useSessionStorageData({ extraData: saveSessionStorageExtraData, formik: props,
                                                      keySessionStorage, ignoreStorageItems })

      if (scrollToError) useScrollToError({ formik: props })

      const setTouchFormFields = touch => {
        if (!touch) return setTouched({}, false)

        setTouched(getTouchedObj(errors))
      }

      useEffect(() => {
        if (savedValues && savedValuesAreReady) setValues(prevValues => ({ ...prevValues, ...savedValues }))
      }, [savedValuesAreReady])

      useEffect(() => {
        onFormikError(isFormikError)
      }, [isFormikError])

      useEffect(() => {
        if (onChangeValues) onChangeValues(values, status)
      }, [values])

      const toProps = { ...props, isFormikError, setTouchFormFields }

      return (
        <Form className={className} id={formId} onSubmit={handleSubmit}>
          {disableRenderProps ? children : children(toProps)}
        </Form>
      )
    }}
  </Formik>
)

FormWithValidations.defaultProps = {
  enableReinitialize: true,
  ignoreStorageItems: {},
  onFormikError: () => {},
  savedValuesAreReady: true,
}


export default FormWithValidations
