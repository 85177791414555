import { useCallback, useEffect, useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { CardElement } from '@stripe/react-stripe-js'
import AlertErrors from 'Shared/Alert/AlertErrors'
import CardOnFileAlert from './CardOnFileAlert/CardOnFileAlert'
import PaymentMethodIcons from './PaymentMethodIcons'

const FormCardElement = ({ children, className, errors, errorsClassName, graphQLErrors, hidePostalCode,
                           inputClassName, label, labelClassName, onChange, onClickSaveCardOnFile,
                           saveCardOnFile, showDefaultPayment, showIcons, stripeElementStyle }) => {
  const [elementCard, setElementCard] = useState()
  const refCallback = useCallback(element => setElementCard(element), [])
  const hasErrors = errors.length > 0 || graphQLErrors
  const classes = {
    container: cnames(className),
    errors: cnames('mb-3', errorsClassName),
    input: cnames('stripe-input', inputClassName),
    label: cnames('fw-bold d-flex mb-2 justify-content-between', labelClassName),
  }

  useEffect(() => {
    if (hasErrors) elementCard.focus()
  }, [errors])

  return (
    <div className={classes.container}>
      {hasErrors && <AlertErrors {...{ errors, graphQLErrors }} className={classes.errors} withScroll />}

      {children}

      <div className="card-form">
        <div className={classes.label}>
          {label}
          {showIcons && <PaymentMethodIcons />}
        </div>

        <div className="mb-3">
          <CardElement className={classes.input} onChange={onChange}
                       onReady={refCallback} options={{ ...stripeElementStyle, hidePostalCode }} />
        </div>

        {showDefaultPayment && <CardOnFileAlert onClickSaveCardOnFile={onClickSaveCardOnFile}
                                                saveCardOnFile={saveCardOnFile} />}
      </div>
    </div>
  )
}

FormCardElement.defaultProps = {
  errors: [],
  errorsClassName: '',
  graphQLErrors: null,
  hidePostalCode: false,
  inputClassName: '',
  label: '',
  labelClassName: '',
  onChange: () => {},
  onClickSaveCardOnFile: () => {},
  saveCardOnFile: false,
  showDefaultPayment: false,
  showIcons: false,
  stripeElementStyle: { style: { base: { fontSize: '16px' } } },
}

FormCardElement.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  errorsClassName: PropTypes.string,
  graphQLErrors: PropTypes.shape({}),
  hidePostalCode: PropTypes.bool,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  onClickSaveCardOnFile: PropTypes.func,
  saveCardOnFile: PropTypes.bool,
  showDefaultPayment: PropTypes.bool,
  showIcons: PropTypes.bool,
  stripeElementStyle: PropTypes.shape({}),
}

export default FormCardElement
