import { useState } from 'react'
import { BASE_ERROR_MESSAGE } from 'Shared/VerifyWebsite/Utils/consts'
import { useQuery } from 'Shared/Hooks/useApolloClient'
import { useDebouncedCallback } from 'use-debounce'
import websiteLinksQuery from 'Shared/VerifyWebsite/Queries/WebsiteLinksQuery'

const INITIAL_STATE = { error: null, isCompleted: false, isLoading: false }

export const useSecureWebsite = domain => {
  const [state, setState] = useState(INITIAL_STATE)
  const { data, loading } = useQuery(websiteLinksQuery)
  const { websitesManageUrl } = data?.options?.externalLinks || {}
  const debouncedApiCall = useDebouncedCallback(() => apiCall(), 350)

  const apiCall = async () => {
    const postData = new URLSearchParams()
    postData.append('name', domain)

    try {
      const response = await fetch(websitesManageUrl, {
        method: 'PUT',
        body: postData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (!response.ok) throw new Error(BASE_ERROR_MESSAGE)

      setState(prev => ({ ...prev, isCompleted: true, isLoading: false }))
    } catch (error) {
      setState({ error: error.message, isCompleted: false, isLoading: false })
    }
  }

  const secureWebsite = () => {
    if (loading) return

    setState({ ...INITIAL_STATE, isLoading: true })
    debouncedApiCall()
  }

  return { ...state, secureWebsite }
}
