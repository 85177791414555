import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { InvoiceProvider } from './InvoiceContext'
import ActionSelect from './ActionSelect'
import ModalWrapper from './ModalWrapper'

const InvoiceActions = ({ id }) => {
  const container = document.getElementById('react-data-container')
  const { slug: campgroundSlug, token } = container.dataset
  window.roverData = { campgroundSlug, token }


  return (
    <ApolloProvider client={apolloClient}>
      <InvoiceProvider id={id}>
        <ActionSelect />

        <ModalWrapper />
      </InvoiceProvider>
    </ApolloProvider>
  )
}

export default InvoiceActions
