const HeaderCell = ({ header }) => {
  const { cols, component, label } = header

  return (
    <div className={`col-${cols || 1}`}>
      {component || <span>{label}</span>}
    </div>
  )
}

export default HeaderCell
