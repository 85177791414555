import PropTypes from 'prop-types'

const RichText = ({ className, html, handleOnClick, tag }) => {
  const Tag = tag
  const showMarkup = () => ({ __html: html })

  /* eslint-disable-next-line react/no-danger */
  return <Tag className={className} dangerouslySetInnerHTML={showMarkup()} onClick={() => handleOnClick()} />
}

RichText.defaultProps = {
  handleOnClick: () => {},
  tag: 'div',
}

RichText.propTypes = {
  handleOnClick: PropTypes.func,
  html: PropTypes.string.isRequired,
  tag: PropTypes.string,
}

export default RichText
