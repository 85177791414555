import cnames from 'classnames'
import PropTypes from 'prop-types'
import TooltipWithTarget from 'Shared/TooltipWithTarget'

const CartRow = ({ className, classTitle, classValue, hide, id, infoText, infoTooltip, showInfoTooltip, title,
                   value }) => {
  if (hide || !title) return null

  const classes = {
    container: cnames('d-flex justify-content-between align-items-center mb-2', className),
    titleItems: cnames(classTitle),
  }
  const displayValue = value?.display || value

  return (
    <div className={classes.container}>
      <div className="d-flex flex-column">
        <div>
          <span className={classes.titleItems}>{title}</span>

          {infoText && (
            <span className="ms-1 text-gray text-smaller-2">{infoText}</span>
          )}

          {(showInfoTooltip && infoTooltip) && (
            <TooltipWithTarget icon="circle-question" iconClass="text-muted ms-1" iconSize="sm"
                               id={`${id}-info-tooltip`}>
              {infoTooltip}
            </TooltipWithTarget>
          )}
        </div>
      </div>
      <span className={classValue}>{displayValue}</span>
    </div>
  )
}

CartRow.defaultProps = {
  classTitle: 'mb-0 h6',
  classValue: '',
  hide: false,
  id: null,
  infoTooltip: '',
  showInfoTooltip: false,
}

CartRow.propTypes = {
  classTitle: PropTypes.string,
  classValue: PropTypes.string,
  hide: PropTypes.bool,
  id: props => {
    if ((props.showInfoTooltip && (props.id === undefined || typeof (props.id) !== 'string')))
      return new Error('The prop `id` is marked as required in `CartRow` with toolTip, but its value is `null`.')
  },
  infoTooltip: PropTypes.string,
  showInfoTooltip: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      display: PropTypes.string,
    }),
  ]).isRequired,
}

export default CartRow
