export const roverPassApi = () => {
  const { host, protocol } = window.location
  const path = window.roverData?.path || '/api/queries'
  return `${protocol}//${host}${path}`
}

export const roverPassWSApi = () => {
  const { host, protocol } = window.location
  const isSecured = protocol === 'https:'
  const socketProtocol = isSecured ? 'wss' : 'ws'

  return `${socketProtocol}://${host}/cable`
}
