import PropTypes from 'prop-types'
import cnames from 'classnames'
import NameWithHelpText from 'Shared/NameWithHelpText'

const RowPricingElement = ({ amount, className, description, helpText, oldAmount, price }) => {
  const classes = cnames('d-flex justify-content-between align-items-center', className)

  return (
    <div className={classes}>
      <div className="w-50">
        {amount && (
          <>
            <span className="fw-bold text-dark">{amount}</span>
            <span className="mx-1 text-dark">x</span>
          </>
        )}

        <NameWithHelpText className="text-dark text-truncate" name={description} helpText={helpText} />

        {oldAmount && (
          <span className="ms-1 text-muted fst-italic scratched">({oldAmount})</span>
        )}
      </div>

      <span className="text-gray">{price}</span>
    </div>
  )
}

RowPricingElement.defaultProps = {
  amount: '',
  oldAmount: '',
}

RowPricingElement.prototypes = {
  amount: PropTypes.string,
  description: PropTypes.string.isRequired,
  oldAmount: PropTypes.string,
  price: PropTypes.string.isRequired,
}

export { RowPricingElement }
