export const payoutsState = {
  isParamsReviewed: false,
  itemsSelected: 0,
  mutationMethodResult: {
    data: false,
    error: null,
    loading: false,
  },
  result: {
    data: {},
    error: null,
    loading: true,
  },
  pagination: {
    currentPage: 1,
    perPage: 25,
  },
  payoutsAmountSum: 0,
}
