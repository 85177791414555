import cnames from 'classnames'
import { classes } from 'Shared/Cart/Constants'

const Container = ({ children, className, color }) => {
  const classWrapper = cnames(classes.container, className)

  return (
    <div color={color} className={classWrapper}>
      {children}
    </div>
  )
}

Container.defaultProps = {
  color: 'light',
}

export default Container
