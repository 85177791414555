import PropTypes from 'prop-types'
import Link from 'Shared/Link/Link'

const ToBanAlertContent = ({ learnMoreLink }) => (
  <>
    <p className="fw-bold">You can also ban the camper from your campground</p>

    <p className="mb-0">
      Reservations cannot be created for banned campers online or with the Reservation Builder.
      <Link className="mx-1 alert-link" isExternal target="_blank" to={learnMoreLink}>
        Learn More
      </Link>
    </p>
  </>
)

ToBanAlertContent.propTypes = {
  learnMoreLink: PropTypes.string.isRequired,
}

export default ToBanAlertContent
