import { withSkeleton } from 'Shared/Skeleton/withSkeleton'
import CardEvent from './CardEvent'

const EventsWithSkeleton = ({ events }) => {
  const length = events?.length || 0

  return (
    <>
      {length > 0 && (<p>Total Events: {length}</p>)}

      <hr />

      {length === 0 ? (
        <>
          <p className="fw-bold text-center">Nothing Here Yet</p>

          <p className="text-muted">
            Everytime you make changes to the reservation they will appear here.
          </p>
        </>
      ) : (
        events.map(event => (
          <CardEvent event={event} key={event.id} />
        ))
      )}
    </>
  )
}

EventsWithSkeleton.defaultProps = {
  events: [
    {
      changesHumanized: 'Event fake 1',
      id: 1,
      name: 'eventFake',
      occurredAt: '2000-01-01T12:00:00-08:00',
      type: 'EventReservation',
    },
    {
      amount: {
        display: '$--.-- ---',
      },
      changesHumanized: 'Event fake 2',
      id: 2,
      installmentLink: '/---/---------/-----/-----',
      installmentId: 11111,
      installmentNumber: 'Payment x of n',
      name: 'eventFake',
      occurredAt: '2000-01-01T12:00:00-08:00',
      type: 'EventInstallment',
      userEmail: '----@------.---',
    },
  ],
}

export default withSkeleton(EventsWithSkeleton)
