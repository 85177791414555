import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import CloseModalButton from '../CloseModalButton'
import './ViewModal.scss'
import 'Styles/SalmonHeaderModal.scss'

const ViewModal = ({ children, classes, icon, isCloseColorWhite, isOpen, onOk, showCloseIcon, showHeader,
                     showOkButton, size, title, toggle, onClosed }) => {
  const closeButton = (<CloseModalButton onClick={toggle} isColorWhite={isCloseColorWhite} />)
  const classesDefault = {
    body: cnames('overflow-auto', classes.body),
    content: classes.content,
    header: cnames('border-0', classes.header),
    modal: cnames('modal-dialog-centered view-modal', classes.modal),
  }

  return (
    <Modal className={classesDefault.modal} contentClassName={classesDefault.content} isOpen={isOpen}
           onClosed={onClosed} size={size} toggle={toggle}>
      {((title || showCloseIcon) && showHeader) && (
        <ModalHeader className={classesDefault.header} close={closeButton} toggle={toggle}>
          {icon && <FontAwesomeIcon className="text-salmon me-2" icon={icon} />}

          <span className="fw-bold">{title}</span>
        </ModalHeader>
      )}

      <ModalBody className={classesDefault.body}>{children}</ModalBody>

      {showOkButton && (
        <ModalFooter className="border-0 pt-0">
          <button className="btn btn-theme-primary me-auto" onClick={onOk || toggle} type="button">
            Ok, I understand
          </button>
        </ModalFooter>
      )}
    </Modal>
  )
}

ViewModal.defaultProps = {
  classes: {},
  icon: null,
  isCloseColorWhite: false,
  onOk: undefined,
  showCloseIcon: false,
  showHeader: true,
  showOkButton: true,
  size: 'md',
  title: '',
  toggle: () => {},
}

ViewModal.propTypes = {
  classes: PropTypes.shape({}),
  icon: PropTypes.string,
  isCloseColorWhite: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  showHeader: PropTypes.bool,
  showOkButton: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.func,
}

export default ViewModal
