import { useFormikContext } from 'formik'
import FormSelect from 'Shared/Formik/FormSelect'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'

const ELECTRIC_VALUE = 'electric'
const LATE_PAYMENT_VALUE = 'late_payment'
const RESERVATION_VALUE = 'reservation'

const CategorySelect = ({ electricValues, itemCategories, itemValues, lateFeeValues }) => {
  const { setValues } = useFormikContext()

  const handleCategory = ({ target }) => {
    const { value } = target
    if (value === ELECTRIC_VALUE) return setValues(electricValues)
    if (value === LATE_PAYMENT_VALUE) return setValues(lateFeeValues)

    const isFee = !(value === RESERVATION_VALUE)
    setValues({ ...itemValues, category: value, isFee, units: isFee ? itemValues.units : 1 })
  }

  return (
    <FormSelect inputClasses="form-control" isNormalSelect label="Item Type" labelClasses="mb-0"
                name="category" onChange={handleCategory}>
      <SelectOptions data={itemCategories} />
    </FormSelect>
  )
}

export default CategorySelect
