import { useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import { SimpleRowItem } from 'Shared/Cart/Components'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import 'Styles/Background.scss'

const DraftBreakDown = ({ installmentDraft }) => {
  const [isOpen, setIsOpen] = useState(false)
  const icon = isOpen ? 'chevron-up' : 'chevron-down'
  const { amount, platformFee, processingFeeTotal, subtotal, tax, total } = installmentDraft || {}
  const toggle = () => setIsOpen(prev => !prev)

  return (
    <div className="w-100 flex-column flex-centered">
      <FontAwesomeIcon className="fs-1 mb-3" color="#007bff" icon={['far', 'paste']} />

      <p>You already have a draft invoice.</p>

      <Button color="link" onClick={toggle}>
        View Invoice Details
        <FontAwesomeIcon className="ms-2" icon={icon} />
      </Button>

      <Collapse className="col-12" id="DraftInvoice" isOpen={isOpen}>
        <div className="rounded bg-gray-light my-2 pb-2 pt-3 px-4">
          <span className="fw-bold">Draft Invoice</span>

          <div className="small">
            <SimpleRowItem className="text-muted fst-italic" label="Reservation Payment" value={amount?.display} />

            <div className="border-top mt-2 pt-2">
              <SimpleRowItem className="fw-bold" label="Subtotal" value={subtotal || ''} />

              <SimpleRowItem className="mb-1" label="Tax" value={tax?.display} />

              <SimpleRowItem className="mb-1" label="Processing Fee" value={processingFeeTotal?.display} />

              <SimpleRowItem className="mb-1" label="Platform Fee" value={platformFee?.display} />
            </div>
          </div>

          <SimpleRowItem className="mt-2fw-bold" label="Total" value={total?.display} />

        </div>
      </Collapse>
    </div>
  )
}

export default DraftBreakDown
