import { Button } from 'reactstrap'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import { initialFormValues } from 'Shared/RefundFormModal/initialFormValues'
import refundSchema from 'Shared/RefundFormModal/refundSchema'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import ModalContentInputs from './ModalContentInputs'
import ModalContentTotals from './ModalContentTotals'

const reasonDescriptionLength = 500

const ModalFormContent = ({ canReversePayout, checkName, id, items, isAdmin, isExternallyPaid, number,
                            onChangeCurrencies, onSubmit, paymentMethod, paymentsCount, paymentSource, platformFee,
                            pricing, resourcesData, toggleFormModal, total }) => {
  const { camperFeeMaxToRefund, camperProcessingFeeMaxToRefund, grandTotalMax, reversePayoutAmountMax,
          subtotal, tax } = pricing
  const reversePayout = { total: reversePayoutAmountMax.amount }
  const amount = { camperFee: camperFeeMaxToRefund.amount,
                   camperProcessingFee: camperProcessingFeeMaxToRefund.amount,
                   grandTotal: grandTotalMax.amount }

  const handlerRefundSchema = () => refundSchema({ ...amount, isExternallyPaid, reasonDescriptionLength,
                                                   maxReversePayout: reversePayout.total })
  return (
    <FormWithValidations formId="refunds-form"
                         initialValues={{ ...initialFormValues, refundMethod: paymentSource }} onSubmit={onSubmit}
                         scrollToError validationSchema={handlerRefundSchema}>
      {({ values, isValid }) => {
        useEffectAfterMount(() => {
          onChangeCurrencies(values.amount)
        }, [values.amount])

          return (
            <>
              <div className="text-bold text-md">
                Payment {number} of {paymentsCount}
                <span className="text-grey text-italic ms-2">#{id}</span>
              </div>

              <ModalContentTotals {...{ amount, camperProcessingFeeMaxToRefund, items, platformFee, subtotal, tax,
                                        total }} />

              <hr className="m-2 text-muted" />

              <ModalContentInputs {...{ amount, canReversePayout, checkName, isAdmin, isExternallyPaid,
                                        paymentMethod, paymentSource, pricing, resourcesData, values }} />

              <div className="border-0 py-2 pe-0 d-flex justify-content-between">
                <Button color="secondary" onClick={toggleFormModal}>Cancel</Button>

                <Button color="salmon" type="submit" disabled={!isValid}>
                  {isExternallyPaid ? 'Save' : 'Process and Save'}
                </Button>
              </div>
            </>
          )
        }
      }
    </FormWithValidations>
  )
}

export default ModalFormContent
