import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import Anchor from 'Shared/Anchor/Anchor'
import 'Styles/Link.scss'

const Link = ({ children, className, isExternal, onClick, to, ...props }) => {
  const classname = cnames(className, { external: isExternal })

  if (isExternal && !onClick) return <a {...props} className={classname} href={to}>{children}</a>

  if (isExternal && onClick) return <Anchor {...props} className={classname} onClick={onClick}>{children}</Anchor>

  return (
    <RouterLink {...props} className={classname} to={to}>
      {children}
    </RouterLink>
  )
}

Link.defaultProps = {
  isExternal: false,
  to: '',
}

Link.propTypes = {
  isExternal: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

export default Link
