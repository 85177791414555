import { useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'

// NOTE: this hook needs to be inside a Stripe <Elements> Provider
export const useStripeCollect = (cb, data, isReady) => {
  const stripe = useStripe()
  const { clientSecret, name } = data
  const billingDetails = { name }

  useEffect(() => {
    if (stripe && isReady)
      stripe.collectBankAccountForPayment({
        clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
        expand: ['payment_method'],
      })
      .then(({ error }) => {
        if (error) return cb({ error })
        stripe.confirmUsBankAccountPayment(clientSecret).then(cb)
      })
  }, [isReady])
}
