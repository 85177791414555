import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import RichText from 'Shared/RichText/RichText'

const Alerts = ({ alerts }) =>
  <ul>
    {alerts.map(alert => <RichText tag="li" html={alert} />)}
  </ul>

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = state => ({
  alerts: state.campground.alerts,
})

export default storeConnect({ mapStateToProps })(Alerts)
