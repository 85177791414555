import { ApolloClient, ApolloProvider, gql, InMemoryCache } from '@apollo/client'
import { splitLink } from './Apollo/link'

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const asyncQuery = (query, props = { fetchPolicy: 'network-only' }) => {
  const { fetchPolicy } = props

  const queryFn = async options => {
    try {
      const queryArgs = { query, ...options, fetchPolicy }
      const response = await apolloClient.query(queryArgs)
      return response
    } catch (error) {
      return error
    }
  }

  return queryFn
}

export const asyncMutation = mutation => {
  const mutationFn = async options => {
    try {
      const mutationArgs = { mutation, ...options, fetchPolicy: 'no-cache' }
      const response = await apolloClient.mutate(mutationArgs)
      return response
    } catch (error) {
      return error
    }
  }

  return mutationFn
}

export { ApolloProvider, gql }

export default apolloClient
