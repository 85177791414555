import cname from 'classnames'
import PropTypes from 'prop-types'
import { AlertWrapper } from 'Shared/Alert'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const RowAlert = ({ className, classNameAlert, color, colSpan, icon, iconClass, message }) => {
  const classes = {
    icon: cname('me-2', iconClass),
    wrapper: cname('mb-2 bg-transparent p-2', classNameAlert),
  }

  return (
    <tr>
      <td className={className} colSpan={colSpan}>
        <AlertWrapper className={classes.wrapper} color={color}>
          <FontAwesomeIcon className={classes.icon} icon={icon} size="lg" />

          {message}
        </AlertWrapper>
      </td>
    </tr>
  )
}

RowAlert.defaultProps = {
  classNameAlert: '',
  color: 'danger-outline',
  colSpan: 3,
  icon: 'circle-exclamation',
  iconClass: 'text-danger',
}

RowAlert.propTypes = {
  classNameAlert: PropTypes.string,
  color: PropTypes.string,
  colSpan: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  message: PropTypes.string.isRequired,
}

export default RowAlert
