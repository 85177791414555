import { memo } from 'react'
import PropTypes from 'prop-types'
import FormCheckInputGroup from 'Shared/Formik/FormCheckInputGroup'
import FormReactSelect from 'Shared/Formik/FormReactSelect'
import FormTextArea from 'Shared/Formik/FormTextArea'
import FormTextInput from 'Shared/Formik/FormTextInput'
import './CustomField.scss'

const CustomField = ({ dataField, index, isPos }) => {
  const { data, subtitle, title, type, requiredInBuilder, requiredInCheckout } = dataField
  const customField = {}
  const displayRequired = isPos ? requiredInBuilder : requiredInCheckout
  const fieldName = `customFields.${index}.value`
  const isMultiSelect = type === 'CustomFieldMultipleChoice'
  const isParagraph = type === 'CustomFieldParagraph'
  const isDropDown = data?.dropdown === 'true'
  const isMultiple = data?.multiple === 'true'
  const optionsToSelect = data.options?.map(option => ({ label: option, value: option }))
  let fieldType = 'text'

  customField.check = () => <FormCheckInputGroup classNameLabel="me-4 mb-3" helpText={subtitle}
                                                 isRequired={displayRequired} label={title} name={fieldName}
                                                 options={data.options} showErrors={isPos}
                                                 type={isMultiple ? 'checkbox' : 'radio'} />

  customField.select = () => (
    <FormReactSelect className="floating-label-height" defaultMessage={`-- ${data.placeholder || title} --`}
                     helpText={subtitle} isMulti={isMultiple} isRequired={displayRequired} label={title}
                     name={fieldName} options={optionsToSelect} showErrors={isPos} />
  )

  customField.text = () => <FormTextInput helpText={subtitle} inputClasses="floating-label-height"
                                          isRequired={displayRequired} label={title} name={fieldName}
                                          placeholder={data.placeholder} showErrors={isPos} />

  customField.textarea = () => <FormTextArea helpText={subtitle} isRequired={displayRequired} label={title}
                                             name={fieldName} placeholder={data.placeholder} rows="3"
                                             showErrors={isPos} />

  customField.check.displayName = 'CustomFormCheckInputGroup'
  customField.select.displayName = 'CustomFormSelect'
  customField.text.displayName = 'CustomFormTextInput'
  customField.textarea.displayName = 'CustomFormTextArea'

  if (isParagraph) fieldType = 'textarea'
  if (isMultiSelect) fieldType = isDropDown ? 'select' : 'check'

  return (
    <div className="custom-field">
      {customField[fieldType]()}
    </div>
  )
}

CustomField.defaultProps = {
  isPos: false,
}

CustomField.propTypes = {
  dataField: PropTypes.shape({
    data: PropTypes.shape({
      placeholder: PropTypes.string,
      options: PropTypes.instanceOf(Array),
    }),
    name: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  isPos: PropTypes.bool,
}

export default memo(CustomField)
