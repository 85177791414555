import { useMemo, useRef, useState } from 'react'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { Alert, Button } from 'reactstrap'
import { isPastDate, isToday, moment, today } from 'Utils/Dates'
import { getLeftButtonText, getRightButtonText } from 'Shared/ReservationOverviewCheckIn/Utils/CheckInLabel'
import reservationCheckInMutation from 'Shared/ReservationOverviewCheckIn/Mutations/CheckInReservationMutation'
import BalanceContent from 'Shared/ReservationOverviewCheckIn/Modal/BalanceContent'
import CheckInNotice from 'Shared/ReservationOverviewCheckIn/Modal/CheckInNotice'
import DatePicker from 'Shared/ReservationOverviewCheckIn/DatePicker/DatePicker'
import EarlyCheckinNotice from 'Shared/ReservationOverviewCheckIn/Modal/EarlyCheckinNotice'

const ModalContent = ({ checkInDate, data, isAdmin, toggle }) => {
  const action = useRef('reload')
  const campground = data?.campground
  const reservation = campground?.reservation
  const [toDate, setToDate] = useState()
  const [reservationCheckIn, { error, loading }] = useMutation(reservationCheckInMutation, {
    onCompleted: mutationData => {
      if (mutationData?.reservationCheckIn?.success) {
        if (action.current === 'reload') return window.location.reload()

        return window.location.replace(reservation.installmentPendingToPay.links.installmentPath)
      }
    },
  })
  const isCheckIn = useMemo(() => isToday(checkInDate) || isPastDate(checkInDate, today()), [checkInDate])
  const isEarlyCheckIn = useMemo(() => moment(checkInDate).isAfter(today()), [checkInDate])
  const balance = reservation?.balance
  const hasBalanceDue = balance?.due?.amount > 0
  const isTotalPaid = balance?.remaining?.amount === 0
  const translates = data?.translates.messages
  const leftButtonText = getLeftButtonText(balance, checkInDate)
  const rightButtonText = getRightButtonText(balance, checkInDate)

  const checkInAction = () => {
    reservationCheckIn({ variables: { to: toDate || reservation.to, reservationId: reservation.id,
                                      isEngineAdmin: isAdmin } })
  }

  const onClickPrimary = () => {
    if (hasBalanceDue) action.current = 'redirect'
    checkInAction()
  }

  const onClickSecondary = () => {
    if (hasBalanceDue) checkInAction()
    toggle()
  }

  const handleDateChange = date => setToDate(date)

  return (
    <>
      {error && (
        <Alert color="danger">
          {error.graphQLErrors[0].message}
        </Alert>
      )}

      {isEarlyCheckIn && (
        <DatePicker campground={campground} handleDateChange={handleDateChange} reservation={reservation}
                    translates={translates} />
      )}

      {!isTotalPaid && <BalanceContent balance={balance} />}

      {isCheckIn && <CheckInNotice balance={balance} />}

      {isEarlyCheckIn && <EarlyCheckinNotice translatesMessages={translates} />}

      <div className="d-flex justify-content-between">
        <Button color="success" disabled={loading} onClick={onClickPrimary}>
          {leftButtonText}
        </Button>

        <Button className="btn-outline-secondary" color="gray" onClick={onClickSecondary}>
          {rightButtonText}
        </Button>
      </div>
    </>
  )
}

export default ModalContent
