import PropTypes from 'prop-types'

const CollapsedDates = ({ from, to }) => (
  <div className="d-flex justify-content-between">
    <div className="d-flex flex-column me-3">
      <span className="text-brownie">{from}</span>
      <span className="text-gray text-smaller-2">Check In</span>
    </div>

    <div className="d-flex flex-column">
      <span className="text-brownie">{to}</span>
      <span className="text-gray text-smaller-2">Check Out</span>
    </div>
  </div>
)

CollapsedDates.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export { CollapsedDates }
