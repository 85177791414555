import { Col, Row } from 'reactstrap'
import EmailInput from 'Shared/Formik/EmailInput'
import FormTextInput from 'Shared/Formik/FormTextInput'
import InternationalPhone from 'Shared/Formik/InternationalPhone/InternationalPhone'
import TelephoneInput from 'Shared/TelephoneInput/TelephoneInput'

const NOTE_TEXT = 'Email is only editable on the first reservation.'

const ContactInformation = ({ displayEmail, handleEmailOnBlur, inputStyles, isEmailDisable, title,
                              useInternationalPhone }) => (
  <>
    {title && (
      <h6 className="fw-bold mb-4">{title}</h6>
    )}

    <Row>
      <Col lg={6}>
        <FormTextInput {...inputStyles} isRequired label="First Name" name="contact.firstName"
                       placeholder="First Name" showErrors />
      </Col>

      <Col lg={6}>
        <FormTextInput {...inputStyles} isRequired label="Last Name" name="contact.lastName"
                       placeholder="Last Name" showErrors />
      </Col>
    </Row>

    <Row>
      {displayEmail && (
        <Col lg={6}>
          <EmailInput {...inputStyles} disabled={isEmailDisable} label="Email (optional)" name="contact.email"
                      noteText={NOTE_TEXT} onBlur={handleEmailOnBlur} showErrors validateWithSchema />
        </Col>
      )}

      <Col lg={displayEmail ? 6 : 12}>
        {useInternationalPhone ? (
          <InternationalPhone {...inputStyles} isRequired label="Phone" name="contact.phone" showErrors />
        ) : (
          <TelephoneInput {...inputStyles} nameScope="contact." />
        )}
      </Col>
    </Row>
  </>
)

ContactInformation.defaultProps = {
  displayEmail: false,
  isEmailDisable: false,
  handlerEmailBlur: () => {},
  useInternationalPhone: false,
}

export default ContactInformation
