import cnames from 'classnames'
import PropTypes from 'prop-types'
import { defaulValue } from 'Shared/Cart/Constants'

const RightComponent = ({ children, className, value }) => {
  const classes = cnames(className, 'flex-none', { 'ms-1': children })

  return (
    <div>
      {children}

      <span className={classes}>{value}</span>
    </div>
  )
}

RightComponent.defaultProps = {
  value: defaulValue,
}

RightComponent.propTypes = {
  value: PropTypes.string,
}

export default RightComponent
