import { useEffect, useState } from 'react'
import useParamsHandler from 'Shared/Hooks/useParamsHandler'

export const usePaginationData = () => {
  const [pagination, setPagination] = useState({ currentPage: 1, paramsReviwed: false, perPage: 25 })
  const params = useParamsHandler()

  const updateCurrentPage = currentPage => setPagination(prev => ({ ...prev, currentPage }))

  const updatePerPage = perPage => setPagination(prev => ({ ...prev, currentPage: 1, perPage }))

  useEffect(() => {
    const { itemsPerPage = 25, page = 1, ...urlParams } = params.getSearchParams()
    setPagination(prev => ({ ...prev, currentPage: parseInt(page), perPage: parseInt(itemsPerPage),
                             paramsReviwed: true, ...urlParams }))
  }, [])

  return { ...pagination, updateCurrentPage, updatePerPage }
}
