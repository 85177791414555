import storeConnect from 'Checkout/Redux/Connect'
import Policies from 'Shared/Policies/Policies'

const DAILY_BLACKLIST_RULES = ['check-in-rule', 'check-out-rule']

const PoliciesWrap = props => {
  const { applyReservationPolicy, campgroundCancellationPolicy, dayUse, reservationPolicy, rulesList } = props
  const policyText = applyReservationPolicy ? reservationPolicy : campgroundCancellationPolicy
  const rulesFiltered = dayUse ? rulesList.filter((item => !DAILY_BLACKLIST_RULES.includes(item.key))) : rulesList

  return <Policies {...props} campgroundCancellationPolicy={policyText} rulesList={rulesFiltered} />
}

const mapStateToProps = state => ({
  advertisement: state.campground.advertisement,
  campgroundCancellationPolicy: state.campground.campgroundCancellationPolicy,
  campgroundCancellationPolicyNote: state.campground.campgroundCancellationPolicyNote,
  checkInProcedure: state.campground.checkInProcedure,
  dayUse: state.search.dayUse,
  name: state.campground.name,
  roverpassCancellationPolicy: state.campground.roverpassCancellationPolicy,
  rulesList: state.campground.rulesList,
  utilities: state.campground.utilityFields,
  whatToKnow: state.campground.whatToKnow,
})

export default storeConnect({ mapStateToProps })(PoliciesWrap)
