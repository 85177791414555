import { useState } from 'react'
import { getElement } from 'Utils/Elements'
import { parseMoment } from 'Utils/Dates'
import FormDateRangePicker from 'Shared/Formik/FormDateRangePicker'
import GeneralDateRangePicker from 'Shared/DatePicker/GeneralDateRangePicker/GeneralDateRangePicker'
import TripInsuranceCoverageAlert from './TripInsuranceCoverageAlert'

const DatePicker = ({ alertMessage, canNotCancelInsurance, checkOutPlaceholder, closureDates, disabled,
                      endDateHiddenName, endDateId, endDateValue, isFlexible, isLegacyReservation, minRange,
                      minYear, numberOfMonths, onChangedDates, reservationCreatedAt, startDateHiddenName,
                      startDateId, startDateValue, submitButtonId, tripInsuranceNightsLimit }) => {
  const [isDatesError, setIsDatesError] = useState(false)
  const submitButtonElement = getElement(submitButtonId)

  const getDates = dates => {
    const { from, to, initialFrom, initialTo } = dates
    const fromDate = from || initialFrom
    const toDate = to || initialTo

    if (onChangedDates) onChangedDates({ from, to })

    if (!fromDate || !reservationCreatedAt) return

    const diffNights = toDate.diff(fromDate, 'days')

    const policyCreated = parseMoment(reservationCreatedAt)
    const isCancelledByNights = policyCreated.isSame(fromDate, 'day') || diffNights > tripInsuranceNightsLimit

    const newIsDatesError = isCancelledByNights && canNotCancelInsurance
    if (submitButtonElement) submitButtonElement.disabled = newIsDatesError
    setIsDatesError(newIsDatesError)
  }

  return (
    <>
      {isLegacyReservation ? (
        <GeneralDateRangePicker areDatesRequired changeFormat closureDates={closureDates} disabled={disabled}
                                endDateHiddenName={endDateHiddenName} endDateId={endDateId}
                                endDatePlaceholder={checkOutPlaceholder} endDateValue={endDateValue}
                                format="MM/DD/YYYY" formatReturned="MM/DD/YYYY" getDates={getDates}
                                isDatesError={isDatesError} maxRange={{ years: 3 }} minRange={minRange}
                                minYear={minYear} numberOfMonths={numberOfMonths}
                                startDateHiddenName={startDateHiddenName} startDateId={startDateId}
                                startDatePlaceholder="Check In" startDateValue={startDateValue} withIds />
      ) : (
        <FormDateRangePicker checkInPlaceholder="Check In" checkOutPlaceholder={checkOutPlaceholder}
                             closureDates={closureDates} disabled={disabled} isDatesError={isDatesError}
                             isFlexible={isFlexible} isRequired label="Stay (check-in & check-out)"
                             labelClasses="fw-bold" minDateAvailable={minRange} name="stay"
                             numberOfMonths={numberOfMonths} onDatesChange={getDates} returnMoment />
      )}

      {isDatesError && <TripInsuranceCoverageAlert alertMessage={alertMessage} />}
    </>
  )
}

DatePicker.defaultProps = {
  checkOutPlaceholder: 'Check Out',
  endDateHiddenName: 'reservation[to]',
  endDateId: 'reservation_to',
  numberOfMonths: 2,
  startDateHiddenName: 'reservation[from]',
  startDateId: 'reservation_from',
}

export default DatePicker
