import cnames from 'classnames'
import { Button } from 'reactstrap'
import Checkbox from 'Shared/Checkbox/Checkbox'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import 'Styles/List.scss'

const BlackoutDatesList = ({ getMoreBlackoutDates, onSelect, site }) => {
  const listClassName = cnames('list-striped ps-0', { collapse: site.collapsed })
  const itemClassName = cnames('list-group-item d-flex justify-content-between align-items-center',
                               'border-0 border-top ps-5')
  const blackoutDates = site.blackoutDates?.nodes || site.blackoutDates

  return (
    <ul className={listClassName}>
      {blackoutDates.map(date => (
        <li className={itemClassName} key={`blackout-li-${date.id}`}>
          {onSelect ? (
            <Checkbox className="me-2" handleClick={() => onSelect(site.id, date.id)} isChecked={date.isSelected}
                      message={date.dateRange} name={`blackout-${date.id}`} />
          ) : (
            <span>{date.dateRange}</span>
          )}

          <span className="fst-italic text-muted">{date.nights}</span>
        </li>
      ))}

      {site.blackoutDates?.hasNextPage && (
        <li className="list-group-item border-0 border-top ps-5 text-center">
          <Button className="text-black text-decoration-none p-0" color="link"
                  onClick={() => getMoreBlackoutDates(site.id, site.blackoutDates?.actualPage || 1)}>
            <FontAwesomeIcon className="fw-bold me-2" icon="angles-down" />
            Show More
          </Button>
        </li>
      )}
    </ul>
  )
}

export default BlackoutDatesList
