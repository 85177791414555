import FormGroupField from 'Shared/Formik/FormGroupField'
import QuantityButtons from 'Shared/QuantityButtons/QuantityButtons'

const FormQuantityButtons = ({ className, enabled, helperText, helperClasses, isRequired, label,
                               labelClasses, labelWrapper, max, name, onChange }) => (
  <FormGroupField className={className} name={name} showErrors>
    {({ field, form, hasErrorClass }) => {
      const handleOnChange = value => {
        form.setFieldValue(name, value)
        if (onChange) onChange({ name, value })
      }

      return (
        <QuantityButtons {...{ enabled, isRequired, label, labelClasses, max, name }} count={field.value}
                         helper={helperText} helperClasses={helperClasses} inputClasses={hasErrorClass}
                         labelWrapper={labelWrapper} onChange={handleOnChange} />
      )
    }}
  </FormGroupField>
)

export default FormQuantityButtons
