import PropTypes from 'prop-types'
import { pluralizeCounter } from 'Utils/Strings'

const ItemReservationExtras = ({ className, itemsDiscount, itemsExtras }) => {
  const discounts = itemsDiscount.map(item => item.name).join(', ')
  const extras = itemsExtras.map(({ name, units }) => {
    const pluralName = pluralizeCounter(name, units)
    return `${units} Extra ${pluralName}`
  }).join(', ')

  const items = [extras, discounts]

  return (
    <div className={className}>
      {items && (
        items.map(item => (
          item && (<span className="d-block text-smaller-2 text-muted" key={item}>{item}</span>)
        ))
      )}
    </div>
  )
}

ItemReservationExtras.defaultProps = {
  itemsDiscount: [],
  itemsExtras: [],
}

ItemReservationExtras.propTypes = {
  itemsDiscount: PropTypes.instanceOf(Array),
  itemsExtras: PropTypes.instanceOf(Array),
}

export default ItemReservationExtras
