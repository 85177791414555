import cnames from 'classnames'
import AlertWrapper from '../AlertWrapper/AlertWrapper'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'

const AlertWarning = ({ className, icon, link, msg, msgClass, msgLink, title }) => {
  const classes = {
    color: 'warning-outline-text-light',
    container: cnames('shadow-sm fw-bold', className),
    icon: 'color-warning fa-fw',
    message: cnames('fw-normal mb-0', msgClass),
  }

  return (
    <AlertWrapper className={classes.container} color={classes.color}>
      {icon && (<FontAwesomeIcon className={classes.icon} icon={icon} size="lg" />) }

      {title && (<p className="fw-bold">{title}</p>)}

      <p className={classes.message}>{msg}</p>

      {msgLink && link && (
        <p className="text-end my-0 mt-2">
          <Link className="text-warning-darker alert-link" isExternal target="_blank"
                to={link}>
            <span className="mx-2"><u>{msgLink}</u></span>

            <FontAwesomeIcon className={classes.icon} icon="arrow-right" size="sm" />
          </Link>
        </p>
      )}
    </AlertWrapper>
  )
}

export default AlertWarning
