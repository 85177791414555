import cname from 'classnames'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import CloseModalButton from 'Shared/CloseModalButton'
import 'Styles/SalmonHeaderModal.scss'

const ItemModal = ({ className, children, isOpen, toggle }) => {
  const classNameWrapper = cname('salmon-header-modal', className)
  const closeButton = (<CloseModalButton isColorWhite onClick={toggle} />)

  return (
    <Modal centered className={classNameWrapper} isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={closeButton} toggle={toggle}>Line Item</ModalHeader>

      <ModalBody className="p-4">
        {children}
      </ModalBody>
    </Modal>
  )
}

export default ItemModal
