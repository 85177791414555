import PropTypes from 'prop-types'
import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { AlertWrapper } from 'Shared/Alert'
import RichText from 'Shared/RichText/RichText'

const AlertShowError = ({ children, className, color, error, icon }) => {
  if (!error) return null

  const classes = cnames('shadow-sm fw-bold', className)

  return (
    <AlertWrapper className={classes} color={color} key={error}>
      <FontAwesomeIcon className="me-2" icon={icon} size="lg" />

      <RichText tag="span" html={error} />

      {children}
    </AlertWrapper>
  )
}

AlertShowError.defaultProps = {
  color: 'danger-outline',
  error: '',
  icon: 'circle-exclamation',
}

AlertShowError.propTypes = {
  color: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export default AlertShowError
