const BasketContainer = ({ children, className }) => {
  const classes = `d-flex flex-column basket ${className || 'border-top border-bottom'}`

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default BasketContainer
