// Please, add any icon you need in the list below (keep it alphabetically sorted)
import { library } from '@fortawesome/fontawesome-svg-core'
import { FaIcons } from 'Shared/FontAwesomeIcon/Icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  FaIcons.faCircleCheck,
  FaIcons.faCircleExclamation,
  FaIcons.faCirclePlus,
  FaIcons.faCircleXmark,
  FaIcons.faChevronLeft,
  FaIcons.faCreditCard,
  FaIcons.faMagnifyingGlass,
  FaIcons.faMoneyBill,
  FaIcons.faMoneyBillWave,
  FaIcons.faMoneyCheck,
  FaIcons.faSort,
  FaIcons.faSortDown,
  FaIcons.faSortUp,
)

export default FontAwesomeIcon
