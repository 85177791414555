import BlackoutDatesList from './BlackoutDatesList'
import Checkbox from 'Shared/Checkbox/Checkbox'
import CollapseAnchor from 'Shared/CollapseAnchor'
import loadingSites from './loadingSites.json' // NOTE: Use this list to make the skeleton loading
import 'Shared/Skeleton/Skeleton.scss'

const SitesList = ({ getMoreBlackoutDates, name, selectBlackout, selectSite, setSites, sites }) => {
  const collapseSite = siteId => {
    setSites(prevState => (
      prevState.map(site => {
        if (site.id === siteId)
          return { ...site, collapsed: !site.collapsed }

        return site
      })
    ))
  }

  return (
    <ul className="list-group" id={`${name}-list`}>
      {sites.map(site => (
        <li className="list-group-item list-group-item-secondary rounded-0 p-0" key={`site-li-${site.id}`}>
          <div className="d-flex justify-content-between align-items-center">
            {selectSite ? (
              <Checkbox className="fw-bold" classNameWrapper="ms-3 py-2" handleClick={() => selectSite(site.id)}
                        isChecked={site.isSelected} isIndeterminate={site.isIndeterminate} message={site.name}
                        name={`site-${site.id}`} />
            ) : (
              <span className="fw-bold py-2 ms-3">{site.name}</span>
            )}

            <CollapseAnchor isCollapseOpen={site.collapsed} toggleCollapse={() => collapseSite(site.id)} />
          </div>

          <BlackoutDatesList getMoreBlackoutDates={getMoreBlackoutDates} onSelect={selectBlackout} site={site} />
        </li>
      ))}
    </ul>
  )
}

SitesList.defaultProps = {
  loading: true,
  name: 'sites',
  sites: loadingSites,
}

export default SitesList
