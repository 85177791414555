import { useEffect, useState } from 'react'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import CancelModal from './CancelModal'
import 'Styles/Button.scss'
import 'Styles/Text.scss'

const App = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { engineName, hideButton, isSingleCancelRequested, onCancelSingle } = props
  const isAccountEngine = engineName === 'accounts'
  const btnColor = isAccountEngine ? 'outline-cardinal' : 'red'
  window.roverData = { ...window?.roverData, ...props, isAccountEngine }

  const toggle = () => setIsOpen(prevState => !prevState)

  const onCancel = () => {
    if (onCancelSingle) onCancelSingle()
    toggle()
  }

  useEffect(() => {
    if (isSingleCancelRequested) setIsOpen(true)
  }, [isSingleCancelRequested])

  return (
    <ApolloProvider client={apolloClient}>
      {!hideButton && (
        <Button className="me-0" color={btnColor} onClick={toggle}>Cancel Reservation</Button>
      )}

      <CancelModal isOpen={isOpen} toggle={onCancel} />
    </ApolloProvider>
  )
}

export default App
