import PropTypes from 'prop-types'

const NameWithHelpText = ({ className, classHelpText, helpText, name }) => (
  <div>
    <span className={className}>{name}</span>
    {helpText && <span className={classHelpText}>{helpText}</span>}
  </div>
)

NameWithHelpText.defaultProps = {
  classHelpText: 'ms-1 fw-normal smaller text-muted',
  helpText: false,
}

NameWithHelpText.propTypes = {
  classHelpText: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
}

export default NameWithHelpText
