import { Col } from 'reactstrap'
import PropTypes from 'prop-types'
import Tag from 'Shared/Tag/Tag'

const RefundedLabel = ({ changeOwed, tagColor, tagFitContent }) => (
  <Col>
    <h6>
      Change Owed
    </h6>

    <Tag className="d-inline text-bold" color={tagColor} fitContent={tagFitContent} textMd>
      {changeOwed?.display || changeOwed}
    </Tag>
  </Col>
)

RefundedLabel.defaultProps = {
  tagColor: '',
  changeOwed: '---',
  tagFitContent: false,
}

RefundedLabel.propTypes = {
  tagColor: PropTypes.string,
  changeOwed: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ display: PropTypes.string }),
  ]),
  tagFitContent: PropTypes.bool,
}

export default RefundedLabel
