import { gql } from 'Utils/apolloClient'

const removeBlackoutDatesMutation = gql`
  mutation BlackoutDatesBulkRemove($params: BlackoutDatesBulkRemoveInput!) {
      blackoutDatesBulkRemove (input: $params) {
        summary {
          blackoutDates {
            id
            dateRange
            nights
          }
          id
          name
        }
        summaryErrors
      }
    }
`

export default removeBlackoutDatesMutation
