import { BASE_ERROR_MESSAGE } from 'Shared/VerifyWebsite/Utils/consts'
import { AlertWrapper } from 'Shared/Alert'
import { Button } from 'reactstrap'

const CnameError = ({ canSecure, errors, refetch }) => (
  <>
    <AlertWrapper color="danger">
      {errors.length > 0 ? (
        <ul className="mb-0">
          {errors.map(({ data, type, name }) => (
            <li key={`${data}-${type}-${name}`}>
              <strong>{name}</strong> is pointed to: <strong>{data}</strong>
            </li>
          ))}
        </ul>
      ) : BASE_ERROR_MESSAGE}
    </AlertWrapper>

    {canSecure && <Button color="primary" onClick={refetch}>Check again</Button>}
  </>
)

CnameError.defaultProps = {
  errors: [],
}

export default CnameError
