import { gql } from 'Utils/apolloClient'
import UserPaymentMethodsDataFragment from '../Queries/userPaymentMethodsDataFragment'

const paymentMethodDeleteMutation = gql`
  mutation PaymentMethodDelete($paymentMethodId: ID!) {
    paymentMethodDelete(input: { paymentMethodId: $paymentMethodId }){
      user {
        id
        ...userPaymentMethodsData
      }
    }
  }

  ${UserPaymentMethodsDataFragment}
`

export default paymentMethodDeleteMutation
