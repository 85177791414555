import * as Yup from 'yup'

const messageRequired = 'Required field.'
const messageOptionalFields = 'You have to fill at least one refund amount field.'
const messageInvalidReason = 'Invalid reason.'
const messageInvalidRefundMethod = 'Invalid refund method.'

const isValidRefundAmount = amount => {
  const values = Object.values(amount)

  return values.some(item => item > 0)
}

const isValidRefundMethod = refundMethod => ['cash', 'check', 'external_processor', 'other'].includes(refundMethod)

const refundSchema = ({ camperFee, camperProcessingFee, grandTotal, isExternallyPaid, reasonDescriptionLength,
                        maxReversePayout }) => {
  Yup.addMethod(Yup.number, 'isValidRefundAmount', function ({ errorMessage }) {
    return this.test('test-is-valid-refund-amount', errorMessage, function () {
      const { createError, parent, path } = this

      return isValidRefundAmount(parent) || createError({ message: errorMessage, path })
    })
  })

  Yup.addMethod(Yup.string, 'isValidRefund', function ({ errorMessage }) {
    return this.test('test-is-valid-refund', errorMessage, function () {
      const { createError, parent, path } = this
      const isEmpty = !parent.reason
      const isNotError = !isEmpty

      return isNotError || createError({ message: errorMessage, path })
    })
  })

  Yup.addMethod(Yup.string, 'isValidRefundMethod', function ({ errorMessage }) {
    return this.test('test-is-valid-refund-method', errorMessage, function () {
      const { createError, parent, path } = this
      const isEmpty = !parent.refundMethod
      const isNotError = !isEmpty && isValidRefundMethod(parent.refundMethod)
      if (!isExternallyPaid) return true

      return isNotError || createError({ message: errorMessage, path })
    })
  })

  const schema = Yup.object().shape({
    amount: Yup.object().shape({
      camperFee: Yup.number()
        .max(camperFee, `Max value is ${camperFee}.`)
        .isValidRefundAmount({ errorMessage: messageOptionalFields }),

      camperProcessingFee: Yup.number()
        .max(camperProcessingFee, `Max value is ${camperProcessingFee}.`)
        .isValidRefundAmount({ errorMessage: messageOptionalFields }),

      grandTotal: Yup.number()
        .max(grandTotal, `Max value is ${grandTotal}.`)
        .isValidRefundAmount({ errorMessage: messageOptionalFields }),
    }),

    number: Yup.number()
      .when('refundMethod', {
        is: 'check',
        then: Yup.number().required(messageRequired),
        otherwise: Yup.number(),
      }),

    reason: Yup.string()
      .required(messageRequired)
      .isValidRefund({ errorMessage: messageInvalidReason }),

    refundMethod: Yup.string()
      .isValidRefundMethod({ errorMessage: messageInvalidRefundMethod }),

    reasonDescription: Yup.string()
      .max(reasonDescriptionLength, `Reason length is ${reasonDescriptionLength} characters.`),

    reversePayout: Yup.object().shape({
      total: Yup.number()
        .max(maxReversePayout, `Max value is ${maxReversePayout}.`),
    }),
  })

  return schema
}

export default refundSchema
