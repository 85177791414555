import { Component } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null, hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      const eventId = Sentry.captureException(error)
      const { getExtraData } = this.props
      const extraData = getExtraData ? getExtraData() : {}

      scope.setExtras({ ...errorInfo, ...extraData })
      this.setState({ eventId })
    })
  }

  render() {
    const { Component } = this.props

    if (this.state.hasError || this.state.eventId) {
      if (Component) return <Component />

      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
