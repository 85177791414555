const detailsClear = data => ({
  type: 'DETAILS_CLEAR', payload: data,
})

const detailsSetData = data => ({
  type: 'DETAILS_SET_DATA', payload: data,
})

const detailsSetError = data => ({
  type: 'DETAILS_SET_ERROR', payload: data,
})

const detailsToggleValidation = () => ({ type: 'DETAILS_TOGGLE_VALIDATIONS' })

const detailsValidateNewFields = data => ({
  type: 'DETAILS_VALIDATE_NEW_FIELDS', payload: data,
})

export { detailsClear, detailsSetData, detailsSetError, detailsToggleValidation, detailsValidateNewFields }
