import { Children } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import paymentStatus from './PaymentStatus'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Anchor from 'Shared/Anchor/Anchor'
import SingleDate from './PaymentWrapper.SingleDate'
import Tag from 'Shared/Tag/Tag'
import './PaymentWrapper.scss'

const PaymentWrapper = ({ children, disabled, id, index, showVoided, onVoidInvoice, state, totalPayments }) => {
  const classes = {
    input: 'col-md-5 mb-3 mb-ms-0',
    toVoid: cnames('btn-void d-flex justify-content-end ms-auto text-decoration-none', { 'un-void': disabled }),
  }
  const voidedIcon = disabled ? 'undo' : 'circle-minus'
  const voidedText = disabled ? 'Undo void' : 'Void this Inovice'

  let _currencyInput
  let _message
  let _singleDate

  Children.forEach(children, child => {
    if (child.type === CurrencyInput) {
      _currencyInput = child
      return false
    }

    if (child.type === Message) {
      _message = child
      return false
    }

    if (child.type === SingleDate) {
      _singleDate = child
      return false
    }
  })

  return (
    <div className="payment-wrapper mt-3">
      {_message}
      <div className="row align-middle">
        <div className="col-md-2 h-100">
          <label className="fw-bold" htmlFor={`total${id}`}>
            {`${index || id} of ${totalPayments}`}
          </label>

          {state && (
            <Tag className="text-capitalize" color={paymentStatus[state].color} fitContent>
              {paymentStatus[state].text}
            </Tag>
          )}
        </div>

        <div className={classes.input}>
          {_currencyInput}
        </div>

        <div className={classes.input}>
          {_singleDate}
        </div>
      </div>

      {showVoided && onVoidInvoice && (
        <Anchor className={classes.toVoid} href="#voided"
                onClick={onVoidInvoice}>
          <FontAwesomeIcon className="me-1" size="lg" icon={voidedIcon} />
          {voidedText}
        </Anchor>
      )}
    </div>
  )
}

const CurrencyInput = ({ children }) => children
const Message = ({ children }) => children

PaymentWrapper.CurrencyInput = CurrencyInput
PaymentWrapper.Message = Message
PaymentWrapper.SingleDate = SingleDate

PaymentWrapper.defaultProps = {
  disabled: false,
  index: 0,
  showVoided: false,
  state: '',
  onVoidInvoice: null,
}

PaymentWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  index: PropTypes.number,
  showVoided: PropTypes.bool,
  onVoidInvoice: PropTypes.func,
  state: PropTypes.string,
  totalPayments: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default PaymentWrapper
