import cnames from 'classnames'
import PropTypes from 'prop-types'
import DoughnutChart from 'Shared/BalanceTracker/DoughnutChart/DoughnutChart'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import RefundsPending from 'Shared/RefundsPending/RefundsPending'
import BalanceSimple from 'Shared/BalanceTracker/Cards/BalanceSimple'
import './Balance.scss'

const Balance = ({ balance, hasRefundsPending, isCalendarView, refunds }) => {
  const { due, noInstallments, remaining, total } = balance || {}
  const hasDue = due?.amount > 0
  const isTotalPaid = remaining?.amount === 0

  const classes = {
    balanceColStyles: cnames('balance', { 'col-xl-5 col-xxl-4': !isCalendarView, 'col-xl-7': isCalendarView }),
    balanceDetails: cnames('fw-bold text-muted fst-italic', { 'd-flex flex-column': isCalendarView }),
    lineColor: cnames('line-color rounded-start', { 'on-schedule': !hasDue, 'on-due': hasDue,
                                                    'total-paid': isTotalPaid || noInstallments }),
    mainText: cnames('text-due fw-normal', { 'text-muted': !hasDue }),
    mainTextValue: cnames('fw-bold m-0', { 'text-due': hasDue }),
  }

  const mainText = hasDue ? { value: due?.display, text: 'Due' } :
                            { value: remaining?.display, text: 'Remaining' }

  if (noInstallments) return <BalanceSimple balance={balance} classes={classes} mainText={mainText} />

  return (
    <div className={classes.balanceColStyles}>
      <div className="balance-card d-flex border rounded shadow-sm">
        <div className={classes.lineColor} />

        <div className="balance-content d-flex flex-fill justify-content-between p-2">
          <div>
            <p className="fw-bold text-uppercase">
              <FontAwesomeIcon className="me-1 icon-color" icon="dollar-sign" />
              Balance
            </p>

            <p className={classes.mainTextValue}>
              {mainText.value}
              <small className={classes.mainText}> {mainText.text}</small>
            </p>

            <div className={classes.balanceDetails}>
              {hasDue && (
                <small className="me-3">
                  {remaining?.display}
                  <span className="fw-normal"> Remaining</span>
                </small>
              )}

              <small className="d-flex">
                {total?.display}
                <span className="mx-1 fw-normal">Total</span>

                {hasRefundsPending && <RefundsPending id="balance" refunds={refunds} onlyIconTrigger />}
              </small>
            </div>
          </div>

          <DoughnutChart balance={balance} hasRefundsPending={hasRefundsPending} />
        </div>
      </div>
    </div>
  )
}

Balance.defaultProps = {
  isCalendarView: false,
}

Balance.propTypes = {
  isCalendarView: PropTypes.bool,
}

export default Balance
