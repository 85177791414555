import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Collapse } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { useStateContext } from '../useStateContext'
import Anchor from 'Shared/Anchor/Anchor'

const Header = ({ anchorClassWrapper, children, keepChildren, showIcon, title, titleColor, titleHelper,
                  withRenderProps }) => {
  const { handleState, isOpen } = useStateContext()
  const anchorID = `link-${title.toLowerCase().replace(/\s/g, '-').replace(/&/g, 'and')}`
  const titleClassName = cnames('fw-bold text-start', { 'ps-2': showIcon }, titleColor)

  return (
    <div className="collapse-header d-flex align-items-center justify-content-between flex-wrap h-auto">
      <div className={anchorClassWrapper}>
        <Anchor className="d-flex align-items-center btn p-0" id={anchorID} onClick={handleState}>
          {showIcon && <FontAwesomeIcon className="text-lg" icon={isOpen ? 'chevron-up' : 'chevron-down'} />}

          <span className={titleClassName}>{title}</span>

          {titleHelper && <small className="ps-1 text-italic">{titleHelper}</small>}
        </Anchor>
      </div>

      <Collapse isOpen={keepChildren || !isOpen}>
        {withRenderProps ? children({ isOpen, handleState }) : children}
      </Collapse>
    </div>
  )
}

Header.defaultProps = {
  anchorClassWrapper: '',
  keepChildren: false,
  showIcon: true,
  titleColor: 'text-blue',
  withRenderProps: false,
}

Header.propTypes = {
  anchorClassWrapper: PropTypes.string,
  keepChildren: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  showIcon: PropTypes.bool,
  withRenderProps: PropTypes.bool,
}

export default Header
