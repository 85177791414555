export const meterReadingsState = {
  meters: {
    bulkSites: {
      completed: false,
      data: {
        sites: [],
        utility: '',
      },
      error: null,
      loading: false,
      utilitySelected: null,
    },
    data: {
      sites: [],
      sitesOptions: [],
      filters: {
        siteSelected: null,
        utilitySelected: null,
      },
      utilitySelected: null,
      sitesSelectedCount: 0,
    },
    error: null,
    loading: true,
    utilitiesOptions: {
      data: [],
      error: null,
      loading: true,
    },
  },
  meterUpload: {
    data: {
      csvErrors: [],
      successCount: null,
    },
    error: null,
    loading: false,
  },
  utilities: {
    data: [],
    error: null,
    loading: true,
  },
  utilitySave: {
    data: {},
    error: null,
    loading: false,
  },
  options: {
    externalLinks: {
      helpMeterReadsCsv: '',
    },
  },
}
