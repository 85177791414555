import Advertisement from './Rules/Advertisement'
import CampgroundRules from './Rules/CampgroundRules'
import CancellationPolicies from './Rules/CancellationPolicies'
import KnowBeforeYourStay from './KnowBeforeYourStay/KnowBeforeYourStay'
import './Policies.scss'

const Policies = ({ advertisement, campgroundCancellationPolicy,
                    campgroundCancellationPolicyNote: cancellationNote, checkInProcedure, children, isDayUse, name,
                    roverpassCancellationPolicy, rulesList, showWhatToKnow, utilities, whatToKnow }) => {
  const note = cancellationNote ? `<p class="mt-3 fst-italic"> ${cancellationNote} </p>` : ''

  const cancellationPolicies = {
    policy: {
      title: `${name} Cancellation Policy`,
      description: `${campgroundCancellationPolicy} ${note}`,
    },
    roverpass: {
      title: 'RoverPass Cancellation Policy',
      description: roverpassCancellationPolicy,
    },
  }

  const cancellationPoliciesCheckInProcedure = {
    'check-in': {
      title: 'Check In Procedure',
      description: checkInProcedure,
    },
  }

  return (
    <div className="policies">
      {showWhatToKnow && (<KnowBeforeYourStay whatToKnow={whatToKnow} utilities={utilities} />)}

      <CancellationPolicies cancellationPolicies={cancellationPolicies} />

      {children}

      {!isDayUse && (<CancellationPolicies cancellationPolicies={cancellationPoliciesCheckInProcedure} />)}

      {!isDayUse && (<CampgroundRules campgroundRules={rulesList} />)}

      <Advertisement text={advertisement} />
    </div>
  )
}

export default Policies
