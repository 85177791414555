import { Provider } from 'react-redux'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import store from 'Shared/CalendarQuickEdit/Store/store'
import CalendarQuickEdit from 'Shared/CalendarQuickEdit/Containers/CalendarQuickEdit'

const App = props => {
  window.roverData = { ...window?.roverData, ...props }

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <CalendarQuickEdit />
      </Provider>
    </ApolloProvider>
  )
}

export default App
