import cnames from 'classnames'
import { numberToMoney } from 'Utils/Parse'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ItemReservationExtras from 'Shared/Cart/Components/ItemReservationExtras'
import Tooltip from 'Shared/Tooltip'

const NO_QUANTITY_TOOLTIP = 'There is a portion of the item being paid, so there is no quantity applied for the item.'

const Item = ({ className, isEditable, isEditableOnOverdue, isRecurringInvoice, item,
                itemsDiscount, itemsExtras, showOnlyBuilderItems, toggleEdit }) => {
  const classNameWrapper = cnames('invoice-item', className)
  const hideQty = ['late_payment', 'reservation'].includes(item?.category)
  const isLateFeeEditable = item?.category === 'late_payment' && isEditableOnOverdue
  const isReservationItem = item?.category === 'reservation'
  const qtyId = `item-qty-${item.id}`

  const getCurrencyValue = currency => {
    if (currency?.display) return currency.display

    return numberToMoney(currency)
  }

  return (
    <tr className={classNameWrapper}>
      {(isEditable || isEditableOnOverdue) && (
        <td className="text-center">
          {(isEditable || isLateFeeEditable) && (
            <Anchor className="edit-item-link" href="edit-item" id={item.id} onClick={() => toggleEdit(item)}>
              <FontAwesomeIcon className="text-facebook" icon={['far', 'pen-to-square']} size="lg" />
            </Anchor>
          )}
        </td>
      )}

      <td className="ps-3">
        <span className="d-inline" id={qtyId}>{hideQty ? '-' : item.units}</span>

        {isReservationItem && (
          <Tooltip autoHide idTarget={qtyId} placement="top" value={NO_QUANTITY_TOOLTIP} />
        )}
      </td>

      <td>
        <span>{item.name}</span>

        {(isReservationItem && isRecurringInvoice) &&
          <FontAwesomeIcon className="text-facebook ms-2" icon="arrows-rotate" />
        }

        {(isReservationItem && showOnlyBuilderItems && (itemsExtras?.length > 0 || itemsDiscount?.length > 0)) && (
          <ItemReservationExtras itemsDiscount={itemsDiscount} itemsExtras={itemsExtras} />
        )}

        {item.units > 1 && (
          <span className="d-block text-smaller-2 text-muted">{`${getCurrencyValue(item.price)} / Ea.`}</span>
        )}
      </td>

      <td className="amount"><span>{getCurrencyValue(item.subtotal)}</span></td>
    </tr>
  )
}

export default Item
