import { FieldArray } from 'formik'
import { Label } from 'reactstrap'
import FormErrorMessage from 'Shared/Formik/FormErrorMessage'
import WrapperTable from 'Shared/Admin/Table/WrapperTable'

const EMPTY_MSG = 'No sites to display.'
const TABLE_HEADERS = [{ label: 'Select All', name: 'name' }]

const FormSites = ({ errorSites, loading, onSelectAllSites, onSelectSite, sites }) => (
  <FieldArray name="sites">
    {({ form, push, remove }) => {
      const { setFieldValue, values } = form
      const { sites: sitesSelected } = values

      const setAllSites = () => {
        const willBeSelected = sitesSelected.length !== sites.length

        if (willBeSelected) {
          const ids = sites.map(site => site.id)
          setFieldValue('sites', ids)
        } else
          setFieldValue('sites', [])

        onSelectAllSites(willBeSelected)
      }

      const setSite = ({ id }) => {
        const index = sitesSelected.indexOf(id)
        if (index >= 0)
          remove(index)
        else
          push(id)

        onSelectSite(id)
      }

      const onClickCheckHeader = payload => {
        if (payload.type === 'TABLE_HEADER')
          setAllSites()
        else
          setSite({ id: payload.value })
      }

      return (
        <div className="mt-4">
          <Label className="main-label" for="sites">Sites</Label>

          <WrapperTable className="admin-table" data={sites || []} displayPagination={false}
                        emptyMessage={errorSites || EMPTY_MSG} emptyIcon="triangle-exclamation"
                        headers={TABLE_HEADERS} loading={loading} onCheckSelection={onClickCheckHeader}
                        tableName="add-blackout-dates" useEmptyComponent withCheckSelection />

          <FormErrorMessage name="sites" />
        </div>
      )
    }}
  </FieldArray>
)

export default FormSites
