export const BLOCK_CONFIGURATION = {
  error: {
    color: 'danger',
    icon: ['far', 'times-circle'],
    msg: "We couldn't apply blackout dates to these sites because they are already blocked for this date range.",
    title: 'Sites Already Blocked',
  },
  partially: {
    color: 'warning',
    icon: 'triangle-exclamation',
    msg: 'We added the desired block around the existing reservations.',
    title: 'Partially Blocked Sites',
  },
  success: {
    color: 'success',
    icon: ['far', 'circle-check'],
    title: 'Successfully Blocked Sites',
  },
}

export const DATE_FORMAT = 'MM/DD/YYYY'

export const DEFAULT_PER_PAGE = 25

export const BLACKOUTDATES_EMPTY = 'There are no Blackout Dates to remove.'

export const BLACKOUTDATES_ERROR = 'Error getting the Blackout Dates, please try again.'

export const INITIAL_MUTATION = { data: null, error: null, loading: false }

export const UNDO_SUCCESS_MSG = 'Blackout Dates have been reversed successfully.'
