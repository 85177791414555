import { useEffect, useState } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { Button, Modal, ModalBody } from 'reactstrap'
import { ReactSVG } from 'react-svg'
import externalLinksQuery from 'Shared/LogInModal/Mutations/externalLinksQuery'
import Link from 'Shared/Link/Link'
import LogInForm from 'Shared/LogInModal/Components/LogInForm/LogInForm'
import RvIcon from 'Shared/Assets/roverpass_logo.svg'
import SingUpForm from 'Shared/LogInModal/Components/SingUpForm/SingUpForm'

const LogInModal = ({ isOpen, toggle }) => {
  const [formActive, setFormActive] = useState('LogIn')
  const [externalLinks, setExternalLinks] = useState({})
  const [getExternalLinks, { loading, data }] = useLazyQueryNoCache(externalLinksQuery)
  const isLogInFormActive = formActive === 'LogIn'

  const formToggle = () => { isLogInFormActive ? setFormActive('SignUp') : setFormActive('LogIn') }

  const closeModal = () => {
    setFormActive('LogIn')
    toggle()
  }

  useEffect(() => {
    if (!loading && !data) getExternalLinks()
  }, [])

  useEffect(() => {
    if (data) setExternalLinks(data.options.externalLinks)
  }, [data])

  const buttonText = isLogInFormActive ? "Don't have and account? Sign up here" : 'Sign Up'
  const title = isLogInFormActive ? 'Welcome Back!' : 'Start Camping!'

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <div className="d-flex align-items-center flex-column pt-2">
          <ReactSVG className="roverpass-icon" src={RvIcon} />

          <h4 className="my-2">{title}</h4>
        </div>

        {isLogInFormActive ? <LogInForm closeModal={closeModal} formToggle={formToggle} /> :
                             <SingUpForm closeModal={closeModal} /> }

        <div className="d-flex align-items-center flex-column mt-4">
          <Link className="text-bold text-salmon" isExternal to={externalLinks.forgotPassword}>
            Forgot your password?
          </Link>

          <Button className="text-bold text-salmon" color="link" onClick={formToggle}>
            {buttonText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LogInModal
