import { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useUpdatePicture } from 'Shared/CampgroundPictures/UseUpdatePicture'
import { validationSchema } from 'Shared/CampgroundPictures/EditAltModal/validationSchema'
import AlertShowError from 'Shared/Alert/AlertShowError/AlertShowError'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import Loading from 'Shared/Loading'

const EditAltModal = ({ modalOpen, pictureAlt, pictureId, pictureUrlAlt, pictureUrl, setToastOpen,
                        toggleModal }) => {
  const { data, graphQLErrors, loading, pictureUpdate, reset } = useUpdatePicture()
  const [pictureAlternativeText, setPictureAlternativeText] = useState(pictureAlt)

  const initialValues = { altText: pictureAlternativeText }

  const handleOnSubmit = values => {
    setPictureAlternativeText(values.altText)
    pictureUpdate({ variables: { params: { action: 'update', altText: values.altText, pictureId } } })
  }

  useEffect(() => {
    if (data?.pictureUpdate) {
      setToastOpen(data.pictureUpdate?.success)
      reset()
      toggleModal()
    }
  }, [data])

  return (
    <Modal centered isOpen={modalOpen} toggle={toggleModal}>
      <FormWithValidations className="alt-form text-white" disableRenderProps initialValues={initialValues}
                           onSubmit={handleOnSubmit} scrollToError validationSchema={validationSchema}>
        <ModalHeader className="background background-salmon" toggle={toggleModal}>
          Adjust Alt Text
        </ModalHeader>

        <ModalBody className="text-center">
          {graphQLErrors && (
            <AlertShowError className="p-2" error={graphQLErrors[0]?.message} />
          )}

          <img className="photo-alt" src={pictureUrl} alt={pictureUrlAlt} width="125" />

          <FormTextInput label="Alt Text" name="altText" placeholder="Type Here the Alt Text for the picture"
                          showErrors />

          <div className="d-flex justify-content-end">
            <Button color="outline-dark me-3" disabled={loading} onClick={toggleModal}>
              Cancel
            </Button>

            <Button color="salmon" type="submit">
              {loading ? (
                <Loading className="px-3" iconClass="text-white" size="sm" />
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </ModalBody>
      </FormWithValidations>
    </Modal>
  )
}

export default EditAltModal
