import { gql } from 'Utils/apolloClient'

const undoBlackoutDatesMutation = gql`
  mutation BlackoutDatesBulkUndo($blackoutId: ID!) {
    blackoutDatesBulkUndo(input: { blackoutDateGroupId: $blackoutId }) {
      success
    }
  }`

export default undoBlackoutDatesMutation
