import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const HelpLink = ({ showLabel, url }) => {
  const buttonClasses = cnames('ms-2 rounded-pill text-white', { 'ps-2': showLabel })
  const iconWithLabel = () => (
    <div className="d-flex align-items-center">
      <span className="fa-stack fa-2xs">
        <FontAwesomeIcon className="text-white fa-stack" icon="circle" />
        <FontAwesomeIcon className="text-success fa-stack-1x" icon="question" />
      </span>

      <span className="ms-1">Get Help</span>
    </div>
  )

  const justIcon = () => <FontAwesomeIcon icon={['far', 'circle-question']} />

  return (
    <Button color="success" className={buttonClasses} href={url} tag="a" target="_blank">
      {showLabel ? iconWithLabel() : justIcon()}
    </Button>
  )
}

HelpLink.defaultProps = {
  showLabel: false,
}

HelpLink.propTypes = {
  showLabel: PropTypes.bool,
  url: PropTypes.string.isRequired,
}

export default HelpLink
