import cnames from 'classnames'
import { getPricingInstallment, PRICING_FOR_SKELETON } from './cartRecurringUtils'
import Items from './Items'
import Pricing from 'Shared/Invoices/Pricing/Pricing'
import PricingError from './PricingError'

const PricingTotals = ({ data, error, loading, showItems }) => {
  const { items, pricing, state } = data || {}
  const className = cnames('cart-recurring--pricing mt-2', { 'skeleton-loading': loading })
  const pricingValues = loading ? PRICING_FOR_SKELETON : getPricingInstallment(pricing)

  if (error) return <PricingError error={error} />

  return (
    <div className={className}>
      {showItems && <Items items={items} />}

      <Pricing {...pricingValues} state={state || 'draft'} textSize="h6" totalClassName="h5"
               totalHelperText="Per Month" totalName="TOTAL" />
    </div>
  )
}

export default PricingTotals
