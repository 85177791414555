import cnames from 'classnames'
import PropTypes from 'prop-types'

const ReservationBasketItem = ({ classContent, className, label, value }) => {
  const classes = cnames('d-flex justify-content-between align-items-center mb-2', className ?? 'pe-3')
  const valueClass = cnames('m-0', classContent)

  return (
    <div className={classes}>
      <p className={valueClass}>{label}</p>
      <p className={valueClass}>{value || '--'}</p>
    </div>
  )
}

ReservationBasketItem.defaultProps = {
  value: '--',
  classContent: '',
}

ReservationBasketItem.propTypes = {
  classContent: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string,
}

export default ReservationBasketItem
