import { useState } from 'react'
import withApolloProvider from 'Shared/VerifyWebsite/Components/withApolloProvider'
import { Fade } from 'reactstrap'
import CnameStatus from 'Shared/VerifyWebsite/Components/CnameStatus/CnameStatus'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import SecureStatus from 'Shared/VerifyWebsite/Components/SecureStatus/SecureStatus'

const VerifyWebsite = ({ canSecure, domain, isAdmin }) => {
  const [isCnameCorrect, setIsCnameCorrect] = useState(false)

  const onCnameCorrect = () => setIsCnameCorrect(true)

  return (
    <section id="verify-website" className="card">
      <div className="card-body d-flex flex-column ps-3">
        <span className="card-title">
          <FontAwesomeIcon className="me-2" icon="lock" size="sm" />
          Verify
        </span>

        <CnameStatus canSecure={canSecure} domain={domain} onCnameCorrect={onCnameCorrect} />

        {isCnameCorrect && (
          <Fade tag="div" in>
            <SecureStatus canSecure={canSecure} domain={domain} isAdmin={isAdmin} />
          </Fade>
        )}
      </div>
    </section>
  )
}

export default withApolloProvider(VerifyWebsite)
