import { useState } from 'react'
import { getButtonText } from 'Shared/ReservationOverviewCheckIn/Utils/CheckInLabel'
import { getElementDataSet } from 'Utils/Elements'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import Modal from './Modal/Modal'
import 'Shared/timemachine'

const MainButton = ({ checkInDate, closeCurrentModal, uuid }) => {
  const [modal, setModal] = useState(false)
  const text = getButtonText(checkInDate).replace('-', ' ')
  const modalData = closeCurrentModal ? { 'data-bs-dismiss': 'modal' } : {}
  const { engineName, slug: campgroundSlug, timezone, token } = getElementDataSet('checkin-data-container')
  const isAdmin = engineName === 'admin'
  window.roverData = { ...window?.roverData, campgroundSlug, isAdmin, timezone, token, uuid }

  const toggle = () => setModal(prev => !prev)

  return (
    <ApolloProvider client={apolloClient}>
      <Button {...modalData} color="success" onClick={toggle}>{text}</Button>

      <Modal checkInDate={checkInDate} isOpen={modal} toggle={toggle} />
    </ApolloProvider>
  )
}

MainButton.defaultProps = {
  closeCurrentModal: false,
}

export default MainButton
