import { createAsyncThunk } from '@reduxjs/toolkit'
import { asyncMutation, asyncQuery } from 'Utils/apolloClient'

const asyncThunkHandler = (asyncFn, errorCallback) => (
  async (data, thunkAPI) => {
    try {
      return await asyncFn({ ...data })
    } catch (error) {
      if (errorCallback) return errorCallback({ error, data: { ...data, ...thunkAPI } })

      if (error.response || error.message)
        return thunkAPI.rejectWithValue(error.response || error.message)

      throw error
    }
  }
)

export const asyncThunkMutationWithErrors = (name, handlerOrMutation, selector = () => {}) => {
  if (handlerOrMutation && typeof handlerOrMutation === 'function')
    return createAsyncThunk(name, asyncThunkHandler(handlerOrMutation))

  return createAsyncThunk(
    name,
    asyncThunkHandler(async ({ variables, mutation: customMutation, ...rest }) => {
      const actualMutation = customMutation || handlerOrMutation
      const { data, errors, graphQLErrors } = await asyncMutation(actualMutation)({ variables })
      const errorMessage = graphQLErrors?.[0] || errors?.[0]
      if (errorMessage) throw errorMessage

      return selector({ data, ...rest })
    })
  )
}

export const asyncThunkQueryWithErrors = (name, handlerOrQuery, selector, queryOptions = { fetchPolicy: 'network-only' }) => {
  if (handlerOrQuery && typeof handlerOrQuery === 'function')
    return createAsyncThunk(name, asyncThunkHandler(handlerOrQuery))

  return createAsyncThunk(
    name,
    asyncThunkHandler(async ({ query: customQuery, variables }) => {
      const actualQuery = customQuery || handlerOrQuery
      const { data, errors, graphQLErrors } = await asyncQuery(actualQuery, queryOptions)({ variables })
      const errorMessage = graphQLErrors?.[0] || errors?.[0]
      if (errorMessage) throw errorMessage

      return selector({ data, variables })
    })
  )
}
