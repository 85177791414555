export const skeletonInstallments = [
  {
    pricing: {
      camperTotal: {
        display: '$0.00',
      },
    },
    dueOn: '00/00',
    id: 1,
    links: {
      accountInstallmentUrl: '',
    },
  },
  {
    pricing: {
      camperTotal: {
        display: '$0.00',
      },
    },
    dueOn: '00/00',
    id: 2,
    links: {
      accountInstallmentUrl: '',
    },
  },
  {
    pricing: {
      camperTotal: {
        display: '$0.00',
      },
    },
    dueOn: '00/00',
    id: 3,
    links: {
      accountInstallmentUrl: '',
    },
  },
]
