import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import alertQuery from './AlertQuery'
import storeConnect from 'Checkout/Redux/Connect'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import { getCookie, setCookie } from 'Utils/Cookies'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import Alerts from 'Checkout/Components/Shared/Alerts'
import ViewModal from 'Shared/ViewModal/ViewModal'

const TWO_DAYS = 2 * 24 * 60

const NoticesModal = ({ alerts, alertsModal, campgroundName, dayUse, from, notAvailable, setAlerts,
                        setAlertsModal, slug, to }) => {
  const [isRequestNotices, setIsRequestNotices] = useState(false)
  const modalTitleHelper = notAvailable ? 'Availability Notices for' : 'Notices from'
  const modalTitle = `${modalTitleHelper} ${campgroundName}`
  const alertQueryArgs = { variables: { filters: { dayUse }, from, slug, to } }
  const [getAlerts, { data, error }] = useLazyQueryNoCache(alertQuery, alertQueryArgs)

  const alertData = data?.campground?.alerts || []
  const cookieName = `checkout-${slug}-view-noticies`
  const cookieValue = getCookie(cookieName)

  useEffect(() => {
    if ((!(alerts.length > 0) || isRequestNotices) && from && slug && to) getAlerts(alertQueryArgs)
    if (from && !isRequestNotices && slug && to) setIsRequestNotices(true)
  }, [from, to])

  useEffect(() => {
    if (data && !error) {
      const isCookieMissing = !cookieValue
      setAlerts({ alerts: alertData, alertsModal: isCookieMissing })
    }
  }, [data])

  const toggleModal = () => setAlertsModal(!alertsModal)

  const onOkModal = () => {
    if (!cookieValue) setCookie(cookieName, true, { expires: TWO_DAYS })
    toggleModal()
  }

  return (
    <>
      {!!alerts.length && (
        <ViewModal icon="triangle-exclamation" isOpen={alertsModal} title={modalTitle} onOk={onOkModal}
                   toggle={toggleModal}>
          <Alerts />
        </ViewModal>
      )}
    </>
  )
}

NoticesModal.defaultProps = {
  from: null,
  notAvailable: false,
  to: null,
}

NoticesModal.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string).isRequired,
  alertsModal: PropTypes.bool.isRequired,
  campgroundName: PropTypes.string.isRequired,
  from: PropTypes.string,
  notAvailable: PropTypes.bool,
  setAlerts: PropTypes.func.isRequired,
  setAlertsModal: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  to: PropTypes.string,
}

const mapStateToProps = state => ({
  alerts: state.campground.alerts,
  alertsModal: state.campground.alertsModal,
  campgroundName: state.campground.name,
  dayUse: state.search.dayUse,
  from: state.search.checkIn,
  slug: state.campground.slug,
  to: state.search.checkOut,
})

const mapDispatchToProps = dispatch => ({
  setAlerts: data => dispatch(actionCampgroundSetData(data)),
  setAlertsModal: alertsModal => dispatch(actionCampgroundSetData({ alertsModal })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(NoticesModal)
