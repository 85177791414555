const setCheckoutData = data => ({
  type: 'SET_CHECKOUT_DATA', payload: data,
})

const setCheckoutStepViewed = data => ({
  type: 'SET_CHECKOUT_STEP_VIEWED', payload: data,
})

const setPageView = data => ({
  type: 'SET_PAGE_VIEW', payload: data,
})

const setIdentifyUser = data => ({
  type: 'SET_IDENTIFY_USER', payload: data,
})

const setOrderCompleted = { type: 'SET_ORDER_COMPLETED' }

const setProductClicked = { type: 'SET_PRODUCT_CLICKED' }

const setProductsSearched = { type: 'SET_PRODUCTS_SEARCHED' }

export { setCheckoutData, setCheckoutStepViewed, setPageView, setIdentifyUser,
         setOrderCompleted, setProductClicked, setProductsSearched }
