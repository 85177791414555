import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const RefundError = ({ error, toggle }) => (
  <div className="d-flex flex-column m-xs-3 mx-auto w-75 px-md-3 my-3">
    <FontAwesomeIcon className="text-danger my-1" icon={['far', 'circle-xmark']} size="2x" />

    <h5 className="my-2 text-center fw-bold">
      Your reservation refund was not completed successfully
    </h5>

    <p className="text-danger text-center">{error}</p>

    <Button block color="salmon" onClick={toggle}>Close</Button>
  </div>
)

export default RefundError
