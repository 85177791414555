export default {
  editingReservationId: '',
  pricing: {
    data: {
      platformFee: {
        amount: 0,
        display: '$0.00',
      },
      processingFee: {
        amount: 0,
        display: '$0.00',
      },
      reservations: [],
      subtotal: {
        amount: 0,
        display: '$0.00',
      },
      tax: {
        amount: 0,
        display: '$0.00',
      },
      total: {
        amount: 0,
        display: '$0.00',
      },
    },
    error: null,
    loading: false,
    paymentProcessing: false,
  },
  reservations: [],
  reservationRemoved: false,
  reservationsValidate: {
    data: {
      errorsGroup: [],
      valid: true,
    },
    error: null,
    loading: true,
  },
}
