import PropTypes from 'prop-types'
import cnames from 'classnames'
import FormSelect from 'Shared/Formik/FormSelect'
import StatesOptions from './StatesOptions'

const StateDropDown = ({ className, defaultMessage, inputStateClass, isFloating, label, labelClasses, name,
                         onChange }) => {
   /* TODO: Move this CustomInput to FloatingLabelInput */
  const inputFloatingClasses = isFloating ? 'form-select floating-label-height floating-input-label-color' : ''
  const inputClasses = cnames(inputFloatingClasses, inputStateClass)
  const labelInput = isFloating ? '' : label

  return (
    <FormSelect className={className} defaultMessage={defaultMessage} inputClasses={inputClasses}
                label={labelInput} labelClasses={labelClasses} name={name} onChange={onChange}>
      <StatesOptions />
    </FormSelect>
  )
}

StateDropDown.defaultProps = {
  defaultMessage: '-- Select State --',
  inputStateClass: '',
  isFloating: false,
  label: 'State',
  name: 'state',
}

StateDropDown.propTypes = {
  defaultMessage: PropTypes.string,
  inputStateClass: PropTypes.string,
  isFloating: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
}

export default StateDropDown
