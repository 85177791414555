export const paymentMethodsState = {
  bankAccountsReusable: {
    completed: false,
    data: [],
    error: null,
    loading: false,
    lastAdded: null,
  },
  clientSecret: {
    completed: false,
    data: null,
    error: null,
    loading: false,
  },
  stripeCardsReusable: {
    completed: false,
    data: [],
    error: null,
    lastAdded: null,
    loading: false,
  },
  installmentsPendingToPay: {
    data: [],
    error: null,
    loading: false,
  },
  installmentsPendingToPayCount: 0,
  dropdownOpenStates: {},
  completed: false,
}
