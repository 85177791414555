import cnames from 'classnames'
import PropTypes from 'prop-types'
import './Tag.scss'

const Tag = ({ children, circle, className, color, fitContent, textMd }) => {
  const colorClass = color && `tag-${color}`
  const classes = cnames('tag d-flex justify-content-center align-items-center', className,
                         colorClass, { 'tag-circle': circle, 'tag-fit-content': fitContent,
                                       'tag__text--md': textMd })

  return (
    <span className={classes}>
      {children}
    </span>
  )
}

Tag.defaultProps = {
  circle: false,
  color: '',
  fitContent: false,
  textMd: false,
}

Tag.propTypes = {
  circle: PropTypes.bool,
  color: PropTypes.string,
  fitContent: PropTypes.bool,
  textMd: PropTypes.bool,
}

export default Tag
