import cnames from 'classnames'
import { FormGroup, Label } from 'reactstrap'

const FloatingLabelTextOnly = ({ className, label, value }) => {
  const classes = cnames('floating-label-text-only floating-label form-group', className)

  return (
    <FormGroup className={classes}>
      <Label>{label}</Label>
      <p className="value mb-0">{value}</p>
    </FormGroup>
  )
}

export default FloatingLabelTextOnly
