import { useState } from 'react'
import { useUpdatePicture } from 'Shared/CampgroundPictures/UseUpdatePicture'
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import EditAltModal from './EditAltModal/EditAltModal'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Toast from 'Shared/Toast/Toast'

const Wrapper = ({ pictureAlt, pictureId, pictureUrl }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [toastOpen, setToastOpen] = useState(false)

  const { pictureUpdate, loading } = useUpdatePicture()

  const deletePicture = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this picture?'))
      pictureUpdate({ variables: { params: { action: 'delete', pictureId } } })
  }

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)
  const toggleModal = () => setModalOpen(prevState => !prevState)
  const toggleToast = () => {
    if (toastOpen) setToastOpen(prevState => !prevState)
  }

  const handleModalToggle = () => {
    toggleModal()
    toggleDropdown()
  }

  return (
    <>
      <Dropdown className="background background-grey float-end" direction="left" isOpen={dropdownOpen}
                toggle={toggleDropdown}>
        <DropdownToggle color="picture-actions link-white">
          <FontAwesomeIcon icon="ellipsis-vertical" />
        </DropdownToggle>

        <DropdownMenu className="py-0">
          <Button className="w-100 mb-1 text-decoration-none" color="link-blue-700" disabled={loading}
                  onClick={handleModalToggle}>
            <FontAwesomeIcon className="me-2" icon="pen-to-square" />
            Adjust Alt Text
          </Button>

          <Button className="w-100" color="link-cardinal text-decoration-none" disabled={loading}
                  onClick={deletePicture}>
            <FontAwesomeIcon className="me-2" icon="trash-can" />
            Remove Photo
          </Button>
        </DropdownMenu>
      </Dropdown>

      <EditAltModal {...{ pictureAlt, pictureId, pictureUrl }} modalOpen={modalOpen} setToastOpen={setToastOpen}
                    toggleModal={toggleModal} />

      <Toast content="Alt text was updated successfully" duration={3000}
             icon={<FontAwesomeIcon className="text-success" icon="circle-check" size="lg" />}
             iconClasses="text-success" isOpen={toastOpen} title="Alternative Text" toggle={toggleToast} />
    </>
  )
}

export default Wrapper
