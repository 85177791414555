import { useEffect, useRef } from 'react'
import cnames from 'classnames'
import { Label } from 'reactstrap'
import FormGroupField from './FormGroupField'
import 'trix'
import '@rails/actiontext'

const FormTrixEditorField = ({ className, label, labelClasses, isRequired, name, placeholder, showErrors }) => {
  const loaded = useRef(false)
  const trixEditor = useRef(null)
  const classes = {
    label: cnames('main-label', labelClasses),
  }

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, form: { setFieldValue } }) => {
        useEffect(() => {
          if (trixEditor.current) {
            const handleChange = event => {
              setFieldValue(name, event.target.value)
            }

            trixEditor.current.addEventListener('trix-change', handleChange)

            return () => {
              trixEditor.current?.removeEventListener('trix-change', handleChange)
            }
          }
        }, [field])

        useEffect(() => {
          if (trixEditor.current && field.value && !loaded.current) {
            trixEditor.current.editor.loadHTML(field.value)
            loaded.current = true
          }
        }, [field])

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}

                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}
            <input id="trix_input" type="hidden" name={name} />

            <trix-editor input="trix_input" className="form-control" placeholder={placeholder}
                         ref={trixEditor} />
          </>
        )
      }}
    </FormGroupField>
  )
}

export default FormTrixEditorField
