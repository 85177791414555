import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { earlyCheckInLegend, tripInsuranceCoverageAlertText } from 'Utils/Translates'
import { getButtonText } from 'Shared/ReservationOverviewCheckIn/Utils/CheckInLabel'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient/useLazyQueryNoCache'
import reservationQuery from 'Shared/ReservationOverviewCheckIn/Queries/ReservationQuery'
import CloseModalButton from 'Shared/CloseModalButton'
import ModalContent from 'Shared/ReservationOverviewCheckIn/Modal/ModalContent'

const ModalWrapper = ({ checkInDate, isOpen, toggle }) => {
  const [getReservation, { data, error, loading }] = useLazyQueryNoCache(reservationQuery)
  const closeButton = (<CloseModalButton isColorWhite onClick={toggle} />)
  const title = getButtonText(checkInDate).replace('-', ' ')
  const { campgroundSlug, isAdmin, uuid } = window.roverData

  useEffect(() => {
    if (isOpen) {
      const keys = [{ key: earlyCheckInLegend }, { key: tripInsuranceCoverageAlertText }]
      getReservation({ variables: { isEngineAdmin: isAdmin, slug: campgroundSlug, keys, uuid } })
    }
  }, [isOpen])

  return (
    <Modal id="check_in_form" isOpen={isOpen} toggle={toggle}>
      <ModalHeader close={closeButton} className="bg-success" toggle={toggle}>
        <span className="text-white">{title}</span>
      </ModalHeader>

      <ModalBody>
        {!loading && !error && data && (
          <ModalContent checkInDate={checkInDate} data={data} toggle={toggle} isAdmin={isAdmin} />
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalWrapper
