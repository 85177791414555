import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import 'Styles/SalmonHeaderModal.scss'

const DeleteModal = ({ isOpen, name, onDelete, toggle }) => (
  <Modal className="delete-modal salmon-header-modal" isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Are you sure you want to delete {name}?</ModalHeader>

    <ModalBody>
      <p>This action cannot be undone.</p>

      <div className="d-flex justify-content-between">
        <Button onClick={toggle}>No, Go Back</Button>

        <Button color="salmon" onClick={onDelete}>
          <FontAwesomeIcon className="me-2" color="white" icon="trash" />

          Yes, Delete it
        </Button>
      </div>
    </ModalBody>
  </Modal>
)

export default DeleteModal
