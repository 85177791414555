import { useEffect } from 'react'
import { setCookie } from 'Utils/Cookies'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import userRegistrationCreateMutation from 'Shared/LogInModal/Mutations/UserRegistrationMutation'
import { Button, Row } from 'reactstrap'
import EmailInput from 'Shared/Formik/EmailInput'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import Loading from 'Shared/Loading'
import MessageError from 'Shared/MessageError/MessageError'
import ValidateSchema from 'Shared/LogInModal/Components/SingUpForm/ValidateSchema'

const SingUpForm = ({ closeModal }) => {
  const initialFormValues = { firstName: '', lastName: '', email: '', password: '' }
  const [userRegistrationCreate, { error, data, loading }] = useMutation(userRegistrationCreateMutation)

  const handleOnSubmit = values => {
    userRegistrationCreate({ variables: values })
  }

  useEffect(() => {
    if (!error && data) {
      const { currentUser } = data?.userRegistrationCreate
      setCookie('user_token', currentUser.authenticationToken, { expires: 6 * 60 })
      closeModal()
      window.location.reload()
    }
  }, [data])

  return (
    <>
      <p className="text-center text-muted">Create a free account today to quickly connect with campgrounds</p>

      {(error) && <MessageError error={error} /> }

      <FormWithValidations className="payment-method-form" disableRenderProps initialValues={initialFormValues}
                           onSubmit={handleOnSubmit} validationSchema={ValidateSchema}>
        <Row>
          <FormTextInput className="col-6 m-0" label="First Name" name="firstName" placeholder="Your First Name" />

          <FormTextInput className="col-6 m-0" label="Last Name" name="lastName" placeholder="Your Last Name" />
        </Row>

        <EmailInput label="Email" name="email" placeholder="youremail@andyadress.com" sizeInput="md" />

        <FormTextInput label="Password" name="password" placeholder="Your Password" type="password" />

        <Button className="text-white w-100 mt-2" color="salmon" type="submit">
          {loading ? <Loading /> : 'Sign In'}
        </Button>
      </FormWithValidations>
    </>
  )
}

export default SingUpForm
