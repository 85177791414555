import { moment } from 'Utils/Dates'
import connect from 'Admin/Store/connect'
import { tripInsuranceCoverageAlertText } from 'Utils/Translates'
import { setDatesValues } from 'Shared/CalendarQuickEdit/Store/CalendarQuickEditSlice'
import DateRangePicker from 'Shared/Revision/DateRangePicker'

const DateRangePickerWrapper = ({ closeSeasonDates, disabled, reservation, setDatesValues,
                                  tripInsuranceCoverageAlertText }) => {
  const { isHold } = window.roverData
  const { createdAt, from, to, tripInsurancePolicy } = reservation
  const { cannotCancel, tripInsuranceNightLimit } = tripInsurancePolicy || {}
  const startDateHiddenName = isHold ? 'inventory_hold_form[from]' : 'reservation[from]'
  const endDateHiddenName = isHold ? 'inventory_hold_form[to]' : 'reservation[to]'
  const minYear = moment().subtract(3, 'years').year()

  const onChangedDates = dates => setDatesValues(dates)

  return (
    <DateRangePicker alertMessage={tripInsuranceCoverageAlertText} canNotCancelInsurance={cannotCancel}
                     closureDates={closeSeasonDates} disabled={disabled} endDateHiddenName={endDateHiddenName}
                     endDateValue={to} isLegacyReservation minYear={minYear} onChangedDates={onChangedDates}
                     reservationCreatedAt={createdAt} startDateHiddenName={startDateHiddenName}
                     startDateValue={from} submitButtonId="reservation_change_form_submit"
                     tripInsuranceNightsLimit={tripInsuranceNightLimit} />
  )
}

const mapDispatchToProps = { setDatesValues }

const mapStateToProps = state => {
  const { campground, translates, reservation } = state.calendarQuickEdit
  const { closeSeasonDates } = campground
  const { messages } = translates

  return { closeSeasonDates, reservation,
           tripInsuranceCoverageAlertText: messages[tripInsuranceCoverageAlertText] }
}

export default connect({ mapDispatchToProps, mapStateToProps })(DateRangePickerWrapper)
