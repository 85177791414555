export const skeletonPrice = {
  platformFee: {
    display: '$-.--',
  },
  processingFee: {
    display: '$-.--',
  },
  reservations: [{
    from: '12/12/1999',
    rate: {
      id: 'loading...',
      name: 'Loading...',
    },
    to: '12/12/1999',
  }],
  subtotal: {
    display: '$-.--',
  },
  tax: {
    display: '$-.--',
  },
  total: {
    display: '$-.--',
  },
}
