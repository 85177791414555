import { useState } from 'react'
import { Button } from 'reactstrap'
import App from './App'
import ConfirmSingleCancelModal from './ConfirmSingleCancelModal'
import 'Styles/Button.scss'

const CancelSingleReservation = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenCancel, setIsOpenCancel] = useState(false)
  const { campgroundName, chatUrl } = props

  const toggle = () => setIsOpen(prevState => !prevState)

  const onCancelSingle = () => setIsOpenCancel(false)

  const onConfirm = () => {
    toggle()
    setIsOpenCancel(true)
  }

  return (
    <div>
      <Button color="outline-cardinal" onClick={toggle}>Cancel Reservation</Button>

      <ConfirmSingleCancelModal {... { campgroundName, chatUrl, isOpen, onConfirm, toggle }} />

      <App {...props} hideButton isSingleCancelRequested={isOpenCancel} onCancelSingle={onCancelSingle} />
    </div>
  )
}

export default CancelSingleReservation
