import * as Yup from 'yup'

export const formSchema = Yup.object().shape({
  days: Yup.array().of(Yup.number().max(7, 'Please select only days of the week.')),

  from: Yup.string().required('Please select the first day of the Blackout range.'),

  sites: Yup.array().min(1, 'Please Select at least one Site.'),

  to: Yup.string().required('Please select the last day of the Blackout range.'),
})
