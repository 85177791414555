import { useEffect } from 'react'
import cnames from 'classnames'
import itemFormSchema from './itemFormSchema'
import { Button } from 'reactstrap'
import BasicFields from './BasicFields'
import CategorySelect from './CategorySelect'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormWithValidations from 'Shared/Formik/FormWithValidations'

const ItemForm = ({ children, initialValues, isEdition, removeItem, onSubmit, showRemoveBtn }) => {
  const classButtons = cnames('d-flex', { 'justify-content-between': showRemoveBtn },
                                        { 'justify-content-end': !showRemoveBtn })

  const onFormSubmit = values => {
    const parseValues = { ...values, tax: values.tax || 0 }
    onSubmit(parseValues)
  }

  return (
    <FormWithValidations initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={itemFormSchema}>
      {({ dirty, isValid, resetForm }) => {
        useEffect(() => {
          resetForm({ values: initialValues })
        }, [initialValues])

        return (
          <>
            {children}

            <div className={classButtons}>
              {showRemoveBtn && (
                <Button color="secondary" onClick={removeItem} size="md">
                  <FontAwesomeIcon className="me-2" icon={['far', 'trash-can']} />
                  Delete
                </Button>
              )}

              <Button color="salmon" disabled={!isValid || (isEdition && !dirty)} size="md" type="submit">
                Save
              </Button>
            </div>
          </>
        )
      }}
    </FormWithValidations>
  )
}

ItemForm.BasicFields = BasicFields
ItemForm.CategorySelect = CategorySelect

export default ItemForm
