import { useEffect } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import userInvoicesQuery from './userInvoicesQuery'
import InstallmentsToPay from './InstallmentsToPay'

const Wrapper = () => {
  const [getUserInvoices, { data, loading }] = useLazyQueryNoCache(userInvoicesQuery)
  const { userId: id } = window.roverData
  const { installmentsPendingToPay = [], installmentsPendingToPayCount = 0 } = data?.user || {}

  useEffect(() => {
    if (id) getUserInvoices({ variables: { id } })
  }, [])

  return (
    <InstallmentsToPay data={installmentsPendingToPay} loading={loading}
                       totalInstallments={installmentsPendingToPayCount} />
  )
}

export default Wrapper
