import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { formatDate } from 'Utils/Dates'

const ReservationModalContent = ({ formatDates, from, guests, isRigRequired, rig, to }) => (
  <div className="w-100">
    <div className="row mx-0">
      <div className="col-6 ps-0">
        <p className="fw-bold text-dark">
          <FontAwesomeIcon className="me-2" icon="arrow-right" />
          Check In
        </p>

        <p> {formatDate(from, { format: formatDates })}</p>
      </div>

      <div className="col-6 pe-0">
        <p className="fw-bold text-dark">
          <FontAwesomeIcon className="me-2" icon="arrow-left" />
          Check Out
        </p>

        <p>{to === 'TBD' ? to : formatDate(to, { format: formatDates })}</p>
      </div>
    </div>

    {guests && (
      <div className="row mx-0">
        <div className="col-6 ps-0">
          <p className="fw-bold text-dark">
            <FontAwesomeIcon className="me-2" icon="user-group" />
            Guest
          </p>

          <div className="d-flex flex-wrap">
            <p className="me-2">{guests.adults} Adults</p>
            <p className="me-2">{guests.children} Children</p>
            <p>{guests.pets} Pets</p>
          </div>
        </div>

        <div className="col-6 pe-0">
          <p className="fw-bold text-dark">
            <FontAwesomeIcon className="me-2" icon="car" />
            Extra Vehicles
          </p>
          {guests.vehicles}
        </div>
      </div>
    )}

    {(rig && isRigRequired) && (
      <>
        <p className="fw-bold text-dark">
          <FontAwesomeIcon className="me-2" icon="truck" />
          Rig Information
        </p>

        <div className="d-flex justify-content-between flex-wrap">
          <p>{rig.year || '--'}</p>
          <p>{rig.rigType || '--'}</p>
          <p>{rig.rigLength || '--'}' long x {rig.rigWidth || '--'}' wide</p>
          <p>{rig.numSlideouts || '--'} Slideouts</p>
        </div>
      </>
    )}
  </div>
)

ReservationModalContent.defaultProps = {
  formatDates: 'MM-DD-YYYY',
  from: null,
  guests: null,
  isRigRequired: true,
  rig: null,
  to: null,
}

ReservationModalContent.propTypes = {
  formatDates: PropTypes.string,
  from: PropTypes.string,
  guests: PropTypes.shape({}),
  isRigRequired: PropTypes.bool,
  rig: PropTypes.shape({}),
  to: PropTypes.string,
}

export default ReservationModalContent
