import cnames from 'classnames'

const BalanceContent = ({ balance }) => {
  const balanceDue = balance?.due
  const hasDue = balanceDue?.amount > 0
  const remainingBalance = balance?.remaining
  const classes = {
    mainText: cnames('text-due fw-normal', { 'text-muted': !hasDue }),
    mainTextValue: cnames('fw-bold m-0', { 'text-cardinal': hasDue }),
  }

  const mainText = hasDue ? { text: 'Due', value: balanceDue?.display } :
                            { text: 'Remaining', value: remainingBalance?.display }

  return (
    <div className="border-bottom-1 justify-content-between mb-3 pb-3 px-0">
      <div className="w-75">
        <p className="fw-bold mb-2 text-grey-800">Reservation Balance</p>

        <p className={classes.mainTextValue}>
          {mainText.value}

          <small className={classes.mainText}> {mainText.text}</small>
        </p>

        <div className={classes.balanceStyleView}>
          {hasDue && (
            <small className="fw-bold text-muted fst-italic m-0 text-sm me-3 col-lg-7 col-xl-12">
              {remainingBalance?.display}

              <span className="fw-normal text-sm"> Remaining</span>
            </small>
          )}
        </div>
      </div>
    </div>
  )
}

export default BalanceContent
