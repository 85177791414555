import { formatDate } from 'Utils/Dates'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'

const InstallmentCard = ({ installment }) => {
  const { pricing: { camperTotal }, dueOn, id, links } = installment

  return (
    <Link className="installment-card btn btn-light d-block border rounded-3 p-2" isExternal target="_blank"
          to={links?.accountInstallmentUrl}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-start">
          <span>{camperTotal.display}</span>
        </div>

        <div>
          <small className="text-muted">Due on:</small> <span>{formatDate(dueOn, { format: 'MM/DD' })}</span>
        </div>

        <div>
          <small className="text-muted">ID:</small> <span>{id}</span>
        </div>

        <div className="text-end">
          <FontAwesomeIcon className="text-muted fa-xs" icon="up-right-from-square" />
        </div>
      </div>
    </Link>
  )
}

export default InstallmentCard
