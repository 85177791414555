import cnames from 'classnames'
import { Card } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import InfoAlert from './InfoAlert'
import ReservationCard from './ReservationCard/ReservationCard'
import ScrollableList from 'Shared/ScrollableList/ScrollableList'
import './GroupBookingsBar.scss'

const GroupBookingsBar = ({ className, displayAlert, linkText, message, redirectUrl, reservations,
                            reservationParentLink, selected }) => {
  const classes = {
    container: cnames('group-bookings-bar', className),
    reservationsCard: cnames('rounded border shadow-sm p-2'),
  }

  return (
    <div className={classes.container}>
      <Card className={classes.reservationsCard}>
        <div className="d-flex justify-content-between">
          <div className="text-grey-800">
            <FontAwesomeIcon className="me-2" icon="users" />

            GROUP BOOKING
          </div>

          <div>
            <strong className="text-grey-700">{reservations?.length}</strong>

            <span className="ms-1 text-grey-800">Reservations</span>
          </div>
        </div>

        <ScrollableList items={reservations}>
          {reservation => <ReservationCard {...reservation} redirectUrl={redirectUrl} selected={selected} />}
        </ScrollableList>
      </Card>

      {displayAlert && (
        <InfoAlert linkText={linkText} reservationParentLink={reservationParentLink} message={message} />
      )}
    </div>
  )
}

GroupBookingsBar.defaultProps = {
  redirectUrl: 'detailsUrl',
  reservations: [],
}

export default GroupBookingsBar
