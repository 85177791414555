import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap'
import FontAwesomeIcon from 'Shared/Admin/FontAwesomeIcon/FontAwesomeIcon'

const SearchForm = ({ className, onSubmit, placeholder, value }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  const handleOnChange = ({ target }) => setInputValue(target.value)

  const handleSubmit = event => {
    event.preventDefault()

    onSubmit(inputValue)
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <InputGroup>
        <InputGroupText>
          <FontAwesomeIcon icon="magnifying-glass" />
        </InputGroupText>

        <Input name="search" placeholder={placeholder} value={inputValue} onChange={handleOnChange} />

        <Button color="primary" type="submit">Search</Button>
      </InputGroup>
    </form>
  )
}

SearchForm.defaultProps = {
  placeholder: '',
  value: '',
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

export default SearchForm
