import { useEffect, useRef, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import ActivationNeeded from 'Shared/InteractiveMap/ActivationNeeded/ActivationNeeded'

const MapInteraction = ({ children, controls: Controls }) => {
  const clickRef = useRef(false)
  const activationRef = useRef(false)
  const wrapperRef = useRef()
  const { isDesktopView } = useWindowDimensions()
  const [activationNeeded, setActivationNeeded] = useState(false)
  const [isPanningDisabled, setIsPanningDisabled] = useState(false)
  const [wheelDisabled, setWheelDisabled] = useState(true)
  const panningData = { disabled: isPanningDisabled }
  const wheel = { smoothStep: 0.01, wheelDisabled }

  const handler = data => setIsPanningDisabled(data.state.scale <= 1)

  const handleActivationNeeded = value => {
    activationRef.current = value
    setActivationNeeded(value)
  }

  const handleWheelDisabled = value => {
    clickRef.current = value
    setWheelDisabled(!value)
  }

  const onClick = () => {
    handleActivationNeeded(false)
    handleWheelDisabled(true)
  }

  useEffect(() => {
    document.addEventListener('wheel', e => {
      if (activationRef.current) return

      const wrapper = e.target.closest('#wrapper')
      handleActivationNeeded(!!wrapper)

      setTimeout(() => {
        handleActivationNeeded(false)
      }, 2000)
    })
  }, [])

  useEffect(() => {
    if (!isDesktopView || !wrapperRef.current) return

    const onClickWraper = () => {
      handleWheelDisabled(true)
      if (!activationRef.current) return

      handleActivationNeeded(false)
    }

    const onMouseLeaveWrapper = () => {
      if (!clickRef.current) return

      handleWheelDisabled(false)
    }

    const wrapper = wrapperRef.current
    wrapper.addEventListener('click', onClickWraper)
    wrapper.addEventListener('mouseleave', onMouseLeaveWrapper)

    return () => {
      wrapper.removeEventListener('click', onClickWraper)
      wrapper.removeEventListener('mouseleave', onMouseLeaveWrapper)
    }
  }, [wrapperRef.current])

  return (
    <TransformWrapper minScale={1} onTransformed={handler} panning={panningData} wheel={wheel}>
      {Controls && <Controls />}

      <div id="wrapper" ref={wrapperRef}>
        <TransformComponent contentClass="w-100" wrapperClass="h-100 w-100">
          {children}
        </TransformComponent>
      </div>

      {activationNeeded && <ActivationNeeded onClick={onClick} />}
    </TransformWrapper>
  )
}

MapInteraction.defaultProps = {
  disableRenderProps: false,
}

export default MapInteraction
