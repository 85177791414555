export const eventMaping = {
  EventInstallment: {
    autoCreated: {
      icon: {
        color: '#2B84BF',
        name: 'rotate',
      },
      label: 'Recurring Invoice Schedule Created',
    },
    created: {
      icon: {
        color: '#20628F',
        name: 'calendar-plus',
      },
      label: 'Invoice Created',
    },
    due: {
      icon: {
        color: '#E35D6A',
        name: 'circle-exclamation',
      },
      label: 'Invoice Due',
    },
    dataChanged: {
      icon: {
        name: ['far', 'calendar-days'],
      },
    },
    itemChanged: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
    },
    itemCreated: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
    },
    itemDeleted: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
    },
    overdue: {
      icon: {
        color: '#E35D6A',
        name: 'circle-exclamation',
      },
      label: 'Invoice Past Due',
    },
    paid: {
      icon: {
        color: '#198754',
        name: 'money-bill',
      },
      label: 'Invoice Paid',
    },
    paymentFailed: {
      icon: {
        color: '#E35D6A',
        name: 'triangle-exclamation',
      },
      label: 'Invoice Payment Failed',
    },
    refundCreated: {
      icon: {
        name: 'arrow-rotate-left',
      },
      label: 'Invoice Refunded',
    },
    reminder: {
      icon: {
        name: 'paper-plane',
      },
      label: 'Invoice Reminder',
    },
    replacement: {
      icon: {
        color: '#34414D',
        name: 'ban',
      },
      label: 'Invoice Replaced',
    },
    scheduled: {
      icon: {
        name: ['far', 'clock'],
      },
      label: 'Invoice Scheduled',
    },
    sent: {
      icon: {
        name: 'paper-plane',
      },
      label: 'Invoice Sent',
    },
    voided: {
      icon: {
        color: '#31414D',
        name: 'ban',
      },
      label: 'Invoice Voided',
    },
  },
  EventReservation: {
    approve: {
      icon: {
        color: '#198754',
        name: 'user-check',
      },
      label: 'Reservation Approved',
    },
    approveReservationRevision: {
      icon: {
        color: '#23741F',
        name: 'user-check',
      },
      label: 'Camper Approved Reservation Revision',
    },
    camperExpire: {
      icon: {
        color: '#BD2031',
        name: 'user-slash',
      },
      label: 'Reservation Request Cancelled by Camper',
    },
    cancel: {
      icon: {
        color: '#E35D6A',
        name: 'calendar-xmark',
      },
      label: 'Reservation Cancelled',
    },
    cancellationRequest: {
      icon: {
        color: '#BD2031',
        name: 'exclamation',
      },
      label: 'Cancellation Request',
    },
    checkIn: {
      icon: {
        color: '#60A2CF',
        name: 'arrow-right',
      },
      label: 'Camper Checked-in',
    },
    checkOut: {
      icon: {
        color: '#20628F',
        name: 'arrow-left',
      },
      label: 'Camper Checked-out',
    },
    checkOutSetted: {
      icon: {
        color: '#B77624',
        name: 'arrow-left',
      },
    },
    create: {
      icon: {
        color: '#20628F',
        name: 'calendar-plus',
      },
    },
    expire: {
      icon: {
        name: 'hourglass-end',
      },
      label: 'Reservation Request Expired',
    },
    group: {
      icon: {
        color: '#20628F',
        name: 'users',
      },
    },
    overriddenRate: {
      icon: {
        name: 'file-invoice-dollar',
      },
    },
    pending: {
      icon: {
        color: '#2B84BF',
        name: 'bell',
      },
      label: 'Reservation Request Pending',
    },
    recalculate: {
      icon: {
        color: '#454F56',
        name: 'file-invoice-dollar',
      },
      label: 'Overridden Rate',
    },
    refund: {
      icon: {
        name: 'arrow-rotate-left',
      },
      label: 'Reservation Refund',
    },
    reject: {
      icon: {
        color: '#BD2031',
        name: 'times',
      },
      label: 'Reservation Request Rejected',
    },
    rejectReservationRevision: {
      icon: {
        color: '#BD2031',
        name: 'user-xmark',
      },
      label: 'Camper Reject Reservation Revision',
    },
    reopen: {
      icon: {
        color: '#2B84BF',
        name: 'rotate',
      },
      label: 'Reservation Reopened',
    },
    revision: {
      label: 'Reservation Revision',
    },
    undoCheckIn: {
      icon: {
        color: '#F19B2F',
        name: 'arrow-right',
      },
      label: 'Check-in Undone',
    },
    undoCheckOut: {
      icon: {
        color: '#B77624',
        name: 'arrow-left',
      },
      label: 'Check-out Undone',
    },
  },
  EventSchedule: {
    amountChanged: {
      icon: {
        color: '#2B84BF',
        name: 'file-invoice-dollar',
      },
      label: 'Invoice Schedule Total amount changed',
    },
    billingDayChanged: {
      icon: {
        color: '#2B84BF',
        name: 'calendar-check',
      },
      label: 'Billing Day Changed',
    },
    itemChanged: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
    },
    itemCreated: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
      label: 'Item added to Invoice Schedule',
    },
    itemDeleted: {
      icon: {
        name: ['far', 'money-bill-1'],
      },
      label: 'Item removed from Invoice Schedule',
    },
    stopDateSetted: {
      icon: {
        color: '#BD2031',
        name: 'circle-stop',
      },
      label: 'Billing End Date has been set and the Recurring Invoice Schedule has been stopped',
    },
  },
}

export const revisionMapping = {
  adults: {
    icon: {
      name: 'user-group',
    },
    label: 'Adults',
  },
  children: {
    icon: {
      name: 'user-group',
    },
    label: 'Children',
  },
  firstName: {
    icon: {
      name: 'users',
    },
    label: 'First Name',
  },
  from: {
    icon: {
      name: 'arrow-right',
    },
    label: 'Check-in',
  },
  lastName: {
    icon: {
      name: 'users',
    },
    label: 'Last Name',
  },
  nightlyTotal: {
    icon: {
      name: 'dollar-sign',
    },
    label: 'Price',
  },
  pets: {
    icon: {
      name: 'dog',
    },
    label: 'Pets',
  },
  phone: {
    icon: {
      name: 'phone',
    },
    label: 'Phone',
  },
  savings: {
    icon: {
      name: 'dollar-sign',
    },
    label: 'Discount',
  },
  siteName: {
    icon: {
      name: 'location-dot',
    },
    label: 'Site',
  },
  siteAssigned: {
    icon: {
      name: 'location-dot',
    },
    label: 'Site Assigned',
  },
  tax: {
    icon: {
      name: 'dollar-sign',
    },
    label: 'Tax',
  },
  to: {
    icon: {
      name: 'arrow-left',
    },
    label: 'Check-out',
  },
  vehicles: {
    icon: {
      name: 'car',
    },
    label: 'Vehicles',
  },
}
