import { ArcElement, Chart, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { externalTooltipHandler } from './CustomTooltip'

Chart.register(ArcElement, Tooltip)

const SingleDoughnutChart = ({ color, label, value }) => {
  const colorMap = {
    red: '#BD2031',
    green: '#24741F',
    orange: '#F19B2F',
    grey: '#D8D8D8',
  }

  const hexColor = colorMap[color]
  const balanceDisplay = { [label]: value }
  const data = {
    labels: [label],
    datasets: [
      {
        data: [1],
        backgroundColor: [hexColor],
        hoverBackgroundColor: [hexColor],
      },
    ],
  }

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        external: context => externalTooltipHandler(balanceDisplay, context),
      },
    },
  }

  return (
    <div className="d-flex align-items-center">
      <Doughnut data={data} height={79} options={options} width={79} />
    </div>
  )
}

export default SingleDoughnutChart
