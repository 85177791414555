import PropTypes from 'prop-types'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const AddItem = ({ onClick, text }) => (
  <tr>
    <td colSpan="3">
      <Anchor className="text-dark" href="#add-item" onClick={onClick}>
        <FontAwesomeIcon className="me-2" icon="plus" />

        <strong>{text}</strong>
      </Anchor>
    </td>
  </tr>
)

AddItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default AddItem
