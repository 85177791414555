import { gql } from 'Utils/apolloClient'

const userInvoicesQuery = gql`
  query User($id: ID) {
    user(id: $id) {
      id
      installmentsPendingToPay {
        pricing {
          camperTotal {
            display
          }
        }
        dueOn
        id
        links {
          accountInstallmentUrl
        }
      }
      installmentsPendingToPayCount
    }
  }`

export default userInvoicesQuery
