import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const Error = ({ error }) => (
  <div className="cart-recurring--error flex-centered flex-column py-5 bg-light rounded">
    <FontAwesomeIcon className="ms-2 text-muted" size="xl" icon="triangle-exclamation" />

    <h5 className="mt-2 text-danger">{error}</h5>
  </div>
)

export default Error
