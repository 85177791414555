import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'

const TextTruncatedWithTooltip = ({ className, id, maxLength, placement, text }) => (
  <>
    <span className={className} id={id}>
      {text}
    </span>

    {text.length > maxLength && (
      <UncontrolledTooltip placement={placement} target={id}>
        {text}
      </UncontrolledTooltip>
    )}
  </>
)

TextTruncatedWithTooltip.defaultProps = {
  className: 'd-block text-truncate w-100',
  maxLength: 40,
  placement: 'auto',
}

TextTruncatedWithTooltip.prototypes = {
  id: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placement: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default TextTruncatedWithTooltip
