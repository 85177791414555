import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const LightboxImages = ({ images, closeLightbox }) => {
  const [lightboxIndex, setLightboxIndex] = useState(0)

  const nextSrc = (lightboxIndex + 1) % images.length
  const prevSrc = (lightboxIndex + images.length - 1) % images.length

  const nextLightboxImage = () => setLightboxIndex(prevSrc)
  const prevLightboxImage = () => setLightboxIndex(nextSrc)

  return <Lightbox mainSrc={images[lightboxIndex]} nextSrc={images[nextSrc]} prevSrc={images[prevSrc]}
                   onCloseRequest={closeLightbox} onMovePrevRequest={prevLightboxImage}
                   onMoveNextRequest={nextLightboxImage} enableZoom={false} />
}

export default LightboxImages
