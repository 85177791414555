import { Label } from 'reactstrap'
import CurrencyInput from 'Shared/Formik/FormCurrencyInput'

const TwoColumnsCurrency = ({ children, id, label, max, name }) => (
  <CurrencyInput classContainerInput="col-md-4" className="row align-items-center mb-3" id={id} max={max}
                 name={name} showErrors>
    <Label className="col-md-8 fw-bold mb-0" for={name}>
      {children || label}
    </Label>
  </CurrencyInput>
)

export default TwoColumnsCurrency
