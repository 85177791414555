import { validationSchema } from './validationSchema'
import { Button } from 'reactstrap'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'

const initialValues = { stripeChargeKey: '' }

const Form = ({ link, toggle }) => {
  const onSubmit = values => link(values)

  return (
    <FormWithValidations disableRenderProps formId="link-stripe-charge-form" initialValues={initialValues}
                         onSubmit={onSubmit} validationSchema={validationSchema}>
      <FormTextInput className="mb-3" label="Stripe Charge Key" name="stripeChargeKey" showErrors sizeInput="md" />

      <div className="d-flex justify-content-between">
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="salmon" type="submit">Save</Button>
      </div>
    </FormWithValidations>
  )
}

export default Form
