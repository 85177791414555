import * as Yup from 'yup'

export const validationSchema = () => (
  Yup.object().shape({
    stripeChargeKey: Yup.string()
      .required('Stripe Charge Key is required')
      .matches(/^ch_[a-zA-Z0-9]{17,48}$/, 'Stripe Charge Key must start with "ch_" and be between 20 and 50 characters')
      .max(50, 'Stripe Charge Key must be at most 50 characters'),
  })
)
