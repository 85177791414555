import { Button, InputGroup, Input, Label } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import PropTypes from 'prop-types'

const CopyToClipboardInput = ({ className, disabled, label, name, value }) => {
  const copyToClipboard = () => navigator.clipboard.writeText(value)

  return (
    <div className={className}>
      <Label for={name}>{label}</Label>

      <InputGroup>
        <Input name={name} disabled={disabled} value={value} />

        <Button onClick={copyToClipboard} outline>
          <FontAwesomeIcon icon={['far', 'copy']} />
        </Button>

        <Button onClick={copyToClipboard} outline>Copy ID</Button>
      </InputGroup>
    </div>
  )
}
CopyToClipboardInput.defaultProps = {
  disabled: false,
  label: '',
}

CopyToClipboardInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default CopyToClipboardInput
