import { useState, useEffect, useCallback } from 'react'
import cnames from 'classnames'
import { Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { removeItem } from 'Utils/Arrays'
import FilterOptions from './FilterOptions/FilterOptions'
import './FilterDropdown.scss'

const FilterDropdown = ({ children, className, filters, options, setFilters, showIcon, titleButton }) => {
  const [activeSaveInStore, setActiveSaveInStore] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [optionsDropdown, setOptionsDropdown] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  const classes = cnames('filter-dropdown', className)

  const setDefaultOptions = useCallback(value => setOptionsDropdown(prevState => [...prevState, value]), [])

  useEffect(() => {
    if (!openDropdown && activeSaveInStore) setFilters(selectedOptions)
    else setSelectedOptions(filters)
  }, [openDropdown])

  const addSelectedOptions = value => setSelectedOptions([...selectedOptions, value])

  const clearSelectedOptions = e => {
    e.preventDefault()
    const clearOptions = selectedOptions.filter(item => optionsDropdown.indexOf(item) < 0)

    if (!activeSaveInStore) setActiveSaveInStore(true)

    setSelectedOptions(clearOptions)
  }

  const removeSelectedOptions = value => {
    const newSelectedOptions = removeItem(selectedOptions, value)

    setSelectedOptions(newSelectedOptions)
  }

  const saveSelectedOptions = () => {
    setFilters(selectedOptions)
    toggleOptionsDropdown()
  }

  const selectFilter = ({ target: { checked, name } }) => {
    if (!activeSaveInStore) setActiveSaveInStore(true)

    if (checked) return addSelectedOptions(name)

    return removeSelectedOptions(name)
  }

  const toggleOptionsDropdown = () => setOpenDropdown(!openDropdown)

  const renderProps = { options, optionsDropdown, selectFilter, selectedOptions, setDefaultOptions }

  return (
    <Dropdown className={classes} isOpen={openDropdown} toggle={toggleOptionsDropdown}>
      <DropdownToggle className="btn-filter fw-bold position-relative py-1 px-4 h-100 w-100">
        {showIcon && (
          <div className="filter-dropdown__mobile h-100 d-flex d-lg-none justify-content-center align-items-center
                          px-3 position-absolute">
            <FontAwesomeIcon icon="bars" size="lg" />
          </div>
        )}

        {titleButton}
      </DropdownToggle>

      <DropdownMenu className="px-3 pt-2 pb-3">
        <div className="mb-3">
          {children ? children(renderProps) : <FilterOptions {...renderProps} />}
        </div>

        <div className="d-flex align-items-center justify-content-lg-between">
          <Button className="px-2 py-0" color="salmon" onClick={saveSelectedOptions}>Apply</Button>

          <a className="filter-dropdown__btn--clear fw-bold ms-4 ms-lg-0" href="#clear"
             onClick={clearSelectedOptions}>
            Clear
          </a>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

FilterDropdown.defaultProps = {
  showIcon: false,
}

FilterDropdown.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  setFilters: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  titleButton: PropTypes.string.isRequired,
}

export default FilterDropdown
