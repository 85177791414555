import { Label, Input } from 'reactstrap'

const RadioButton = ({ checked, className, label, name, onChange, value }) => (
  <Label className={className} check>
    <Input checked={checked} className="me-2" name={name} onChange={onChange} type="radio" value={value} />
    {label}
  </Label>
)

export default RadioButton
