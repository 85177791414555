import { gql } from 'Utils/apolloClient'

const installmentChargeMutation = gql`
  mutation InstallmentStripeCharge($installmentId: ID!, $stripeChargeKey: String) {
    installmentStripeCharge(input: { installmentId: $installmentId, stripeChargeKey: $stripeChargeKey }){
      success
    }
  }
`

export default installmentChargeMutation
