import CheckDaysWrapper from './CheckDays/CheckDaysWrapper'
import CurrencyInput from 'Shared/CurrencyInput/CurrencyInput'

const NightlyConfiguration = ({ dailyPrices, nigthsBookTogether, weeklyPrice }) => (
  <div className="nightly-configuration">
    <div className="nightly-configuration--title">
      <h5>Nightly Configuration</h5>

      <p className="text-muted">
        Set your Minimum Nights Required below and Nights Booked Together rules in the checkboxes.
      </p>
    </div>

    <CheckDaysWrapper dailyPrices={dailyPrices} nigthsBookTogether={nigthsBookTogether} />

    <hr />

    <div className="d-flex justify-content-between col-12 col-sm-8 col-md-7 col-lg-6">
      <label className="fw-normal ps-4" htmlFor="period_weekly">Weekly</label>

      <CurrencyInput classInputGroup="mb-3 w-50 input-group-sm" id="period_weekly" inputSize="sm"
                     name="period[weekly]" value={parseInt(weeklyPrice || 0)} />
    </div>
  </div>
)

NightlyConfiguration.defaultProps = {
  dailyPrices: {},
  nigthsBookTogether: {},
  weeklyPrice: 0,
}

export default NightlyConfiguration
