import { withoutTypeName } from 'Utils/Parse'
import { pluralizeCounter } from 'Utils/Strings'

function guestsAddedToString(guests, useGuests, separator = ', ', ignore = []) {
  const copy = withoutTypeName({ ...guests })
  const filterKeys = Object.keys(copy).filter(key =>
    typeof copy[key] === 'number' && copy[key] > 0 && !ignore.includes(key))

  if (useGuests) return _generateIsUseGuestsStrig(filterKeys, copy, separator)

  return _generateGuestsString(filterKeys, copy).join(separator)
}

const totalGuests = guestsObject => Object.values(guestsObject).reduce((total, value) => total + value, 0)

// private

const _generateGuestsString = (filterKeys, guestsObject) =>
  filterKeys.map(key => pluralizeCounter(key, guestsObject[key], true))

const _generateIsUseGuestsStrig = (keys, guestsObject, separator) => {
  const { adults, children } = guestsObject
  const currentTotal = totalGuests({ adults, children })
  const filterKeys = keys.filter(key => key !== 'adults' && key !== 'children')
  const guestlabel = currentTotal > 0 ? pluralizeCounter('guests', currentTotal, true) : ''

  return [guestlabel, ..._generateGuestsString(filterKeys, guestsObject)].join(separator)
}

export { guestsAddedToString, totalGuests }
