import { useMemo } from 'react'
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import { compact } from 'Utils/objects'

const useRouter = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const params = useParams()

  /**
   * Replaces the query parameters in the same path
   * @param {Object} search - query params to replace
   */
  const replaceQuery = search => {
    const currentParams = _getSearchParams()
    const params = compact({ ...currentParams, ...search })
    const query = _mergeQueryParams(params)

    history.replace({ search: query })
  }

  const router = useMemo(() => ({
    // data
    history,
    location,
    match,
    pathname: location.pathname,
    query: { ...queryString.parse(location.search), ...params },
    // functions
    replace: history.replace,
    replaceQuery,
  }), [history, location, match, params])

  // private
  const _getSearchParams = () => queryString.parse(location.search)

  const _mergeQueryParams = params => queryString.stringify(params, { skipEmptyString: true })

  return router
}

export default useRouter
