import { useDispatch, useSelector } from 'react-redux'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import { useCart } from 'Checkout/Hooks/useCart'
import { Button, Modal, ModalBody } from 'reactstrap'

const TEXTS = {
  modifying: {
    title: 'Discard Changes?',
    text: 'You have made changes to this reservation without updating your cart. Do you want to discard changes and go to your cart?',
  },
  rate: {
    title: 'Discard Changes?',
    text: 'It is required to select the rate before moving on. Otherwise, your changes will be discarded.',
  },
  site: {
    title: 'Start over?',
    text: 'It is required to select an available site before moving on. If no site works for you, you will need to reselect a rate on the previous page.',
  },
  siteNeeded: {
    title: 'Discard Changes?',
    text: 'It is required to select an available site before moving on. Click Cancel and select the rate you would like to move onto site selection. Otherwise, your changes will be discard.',
  },
}

const DiscardChanges = ({ isOpen, isRatePage, routeToGo, toggle }) => {
  const dispatch = useDispatch()
  const newSiteNeeded = useSelector(state => state.search.newSiteNeeded)
  const newRateNeeded = useSelector(state => state.search.newRateNeeded)
  const editingReservationId = useSelector(state => state.cart.editingReservationId)
  const { fillCurrentReservationFromCart } = useCart()

  const getTextKey = () => {
    if (isRatePage && newSiteNeeded) return 'siteNeeded'
    if (newSiteNeeded) return 'site'
    if (newRateNeeded) return 'rate'

    return 'modifying'
  }

  const key = getTextKey()
  const { title, text } = TEXTS[key] || {}

  const onDiscardChanges = () => {
    dispatch(searchFilterUpdate({ isDiscardAlertOpen: false, newRateNeeded: false, newSiteNeeded: false }))
    fillCurrentReservationFromCart(editingReservationId, { withRedirectTo: routeToGo || 'cart' })
  }

  return (
    <Modal centered isOpen={isOpen}>
      <ModalBody>
        <p>{title}</p>

        <p className="text-muted">{text}</p>

        <div className="d-flex justify-content-between">
          <Button color="dark-blue" outline onClick={toggle}>Cancel</Button>

          <Button color="danger" onClick={onDiscardChanges}>Discard Changes</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DiscardChanges
