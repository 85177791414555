import { useEffect } from 'react'
import connect from 'Admin/Store/connect'
import { getInventoryHold, getReservation } from 'Shared/CalendarQuickEdit/Store/CalendarQuickEditSlice'
import { tripInsuranceCoverageAlertText } from 'Utils/Translates'
import { Spinner } from 'reactstrap'
import DateRangePickerWrapper from 'Shared/CalendarQuickEdit/Components/DateRangePickerWrapper'
import SitesSelector from 'Shared/CalendarQuickEdit/Components/SitesSelector'

const CalendarQuickEdit = ({ calendarQuickEdit, getInventoryHold, getReservation, reservation }) => {
  const { isDisabled, isHold, reservationId, reservationUuid, slug } = window.roverData
  const queryValue = isHold ? calendarQuickEdit.inventoryHoldQuery : calendarQuickEdit.reservationQuery
  const { called, error, loading } = queryValue
  const isReservationNotPresent = reservation

  useEffect(() => {
    const keys = [{ key: tripInsuranceCoverageAlertText }]

    if (isHold)
      getInventoryHold({ variables: { id: reservationId, keys, slug } })
    else
      getReservation({ variables: { keys, slug, uuid: reservationUuid } })
  }, [])

  if (!called || loading) return (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  )
  if (error || isReservationNotPresent) return <p className="mt-3 text-center text-danger">Error</p>

  return (
    <>
      <DateRangePickerWrapper disabled={isDisabled} />

      <SitesSelector />
    </>
  )
}

const mapDispatchToProps = { getInventoryHold, getReservation }

const mapStateToProps = state => ({ calendarQuickEdit: state.calendarQuickEdit, reservation: state.reservation })

export default connect({ mapDispatchToProps, mapStateToProps })(CalendarQuickEdit)
