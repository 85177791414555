import { useDeferredValue, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'reactstrap'
import { getRelativePosition } from 'Utils/Elements'

const MapSitesPopover = ({ children, isOpen, parentId, svgSelectedSiteId }) => {
  const [position, setPosition] = useState({})
  const openPopover = useDeferredValue(isOpen, { timeoutMs: 150 })

  useEffect(() => {
    if (svgSelectedSiteId) {
      const newPosition = getRelativePosition(parentId, svgSelectedSiteId)
      setPosition(newPosition)
    }
  }, [svgSelectedSiteId])

  return (
    <>
      <div id="path-selected" style={position} className="position-absolute" />

      <Popover className="map-sites-popover" isOpen={openPopover} placement="left" target="path-selected">
        {children}
      </Popover>
    </>
  )
}

MapSitesPopover.defaultProps = {
  isOpen: false,
  svgSelectedSiteId: null,
}

MapSitesPopover.propTypes = {
  isOpen: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
  svgSelectedSiteId: PropTypes.string,
}

export default MapSitesPopover
