export const invoicesState = {
  campground: {
    hasLateFeeConfig: null,
    isInventory: null,
  },
  filters: {
    invoiceStatus: [],
    payoutStatus: '',
    term: '',
  },
  installmentDetails: {
    action: 'getDetails',
    changeOwed: '---',
    data: {},
    fetchDetails: false,
    error: null,
    loading: false,
  },
  installments: {
    data: {},
    error: null,
    loading: false,
  },
  isParamsReviewed: false,
  item: {},
  pagination: {
    currentPage: 1,
    perPage: 25,
  },
  sortedField: {},
}
