import { useState } from 'react'
import { ApolloProvider } from 'Utils/apolloClient'
import { getElementDataSet } from 'Utils/Elements'
import apolloClient from 'Utils/apolloClient'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import HistoryCanvas from './HistoryCanvas/HistoryCanvas'
import 'Styles/Button.scss'

const MainButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { engineName, slug: campgroundSlug, token, uuid } = getElementDataSet('react-data-container-history')
  const currentWindowData = window.roverData || {}
  const isAdmin = engineName === 'admin'
  window.roverData = { ...currentWindowData, campgroundSlug, isAdmin, token, uuid }

  const toggle = () => setIsOpen(prevState => !prevState)

  return (
    <ApolloProvider client={apolloClient}>
      <Button className="w-100" color="outline-dark-blue" onClick={toggle}>
        See Reservation History

        <FontAwesomeIcon className="ms-2" color="blue-dark" icon="chevron-right" />
      </Button>

      <HistoryCanvas isOpen={isOpen} toggle={toggle} />
    </ApolloProvider>
  )
}

export default MainButton
