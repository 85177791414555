import React from 'react'

const StateContext = React.createContext()

const StateProvider = ({ children, value }) => (
  <StateContext.Provider value={value}>{children}</StateContext.Provider>
)

const useStateContext = () => {
  const context = React.useContext(StateContext)

  if (context === undefined) throw new Error('useStateContext must be used within a StateProvider')

  return context
}

export { StateProvider, useStateContext }
