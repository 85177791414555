import { Col, Container } from 'reactstrap'
import cnames from 'classnames'

const MainColumn = ({ className, children, ...props }) => {
  const classes = cnames('main-column d-flex flex-fill', className)

  return (
    <Col className={classes} {...props}>
      <Container className="d-flex flex-column flex-fill responsive-padding pb-5">
        {children}
      </Container>
    </Col>
  )
}

export default MainColumn
