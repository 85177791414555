import { useCallback, useRef } from 'react'
import throttle from 'lodash.throttle'

const useThrottle = (callback, delay) => {
  const callbackRef = useRef(callback)
  const options = { leading: false }

  return useCallback(throttle((...args) => {
    callbackRef.current(...args)
  }, delay, options), [delay])
}

export default useThrottle
