import { memo } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { useHistory } from 'react-router-dom'
import FontAwesomeIcon from 'Shared/Admin/FontAwesomeIcon/FontAwesomeIcon'
import Anchor from 'Shared/Anchor/Anchor'
import HelpLink from 'Shared/Admin/HelpLink'

const Header = ({ back, backUrl, children, className, helpUrl, textHelper, title,
                  TitleTag, TitleTagClassName, wrapperClass }) => {
  const history = useHistory()

  const classes = {
    tittleContainer: cnames('top-wrapper', className ?? 'd-flex'),
    wrapper: cnames('header-wrapper', wrapperClass),
  }

  const goBack = () => {
    if (backUrl) {
      window.location.href = backUrl
      return null
    }

    if (['PUSH', 'REPLACE'].includes(history.action)) return history.goBack()

    history.push('/')
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.tittleContainer}>
        <div className="d-flex align-items-center flex-wrap">
          {back && (
            <Anchor className="align-items-center btn btn-white-salmon d-flex me-2 text-decoration-none"
                    href="#back" onClick={goBack}>
              <FontAwesomeIcon icon="chevron-left" className="me-1" /> Back
            </Anchor>
          )}

          <TitleTag className={TitleTagClassName}>{title}</TitleTag>
          {helpUrl && <HelpLink showLabel url={helpUrl} />}
        </div>

        {children}
      </div>

      {textHelper && <p className="helper">{textHelper}</p>}
    </div>
  )
}

Header.defaultProps = {
  back: false,
  backUrl: null,
  helpUrl: null,
  textHelper: null,
  TitleTag: 'h1',
  TitleTagClassName: null,
  wrapperClass: null,
}

Header.propTypes = {
  back: PropTypes.bool,
  backUrl: PropTypes.string,
  helpUrl: PropTypes.string,
  textHelper: PropTypes.string,
  title: PropTypes.string.isRequired,
  TitleTag: PropTypes.string,
  TitleTagClassName: PropTypes.string,
  wrapperClass: PropTypes.string,
}

export default memo(Header)
