import ModalContent from './ModalContent'
import ViewModal from 'Shared/ViewModal/ViewModal'

const PaymentFormModal = ({ isAdmin, isOpen, resetRefund, onChangeCurrencies, onSubmit, payment, purchaseRefunded,
                            refundError, refundFinished, refundLoading, resourcesData, toggleModal }) => {
  const modalClasses = {
    content: refundLoading ? 'h-50 flex-row align-items-center' : '',
    header: 'new-refund-modal',
    modal: refundLoading ? 'h-50' : 'salmon-header-modal confirmation-modal refund-modal',
  }

  return (
    <ViewModal classes={modalClasses} isCloseColorWhite isOpen={isOpen} onClosed={resetRefund}
               showHeader={!refundLoading} showOkButton={false} size="lg" title="Refund Payment"
               toggle={toggleModal}>
      <ModalContent {...{ isAdmin, onChangeCurrencies, onSubmit, payment, purchaseRefunded,
                          refundError, refundFinished, refundLoading, resourcesData, toggleModal }} />
    </ViewModal>
  )
}

PaymentFormModal.defaultProps = {
  onChangeCurrencies: () => {},
}

export default PaymentFormModal
