import { Row } from 'reactstrap'
import cnames from 'classnames'
import pluralize from 'pluralize'
import Anchor from 'Shared/Anchor/Anchor'
import VehicleCard from './VehicleCard/VehicleCard'

const CamperVehicles = ({ addRigClassName, Component, selectedVehicle, setSelectedVehicle, vehicles }) => {
  const classes = {
    addRig: cnames('d-inline-flex', addRigClassName),
  }
  const hasSavedVehicles = vehicles?.length > 0
  const VehicleCardComponent = Component || VehicleCard

  const onSelectVehicle = vehicleId => {
    const valueId = selectedVehicle !== vehicleId ? vehicleId : null

    setSelectedVehicle(valueId)
  }

  if (!hasSavedVehicles) return null

  return (
    <div className="mt-4 mt-md-2">
      <p className="fw-bold">Saved Rigs</p>

      <Row className="g-0 mt-3">
        {vehicles.map(vehicle => {
          const { id, numSlideouts, rigLength, rigType, rigWidth, year } = vehicle
          const slideouts = pluralize('Slideout', parseInt(numSlideouts), true)
          const isSelectedVehicle = selectedVehicle?.id === id
          const showName = `${year} ${rigType}, ${slideouts}`

          return (
            <VehicleCardComponent handleSelect={() => onSelectVehicle(vehicle)} isSelected={isSelectedVehicle}
                                  key={id} length={rigLength} name={showName} width={rigWidth} />
          )
        })}
      </Row>

      {selectedVehicle?.id && (
        <Anchor className={classes.addRig} onClick={() => onSelectVehicle(null)}>Add Rig</Anchor>
      )}
    </div>
  )
}

export default CamperVehicles
