import PropTypes from 'prop-types'
import FormSelect from 'Shared/Formik/FormSelect'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'

const RefundMethodSelector = ({ name, options }) => (
  <FormSelect className="row mb-3" defaultMessage="Select a Refund Method" id="refundMethod"
              inputWrapperClass="col-md-4" label="Refund Method" labelClasses="col-md-8 fw-bold mb-0"
              name={name}>
    <SelectOptions data={options} />
  </FormSelect>
)

RefundMethodSelector.defaultProps = {
  name: '',
  options: [],
}

RefundMethodSelector.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
}

export default RefundMethodSelector
