import cnames from 'classnames'
import { Button } from 'reactstrap'
import { formatDate } from 'Utils/Dates'
import { DATE_FORMAT } from '../constants'
import ResponseBlock from './ResponseBlock'

const Summary = ({ loadingUndo, onComplete, responseData, undoBlackouts }) => {
  const { daysOfWeek, from, response, to } = responseData || { from: '', response: {}, to: '' }
  const className = cnames('summary', { 'skeleton-loading': loadingUndo })
  const dateClass = {
    container: 'flex-fill me-2',
    name: 'd-block text-muted text-smaller-1',
  }

  return (
    <div className={className}>
      <h5>Summary</h5>

      <div className="d-flex">
        <p className={dateClass.container}>
          <span className={dateClass.name}>From</span>
          <span>{formatDate(from, { format: DATE_FORMAT })}</span>
        </p>

        <p className={dateClass.container}>
          <span className={dateClass.name}>To</span>
          <span>{formatDate(to, { format: DATE_FORMAT })}</span>
        </p>
      </div>

      {daysOfWeek && (
        <p>
          <span className={dateClass.name}>Nights of the week</span>
          <span>{daysOfWeek}</span>
        </p>
      )}

      {Object.entries(response).map(([key, value]) => (
        value?.length > 0 && (
          <ResponseBlock key={key} state={key} sites={value} />
        )
      ))}

      <div className="border-top d-flex justify-content-between p-0 pt-3">
        <Button className="btn btn-outline-salmon" onClick={undoBlackouts}>Undo</Button>

        <Button color="salmon" onClick={onComplete}>Continue</Button>
      </div>
    </div>
  )
}

export default Summary
