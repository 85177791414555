import { Container } from 'reactstrap'
import './Footer.scss'
import roverPassSvg from './roverPassSvg.svg'

const Footer = () => (
  <footer className="checkout-footer py-1 w-100">
    <Container className="fst-italic text-center text-smaller-2 text-white">
      <span className="current-year me-1">
        {(new Date().getFullYear())}
      </span>

      <span className="powered-by me-2">Powered by</span>
      <img className="footer-logo" src={roverPassSvg} alt="roverPass" />
    </Container>
  </footer>
)

export default Footer
