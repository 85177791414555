import { useEffect, useState } from 'react'
import cnames from 'classnames'
import { isEmpty } from 'Utils/objects'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { validationSchema } from './validationSchema'
import cancelMutation from './reservationGroupCancelMutation'
import { Button } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import CancellationOptions from 'Shared/Formik/CancellationOptions'
import CancellationPolicies from 'Shared/CancelReservation/CancellationPolicies'
import FormSwitch from 'Shared/Formik/FormSwitch'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import ReservationsList from './ReservationsList'
import ReservationRow from 'Shared/CancelReservation/ReservationRow'

const INITIAL_VALUES = { refundReservation: false, rejectionReason: 'made_another_reservation',
                         rejectionText: '', reservations: [] }

const CancelForm = ({ cancellationPolicies, reservation, toggle }) => {
  const { isAccountEngine } = window.roverData
  const [hasRefund, setHasRefund] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { canRefundPayment, id: idSingle, isGroup, links, parentId,
          reservationsGroupNotCancelled: reservations } = reservation
  const [cancelReservations, { loading, error: errorMutation }] = useMutation(cancelMutation, {
    onCompleted: response => {
      if (response.reservationGroupCancel.success) {
        if (hasRefund) {
          const url = isGroup ? links.refundsParentUrl : links.refundsUrl
          window.location.href = url
        } else
          window.location.reload(false)
        setSubmitted(true)
      }
    },
  })
  const className = cnames('cancel-form', { 'skeleton-loading': loading || submitted })
  const reservationsLength = reservations?.length
  const textLabel = isAccountEngine ? '' : 'Write a message to the camper:'
  const placeholder = isAccountEngine ? "Write a message to the campground for why you're cancelling." : ''
  const validationSchemaForm = validationSchema({ isGroup })

  const onSubmit = values => {
    const { refundReservation, rejectionReason, rejectionText, reservations: ids } = values
    if (refundReservation) setHasRefund(true)

    const id = isGroup ? parentId : idSingle
    const idList = isGroup ? ids : [idSingle]
    const variables = { id, ids: idList, rejectionReason, rejectionText }
    cancelReservations({ variables })
  }

  return (
    <FormWithValidations className={className} id="cancel-form" initialValues={INITIAL_VALUES}
                         onSubmit={onSubmit} validationSchema={validationSchemaForm}>
      {({ errors, isValid, setFieldValue, values: { refundReservation, reservations: reservationsFormik } }) => {
        const errorsArray = !isEmpty(errors) && Object.values(errors)
        const formikLength = reservationsFormik?.length
        const isAllChecked = formikLength === reservationsLength
        const isIndeterminate = formikLength > 0 && !isAllChecked

        const onCheckHeader = () => {
          if (isAllChecked) {
            if (reservationsFormik.includes(parentId) && refundReservation)
              setFieldValue('refundReservation', false)
            return setFieldValue('reservations', [])
          }

          const ids = reservations.map(item => item.id)
          setFieldValue('reservations', ids)
        }

        const onCheckRow = reservationId => {
          const temporal = [...reservationsFormik]
          if (temporal.includes(reservationId)) {
            if (reservationId === parentId && refundReservation)
              setFieldValue('refundReservation', false)
            temporal.splice(temporal.indexOf(reservationId), 1)
          } else
            temporal.push(reservationId)

          setFieldValue('reservations', temporal)
        }

        const onRefundSelect = value => setFieldValue('refundReservation', value)

        useEffect(() => {
          if (errorsArray || errorMutation) setSubmitted(false)
        }, [errorsArray, errorMutation])

        return (
          <>
            {(errorsArray || errorMutation) && (
              <AlertErrors className="m-0 mt-3" errors={errorsArray || []} graphQLErrors={errorMutation || []} />
            )}

            {isGroup ? (
              <ReservationsList isAllChecked={isAllChecked} isGroup={isGroup} isIndeterminate={isIndeterminate}
                                onCheckHeader={onCheckHeader} onCheckRow={onCheckRow}
                                reservations={reservations} />
            ) : (
              <div className="mt-3 pb-3">
                <ReservationRow hideCheckbox isSingle item={reservations[0]} />
              </div>
            )}

            {isAccountEngine && <CancellationPolicies cancellationPolicies={cancellationPolicies} />}

            <p className="fw-bold mb-2">Cancellation Reason</p>

            <CancellationOptions optionName="rejectionReason" showRadioButton={!isAccountEngine}
                                 placeholder={placeholder} textLabel={textLabel} textName="rejectionText" />

            {(!isGroup && canRefundPayment && !isAccountEngine) && (
              <FormSwitch checked={refundReservation} classContainer="d-flex flex-column-reverse mt-4"
                          classLabel="fw-normal mb-2" className="mb-3"
                          name="refundReservation" label="Do you want to refund this reservation?"
                          onChange={onRefundSelect} onColor="#007BFF" />
            )}

            <div className="d-flex justify-content-between mt-4 pt-2">
              <Button color="outline-gray-800" onClick={toggle}>Cancel</Button>

              <Button color="red" data-test="save-button" disabled={!isValid || submitted} type="submit">
                {refundReservation ? 'Save & Refund' : 'Save'}
              </Button>
            </div>
          </>
        )
      }}
    </FormWithValidations>
  )
}

export default CancelForm
