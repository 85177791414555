import { capitalizeFirstLetter } from 'Utils/Strings'

const Refund = ({ index, refund, refundsCount, text }) => {
  const isNotLastRefund = refundsCount !== index

  return (
    <div className="refund-popover--row">
      <div className="d-flex fs-6 justify-content-between">
        <span className="text-muted">
          Refund {capitalizeFirstLetter(refund.state || '')}
        </span>

        <span>{refund?.total?.display || text}</span>
      </div>

      {isNotLastRefund && <hr className="my-2" />}
    </div>
  )
}

export default Refund
