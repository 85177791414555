import { useEffect, useState } from 'react'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { Button, ButtonGroup, TabContent } from 'reactstrap'
import { dateToISO, formatToday } from 'Utils/Dates'
import InstallmentScheduleAndCreateMutation from 'Shared/CreateInvoiceModal/InstallmentScheduleAndCreateMutation'
import DueDatePicker from 'Shared/CreateInvoiceModal/Modal/DueDatePicker'
import Loading from 'Shared/Loading'

const TABS_MODAL = { keepEditing: 'keepEditing', scheduleAndCreate: 'scheduleAndCreate' }

const Tabs = ({ closeModal, installmentDraft, slug }) => {
  const [dueOn, setDueOn] = useState(() => formatToday())
  const [activeTab, setActiveTab] = useState(TABS_MODAL.keepEditing)
  const [scheduleAndCreate, { data, loading }] = useMutation(InstallmentScheduleAndCreateMutation)
  const { id: installmentId, links, scheduledOn } = installmentDraft || {}

  useEffect(() => {
    if (data) {
      const { installment } = data?.installmentScheduleAndCreate
      closeModal()
      window.location.replace(installment.links.installmentPath)
    }
  }, [data])

  const openTab = tab => {
    if (tab === TABS_MODAL.scheduleAndCreate) {
      const minDate = scheduledOn || null
      setDueOn(minDate || formatToday())
    }
    setActiveTab(tab)
  }

  const isTabActive = tab => activeTab === tab

  const getTabClassName = tabName => (isTabActive(tabName) ? 'active' : 'inactive')

  const getTabColor = tabName => (isTabActive(tabName) ? 'salmon' : 'secondary')

  const handleCancel = () => {
    if (activeTab === TABS_MODAL.scheduleAndCreate) return setActiveTab(TABS_MODAL.keepEditing)
    return closeModal()
  }

  const handleContinue = () => {
    if (activeTab === TABS_MODAL.scheduleAndCreate) {
      const variables = { dueOn, installmentId, slug }
      scheduleAndCreate({ variables })
    } else {
      closeModal()
      window.location.replace(links.installmentPath)
    }
  }

  const classNames = {
    keepEditing: getTabClassName(TABS_MODAL.keepEditing),
    scheduleAndCreate: getTabClassName(TABS_MODAL.scheduleAndCreate),
  }

  const colors = {
    keepEditing: getTabColor(TABS_MODAL.keepEditing),
    scheduleAndCreate: getTabColor(TABS_MODAL.scheduleAndCreate),
  }

  if (loading) return <Loading className="d-flex justify-content-center py-5" />

  return (
    <>
      <div className="d-flex flex-column align-items-center border-bottom pb-3 mb-3">
        <p className="text-muted mt-2">What would you like to do?</p>

        <ButtonGroup className="w-100">
          <Button className={classNames.keepEditing} color={colors.keepEditing}
                  onClick={() => openTab(TABS_MODAL.keepEditing)}>
            Keep Editing Draft
          </Button>

          <Button className={classNames.scheduleAndCreate} color={colors.scheduleAndCreate}
                  onClick={() => openTab(TABS_MODAL.scheduleAndCreate)}>
            Schedule & Create New Invoice
          </Button>
        </ButtonGroup>

        <TabContent activeTab={activeTab} className="w-100">
          <DueDatePicker dueOn={dueOn} isOpen={isTabActive(TABS_MODAL.scheduleAndCreate)}
                             updateDate={date => setDueOn(dateToISO(date))} />
        </TabContent>
      </div>

      <div className="d-flex justify-content-between">
        <Button color="outline-salmon" onClick={handleCancel}>
          Cancel
        </Button>

        <Button color="salmon" onClick={handleContinue}>
          Continue
        </Button>
      </div>

      <span className="d-flex small mt-3 text-muted justify-content-center">
        You may only have one draft installment on a reservation at a time.
      </span>
    </>
  )
}

export default Tabs
