import { useDispatch } from 'react-redux'
import cnames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import { formatDate } from 'Utils/Dates'
import { routesHandler } from 'Checkout/Routes/routesHandler'
import { useLocation } from 'react-router-dom'
import { searchToggleModifyNotSaved } from 'Checkout/Redux/Actions/Search'
import CartButton from 'Checkout/Components/Shared/Header/CartButton'
import DiscardChanges from 'Checkout/Components/Shared/DiscardChanges/DiscardChanges'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import SignInLink from 'Checkout/Components/Shared/SignInLink/SignInLink'
import ViewButtons from 'Checkout/Components/Shared/ViewButtons/ViewButtons'
import './Header.scss'

const Header = ({ addressClass, campgroundCity, campgroundClass, campgroundLogo, campgroundName, campgroundState,
                  checkIn, checkOut, className, hideLogoMobile, isGroupBookingAvailable, isInventory,
                  modifyNotSaved, showCheckoutInfo, showLogo, showMobileButtons, showMobileTitle, showSignIn,
                  signInClass, siteType }) => {
  const dispatch = useDispatch()
  const { notDesktopView } = useWindowDimensions()
  const { pathname } = useLocation()
  const classes = {
    address: cnames('d-none d-md-block text-muted m-0', addressClass),
    campground: cnames('m-0 fw-bold', campgroundClass),
    header: cnames('header--content d-flex m-0 w-100 align-items-center', className),
    inventory: cnames('d-md-block text-salmon me-2', { 'd-none': !showMobileTitle }),
    logo: cnames('d-md-block text-center me-3', { 'd-none': hideLogoMobile }),
    signIn: cnames('ms-4 ms-md-auto d-flex', signInClass),
  }
  const { cart, confirmation, home } = routesHandler
  const showCartButton = isGroupBookingAvailable && ![home.path, cart.path, confirmation.path].includes(pathname)

  const addressData = campgroundCity && campgroundState ? `${campgroundCity}, ${campgroundState}` : ''

  const logoStyle = `url(${campgroundLogo}) center no-repeat`

  const parseDate = date => formatDate(date, { format: 'ddd, MMM. D' })

  const toggleDiscardChanges = () => dispatch(searchToggleModifyNotSaved())

  return (
    <div id="checkout-header">
      <div className={classes.header}>
        {showLogo && campgroundLogo && (
            <div className={classes.logo}>
              <div className="logo" style={{ background: logoStyle }} />
            </div>
        )}

        <div className="header-data overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            {isInventory && (
              <FontAwesomeIcon icon="bolt" className={classes.inventory} />
            )}

            <h5 className={classes.campground} title={campgroundName}>
              {campgroundName}
            </h5>

            {!showSignIn && (
              <SignInLink className={classes.signIn} text="Sign In" />
            )}
          </div>

          <p className={classes.address}>{addressData}</p>

          {showCheckoutInfo && (
            <>
              <p className="d-md-none text-muted text-start m-0">
                {parseDate(checkIn)} - {parseDate(checkOut)}
              </p>

              {siteType && <p className="d-md-none text-muted text-start m-0">{siteType.name}</p>}
            </>
          )}
        </div>

        {showSignIn && (
          <SignInLink className={classes.signIn} text="Sign In" />
        )}

        {showCartButton && <CartButton />}
      </div>

      {(notDesktopView && showMobileButtons) && (
        <div className="d-block d-lg-none">
          <ViewButtons className="mb-3 w-100" />
        </div>
      )}

      <DiscardChanges isOpen={modifyNotSaved} toggle={toggleDiscardChanges} />
    </div>
  )
}

Header.defaultProps = {
  addressClass: '',
  campgroundClass: '',
  className: 'py-4',
  hideLogoMobile: true,
  hr: true,
  showCheckoutInfo: true,
  showLogo: true,
  showMobileButtons: true,
  showMobileTitle: true,
  showSignIn: true,
  signInClass: 'text-gray',
}

const mapStateToProps = state => ({
  campgroundCity: state.campground.cityName,
  campgroundLogo: state.campground.logoUrl,
  campgroundName: state.campground.name,
  campgroundState: state.campground.stateName,
  isGroupBookingAvailable: state.campground.isGroupBookingAvailable,
  checkIn: state.search.checkIn,
  checkOut: state.search.checkOut,
  isInventory: state.campground.isInventory,
  modifyNotSaved: state.search.modifyNotSaved,
  siteType: state.search.siteType,
})

export default storeConnect({ mapStateToProps })(Header)
