import { Label } from 'reactstrap'
import { flexibleCheckoutMoment, formatDate } from 'Utils/Dates'
import DateRangePicker from 'Shared/DatePicker/DateRangePicker/DateRangePicker'
import FormGroupField from 'Shared/Formik/FormGroupField'
import 'react-dates/initialize'

const FormDateRangePicker = ({ className, checkInPlaceholder, checkOutPlaceholder, closureDates, disabled,
                               isDatesError, isFlexible, isRequired, label, labelClasses, minimumNights,
                               minDateAvailable, name, numberOfMonths, onDatesChange, openDirection, returnMoment,
                               showErrors }) => (
  <FormGroupField className={className} name={name} showErrors={showErrors}>
    {({ form: { errors, setValues, values } }) => {
      const areErrors = errors.from || errors.to
      const checkOutDate = isFlexible ? null : values.to
      const disabledField = isFlexible ? 'endDate' : disabled
      const toPlaceholder = isFlexible ? 'TBD' : checkOutPlaceholder

      const onChange = ({ startDate, endDate }) => {
        if ((startDate && endDate) || (startDate && isFlexible)) {
          const from = formatDate(startDate)
          const to = isFlexible ? formatDate(flexibleCheckoutMoment) : formatDate(endDate)
          setValues({ ...values, from, to })
          if (returnMoment) {
            const parseEnd = isFlexible ? flexibleCheckoutMoment : endDate
            onDatesChange({ from: startDate, to: parseEnd })
          } else
            onDatesChange({ from, to })
        }
      }

      return (
        <>
          {label && (
            <Label className={labelClasses} for={name}>
              {label}
              {isRequired && <span className="text-danger ms-1">*</span>}
            </Label>
          )}

          <DateRangePicker checkInPlaceholder={checkInPlaceholder} checkInValue={values.from}
                           checkOutPlaceholder={toPlaceholder} checkOutValue={checkOutDate}
                           closureDates={closureDates} disabled={disabledField} format="MM/DD/YYYY"
                           isDatesError={isDatesError} isPos minDate={minDateAvailable}
                           minimumNights={minimumNights} numberOfMonths={numberOfMonths} onlyCheckIn
                           openDirection={openDirection} showDefaultInputIcon updateDates={onChange} />

          {(showErrors && areErrors) && (
            <p className="alert alert-danger mt-2 mb-0">
              Please select both dates before to continue.
            </p>
          )}
        </>

      )
    }}
  </FormGroupField>
)

FormDateRangePicker.defaultProps = {
  checkInPlaceholder: null,
  checkOutPlaceholder: null,
  closureDates: null,
  disabled: false,
  isDatesError: false,
  isRequired: false,
  numberOfMonths: null,
  onDatesChange: () => {},
  returnMoment: false,
  showErrors: false,
}

export default FormDateRangePicker
