import { gql } from 'Utils/apolloClient'

const stripeSetupIntentCreateMutation = gql`
  mutation StripeSetupIntentCreate($input: StripeSetupIntentCreateInput!) {
    stripeSetupIntentCreate(input: $input) {
      stripeSetupIntent {
        clientSecret
      }
    }
  }
`

export default stripeSetupIntentCreateMutation
