import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ReservationModalContent from './ReservationModalContent'

const ReservationItem = ({ campsite, from, guests, isRigRequired, rig, showBorder, to }) => (
  <div className={`${showBorder && 'border-bottom'} p-2`}>
    <div>
      <p className="fw-bold text-dark">
        <FontAwesomeIcon className="me-2" icon="calendar-days" />
        Reservation
      </p>

      {campsite && (
        <div className="w-100 d-flex justify-content-between" id={campsite.name}>
          <p>{campsite.name}</p>
        </div>
      )}

      <ReservationModalContent {...{ from, guests, isRigRequired, rig, to }} />
    </div>
  </div>
)


ReservationItem.defaultProps = {
  isRigRequired: false,
  rig: null,
  showBorder: true,
}

ReservationItem.propTypes = {
  campsite: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  from: PropTypes.string.isRequired,
  guests: PropTypes.shape({}).isRequired,
  isRigRequired: PropTypes.bool,
  rig: PropTypes.shape({}),
  showBorder: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

export default ReservationItem
