import { Alert } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'

const EmptyActionAlert = ({ actionLink, actionLinkText, icon, message, secondaryMessage, useActionAlert }) => (
  <>
    {(useActionAlert) ? (
      <div className="grouping-child-alert bg-light m-4 p-3 rounded text-center">
        <p className="mb-1">{message}</p>

        <p className="text-sm text-grey">{secondaryMessage}</p>

        <Link className="btn btn-outline-dark-blue" isExternal to={actionLink}>{actionLinkText}</Link>
      </div>
    ) : (
      <Alert className="mt-5" color="warning">
        <FontAwesomeIcon className="text-warning me-2" icon={icon} />

        {message}
      </Alert>
    )}
  </>
)

export default EmptyActionAlert
