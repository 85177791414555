// TODO: Fetch the data from the API
export const refundsState = {
  result: {
    data: null,
    error: null,
    loading: false,
  },
  refundValues: {
    grandTotal: 0,
    camperFee: 0,
    camperProcessingFee: 0,
  },
  refundTotal: 0,
}
