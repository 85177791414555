import { Children, useEffect, useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button, TabContent, TabPane } from 'reactstrap'
import { formatToday } from 'Utils/Dates.js'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CashPayment, CheckPayment, CreditCardPayment, PaymentMethodsContext } from 'Shared/PaymentMethods'
import AlertErrors from 'Shared/Alert/AlertErrors'

const CHECK = 'check'
const CARD = 'card'
const CASH = 'cash'

const PaymentMethodsLayout = ({ btnContainerClass, campgroundName, stripeCardOnFile, children, displayAmount,
                                displayOptions, displayTitle, handleTabChange, mutation, onChangePaymentCash,
                                onCompletePayment, paymentChangeOwed, payNow, showErrors, toggle, total,
                                totalBeforeProcessing }) => {
  const [activeTab, setActiveTab] = useState(CARD)
  const [mutationErrors, setMutationErrors] = useState()
  const [stripePromise] = useState(() => loadStripe(window.roverData.stripeKey))
  const btnClass = 'border p-4 rounded fw-bold w-100 m-1'
  const btnContainerClasses = cnames('border-bottom mb-4 border-bottom', btnContainerClass)
  const isPaymentExternal = activeTab !== CARD
  let _alert
  let _creditCardPayment
  let _paymentStatus

  Children.forEach(children, child => {
    if (child?.type === Alert) {
      _alert = child
      return false
    }

    if (child?.type === CreditCardPayment) {
      _creditCardPayment = child
      return false
    }

    if (child?.type === PaymentStatus) {
      _paymentStatus = child
      return false
    }
  })

  const btnColor = btnTitle => (btnTitle === activeTab ? 'primary' : '')

  const cashVariables = values => {
    const { amount: amountReceived, source } = values
    const changeOwed = paymentChangeOwed?.amount || 0
    const cash = { amountReceived, changeOwed }
    const paymentExternal = { amount: totalBeforeProcessing.amount, data: { cash }, source }

    return { paymentExternal, paymentSource: source }
  }

  const checkVariables = values => {
    const { checkNumber, memo, source } = values
    const paymentExternal = { amount: totalBeforeProcessing.amount, name: checkNumber, note: memo, source }

    return { paymentExternal, paymentSource: source }
  }

  const cardVariables = values => {
    const { cardOnFile, saveCardOnFile, stripeToken, source } = values

    if (cardOnFile) return { useCardOnFile: true, paymentSource: source }

    return { paymentMethod: { stripeToken }, paymentSource: source, saveCardOnFile }
  }

  const clearMutationErrors = () => mutationErrors && showErrors && setMutationErrors(null)

  const onSubmit = values => {
    const actions = { cash: cashVariables, check: checkVariables, stripe_card: cardVariables }

    payNow(actions[values.source](values))

    if (!showErrors && isPaymentExternal) toggle()
  }

  const toggleTap = tab => {
    if (activeTab !== tab) setActiveTab(tab)
    clearMutationErrors()
    handleTabChange(tab)
  }

  useEffect(() => {
   setMutationErrors(mutation.error)
  }, [mutation.error])

  useEffect(() => {
    if (mutation.data && !mutation.error && isPaymentExternal && showErrors) onCompletePayment()
  }, [mutation.data])

  return (
    <PaymentMethodsContext.Provider value={{ clearMutationErrors, stripeCardOnFile, displayAmount, displayOptions,
                                             mutation, onChangePaymentCash, onCompletePayment, onSubmit,
                                             paymentChangeOwed, toggle, total, totalBeforeProcessing }}>
      <div>
        <div className={btnContainerClasses}>
          {displayTitle && (
            <h3 className="h5 fw-bold">Select Payment</h3>
          )}

          <div className="d-flex">
            <Button className={btnClass} color={btnColor(CARD)} onClick={() => toggleTap(CARD)}
                    type="button">
              Credit Card
            </Button>

            <Button className={btnClass} color={btnColor(CASH)} onClick={() => toggleTap(CASH)}
                    type="button">
              Cash
            </Button>

            <Button className={btnClass} color={btnColor(CHECK)} onClick={() => toggleTap(CHECK)}
                    type="button">
              Check
            </Button>
          </div>
        </div>

        {_paymentStatus}

        <div className="bg-light px-4 py-3 rounded-3">
          {showErrors && mutationErrors && <AlertErrors errors={[mutationErrors]} withScroll />}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={CARD}>
              <Elements stripe={stripePromise}>
                {_creditCardPayment}
              </Elements>
            </TabPane>

            <TabPane tabId={CASH}>
              <CashPayment>
                {_alert}
              </CashPayment>
            </TabPane>

            <TabPane tabId={CHECK}>
              <CheckPayment date={formatToday()} seller={campgroundName}>
                {_alert}
              </CheckPayment>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </PaymentMethodsContext.Provider>
  )
}

const Alert = ({ children }) => children
const PaymentStatus = ({ children }) => children

PaymentMethodsLayout.Alert = Alert
PaymentMethodsLayout.CreditCardPayment = CreditCardPayment
PaymentMethodsLayout.PaymentStatus = PaymentStatus

PaymentMethodsLayout.defaultProps = {
  btnContainerClass: 'py-4',
  campgroundName: '',
  stripeCardOnFile: {},
  displayAmount: false,
  displayOptions: false,
  displayTitle: false,
  handleTabChange: () => {},
  mutation: {
    error: '',
    data: {},
    loading: false,
  },
  onChangePaymentCash: () => {},
  onCompletePayment: () => {},
  payNow: () => {},
  showErrors: false,
  toggle: () => {},
  total: {
    amount: 0.0,
    display: '',
  },
}

PaymentMethodsLayout.propTypes = {
  btnContainerClass: PropTypes.string,
  campgroundName: PropTypes.string,
  stripeCardOnFile: PropTypes.shape({
    id: PropTypes.string,
    lastFour: PropTypes.string,
  }),
  displayAmount: PropTypes.bool,
  displayOptions: PropTypes.bool,
  displayTitle: PropTypes.bool,
  handleTabChange: PropTypes.func,
  mutation: PropTypes.shape({
    error: PropTypes.string,
    data: PropTypes.shape({}),
    loading: PropTypes.bool,
  }),
  onChangePaymentCash: PropTypes.func,
  onCompletePayment: PropTypes.func,
  payNow: PropTypes.func,
  showErrors: PropTypes.bool,
  toggle: PropTypes.func,
  total: PropTypes.shape({
    amount: PropTypes.number,
    display: PropTypes.string,
  }),
}

export default PaymentMethodsLayout
