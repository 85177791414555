import { gql } from 'Utils/apolloClient'

const onDataLoading = gql`
  subscription onDataLoading {
    dataLoading {
      percentage
    }
  }
`

export default onDataLoading
