import PropTypes from 'prop-types'
import PricingItem from 'Shared/Invoices/Pricing/PricingItem'
import TotalPrice from './TotalPrice'

const Pricing = ({ camperExtras, hasPendingRefunds, id, platformFee, platformFeeName, pricing, processingFeeTotal,
                   refunds, state, subtotal, tax, textSize, total, totalClassName, totalName,
                   totalHelperText }) => {
  const hasPlatformFee = platformFee?.amount > 0
  const hasCamperExtras = camperExtras?.amount > 0
  const { isTaxIncluded, taxConfig } = pricing || {}
  const taxRenamed = taxConfig?.taxName || 'Tax'
  const subtotalInfoText = isTaxIncluded && `(${taxRenamed} included)`

  return (
    <>
      {subtotal && (
        <PricingItem infoText={subtotalInfoText} itemName="Subtotal" rowClassName={textSize} value={subtotal} />
      )}

      <hr className="mt-1 mb-3" />

      {!isTaxIncluded && <PricingItem itemName={taxRenamed} rowClassName={textSize} value={tax?.display} />}

      {processingFeeTotal && (
        <PricingItem itemName="Processing Fee" rowClassName={textSize} value={processingFeeTotal.display} />
      )}

      {hasPlatformFee && (
        <PricingItem itemName={platformFeeName} rowClassName={textSize} value={platformFee.display} />
      )}

      {hasCamperExtras && (
        <PricingItem itemName="Trip Insurance" rowClassName={textSize} value={camperExtras.display} />
      )}

      <hr className="mt-1 mb-3" />

      {total && (
        <TotalPrice {...{ hasPendingRefunds, id, pricing, refunds, state, textSize, totalClassName,
                          totalHelperText, total, totalName }} />
      )}

      {isTaxIncluded && (
        <>
          <hr className="mt-1 mb-3" />

          <PricingItem itemName={taxRenamed} value={tax?.display} />
        </>
      )}
    </>
  )
}

Pricing.defaultProps = {
  processingFeeTotal: {
    display: '',
  },
  platformFee: {
    amount: 0,
    display: '',
  },
  platformFeeName: 'Invoice Fee',
  subtotal: '',
  tax: {
    display: '',
  },
  textSize: 'h5',
  total: {
    display: '',
  },
  totalHelperText: '',
  totalName: 'Total',
}

Pricing.propTypes = {
  processingFeeTotal: PropTypes.shape({
    display: PropTypes.string,
  }),
  platformFee: PropTypes.shape({
    amount: PropTypes.number,
    display: PropTypes.string,
  }),
  platformFeeName: PropTypes.string,
  state: PropTypes.string.isRequired,
  subtotal: PropTypes.string,
  tax: PropTypes.shape({
    display: PropTypes.string,
  }),
  textSize: PropTypes.string,
  total: PropTypes.shape({
    display: PropTypes.string,
  }),
  totalHelperText: PropTypes.string,
  totalName: PropTypes.string,
}

export default Pricing
