export const rateEditorState = {
  campground: {
    data: null,
    error: null,
    loading: true,
  },
  options: {},
  pricePeriodSelected: '',
  rateSelected: null,
  sitePlans: [],
  siteTypeSelected: '',
  wasRateEdited: false,
}
