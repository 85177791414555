import { gql } from 'Utils/apolloClient'

const reservationGroupCancelMutation = gql`
  mutation ReservationGroupCancel($id: ID!, $ids: [ID!]!, $rejectionReason: String!, $rejectionText: String) {
    reservationGroupCancel(input: { id: $id, ids: $ids, rejectionReason: $rejectionReason,
                                    rejectionText: $rejectionText}) {
      success
    }
  }`

export default reservationGroupCancelMutation
