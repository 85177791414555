import { useStripe } from '@stripe/react-stripe-js'
import { useEffect } from 'react'

// NOTE: this hook needs to be inside a Stripe <Elements> Provider
export const useStripeACH = (cb, data, isReady) => {
  const stripe = useStripe()
  const { clientSecret, name } = data
  const billingDetails = { name }

  const collectBankAccount = async () => {
    const result = await stripe.collectBankAccountForSetup({
      clientSecret,
      expand: ['payment_method'],
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
    })

    return result
  }

  useEffect(() => {
    const exectureAsync = async () => {
      const { error, setupIntent } = await collectBankAccount()
      if (error || setupIntent.status === 'requires_payment_method') return cb({ error, setupIntent })

      stripe.confirmUsBankAccountSetup(clientSecret).then(cb)
    }

    if (stripe && isReady) exectureAsync()
  }, [isReady])
}
