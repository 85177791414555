import Cookies from 'js-cookie'

const getCookie = key => Cookies.get(key)

const removeCookie = key => Cookies.remove(key)

const setCookie = (key, value, options = {}) => Cookies.set(key, value, _cleanOptions(options))

// private

// Cookies Attributes https://bit.ly/2Mp2giV
function _cleanOptions(options) {
  const isSecure = options.secure === undefined ? _isSecure() : !!options.secure
  const expiresDate = _experiesAt(options.expires)

  return { ...options, secure: isSecure, expires: expiresDate }
}

function _experiesAt(mins) {
  if (!mins) return null // If !mins, the cookie becomes a session cookie.

  const milisecs = mins * 60 * 1000
  return new Date(new Date().getTime() + milisecs)
}

const _isSecure = () => window.roverData?.railsEnv === 'production'

export { getCookie, removeCookie, setCookie }
