import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormCheckbox from 'Shared/Formik/FormCheckbox'

const CardOnFile = ({ cardOnFileLastFour }) => (
  <>
    <p className="fw-bold text-lg mb-2">Card on File</p>

    <div className="d-flex">
      <FormCheckbox disabled={!cardOnFileLastFour} message="Use customer's payment card on file"
                    name="cardOnFile" />
    </div>

    {cardOnFileLastFour ? (
      <div className="d-flex align-items-center mb-3">
        <FontAwesomeIcon className="me-2 card-icon" icon="credit-card" />
        <p className="mb-0 me-4">{`Card ending in ${cardOnFileLastFour}`}</p>
      </div>
    ) : (
      <p className="ps-4 smaller text-grey">
        <strong>No card on file. </strong>
        You can save a card to be on file when the next payment is made.
      </p>
    )}

    <hr />
  </>
)

export default CardOnFile
