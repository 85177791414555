import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Loading from 'Shared/Loading'

const CartButtonSubmit = ({ block, children, className, color, content, dataTest, formId, iconSize, isDisabled,
                            loading, loadingMessage, loadingMessageClass }) => {
  const classes = cnames('mb-2', className)

  return (
    <Button block={block} className={classes} color={color} data-test={dataTest} disabled={isDisabled}
            form={formId} size="lg" type="submit">
      {loading ? (
        <Loading iconClass="text-white" message={loadingMessage} messageClass={loadingMessageClass}
                 size={iconSize} />
      ) : (
        children || content
      )}
    </Button>
  )
}

CartButtonSubmit.defaultProps = {
  block: true,
  content: '',
  color: 'salmon',
  dataTest: '',
  formId: '',
  iconSize: '1x',
  isDisabled: false,
  loading: false,
  loadingMessage: null,
  loadingMessageClass: null,
}

CartButtonSubmit.propTypes = {
  block: PropTypes.bool,
  content: PropTypes.string,
  color: PropTypes.string,
  dataTest: PropTypes.string,
  formId: PropTypes.string,
  iconSize: PropTypes.string,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  loadingMessageClass: PropTypes.string,
}

export default CartButtonSubmit
