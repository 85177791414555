import pluralize from 'pluralize'
import PropTypes from 'prop-types'

const SelectOptions = ({ data, isPluralize, label }) => {
  if (!data) return null

  const options = data.map(option => {
    const value = option.value || option
    const description = option.label || option

    return (
      <option key={value} value={value}>
        {isPluralize && label ? pluralize(label, description, true) : `${description} ${label}`}
      </option>
    )
  })

  return options
}

SelectOptions.defaultProps = {
  data: [],
  isPluralize: true,
  label: '',
}

SelectOptions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ])),
  isPluralize: PropTypes.bool,
  label: PropTypes.string,
}

export default SelectOptions
