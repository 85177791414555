import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'

const CheckInput = ({ className, inputClass, label, ...field }) => {
  const inputClasses = cnames('me-2', inputClass)

  return (
    <Label check className={className}>
      <Input className={inputClasses} {...field} />
      {label}
    </Label>
  )
}

CheckInput.defaultProps = {
  label: '',
}

CheckInput.propTypes = {
  label: PropTypes.string,
}

export default CheckInput
