import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import CheckInput from 'Shared/CheckInput'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'

const FormCheckInputGroup = ({ className, classNameLabel, helpText, isRequired, name, label, options, showErrors,
                               type }) => {
  const classNames = {
    formGroup: cnames('check-input', className),
    label: cnames('check-input', classNameLabel),
  }

  const isChecked = ({ input, field }) => {
    const isObject = typeof field.value === 'object'
    if (isObject) return field.value.includes(input)

    return field.value === input
  }

  return (
    <FormGroupField check className={classNames.formGroup} name={name} showErrors={showErrors}>
      {({ field, hasErrorClass, isInvalidClass }) => {
        const inputClass = hasErrorClass || null

        return (
          <>
            {label && (
              <Label className="main-label" for={name}>
                {label}
                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}

            {helpText && <RichText className="help-text w-100" html={helpText} />}

            <div className="control-check-group mt-2">
              {options.map((input, index) => {
                const label = input?.label || input
                const value = input?.value || input
                const checked = isChecked({ input: value, field })

                return <CheckInput {...{ ...field, checked, label, type, value }} className={classNames.label}
                                   id={`${name}[${index}]`} inputClass={inputClass} invalid={isInvalidClass}
                                   key={value} />
              })}
            </div>
          </>
        )
      }}
    </FormGroupField>
  )
}

FormCheckInputGroup.defaultProps = {
  classNameLabel: '',
  isRequired: false,
  options: [],
}

FormCheckInputGroup.propTypes = {
  classNameLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ),
  type: PropTypes.string.isRequired,
}

export default FormCheckInputGroup
