import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'

const DEBOUNCED_MILLISECONDS = 700

const TextInput = ({ className, onValueChange, value }) => {
  const [state, setState] = useState('')
  const debounced = useDebouncedCallback(newValue => onValueChange(newValue), DEBOUNCED_MILLISECONDS)

  const onChange = e => {
    const { value } = e.target
    setState(value)
    debounced(value)
  }

  useEffect(() => {
    if (value !== state) setState(value)
  }, [value])

  return <Input className={className} onChange={onChange} type="text" value={state} />
}

TextInput.defaultProps = {
  className: '',
}

TextInput.propTypes = {
  className: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default TextInput
