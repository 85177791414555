import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { Alert } from 'reactstrap'
import { BLOCK_CONFIGURATION } from '../constants'
import 'Styles/List.scss'

const ResponseBlock = ({ state, sites }) => {
  const { color, icon, msg, title } = BLOCK_CONFIGURATION[state]

  return (
    <div className="border-top pt-4 mb-4">
      <h5>
        <FontAwesomeIcon className={`mx-1 text-${color}`} icon={icon} />
        {title}
      </h5>

      {msg && (
        <Alert color={color}>{msg}</Alert>
      )}

      <ul className="sites list-group list-striped" id={`${state}-sites-list`}>
        {sites.map(({ id, name }) => (
          <li className="list-group-item" key={id}><span>{name}</span></li>
        ))}
      </ul>
    </div>
  )
}

export default ResponseBlock
