import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const SuccesfullyMessage = ({ close }) => (
  <div className="text-center">
    <FontAwesomeIcon className="text-success h1" icon="circle-check" />

    <h3 className="py-2">Sent!</h3>

    <Button onClick={close} size="lg" color="danger" block>
      Close
    </Button>
  </div>
)

export default SuccesfullyMessage
