import { DNS_GOOGLE_PATH } from 'Shared/VerifyWebsite/Utils/consts'
import { urlStartsWithHttp, urlReplaceSubdomain } from 'Shared/RegExpressions'

export const buildUrlFromDomain = domain => `https://${domain}`

export const dnsResolverApi = async domain => {
  const response = await fetch(`${DNS_GOOGLE_PATH}?name=${domain}`)
  const json = await response.json()

  return json
}

const replaceSubdomain = (url, subdomain) => url.replace(urlReplaceSubdomain, `${subdomain}$3`)

export const getVariations = url => {
  const withoutProtocol = url.replace(urlStartsWithHttp, '')
  const isNakedDomain = withoutProtocol.split('.').length === 2
  const variations = [`https://${withoutProtocol}`, `http://${withoutProtocol}`]

  if (!isNakedDomain) {
    const withoutSubdomain = replaceSubdomain(url, '')
    variations.push(`https://${withoutSubdomain}`, `http://${withoutSubdomain}`)
  }

  return variations
}
