import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Col, Row } from 'reactstrap'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { formatDate } from 'Utils/Dates'
import DiscountSelect from './DiscountSelect'
import PageSubtitle from 'Shared/PageSubtitle'
import Loading from 'Shared/Loading'
import discountsQuery from './DiscountsQuery'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import FormTextInput from 'Shared/Formik/FormTextInput'

const Discount = ({ campgroundSlug, discountsArray, from, isFloating, numberRequired, isPos, labelClasses,
                    layoutColumn, note, noteClasses, propsDiscountCodeInput, rateIds, sizeInput, to, title,
                    titleClasses, useDiscountsArray }) => {
  const [getDiscounts, { data, loading, error }] = useLazyQueryNoCache(discountsQuery)
  const discountSelectFormGroupClass = cnames({ 'mb-0': layoutColumn.first < 12 })
  const InputComponent = isFloating ? FloatingLabelInput : FormTextInput
  const discounts = useMemo(() => {
    if (useDiscountsArray) return discountsArray

    if (!data) return []

    return data?.campground?.discountClubsForRates
  }, [data, useDiscountsArray])

  useEffect(() => {
    if (!useDiscountsArray && campgroundSlug && rateIds && from && to) {
      const discountQueryArgs = { variables: { from: formatDate(from), isPos, rateIds, slug: campgroundSlug,
                                               to: formatDate(to) } }

      getDiscounts(discountQueryArgs)
    }
  }, [campgroundSlug, from, to, useDiscountsArray])

  if (loading) return <Loading className="mt-5 text-center" />

  if ((useDiscountsArray && !discountsArray?.length) || error || !discounts || !discounts.length) return null

  return (
    <Row className="discount mb-4">
      <Col md="12">
        <PageSubtitle className={titleClasses} text={title} />
        {note && <div className={noteClasses}>{note}</div>}
      </Col>

      <Col className="mb-2 mb-lg-0" md={layoutColumn.first}>
        <DiscountSelect {...{ isFloating, labelClasses, sizeInput }} className={discountSelectFormGroupClass}
                        discounts={discounts} showRate={isPos} />
      </Col>

      {numberRequired && (
        <Col className="mb-0" md={layoutColumn.second}>
          <InputComponent {...{ labelClasses, sizeInput, ...propsDiscountCodeInput }} className="mb-0"
                          name="discount.code" showErrors={!isFloating} />
        </Col>
      )}
    </Row>
  )
}

Discount.defaultProps = {
  discountsArray: [],
  from: null,
  isFloating: false,
  isPos: false,
  labelClasses: '',
  layoutColumn: {
    first: 6,
    second: 6,
  },
  note: '',
  noteClasses: '',
  propsDiscountCodeInput: {
    label: 'Discount Code',
    placeholder: 'Discount Code',
  },
  rateIds: [],
  sizeInput: '',
  title: 'Discounts',
  titleClasses: '',
  to: null,
  useDiscountsArray: false,
}

Discount.propTypes = {
  campgroundSlug: PropTypes.string.isRequired,
  discountsArray: PropTypes.arrayOf(PropTypes.shape({})),
  from: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  isPos: PropTypes.bool,
  isFloating: PropTypes.bool,
  numberRequired: PropTypes.bool.isRequired,
  labelClasses: PropTypes.string,
  layoutColumn: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number,
  }),
  note: PropTypes.string,
  noteClasses: PropTypes.string,
  propsDiscountCodeInput: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  rateIds: PropTypes.arrayOf(PropTypes.string),
  sizeInput: PropTypes.string,
  title: PropTypes.string,
  titleClasses: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  useDiscountsArray: PropTypes.bool,
}

export default Discount
