import AlertWrapper from 'Shared/Alert/AlertWrapper/AlertWrapper'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const SuccessAlert = () => (
    <AlertWrapper className="align-items-center d-flex" color="success">
      <FontAwesomeIcon className="me-2 fa-sm ms-1 text-success" icon="money-bill-transfer" />

      <p className="mb-0 ms-2">
        Payment will run on Recurring Invoices with autopay authorized on the invoice due date.
      </p>
    </AlertWrapper>
  )

export default SuccessAlert
