import { useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'

const useStripeToken = ({ stripeElement }) => {
  const [state, setState] = useState({ errors: [], loading: false, token: null })
  const elements = useElements()
  const stripe = useStripe()

  const clearErrors = () => setState(prevState => ({ ...prevState, errors: [] }))

  const getErrorMessages = errors => {
    if (errors.length) return errors.map(error => error.message)

    return [errors.message]
  }

  const getToken = async additionalData => {
    setState(prevState => ({ ...prevState, loading: true, token: null }))
    const { token, error } = await stripe.createToken(elements.getElement(stripeElement), additionalData)

    if (token) setState(prevState => ({ ...prevState, loading: false, token: token.id }))
    else {
      const errors = getErrorMessages(error)

      setState(prevState => ({ ...prevState, loading: false, errors }))
    }
  }

  const { errors, loading, token } = state

  return { clearErrors, getToken, token, tokenErrors: errors, tokenLoading: loading }
}

export default useStripeToken
