import { useDeferredValue } from 'react'
import { Modal, ModalBody } from 'reactstrap'

const MobileModal = ({ children, isOpen }) => {
  const isOpenDeferred = useDeferredValue(isOpen, { timeoutMs: 150 })

  return (
    <Modal centered className="map-sites-popover" isOpen={isOpenDeferred}>
      <ModalBody className="p-0">
        {children}
      </ModalBody>
    </Modal>
  )
}

export default MobileModal
