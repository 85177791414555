import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import cnames from 'classnames'

const StepIndicator = ({ navigateToRoute, isActive, isLast, isOverlay, step, size }) => {
  const className = cnames('align-items-center d-flex justify-content-center indicator',
                           size, { 'last-indicator': isLast })

  if (isActive || isOverlay)
    return (
      <Anchor className={className} href={`#${step.name}`} onClick={navigateToRoute}>
        {step.icon ? <FontAwesomeIcon icon={step.icon} /> : step.index}
      </Anchor>
    )

  return (
    <span className={className}>
      {step.icon ? <FontAwesomeIcon icon={step.icon} /> : step.index}
    </span>
  )
}

export default StepIndicator
