import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const AlwaysScrollTo = ({ deps }) => {
  const elementRef = useRef()

  useEffect(() => {
    elementRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [...deps])

  return <div ref={elementRef} />
}

AlwaysScrollTo.defaultProps = {
  deps: [],
}

AlwaysScrollTo.propTypes = {
  deps: PropTypes.instanceOf(Array),
}

export default AlwaysScrollTo
