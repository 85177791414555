import Checkbox from 'Shared/Checkbox/Checkbox'

const CheckboxTable = ({ className, isDisabled, handleClick, id, isChecked, isIndeterminate, message, name }) => (
  <div className="align-items-center d-flex justify-content-center position-relative">
    <Checkbox className={className} disabled={isDisabled} handleClick={handleClick} id={id} isChecked={isChecked}
              isIndeterminate={isIndeterminate} labelClassname="visually-hidden" message={message} name={name} />
  </div>
)

export default CheckboxTable
