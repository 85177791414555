import { useHistory } from 'react-router-dom'
import { routesHandler } from 'Checkout/Routes/routesHandler'

export const useRedirects = () => {
  const { push } = useHistory()

  const buildBaseParams = reservation => {
    const { data, dayUse, extras: { filters: { maxLength, maxWidth } }, from, to } = reservation
    const { rate, siteType } = data
    const accommodation = dayUse ? 'daily' : 'overnight'
    const params = `accommodation=${accommodation}&check_in=${from}&rate_id=${rate.id}&site_type=${siteType.type}`
    const baseParams = dayUse ? params : `${params}&check_out=${to}`
    if (!maxLength || !maxWidth) return baseParams

    return `${baseParams}&rig_length=${maxLength}&rig_width=${maxWidth}`
  }

  const buildRoute = (to, reservation) => {
    const { path } = routesHandler[to]

    return `${path}?${buildBaseParams(reservation)}`
  }

  const cartRedirect = () => {
    const { cart: { path } } = routesHandler
    push(path)
  }

  const detailsRedirect = reservation => {
    const route = buildRoute('details', reservation)
    push(route)
  }

  const homeRedirect = () => push(routesHandler.home.path)

  const searchRedirect = reservation => {
    const route = buildRoute('search', reservation)
    push(route)
  }

  const sitesRedirect = reservation => {
    const route = buildRoute('sites', reservation)
    push(route)
  }

  return { cartRedirect, detailsRedirect, homeRedirect, searchRedirect, sitesRedirect }
}
