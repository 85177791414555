import cnames from 'classnames'
import PropTypes from 'prop-types'
import { getInitials } from 'Utils/Strings'
import Image from 'Shared/Image/Image'
import 'Shared/AvatarPlaceholder/AvatarPlaceholder.scss'

const AvatarPlaceholder = ({ className, md, name, src }) => {
  const classNameWrapper = cnames('avatar-placeholder flex-centered', className, { 'avatar-placeholder--md': md })

  return (
    <div className={classNameWrapper}>
      {src ? <Image srcToLoad={src} /> : getInitials(name)}
    </div>
  )
}

AvatarPlaceholder.defaultProps = {
  className: '',
  md: false,
  src: '',
}

AvatarPlaceholder.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  md: PropTypes.bool,
  src: PropTypes.string,
}

export default AvatarPlaceholder
