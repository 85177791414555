import PropTypes from 'prop-types'
import cnames from 'classnames'

const CloseModalButton = ({ className, onClick, isColorWhite }) => {
  const classname = cnames('btn-close', { 'btn-close-white': isColorWhite }, className)

  return (
    <button className={classname} name="Close" onClick={onClick} type="button" aria-label="Close" />
  )
}

CloseModalButton.defaultProps = {
  isColorWhite: false,
}

CloseModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isColorWhite: PropTypes.bool,
}

export default CloseModalButton
