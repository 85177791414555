import { earlyCheckInLegend } from 'Utils/Translates'

const EarlyCheckinNotice = ({ translatesMessages }) => {
  const earlyCheckInMessage = translatesMessages[earlyCheckInLegend]

  return (
    <div className="border-bottom-1 mb-3 pb-3">
      <span className="text-danger text-sm text-justify">{earlyCheckInMessage}</span>
    </div>
  )
}

export default EarlyCheckinNotice
