import { roundWithDecimals } from 'Utils/Parse'

export const equalsArrayCheck = (a, b) => (
  JSON.stringify(a) === JSON.stringify(b)
)

export const fillArrayWithMinToMax = ({ min, max }) => {
  if (min > max) return [max]

  return Array.from(Array((max - min) + 1), (_, i) => min + i)
}

export const fillArrayWithMaxToMin = values => fillArrayWithMinToMax(values).reverse()

export const getTotalAccepted = (array, total, rangeAccepted = 1) => {
  const calculated = array.reduce((sum, item) => (sum + item.total), 0)
  const min = total - rangeAccepted
  const max = total + rangeAccepted

  if (calculated >= min && calculated <= max) return calculated

  return total
}

export const getSplitPaymentTotals = ({ payments, total }) => {
  const paymentsLength = payments.length
  const splitTotal = roundWithDecimals(total / paymentsLength)

  return payments.map(payment => ({ ...payment, total: splitTotal }))
}

export const removeItem = (items, removeItem) => items.filter(item => item !== removeItem)

export const removeItemSplice = (items, index) => {
  items.splice(index, 1)
  return items
}

export const uniqueItems = array => [...new Set(array)]
