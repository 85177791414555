import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

export const messagePhone = 'The phone number provided does not match the format for that country.'

export const topCountries = ['US', 'CA', 'MX', 'AU', '|', '...']

export const parsePhoneToObject = phone => {
  if (!phone) return

  const phoneData = parsePhoneNumber(phone)
  if (phoneData) {
    const { countryCallingCode: country, nationalNumber: number } = phoneData
    return { country: `+${country}`, number }
  }
}

export function isValidPhone() {
  return this.test('test-is-valid-phone', messagePhone, function (value = '') {
    const { createError, path } = this
    return isPossiblePhoneNumber(value) || createError({ message: messagePhone, path })
  })
}
