export const paymentsState = {
  filters: {
    source: [],
    status: '',
    term: '',
    usedFor: ['all'],
  },
  isParamsReviewed: false,
  pagination: {
    currentPage: 1,
    perPage: 25,
  },
  payments: {
    data: {},
    error: {},
    loading: false,
  },
  sortedField: {},
}
