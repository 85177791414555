import PropTypes from 'prop-types'
import FormErrorMessages from 'Shared/Formik/FormErrorMessages'

const MaxPeopleMessage = ({ maxPeople, total }) => {
  const message = `This site only accepts up to ${maxPeople} people`

  return total > maxPeople && (
    <FormErrorMessages errors={[message]} />
  )
}

MaxPeopleMessage.defaultProps = {
  maxPeople: 0,
  total: 0,
}

MaxPeopleMessage.propTypes = {
  maxPeople: PropTypes.number,
  total: PropTypes.number,
}

export default MaxPeopleMessage
