import { useEffect, useState } from 'react'

const initialState = { isError: null, isLoading: true, src: null }

export const useImage = ({ src }) => {
  const [data, setData] = useState(initialState)

  useEffect(() => {
    if (src) {
      let isMounted = true

      const img = new Image()
      img.onload = () => isMounted && setData({ src, isLoading: false })
      img.onerror = () => isMounted && setData({ isError: true, isLoading: false })
      img.src = src

      return () => {
        isMounted = false
        img.src = '' // NOTE: To cancel the download
      }
    }
  }, [src])

  return data
}
