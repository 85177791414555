export const scheduleState = {
  editSchedule: {
    data: null,
    error: false,
    loading: false,
    showSuccessToast: false,
  },
  installmentPreview: {
    data: null,
    error: false,
    loading: false,
  },
  stopSchedule: {
    completed: false,
    data: null,
    error: false,
    loading: false,
  },
}
