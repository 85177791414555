import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Collapse } from 'reactstrap'
import AlertWrapper from 'Shared/Alert/AlertWrapper/AlertWrapper'
import Checkbox from 'Shared/Checkbox/Checkbox'
import saveCardIcon from './saveCard.svg'
import './CardOnFileAlert.scss'

const CardOnFileAlert = ({ className, isAccount, onClickSaveCardOnFile, saveCardOnFile, subtitle, title }) => {
  const classNameWrapper = cnames('mb-2 ps-2 py-2', className)
  const classNamesSaveOnFileInAccount = cnames({ success: saveCardOnFile, primary: !saveCardOnFile })

  return (
    <div className="card-on-file-alert mb-2">
      <AlertWrapper className={classNameWrapper} color="info-outline-filled mb-0">
        <div className="align-items-center d-flex justify-content-between smaller">
          <div className="align-items-center d-flex">
            <img alt="card on file icon" className="me-2" src={saveCardIcon} />

            <div className="d-flex flex-column">
              <p className="fw-bold mb-0">{title}</p>

              <span className="text-smaller-1">{subtitle}</span>
            </div>
          </div>

          <div>
            {isAccount ? (
              <Button color={classNamesSaveOnFileInAccount} onClick={onClickSaveCardOnFile} size="sm">
                {saveCardOnFile ? 'Allowed!' : 'Yes, Allow it'}
              </Button>
            ) : (
              <Checkbox className="mt-2" classNameInput="position-relative mt-0"
                        handleClick={onClickSaveCardOnFile} isChecked={saveCardOnFile} isCircle
                        name="card-on-file-for-future" />
            )}
          </div>
        </div>
      </AlertWrapper>

      <Collapse className="ps-2 card-saved text-smaller-1" isOpen={saveCardOnFile}>
        Awesome! The card will be saved when payment is processed
      </Collapse>
    </div>
  )
}

CardOnFileAlert.defaultProps = {
  isAccount: false,
  onClickSaveCardOnFile: () => {},
  saveCardOnFile: false,
  subtitle: 'Remember to get permission from the card owner',
  title: 'Save card on file for this reservation',
}

CardOnFileAlert.propTypes = {
  isAccount: PropTypes.bool,
  onClickSaveCardOnFile: PropTypes.func,
  saveCardOnFile: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

export default CardOnFileAlert
