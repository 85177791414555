import * as Yup from 'yup'

const validateSchema = () => Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .max(255, 'Email is too long')
    .required('Email is required'),

  firstName: Yup.string()
    .max(255, 'First name is too long')
    .required('First name is required'),

  lastName: Yup.string()
    .max(255, 'Last name is too long')
    .required('Last name is required'),

  password: Yup.string()
    .required('Password is required')
    .max(255, 'Password is too long')
    .min(8, 'Password is too short'),
})

export default validateSchema
