import * as Yup from 'yup'

const messageRequired = 'Required field.'
const minZero = 'Min value is 0'

const electricSchema = Yup.object().shape({
  kws: Yup.number()
    .required(messageRequired),

  meterEnd: Yup.number()
    .when(['meterStart'], (meterStart, schema) => (
      (meterStart > 0 ? schema.min(meterStart + 0.01) : schema.min(0))
    )),

  meterStart: Yup.number()
    .min(0, minZero),

  total: Yup.object().shape({
    amount: Yup.number()
      .required(messageRequired),

    display: Yup.string(),
  }),

  priceKw: Yup.number(),
})

export default electricSchema
