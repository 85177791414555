import { gql } from 'Utils/apolloClient'

const currentUserDataFragment = gql`
  fragment currentUserData on CurrentUser {
    address
    address2
    authenticationToken
    city
    createdAt
    stripeCards {
      id
      expDate
      lastFour
      userAddress
      userName
    }
    email
    equipment(filters: $filters) {
      id
      name
      numSlideouts
      rigLength
      rigType
      rigWidth
      year
    }
    firstName
    id
    isBanned(slug: $slug)
    lastName
    name
    phone {
      country
      display
      number
    }
    state
    zipCode
  }`

export default currentUserDataFragment
