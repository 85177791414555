import cnames from 'classnames'
import { Alert as ReactstrapAlert } from 'reactstrap'
import './AlertWrapper.scss'

const AlertWrapper = props => {
  const { children, className } = props
  const classes = cnames('custom', className)

  return (
    <ReactstrapAlert {...props} className={classes}>
      {children}
    </ReactstrapAlert>
  )
}

export default AlertWrapper
