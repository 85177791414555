import { useMutation } from 'Shared/Hooks/useApolloClient'
import PictureUpdateMutation from './PictureUpdateMutation'


export const useUpdatePicture = () => {
  const [pictureUpdate, { data, error, loading, reset }] = useMutation(PictureUpdateMutation, {
    onCompleted: (_, data) => {
      if (data.variables.params.action === 'delete') return window.location.reload()
    },
  })

  const { graphQLErrors } = error || {}
  return { data, graphQLErrors, loading, pictureUpdate, reset }
}
