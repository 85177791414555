import cnames from 'classnames'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'

const RefundSuccess = ({ installmentRefunded, isRefundPage, toggle }) => {
  const { id, links, number, refund, refundExternal, installmentsCount,
          isExternallyPaid } = installmentRefunded || {}
  const { state, source, total } = (isExternallyPaid ? refundExternal : refund) || {}
  const successMessage = isExternallyPaid ? 'Refund has been recorded successfully' : cnames({
    'Refund in Process': state === 'pending',
    'Refunded Successfully': state === 'completed',
  })

  return (
    <div className="d-flex flex-column m-xs-3 mx-auto w-75 px-md-3 my-3">
      <FontAwesomeIcon className="text-success my-1" icon={['far', 'circle-check']} size="2x" />

      <h5 className="mt-2 text-center fw-bold">
        <p><span className="text-primary">{total?.display}</span> {successMessage}</p>
      </h5>

      <p className="text-center text-muted">
        <span>From Payment {number} of {`${installmentsCount} #${id}`}</span>
      </p>

      <p className="text-center text-muted">
        <span>Refund Method: {source}</span>
      </p>

      <Button block className="my-2" color="salmon" onClick={toggle}>Close</Button>

      {isRefundPage && (
        <Link className="btn btn-secondary my-2 w-100" isExternal to={links?.installmentPath}>
          Go to Invoice Details
        </Link>
      )}
    </div>
  )
}

export default RefundSuccess
