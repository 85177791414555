import cnames from 'classnames'
import RowCell from './RowCell'

const TableRow = ({ contentClasses, item, headers, onRowCheckSelected, rowClassname, tableName }) => {
  const className = cnames(`${tableName}-row`, rowClassname)

  return (
    <tr className={className}>
      {headers.map(header => <RowCell contentClasses={contentClasses} header={header} item={item}
                                      key={`${header.name}-${item.id}`}
                                      onRowCheckSelected={onRowCheckSelected} />)}
    </tr>
  )
}

export default TableRow
