import { Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { dateToISO } from 'Utils/Dates'
import CloseModalButton from 'Shared/CloseModalButton'
import Form from 'Shared/BlackoutDates/AddForm/Form'
import Summary from './Summary/Summary'
import 'Shared/Skeleton/Skeleton.scss'
import 'Styles/Modal.scss'

const AddBlackoutDatesModal = ({ errorSites, isOpen, loadingSites, loadingUndo, mutation, sitesApi, saveBlackouts,
                                 toggle, undoBlackouts }) => {
  const { closeSeasonDates, sites } = sitesApi?.campground || {}
  const closeButton = (<CloseModalButton className="pt-0" isColorWhite onClick={toggle} />)
  const { data: mutationData } = mutation || {}
  const displaySumary = mutationData != null

  const saveData = values => {
    const { days, from, sites, to } = values
    const variables = { days: days.sort(), from: dateToISO(from), sites, to: dateToISO(to) }
    saveBlackouts(variables)
  }

  return (
    <Modal className="confirmation-modal modal-dialog-centered view-modal" id="add-blackout-dates-modal"
           isOpen={isOpen} size="md" toggle={toggle}>
      <ModalHeader className="border-0" close={closeButton} toggle={toggle}>
        Add Blackout Dates
      </ModalHeader>

    <ModalBody className="overflow-auto">
      <Collapse isOpen={!displaySumary}>
        <Form closureDates={closeSeasonDates} error={errorSites} mutationData={mutation}
              loading={loadingSites} saveData={saveData} sitesApi={sites || []} toggle={toggle} />
      </Collapse>

      <Collapse isOpen={displaySumary}>
        <Summary loadingUndo={loadingUndo} onComplete={toggle} responseData={mutationData}
                 undoBlackouts={undoBlackouts} />
      </Collapse>
    </ModalBody>
  </Modal>
  )
}

export default AddBlackoutDatesModal
