import cnames from 'classnames'
import PropTypes from 'prop-types'
import { defaulValue } from 'Shared/Cart/Constants'
import NameWithHelpText from 'Shared/NameWithHelpText'

const ContentFooter = ({ colorClass, helpText, name, showBorder, value }) => {
  const classes = cnames('d-flex fw-bold justify-content-between', colorClass,
                         { 'border-top pt-3': showBorder })

  return (
    <div className={classes}>
      <NameWithHelpText name={name} helpText={helpText} />

      <span>{value}</span>
    </div>
  )
}

ContentFooter.defaultProps = {
  colorClass: 'text-dark',
  helpText: null,
  showBorder: false,
  value: defaulValue,
}

ContentFooter.prototypes = {
  colorClass: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
  showBorder: PropTypes.bool,
  value: PropTypes.string,
}

export { ContentFooter }
