import { createRef, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import cnames from 'classnames'
import { InputGroup, InputGroupText, Input } from 'reactstrap'

const CurrencyInput = ({ classInputGroup, classTextGroup, debouncedTime, disabled, id, inputClass, inputSize,
                         max, name, onValueChange, style, value }) => {
  const debounced = useDebouncedCallback(newValue => onValueChange(newValue || 0), debouncedTime)
  const [state, setState] = useState({ first: false, focus: false, innerValue: '0.00' })
  const inputRef = createRef()
  const { currencySymbol = '$' } = window?.roverData || {}
  const { first, focus, innerValue } = state
  const containerClass = cnames('flex-nowrap', classInputGroup)
  const textClass = cnames(classTextGroup, { 'bg-white': !disabled && classTextGroup })

  const countDecimals = value => value.split('.')[1]?.length || 0

  const getDisplayValue = value => {
    if (value === 0) {
      if (disabled) return '0.00'

      return ''
    }

    if (disabled || !first || !focus) return value.toFixed(2)

    return value.toString()
  }

  const handleOnBlur = event => {
    const newValue = parseFloat(event.target.value) || 0
    setState(prevState => ({ ...prevState, focus: false, innerValue: newValue.toFixed(2) }))
  }

  const handleOnFocus = () => setState(prevState => ({ ...prevState, focus: true }))

  const handleOnChange = event => {
    event.persist()

    const currentValue = event.target.value
    const parseValue = parseFloat(currentValue)
    const newValue = Number.isNaN(parseValue) ? '' : parseValue
    const regex = new RegExp(/^$|^\d{0,10}(\.\d{0,2})?$/)

    if (!regex.test(newValue) || countDecimals(currentValue) > 2) return false

    const validatedValue = newValue > max ? max : newValue

    setState(prevState => ({ ...prevState, innerValue: validatedValue }))
    if (onValueChange) debounced(validatedValue)
  }

  const setFocus = () => inputRef.current.focus()

  useEffect(() => {
    const valueExist = innerValue === '0.00' && value === 0
    const displayValue = getDisplayValue(value)

    if (value !== innerValue && !valueExist) setState({ first: true, innerValue: displayValue })
  }, [value])

  return (
    <InputGroup className={containerClass}>
      <InputGroupText className={textClass} htmlFor={name} onClick={setFocus}>
        {currencySymbol}
      </InputGroupText>

      <Input autoComplete="off" bsSize={inputSize} className={inputClass} disabled={disabled} id={id || name}
             innerRef={inputRef} max={max} name={name} onBlur={handleOnBlur} onFocus={handleOnFocus}
             onChange={handleOnChange} onWheel={e => e.target.blur()} step="any" style={style}
             type="number" value={innerValue} />

    </InputGroup>
  )
}

CurrencyInput.defaultProps = {
  debouncedTime: 600,
  inputSize: 'lg',
  disabled: false,
  max: Number.MAX_SAFE_INTEGER,
  style: {},
}

export default CurrencyInput
