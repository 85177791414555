import cnames from 'classnames'
import PropTypes from 'prop-types'
import { formatDate } from 'Utils/Dates'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const NextPayment = ({ isAccount, isCalendarView, isDesktopView, nextPayment }) => {
  const { dueOn, links, total } = nextPayment || {}
  const { accountInstallmentNextToPayPath, installmentPath } = links || {}
  const nextPaymentLink = isAccount ? accountInstallmentNextToPayPath : installmentPath
  const dueDate = formatDate(dueOn, { format: 'MM/DD/YYYY' })

  const classes = {
    nextPaymentColStyles: cnames('next-payment', { 'col-md-7 col-xl-3': !isCalendarView,
                                                   'col-lg-6 col-xl-5': isCalendarView }),
  }

  return (
    <div className={classes.nextPaymentColStyles}>
      <div className="next-payment-card border rounded shadow-sm p-2 h-100">
        <div className="d-flex justify-content-between">
          <p className="fw-bold text-uppercase mb-0 mb-lg-1 ms-1">
            <FontAwesomeIcon className="me-1 text-muted" icon={['far', 'calendar-days']} />
            Next Payment
          </p>

          {!isDesktopView && (
            <>
              <small className="m-0 text-muted align-self-center">{total.display}</small>

              <div className="ms-3">
                <small>Due:</small>

                <small className="ms-1 text-muted fst-italic">{dueDate}</small>
              </div>
            </>
          )}

          <a className="installment-link text-decoration-none" href={nextPaymentLink}>
            <FontAwesomeIcon className="text-muted" icon="up-right-from-square" />
          </a>
        </div>

        {isDesktopView && (
          <div className="d-flex justify-content-between">
            <div>
              <small className="text-muted fst-italic">Amount</small>

              <p className="m-0">{total.display}</p>
            </div>

            <div className="me-3">
              <small className="text-muted fst-italic">Due Date</small>

              <p className="m-0">{dueDate}</p>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

NextPayment.defaultProps = {
  isAccount: false,
  isCalendarView: false,
}

NextPayment.propTypes = {
  isAccount: PropTypes.bool,
  isCalendarView: PropTypes.bool,
}

export default NextPayment
