import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CloseCanvasBtn = ({ toggle }) => (
  <Button className="close-canvas text-black text-decoration-none" color="link" onClick={toggle}>
    <FontAwesomeIcon icon="arrow-right" size="lg" />
  </Button>
)

export default CloseCanvasBtn
