import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addDateTime, formatDate } from 'Utils/Dates'

export const useStayDates = () => {
  const { checkIn, checkOut, dayUse } = useSelector(state => state.search)
  const checkOutDate = useMemo(() => {
    if (dayUse && checkIn) return formatDate(addDateTime(checkIn, { day: 1 }))

    return checkOut
  }, [checkIn, checkOut, dayUse])

  return {
    checkIn,
    checkOut: checkOutDate,
    validStayDates: (dayUse && checkIn) || (checkIn && checkOut),
  }
}
