export const resourcesState = {
  options: {
    data: {},
    error: null,
    loading: true,
  },
  campground: {
    data: null,
    error: null,
    loading: false,
  },
  channexData: {
    errorsList: [],
    isCreated: false,
    isError: false,
    isErrorModalOpen: false,
    response: null,
  },
  slug: '',
}
