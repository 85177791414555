import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatDate, today } from 'Utils/Dates'
import { DatePickerSingle } from 'Shared/DatePicker'
import FormGroupField from './FormGroupField'

const FormSingleDate = ({ className, direction, disabled, displayFormat, id, initialDate, isOutsideRange, label,
                          name, onDateChange, placeholder, showDefaultInputIcon, showErrors, small,
                          textToLeft }) => (
  <FormGroupField name={name} className={className} showErrors={showErrors}>
    {({ field, form: { setFieldValue, setStatus }, hasErrorClass }) => {
      const currentValue = field.value

      const onUpdateDate = value => {
        const newValue = value._isValid ? formatDate(value) : null
        setFieldValue(name, newValue)
        setStatus(true)

        if (onDateChange) onDateChange(newValue)
      }

      useEffect(() => {
        if (onDateChange) onDateChange(currentValue)
      }, [currentValue])

      return (
        <DatePickerSingle block className={hasErrorClass} date={currentValue} direction={direction}
                          disabled={disabled} displayFormat={displayFormat} id={id || name}
                          initialMonth={initialDate} isOutsideRange={isOutsideRange} label={label}
                          onDateChange={onUpdateDate} placeholder={placeholder}
                          showDefaultInputIcon={showDefaultInputIcon} small={small} textToLeft={textToLeft} />
      )
    }}
  </FormGroupField>
)

FormSingleDate.defaultProps = {
  direction: 'right',
  disabled: false,
  displayFormat: 'MM/DD/YYYY',
  id: null,
  isOutsideRange: day => day.isBefore(today()),
  onDateChange: null,
  showDefaultInputIcon: false,
  showErrors: false,
  small: false,
}

FormSingleDate.propTypes = {
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  displayFormat: PropTypes.string,
  id: PropTypes.string,
  isOutsideRange: PropTypes.func,
  name: PropTypes.string.isRequired,
  onDateChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  showDefaultInputIcon: PropTypes.bool,
  showErrors: PropTypes.bool,
  small: PropTypes.bool,
}

export default FormSingleDate
