import { useEffect, useRef, useState } from 'react'
import cnames from 'classnames'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import Anchor from 'Shared/Anchor/Anchor'

const ShowMoreLess = ({ maxLines, showMoreText, showLessText, textDetails }) => {
  const [showAllText, setShowAllText] = useState(false)
  const [hideButton, setHideButton] = useState(false)
  const textRef = useRef()
  const classes = cnames(' text-break text-muted d-block', { [`text-max-lines-${maxLines}`]: !showAllText })
  const seeMoreIcon = showAllText ? 'chevron-up' : 'chevron-down'

  useEffect(() => {
    if (textRef.current) {
      const { offsetHeight, scrollHeight } = textRef.current
      setHideButton(offsetHeight >= scrollHeight)
    }
  }, [textRef])


  return (
    <div className="mt-2">
      <small className={classes} ref={textRef}>{textDetails}</small>

      {!hideButton &&
        <div className="d-flex justify-content-center mt-1">
          <Anchor className="small show-details-button" onClick={() => setShowAllText(prevState => !prevState)}>
            {showAllText ? showLessText : showMoreText}

            <FontAwesomeIcon className="ms-1" icon={seeMoreIcon} />
          </Anchor>
        </div>}
    </div>
  )
}

export default ShowMoreLess
