import { useEffect, useMemo, useState } from 'react'
import cnames from 'classnames'
import { BLACKOUTDATES_EMPTY, BLACKOUTDATES_ERROR, DEFAULT_PER_PAGE } from 'Shared/BlackoutDates/constants'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import siteBlackoutDates from 'Shared/BlackoutDates/Queries/siteBlackoutDates'
import { Button } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import EmptyTable from 'Shared/Admin/Table/EmptyTable/EmptyTable'
import SitesList from '../Components/List/SitesList'

const Home = ({ calledApi, callRemoveBlackoutDates, data, error, loading, setDisplaySites }) => {
  const [sites, setSites] = useState()
  const [geSiteData, { data: siteData, loading: siteLoading }] = useLazyQueryNoCache(siteBlackoutDates)
  const { campgroundSlug: slug } = window.roverData
  const className = cnames({ 'skeleton-loading': loading })

  const isApplyDisabled = useMemo(() => {
    if (!sites) return true

    return sites.filter(site => (site.isSelected || site.isIndeterminate)).length === 0
  }, [sites])

  const selectBlackout = (siteId, blackoutId) => {
    setSites(prevState => (
      prevState.map(site => {
        if (site.id === siteId) {
          const blackoutDatesData = site.blackoutDates.nodes.reduce((acc, item) => {
            const isSelected = item.id === blackoutId ? !item.isSelected : item.isSelected
            if (isSelected) acc.selected += 1

            acc.items.push({ ...item, isSelected })

            return acc
          }, { selected: 0, items: [] })

          const isSiteSelected = blackoutDatesData.selected === site.blackoutDates.nodes.length
          const isSiteIndeterminate = blackoutDatesData.selected > 0 && !isSiteSelected

          return { ...site, blackoutDates: { ...site.blackoutDates, nodes: blackoutDatesData.items },
                   isSelected: isSiteSelected, isIndeterminate: isSiteIndeterminate }
        }

        return site
      })
    ))
  }

  const selectSite = siteId => {
    setSites(prevState => (
      prevState.map(site => {
        if (site.id === siteId) {
          const isSelected = !site.isSelected
          const blackoutDates = site.blackoutDates.nodes.map(bd => ({ ...bd, isSelected }))
          return { ...site, blackoutDates: { ...site.blackoutDates, nodes: blackoutDates }, isSelected,
                   isIndeterminate: false }
        }

        return site
      })
    ))
  }

  const removeBlackoutDates = () => {
    const selectedBlackoutDates = sites.map(site => (
      site.blackoutDates.nodes.reduce((acc, curr) => {
        if (curr.isSelected) acc.push(curr.id)
        return acc
      }, [])
    ))
    const variables = { params: { blackoutDateIds: selectedBlackoutDates.flat() } }
    setDisplaySites(false)
    callRemoveBlackoutDates({ variables })
  }

  const getMoreBlackoutDates = (siteId, actualPage) => {
    const page = actualPage + 1
    const bodFilters = { fromYesterday: true }
    geSiteData({ variables: { bodFilters, page, perPage: DEFAULT_PER_PAGE, siteId, slug } })
  }

  useEffect(() => {
    if (data?.campground) setSites(data.campground.sites)
  }, [data])

  useEffect(() => {
    if (siteData?.campground) {
      const { site: newData } = siteData.campground

      setSites(prevState => (
        prevState.map(site => {
          if (site.id === newData.id) {
            const { blackoutDates, isSelected, isIndeterminate } = site
            const { actualPage } = blackoutDates
            const { blackoutDates: newBlacks } = newData
            const page = actualPage ? actualPage + 1 : 2
            const newIsIndeterminate = isSelected || isIndeterminate || false
            return { ...site, isIndeterminate: newIsIndeterminate, isSelected: false,
                     blackoutDates: { actualPage: page, hasNextPage: newBlacks.hasNextPage,
                                      nodes: [...blackoutDates.nodes, ...newBlacks.nodes] } }
          }

          return site
        })
      ))
    }
  }, [siteData])

  if (error || (data && !data?.campground && calledApi))
    return <AlertErrors errors={[BLACKOUTDATES_ERROR]} graphQLErrors={error} />

  if (data && data?.campground?.sites?.length === 0 && calledApi)
    return <EmptyTable icon="triangle-exclamation" message={BLACKOUTDATES_EMPTY} />

  return (
    <div className={className}>
      <p className="d-inline-block">Sites</p>

      <SitesList getMoreBlackoutDates={getMoreBlackoutDates} loading={loading} selectBlackout={selectBlackout}
                 selectSite={selectSite} setSites={setSites} siteLoading={siteLoading} sites={sites} />

      <div className="position-sticky sticky-bottom bg-white mt-5 pb-3">
        <hr />

        <Button color="salmon" disabled={isApplyDisabled} onClick={removeBlackoutDates}>Apply</Button>
      </div>
    </div>
  )
}

export default Home
