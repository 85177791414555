import { useState } from 'react'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import RefundsPopover from 'Shared/RefundsPending/RefundsPopover'
import 'Shared/RefundsPending/RefundsPending.scss'

const RefundsPending = ({ id, onlyIconTrigger, refunds, text }) => {
  const [isOpen, setIsOpen] = useState(false)
  const targetId = `installment-${id}--refund-popover`

  const toggle = () => setIsOpen(prev => !prev)

  return (
    <div className="refunds-pending p-0">
      <span className="refund-popover-triger" id={targetId} onMouseEnter={toggle} onMouseLeave={toggle}>
        <FontAwesomeIcon className="fa-flip-horizontal me-1 refund-icon" icon="share" />

        {!onlyIconTrigger && (
          <span className="fst-italic text-decoration-underline text-muted">
            Refund(s) Pending
          </span>
        )}
      </span>

      <RefundsPopover {...{ isOpen, refunds, targetId, text, toggle }} />
    </div>
  )
}

export default RefundsPending
