import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const Empty = () => (
  <div className="cart-recurring--empty d-flex flex-centered flex-column py-5 my-4">
    <FontAwesomeIcon className="mb-2" color="grey" icon="cart-plus" size="xl" />

    <p className="h5">Add items to see monthly value</p>
  </div>
)

export default Empty
