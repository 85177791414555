import { useEffect, useState } from 'react'
import { Toast as ToastReacstrap, ToastHeader, ToastBody } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { getCookie, removeCookie } from 'Utils/Cookies'

const Toast = ({ cookieName, message }) => {
  const [showToast, setToast] = useState(false)

  const handleShowToast = () => setToast(prevState => !prevState)

  useEffect(() => {
    if (getCookie(cookieName)) {
      handleShowToast(cookieName)
      removeCookie(cookieName)
      setTimeout(() => setToast(false), 5000)
    }
  }, [])

  return (
    <div className="position-absolute bottom-0 start-0 p-3" style={{ zIndex: 2050 }}>
      <ToastReacstrap className="bg-white" isOpen={showToast}>
        <ToastHeader toggle={handleShowToast}>
          <FontAwesomeIcon className="me-1" icon="rotate-left" />
          Undo Blackout Dates
        </ToastHeader>

        <ToastBody>
          {message}
        </ToastBody>
      </ToastReacstrap>
    </div>
  )
}

export default Toast
