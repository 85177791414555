const addClass = (element, className) => element.classList.add(className)

const getAttributeValue = (element, attribute) => element.getAttribute(attribute)

const getElementDataSet = element => {
  const container = getElement(element)

  return container.dataset || {}
}

const getElement = (id, base = document) => base.getElementById(id)

const getElementQuery = (query, base = document) => base.querySelector(query)

const getElementBounding = ({ id, query }) => {
  const element = id ? getElement(id) : getElementQuery(query)
  const { top, left, height, width } = element.getBoundingClientRect()

  return { top: parseInt(top), left: parseInt(left), height: parseInt(height), width: parseInt(width || 1) }
}

const getRelativePosition = (parentId, childId) => {
  const query = `.site[id = "${childId}"], .site[data-id = "${childId}"]`
  const { top: parentTop, left: parentLeft } = getElementBounding({ id: parentId })
  const { top: childTop, left: childLeft, height, width } = getElementBounding({ query })

  let position = { top: '0', left: '0', height: '0' }

  const top = childTop - parentTop
  const leftDifference = childLeft - parentLeft
  const elementWith = width / (leftDifference > 300 ? 6 : 2)
  const left = elementWith + leftDifference

  if (top > 0) position = { top: `${top}px`, left: `${left}px`, height: `${height}px` }

  return position
}

const getAssignedSitesIds = siteIdAssigned => {
  const sitesAssigned = document.querySelectorAll('.site[data-id]')

  const assignedSitesIds = Array.from(sitesAssigned).reduce((result, path) => {
    const siteId = path.getAttribute('data-id')
    if (siteIdAssigned !== siteId) result.push(siteId)
    return result
  }, [])

  return assignedSitesIds
}

function getElements(selector, options = {}) {
  const defaultValues = { by: 'className', base: document }
  options = { ...defaultValues, ...options }

  const handler = {
    className: _getElementsByClassName,
    queryAll: _querySelectorAll,
    tagName: _getElementsByTagName,
  }[options.by]

  if (handler) return handler(selector, options.base)
}

const removeAttribute = (element, attribute) => element.removeAttribute(attribute)

const removeClass = (element, className) => element.classList.remove(className)

const scrollToElement = (id, behavior = 'smooth') => {
  const container = getElement(id)
  window.scrollTo({
    top: container.offsetTop,
    behavior,
  })
}

const scrollToQueryElement = (query, behavior = 'smooth') => {
  const element = getElementQuery(query)
  if (!element) return false

  element.scrollIntoView({ behavior })
  return true
}

const setAttribute = (element, attribute = []) => element.setAttribute(...attribute)

const callEventToUncontrolledElement = ({ element, event = 'change', value }) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set

  if (valueSetter) valueSetter.call(element, value)

  const eventToDispatch = new Event(event, { bubbles: true })
  element.dispatchEvent(eventToDispatch)
}

const toggleAttribute = (element, attribute, value) => {
  value ? setAttribute(element, [attribute, value]) : removeAttribute(element, attribute)
}

// private
const _getElementsByClassName = (selector, base) => base.getElementsByClassName(selector)

const _getElementsByTagName = (selector, base) => base.getElementsByTagName(selector)

const _querySelectorAll = (selector, base) => base.querySelectorAll(selector)

export { addClass, callEventToUncontrolledElement, getAssignedSitesIds, getAttributeValue, getElement,
         getElementDataSet, getElementQuery, getRelativePosition, getElements, removeAttribute, removeClass,
         scrollToElement, scrollToQueryElement, setAttribute, toggleAttribute }
