import { useState } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import customFieldsQuery from 'Shared/Queries/CustomFieldsQuery'

const useCustomFields = (customFields = []) => {
  const [customFieldsData, setCustomFieldsData] = useState({ data: [], isCompleted: false })

  const [getCustomFields, { loading }] = useLazyQueryNoCache(customFieldsQuery, {
    onCompleted: response => {
      const customFieldsGotten = response?.campground?.customFields
      setCustomFieldsData({ data: handleCustomFields(customFieldsGotten), isCompleted: true })
    },
  })

  const getSavedField = field => customFields.find(customField => customField.id === field.id)?.value || ''

  const handleCustomFields = customFieldsGotten => {
    if (customFieldsGotten.length === 0) return []

    return customFieldsGotten.map(field => ({ ...field, value: getSavedField(field) }))
  }

  return [getCustomFields, { customFieldsData, loadingFieldsData: !customFieldsData.isCompleted || loading }]
}

export default useCustomFields
