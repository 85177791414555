import apolloClient, { ApolloProvider } from 'Utils/apolloClient'

const withApolloProvider = Component => {
  const apolloWrapper = ({ token, ...props }) => {
    window.roverData = { ...(window.roverData || {}), token }

    return (
      <ApolloProvider client={apolloClient}>
        <Component {...props} />
      </ApolloProvider>
    )
  }

  return apolloWrapper
}

export default withApolloProvider
