import { memo } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import useParamsHandler from 'Shared/Hooks/useParamsHandler'

const Pagination = ({ currentPage, needsQueryUrl, pageName, pagesCount, updatePageSelected }) => {
  const params = useParamsHandler()
  // Note: initialPage starts in cero
  const initialPage = currentPage - 1

  const handlePageChange = page => {
    const selectedPage = page.selected + 1
    if (selectedPage !== currentPage) {
      if (needsQueryUrl) params.replaceQuery({ [pageName]: selectedPage })
      updatePageSelected(selectedPage)
    }
  }

  return (
    <ReactPaginate activeClassName="active" breakLabel="..." breakClassName="page-link"
                   containerClassName="pagination m-0 me-4" forcePage={initialPage} nextClassName="page-link"
                   nextLabel="→" marginPagesDisplayed={1} pageClassName="page-item" pageCount={pagesCount}
                   pageLinkClassName="page-link" pageRangeDisplayed={5} previousClassName="page-link"
                   previousLabel="←" onPageChange={handlePageChange} subContainerClassName="pages pagination" />
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageName: PropTypes.string.isRequired,
  pagesCount: PropTypes.number.isRequired,
  updatePageSelected: PropTypes.func.isRequired,
  needsQueryUrl: PropTypes.bool.isRequired,
}

export default memo(Pagination)
