import { useCallback, useState } from 'react'

export const useSessionStorage = ({ initialValue, key }) => {
  const readStorage = useCallback(() => {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  }, [initialValue, key])
  const [storage, setStorage] = useState(readStorage)

  const setStorageValue = value => {
    sessionStorage.setItem(key, JSON.stringify(value))
    setStorage(value)
  }

  return [storage, setStorageValue]
}
