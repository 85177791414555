import { guestsAddedToString } from 'Utils/guestHelpers'
import { CollapsedDates, TitledElement } from 'Shared/Cart/Components'
import CollapseWrapper from 'Shared/Collapse/CollapseWrapper'
import TextTruncatedWithTooltip from 'Shared/TextTruncatedWithTooltip'

const ReservationContainer = ({ campsite, from, guests, handleOnClick, isOpenByDefault, isRigRequired,
                                rig, to }) => {
  const { useGuests, vehicles } = guests
  const classRow = 'row mt-4 mx-0'
  const formattedGuests = guestsAddedToString(guests, useGuests, ' - ', ['vehicles'])

  return (
    <CollapseWrapper handleOnClick={handleOnClick} isOpenByDefault={isOpenByDefault}>
      <CollapseWrapper.Header title="Reservation">
        <CollapsedDates from={from || ''} to={to || ''} />
      </CollapseWrapper.Header>

      <CollapseWrapper.Content className="w-100">
        {campsite && (
          <TitledElement className="mt-2 p-0" icon="location-dot" title="Site">
            <TextTruncatedWithTooltip id="cartSiteName" text={campsite.name} />
          </TitledElement>
        )}

        <div className={classRow}>
          <TitledElement className="col-6 ps-0" icon="arrow-right" title="Check In" value={from} />

          <TitledElement className="col-6 pe-0" icon="arrow-left" title="Check Out" value={to} />
        </div>

        <div className={classRow}>
          {formattedGuests && (
            <TitledElement className="col-6 ps-0" icon="user-group" title="Guest" value={formattedGuests} />
          )}

          <TitledElement className="col-6 pe-0" icon="car" title="Extra Vehicles" value={vehicles} />
        </div>

        {(rig && isRigRequired) && (
          <TitledElement className="mt-4 p-0" icon="truck" title="Rig Information"
                         valueClass="d-flex justify-content-between flex-wrap">
            <span>{rig.year}</span>
            <span>{rig.rigType}</span>
            <span>{rig.rigLength}' long x {rig.rigWidth}' wide</span>
            <span>{rig.numSlideouts} Slideouts</span>
          </TitledElement>
        )}
      </CollapseWrapper.Content>
    </CollapseWrapper>
  )
}

ReservationContainer.defaultProps = {
  formatDates: 'MM-DD-YYYY',
}

export default ReservationContainer
