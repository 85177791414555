import { useEffect, useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { ButtonGroup, TabContent, TabPane } from 'reactstrap'
import './TabPane.scss'

const TabPaneWrapper = ({ classNameContainer, classNameTabContainer, getActiveTab, options, propsToPane }) => {
  const [activeTab, setActiveTab] = useState('')
  const classNames = {
    containerWrapper: cnames('tab-pane-container', classNameContainer),
    containerTabPane: cnames('tab-content-container', classNameTabContainer),
  }

  const toggle = value => setActiveTab(value)

  useEffect(() => {
    const optionActiveByDefault = options.find(option => option.active)

    setActiveTab(optionActiveByDefault.key)
  }, [])

  useEffect(() => {
    getActiveTab(activeTab)
  }, [activeTab])

  return (
    <div className={classNames.containerWrapper}>
      <ButtonGroup>
        {options.map(option => (
          <button className={`btn ${activeTab === option.key ? 'btn-salmon' : 'btn-secondary'}`} key={option.key}
                  type="button" onClick={() => { toggle(option.key) }}>
            {option.message}
          </button>
        ))}
      </ButtonGroup>

      <TabContent activeTab={activeTab} className="w-100">
        {options.map(({ Component, key }) => (
          <TabPane key={key} tabId={key}>
            <div className={classNames.containerTabPane}>
              <Component activeTab={activeTab} {...propsToPane} />
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

TabPaneWrapper.defaultProps = {
  classNameContainer: '',
  classNameTabContainer: '',
  getActiveTab: () => {},
}

TabPaneWrapper.propTypes = {
  classNameContainer: PropTypes.string,
  classNameTabContainer: PropTypes.string,
  getActiveTab: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    className: PropTypes.string,
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    key: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  })).isRequired,
}

export default TabPaneWrapper
