import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Anchor from 'Shared/Anchor/Anchor'

const CollapseAnchor = ({ className, id, isCollapseOpen, message, testId, toggleCollapse }) => {
  const classAnchor = cnames('me-3 text-black', className)

  return (
    <Anchor className={classAnchor} href="#collapse" id={id} onClick={toggleCollapse} data-testid={testId || id}>
      {message}
      <FontAwesomeIcon className="ms-2 text-black" icon={isCollapseOpen ? 'angle-up' : 'angle-down'} size="lg" />
    </Anchor>
  )
}

export default CollapseAnchor
