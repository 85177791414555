import 'Shared/Image/Image.scss'
import { Fade } from 'reactstrap'
import { useImage } from 'Shared/Hooks/useImage'

const LoadingImage = () => (<div className="h-100 skeleton-loading-img w-100" />)
const ALT_ERROR_IMAGE = 'Error loading image'

const Image = ({ alt, children, className, defaultImage, srcToLoad }) => {
  const { isError, isLoading, src } = useImage({ src: srcToLoad || defaultImage })

  if (isLoading || srcToLoad === 'KEEP_LOADING') return children || <LoadingImage />
  if (isError && defaultImage)
    return <Image alt={ALT_ERROR_IMAGE} srcToLoad={defaultImage} className={className} />

  return (
    <Fade className="h-100">
      <img alt={alt} className={className} src={src} />
    </Fade>
  )
}

export default Image
