import { Button, Card } from 'reactstrap'
import PropTypes from 'prop-types'
import RichText from 'Shared/RichText/RichText'
import './TypePayment.scss'

const TypePayment = ({ children, description, inFullText, isInFull, onClickInFull, onClickMultiplePayments,
                       multipleText, question }) => (
  <Card className="mt-3 p-3 type-payment paid">
    <p className="fw-bold">{question}</p>

    {description && <RichText className="me-md-5 pe-0 pe-lg-5" html={description} tag="p" />}

    <div>
      <Button active={isInFull} className="mb-2 me-3 btn-outline-blue px-5" color="none"
              data-test="cb-in-full-button" onClick={onClickInFull}>
        {inFullText}
      </Button>

      <Button active={!isInFull} className="mb-2 me-3 btn-outline-blue px-5" color="none"
              data-test="cb-in-multiple-button" onClick={onClickMultiplePayments}>
        {multipleText}
      </Button>

      {children}
    </div>
  </Card>
)

TypePayment.defaultProps = {
  description: '',
  inFullText: 'In Full',
  multipleText: 'Multiple Payments',
  question: 'How would you like to be paid?',
}

TypePayment.propTypes = {
  description: PropTypes.string,
  inFullText: PropTypes.string,
  isInFull: PropTypes.bool.isRequired,
  onClickInFull: PropTypes.func.isRequired,
  onClickMultiplePayments: PropTypes.func.isRequired,
  multipleText: PropTypes.string,
  question: PropTypes.string,
}

export default TypePayment
