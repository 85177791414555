import Loading from 'Shared/Loading'
import ModalFormContent from './ModalFormContent'
import RefundError from './RefundError'
import RefundSuccess from './RefundSuccess'

const ModalContent = ({ canReversePayout, installment, installmentsCount, installmentRefunded, isAdmin,
                        isRefundPage, onChangeCurrencies, onSubmit, refundLoading, refundError, refundFinished,
                        reservation, resourcesData, toggleFormModal }) => {
  const { id, isExternallyPaid, items, number, paymentMethod, paymentSource, platformFee, pricing,
          total } = installment || {}
  const { name: checkName } = reservation || {}
  const refundSuccess = installmentRefunded || refundFinished

  if (refundLoading) return <Loading className="text-center" />

  if (refundError) return <RefundError error={refundError} toggle={toggleFormModal} />

  if (refundSuccess) return <RefundSuccess {...{ installmentRefunded, isRefundPage }} toggle={toggleFormModal} />

  if (installment && !installmentRefunded)
    return <ModalFormContent {...{ canReversePayout, checkName, id, items, isAdmin, isExternallyPaid, number,
                              onChangeCurrencies, onSubmit, paymentMethod, paymentSource, platformFee, pricing,
                              resourcesData, toggleFormModal, total }} paymentsCount={installmentsCount} />
}

export default ModalContent
