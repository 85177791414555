import classNames from 'classnames'
import './BrandedHR.scss'

const BrandedHR = ({ className, opacity, width }) => {
  const classes = classNames('branded-hr w-100', className)

  return (
    <hr className={classes} style={{ borderWidth: `${width}px`, opacity }} />
  )
}

BrandedHR.defaultProps = {
  opacity: 1,
  width: 1,
}

export default BrandedHR
