import * as Yup from 'yup'

const requiredOnCardOnFile = { is: isCardOnFile => !isCardOnFile, then: Yup.string().required() }

const CARD_ON_FILE = 'cardOnFile'

const CreditCardPaymentFormSchema = Yup.object().shape({
  address: Yup.string()
    .when(CARD_ON_FILE, requiredOnCardOnFile),

  address2: Yup.string(),

  cardholderName: Yup.string()
    .when(CARD_ON_FILE, requiredOnCardOnFile),

  city: Yup.string()
    .when(CARD_ON_FILE, requiredOnCardOnFile),

  state: Yup.string()
    .when(CARD_ON_FILE, requiredOnCardOnFile),

  zip: Yup.string()
    .when(CARD_ON_FILE, requiredOnCardOnFile),
})

export default CreditCardPaymentFormSchema
