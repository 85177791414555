import cnames from 'classnames'
import pluralize from 'pluralize'
import { skeletonInstallments } from './skeletonInstallments'
import { Card, CardBody, Col, Row } from 'reactstrap'
import useWindowDimensions from 'Shared/Hooks/useWindowDimensions'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import InstallmentCard from './InstallmentCard'
import './InstallmentsToPay.scss'

const InstallmentsToPay = ({ data, loading, totalInstallments }) => {
  const { isDesktopView } = useWindowDimensions(1921)
  const containerClass = cnames('installments-to-pay shadow-sm mb-5', { 'skeleton-loading': loading })
  const rowClass = cnames({ 'w-75': isDesktopView })
  const installmentsPendingToPay = loading ? skeletonInstallments : data

  if (!totalInstallments) return null

  return (
    <Card className={containerClass}>
      <CardBody>
        <div className="d-flex text-blue align-items-baseline mb-3">
          <FontAwesomeIcon className="me-2 fs-4" icon="file-invoice-dollar" />

          <p className="mb-0">
            <span className="fw-bold fs-4 me-1">{totalInstallments}</span>

            {pluralize('Invoice', totalInstallments)}

            <small className="text-muted"> Ready to pay</small>
          </p>
        </div>

        <Row className={rowClass}>
          {installmentsPendingToPay.map(installment => (
            <Col className="installment col" key={`installment-${installment.id}`} xs="12" xl="4">
              <InstallmentCard installment={installment} />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default InstallmentsToPay
