import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from 'Shared/Hooks/useApolloClient'
import { DEFAULT_PER_PAGE } from 'Shared/BlackoutDates/constants'
import removeBlackoutDatesMutation from 'Shared/BlackoutDates/Queries/removeBlackoutDatesMutation'
import sitesBlackoutDates from 'Shared/BlackoutDates/Queries/sitesBlackoutDates'
import { Collapse } from 'reactstrap'
import Header from 'Shared/Admin/Header'
import Home from './Home'
import Summary from './Summary'

const App = () => {
  const [displaySites, setDisplaySites] = useState(true)
  const [geSitesData, { data, loading, error,
                        called }] = useLazyQuery(sitesBlackoutDates, { fetchPolicy: 'no-cache' })
  const [callRemoveBlackoutDates, { data: mutationData, error: mutationError,
                                    loading: mutationLoading }] = useMutation(removeBlackoutDatesMutation)
  const { campgroundSlug: slug, engineName } = window.roverData
  const isAdmin = engineName === 'admin'
  const calendarUrl = data?.options?.externalLinks.campgroundCalendar

  useEffect(() => {
    const bodFilters = { fromYesterday: true }
    const siteFilters = { blackoutDates: true }
    geSitesData({ variables: { bodFilters, isAdmin, perPage: DEFAULT_PER_PAGE, siteFilters, slug } })
  }, [])

  return (
    <>
      <Header title="Remove Blackout Dates" />

      <Collapse isOpen={displaySites}>
        <Home calledApi={called} callRemoveBlackoutDates={callRemoveBlackoutDates} changeCollapse={setDisplaySites}
              data={data} error={error} loading={loading} setDisplaySites={setDisplaySites} />
      </Collapse>

      <Collapse isOpen={!displaySites}>
        <Summary calendarUrl={calendarUrl} error={mutationError} loading={mutationLoading}
                 mutationData={mutationData} />
      </Collapse>
    </>
  )
}

export default App
