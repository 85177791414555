import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Col, Row } from 'reactstrap'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import FormTextInput from 'Shared/Formik/FormTextInput'
import PageSubtitle from 'Shared/PageSubtitle'
import StateDropDown from 'Shared/StateDropDown/StateDropDown'

const BillingAddress = ({ addressLabel, addressPlaceholder, className, disabled, isFloating, layoutColumn,
                          showZipCode, stateDropDownProps, title }) => {
  const classes = cnames('billing-address', className)
  const InputComponent = isFloating ? FloatingLabelInput : FormTextInput
  const firstFieldWidth = showZipCode ? 12 : layoutColumn.first

  const setStateAndCountry = (event, setFieldValue) => {
    const select = event.target

    setFieldValue('state', select.value)
    setFieldValue('country', select.querySelector(':checked').parentElement.label)
  }

  return (
    <div className={classes}>
      <PageSubtitle text={title} />

      <Row>
        <Col md={firstFieldWidth}>
          <InputComponent className="mb-3" disabled={disabled} label={addressLabel} name="address"
                          placeholder={addressPlaceholder} />
        </Col>

        <Col md={layoutColumn.second}>
          <InputComponent className="mb-3" disabled={disabled} label="Address 2 (Optional)" name="address2"
                          placeholder="Apt. A" />
        </Col>

        {showZipCode && (<Col md={layoutColumn.first}>
          <InputComponent className="mb-3" disabled={disabled} label="Enter Zip code" name="zip"
                          placeholder="Enter Zip code" />
        </Col>)}

        <Col md={layoutColumn.first}>
          <InputComponent className="mb-md-0 mb-3" disabled={disabled} label="City" name="city"
                          placeholder="Austin" />
        </Col>

        <Col md={layoutColumn.second}>
          <StateDropDown {...stateDropDownProps} className="mb-0 h-100" disabled={disabled} isFloating={isFloating}
                         onChange={setStateAndCountry} />
        </Col>

        <Col className="d-none">
          <InputComponent className="mb-md-0" disabled={disabled} label="Country" name="country"
                          placeholder="United States" />
        </Col>
      </Row>
    </div>
  )
}

BillingAddress.defaultProps = {
  addressLabel: 'Billing Address',
  addressPlaceholder: '123 Home Ln.',
  disabled: false,
  isFloating: false,
  layoutColumn: {
    first: 6,
    second: 6,
  },
  showZipCode: false,
  title: 'Billing Address',
}

BillingAddress.propTypes = {
  addressLabel: PropTypes.string,
  addressPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  isFloating: PropTypes.bool,
  layoutColumn: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number,
  }),
  showZipCode: PropTypes.bool,
  title: PropTypes.string,
}

export default BillingAddress
