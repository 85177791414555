import PropTypes from 'prop-types'
import cnames from 'classnames'
import { AlertWrapper } from 'Shared/Alert'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ToBanAlertContent from './ToBanAlertContent'
import ToUnbanAlertContent from './ToUnbanAlertContent'

const BanAlert = ({ buttonContainerClasses, classes, flagLoading, flexClasses, isBanned, learnMoreLink, toggle,
                    unbanSubtitleText }) => {
  const alertClasses = cnames('border border-danger p-3', classes, flexClasses)
  const buttonWrapperClasses = cnames('text-center mt-3 mt-md-0"', buttonContainerClasses)
  const icon = isBanned ? 'user-check' : 'user-slash'

  return (
    <AlertWrapper className={alertClasses} color="danger text-danger">
      <div>
        {isBanned ? (
            <ToUnbanAlertContent subtitle={unbanSubtitleText} />
          ) : (
            <ToBanAlertContent learnMoreLink={learnMoreLink} />
          )}
      </div>

      <div className={buttonWrapperClasses}>
        <Button color="salmon" disabled={flagLoading} onClick={toggle}>
          <FontAwesomeIcon className="me-2" color="danger" icon={icon} />
          {isBanned ? 'Unban Camper' : 'Ban Camper'}
        </Button>
      </div>
    </AlertWrapper>
  )
}

BanAlert.defaultProps = {
  buttonContainerClasses: '',
  classes: '',
  flexClasses: 'align-items-center d-md-flex justify-content-between',
}

BanAlert.propTypes = {
  buttonContainerClasses: PropTypes.string,
  classes: PropTypes.string,
  flagLoading: PropTypes.bool.isRequired,
  flexClasses: PropTypes.string,
  isBanned: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default BanAlert
