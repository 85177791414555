import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Button, Input } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './QuantityButtons.scss'

// TODO: Create a wrapper component to use the same functions as ScaleInput.jsx, use the example that is in
// app/javascript/src/Shared/FilterDropdown
const QuantityButtons = ({ count, enabled, helper, helperClasses, inputClasses, isRequired, label, labelClasses,
                           labelWrapper, max, min, name, onChange }) => {
  const [quantity, setQuantity] = useState(0)
  const INTBASE = 10
  const REDUCEQUANTITY = 1
  const classes = {
    input: cnames('text-center mx-2', inputClasses),
    label: cnames('d-flex mb-0 lh-base', labelClasses),
    labelWrapper: cnames('d-flex mb-2', labelWrapper, { 'justify-content-center': !labelWrapper }),
    helper: cnames('ms-1', helperClasses, { 'opacity-50': !helperClasses }),
  }

  const allowMoreQuantity = quantity < max && enabled
  const isLessQuantityDisabled = quantity === min

  const updateQuantity = value => {
    const parsedValue = parseInt(value, INTBASE)
    if (parsedValue >= min && parsedValue <= max) {
      setQuantity(parsedValue)
      onChange(parsedValue)
    }
  }

  const reduceAction = () => {
    let tempQuantity = quantity
    if (tempQuantity > 0) tempQuantity -= REDUCEQUANTITY
    if (tempQuantity === 0) tempQuantity = 0

    updateQuantity(tempQuantity)
  }

  const updateAction = () => {
    if (allowMoreQuantity) updateQuantity(quantity + 1)
  }

  const onEmptyQuantity = () => {
    if (quantity === 0) setQuantity('')
    if (quantity === '') setQuantity(0)
  }

  useEffect(() => {
    if (quantity !== count) setQuantity(count)
  }, [count])

  return (
    <div className="quantity-buttons">
      <div className={classes.labelWrapper}>
        <label className={classes.label} htmlFor={name}>
          {label}
          {isRequired && <span className="text-danger ms-1">*</span>}
        </label>

        {helper && <span className={classes.helper}>{helper}</span>}
      </div>

      <div className="d-flex">
        <Button className="ms-auto px-3 rounded-circle" color="quantity" onClick={reduceAction}
                type="button" disabled={isLessQuantityDisabled}>
          <FontAwesomeIcon icon="minus" />
        </Button>

        <Input className={classes.input} bsSize="lg" type="number" id={name} name={name} value={quantity}
               onChange={event => updateQuantity(event.target.value)}
               onFocus={() => { onEmptyQuantity() }} onBlur={() => { onEmptyQuantity() }} />

        <Button className="ms-auto px-3 rounded-circle" color="quantity" disabled={!allowMoreQuantity}
                onClick={updateAction} type="button">
          <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
    </div>
  )
}

QuantityButtons.defaultProps = {
  enabled: true,
  helper: null,
  label: '',
  max: Number.MAX_SAFE_INTEGER,
  min: 0,
}

QuantityButtons.propTypes = {
  count: PropTypes.number.isRequired,
  enabled: PropTypes.bool,
  helper: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default QuantityButtons
