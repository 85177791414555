import cnames from 'classnames'
import { Badge } from 'reactstrap'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CartButton = ({ itemsCount, onClick }) => {
  const disabled = itemsCount === 0
  const iconColor = cnames({ '#1F608A': !disabled })
  const titleAnchor = disabled ? 'Add a reservation to visit your Cart.' : ''

  return (
    <Anchor className="cart-button ms-auto py-0 text-muted" disabled={disabled} id="cart-button"
            onClick={onClick} title={titleAnchor}>
      <FontAwesomeIcon color={iconColor} icon="cart-shopping" size="lg" />

      {!disabled && (
        <Badge className="position-absolute translate-middle rounded-pill" color="action">
          {itemsCount}
        </Badge>
      )}
    </Anchor>
  )
}

export default CartButton
