const CampgroundRules = ({ campgroundRules }) => (
  <div className="not-loading">
    <h5 id="rules" className="fw-bold mb-2">Rules</h5>

    {campgroundRules.map(({ key, content, name }) => (
      <div className="mb-1" key={key}>
        <p className="fw-bold m-0">{name}</p>
        <p className="m-0">{content}</p>
      </div>
    ))}
  </div>
)

export default CampgroundRules
