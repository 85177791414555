const TableHeader = ({ isEditable }) => (
  <thead>
    <tr>
      {isEditable && <th className="col-1">{' '}</th>}
      <th className="col-1"><span className="h5">Qty.</span></th>
      <th className="col-md-7 col-lg-8"><span className="h5">Item</span></th>
      <th className="amount col-md-3 col-lg-2"><span className="h5">Amount</span></th>
    </tr>
  </thead>
)

export default TableHeader
