import * as Yup from 'yup'
import electricSchema from './electricSchema'

const messageRequired = 'Required field.'
const minCent = 'Min value is 0.01'
const minOne = 'Min value is 1'

const itemFormSchema = () => (
  Yup.object().shape({
    category: Yup.string()
      .required(messageRequired),

    name: Yup.string()
      .max(500, 'Max 500 Characters')
      .required(messageRequired),

    price: Yup.number()
      .min(0.01, minCent)
      .required(messageRequired),

    tax: Yup.number()
      .min(0, 'Min value is 0')
      .max(100, 'Max value is 100'),

    units: Yup.number()
      .when({
        is: category => category === 'custom',
        then: Yup.number()
          .min(1, minOne)
          .required(messageRequired),
      }),

    electric: Yup.object()
      .when({
        is: category => category === 'electric',
        then: electricSchema
          .required(messageRequired),
      }),
  })
)

export default itemFormSchema
