import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { amountCurrencyField } from 'Utils/Currency'
import ReservationDetailItem from 'Shared/ReservationDetailItem'

const SITE_LOCK_FEE = 'Site Lock Fee'

const ReservationAddons = ({ addons, boldText, className, classValue, displayIcon }) => {
  const sitesMapUrl = useSelector(state => state.campground.sitesMapUrl)

  return (
    <div className={className}>
      {addons.map(({ base, itemableType, name, units }, i) => {
        if (itemableType === 'discount' && base?.amount === 0) return null

        const textWeight = boldText ? 'fw-bold' : 'fw-normal'
        const showAmount = ['extra_fee', 'product'].includes(itemableType)
        const amount = showAmount && units
        const icon = SITE_LOCK_FEE === name && sitesMapUrl && displayIcon ? 'lock' : null
        const showFreeLabel = showAmount && amountCurrencyField(base) === 0
        const price = showFreeLabel ? 'FREE' : base?.display

        return (
          <ReservationDetailItem amount={amount} amountClass={`${textWeight} me-4`}
                                 containerClass="align-items-center d-flex" key={name} hideColon icon={icon}
                                 itemNameClass={`${textWeight} text-truncate`} itemName={name}
                                 idTooltipName={`addon-${i}`} value={price} valueClass={classValue} />
        )
      })}
    </div>
  )
}

ReservationAddons.defaultProps = {
  boldText: true,
  className: 'my-4',
  classValue: 'text-gray',
  displayIcon: true,
}

ReservationAddons.propTypes = {
  addons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    quantity: PropTypes.number,
  })).isRequired,
  boldText: PropTypes.bool,
  className: PropTypes.string,
  classValue: PropTypes.string,
  displayIcon: PropTypes.bool,
}

export default ReservationAddons
