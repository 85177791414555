import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { dateToISO, formatDate, today } from 'Utils/Dates'
import DatePickerSingle from './DatePickerSingle'
import 'react-dates/initialize'

const DatePickerSingleLocalStore = ({ date, displayFormat, id, inputId, inputName, isBlockBeforeToday,
                                      placeholder }) => {
  const [currentDate, setCurrentDate] = useState()
  const hiddenDateValue = formatDate(currentDate, { format: displayFormat })

  const blockPastDays = day => (isBlockBeforeToday && day.isBefore(today()))

  const onDateChange = value => setCurrentDate(dateToISO(value))

  useEffect(() => {
    setCurrentDate(dateToISO(date))

    return () => setCurrentDate(null)
  }, [])

  return (
    <>
      {(inputId || inputName) && (
        <input id={inputId || inputName} name={inputName || inputId} type="hidden"
               value={hiddenDateValue || ''} />
      )}

      <DatePickerSingle block date={currentDate} displayFormat={displayFormat} id={id}
                        isOutsideRange={blockPastDays} onDateChange={onDateChange}
                        placeholder={placeholder} readOnly showDefaultInputIcon />
    </>
  )
}

DatePickerSingleLocalStore.defaultProps = {
  date: null,
  displayFormat: 'MM/DD/YYYY',
  id: 'date-picker-single',
  inputId: null,
  inputName: null,
  isBlockBeforeToday: false,
  placeholder: 'MM/DD/YYYY',
}

DatePickerSingleLocalStore.propTypes = {
  date: PropTypes.string,
  displayFormat: PropTypes.string,
  id: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  isBlockBeforeToday: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default DatePickerSingleLocalStore
