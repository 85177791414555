import { Button } from 'reactstrap'

const CreateStripeCharge = ({ link, toggle }) => (
    <>
      <p>Are you sure you want to create a Stripe Charge and mark Invoice as paid?</p>

      <div className="d-flex justify-content-between">
        <Button color="secondary" onClick={toggle}>Cancel</Button>

        <Button color="salmon" onClick={link}>Ok</Button>
      </div>
    </>
  )

export default CreateStripeCharge
