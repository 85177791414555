import cnames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Anchor from 'Shared/Anchor/Anchor'
import './CampsiteTabs.scss'

const CampsiteTabs = ({ setShowList, showList }) => {
  const classesTab = 'campsite-tabs--tab d-flex align-items-center justify-content-center text-decoration-none'
  const classesListTab = cnames(classesTab, { 'campsite-tabs--active': showList })
  const classesMapTab = cnames(classesTab, { 'campsite-tabs--active': !showList })

  return (
    <div className="d-flex flex-row-reverse">
      <div className="campsite-tabs d-flex mb-2">
        <Anchor className={classesListTab} href="#list" onClick={() => setShowList(true)}>
          <FontAwesomeIcon className="fa-lg" icon="list" />
        </Anchor>

        <Anchor className={classesMapTab} href="#map" onClick={() => setShowList(false)}>
          <FontAwesomeIcon className="fa-lg" icon="location-dot" />
        </Anchor>
      </div>
    </div>
  )
}

CampsiteTabs.propTypes = {
  setShowList: PropTypes.func.isRequired,
  showList: PropTypes.bool.isRequired,
}

export default CampsiteTabs
