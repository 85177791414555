import PropTypes from 'prop-types'
import ItemsPerPage from './ItemsPerPage'
import Pagination from './Pagination'

const ControlsTable = ({ currentPage, itemsName, needsQueryUrl, pageName, pagesCount, perPage, totalCount,
                         updateCurrentPage, updatePerPage }) => {
  const handleUpdateCurrentPage = page => updateCurrentPage(page)

  const handleUpdatePerPage = perPage => updatePerPage(perPage)

  return (
    totalCount > 0 && (
      <div className="d-flex justify-content-center">
        {pagesCount > 1 && (
          <Pagination currentPage={currentPage} needsQueryUrl={needsQueryUrl} pageName={pageName}
                      pagesCount={pagesCount} updatePageSelected={handleUpdateCurrentPage} />
        )}

        <ItemsPerPage itemsName={itemsName} needsQueryUrl={needsQueryUrl} pageName={pageName} perPage={perPage}
                      totalCount={totalCount} updatePerPage={handleUpdatePerPage} />
      </div>
    )
  )
}

ControlsTable.defaultProps = {
  currentPage: 0,
  itemsName: 'itemsPerPage',
  needsQueryUrl: true,
  pageName: 'page',
  pagesCount: 0,
  perPage: 0,
  totalCount: 0,
  updateCurrentPage: null,
  updatePerPage: null,
}

ControlsTable.propTypes = {
  currentPage: PropTypes.number,
  itemsName: PropTypes.string,
  needsQueryUrl: PropTypes.bool,
  pageName: PropTypes.string,
  pagesCount: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  updateCurrentPage: PropTypes.func,
  updatePerPage: PropTypes.func,
}

export default ControlsTable
