import cnames from 'classnames'
import ReservationDetailItem from 'Shared/ReservationDetailItem'

const TaxDetails = ({ className, hideColon, isDisplayWithCode, nameClass, showText, tax, taxName, titleClass,
                      valueClass }) => {
  const classes = {
    name: cnames(nameClass),
    title: cnames(`mt-3 text-center ${titleClass}`),
    values: cnames(valueClass),
  }
  const taxToDisplay = isDisplayWithCode ? tax?.displayWithCode : (tax?.display || tax)

  return (
    <div className={className}>
      {showText && (<p className={classes.title}>{`All Prices are ${taxName} included`}</p>)}
      <ReservationDetailItem hideColon={hideColon} itemName={taxName} itemNameClass={classes.name}
                             value={taxToDisplay} valueClass={classes.values} />
    </div>
  )
}

TaxDetails.defaultProps = {
  hideColon: false,
  isDisplayWithCode: false,
  showText: true,
}

export default TaxDetails
