import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const Header = () => (
  <div className="cart-recurring--header">
    <div>
      <FontAwesomeIcon className="me-2" color="#2B84BF" icon="arrows-rotate" size="lg" />

      <span className="h6">Recurring Invoice Details</span>
    </div>

    <span className="smaller text-muted">This amount will be charged monthly.</span>
  </div>
)

export default Header
