import { gql } from 'Utils/apolloClient'

const reservationCheckInMutation = gql`
  mutation ReservationCheckIn($isEngineAdmin: Boolean!, $reservationId: ID!, $to: ISO8601Date!) {
    reservationCheckIn(input: { isEngineAdmin: $isEngineAdmin, reservationId: $reservationId, to: $to }) {
      success
    }
  }
`

export default reservationCheckInMutation
