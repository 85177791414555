import { Button } from 'reactstrap'
import cnames from 'classnames'

const DayButton = ({ dayNumber, isSelected, onClick, value }) => {
  const className = cnames('', { active: isSelected })

  return (
    <Button className={className} onClick={() => onClick(dayNumber)}>
      {value}
    </Button>
  )
}

export default DayButton
