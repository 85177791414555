import PropTypes from 'prop-types'
import CollapseWrapper from 'Shared/Collapse/CollapseWrapper'
import { ContentFooter, SimpleRowItem } from 'Shared/Cart/Components'
import InstallmentPaid from '../Components/InstallmentPaid'
import RefundedAmountRows from '../Components/RefundedAmountRows'

const InstallmentsPaidContainer = ({ handleOnClick, helpTextTotal, installmentsPaid, isOpenByDefault,
                                     isTaxIncluded, paymentInitial, totalPaid }) => {
  const itemClass = 'ps-2 text-smaller-1'
  const classContent = 'text-gray'
  const labelTotal = isTaxIncluded ? `Total ${helpTextTotal}` : 'Total'

  return (
    <CollapseWrapper className="border-top border-bottom" handleOnClick={handleOnClick}
                     isOpenByDefault={isOpenByDefault}>
      <CollapseWrapper.Header title="Previously Paid">
        <p className="mb-0 fw-bold">{totalPaid}</p>
      </CollapseWrapper.Header>

      <CollapseWrapper.Content className="previously-paid w-100 pb-3">
        <div className="d-flex flex-column gap-1">
          {paymentInitial && (
            <InstallmentPaid title="Initial Payment">
              <SimpleRowItem classContent={classContent} className={itemClass} label={labelTotal}
                             value={paymentInitial?.total?.displayWithCode} />

              {paymentInitial?.totalRefunded.amount > 0 && (
                <RefundedAmountRows itemClass={itemClass}
                                    totalRefunded={paymentInitial?.totalRefunded?.displayWithCode}
                                    totalRemaining={paymentInitial?.totalRemaining?.displayWithCode} />
              )}
            </InstallmentPaid>
          )}

          {installmentsPaid.map(item => (
            <InstallmentPaid key={item.id} title={`Payment ${item.number}`}>
              <SimpleRowItem classContent={classContent} className={itemClass} label="Installment Amount"
                             value={item.amount?.display} />

              <SimpleRowItem classContent={classContent} className={itemClass} label="Processing Fee"
                             value={item.processingFeeTotal?.display} />

              <SimpleRowItem classContent={classContent} className={itemClass} label="Platform Fee"
                             value={item.platformFee?.display} />

              {!isTaxIncluded && (
                <SimpleRowItem classContent={classContent} className={itemClass} label="Tax"
                               value={item.tax?.display} />
              )}

              <SimpleRowItem className="mt-2 ps-2 text-dark" label={labelTotal}
                             value={item.total.displayWithCode} />

              {item?.totalRefunded?.amount > 0 && (
                <RefundedAmountRows itemClass={itemClass} totalRefunded={item.totalRefunded.display}
                                    totalRemaining={item.totalRemaining.display} />
              )}
            </InstallmentPaid>
          ))}

          <ContentFooter name="Previously Paid Total" value={totalPaid} />
        </div>
      </CollapseWrapper.Content>
    </CollapseWrapper>
  )
}

InstallmentsPaidContainer.defaultProps = {
  installmentsPaid: null,
  isTaxIncluded: false,
  paymentInitial: null,
}

InstallmentsPaidContainer.propTypes = {
  installmentsPaid: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isTaxIncluded: PropTypes.bool,
  paymentInitial: PropTypes.shape({
    amount: PropTypes.shape({
      display: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
  totalPaid: PropTypes.string.isRequired,
}

export default InstallmentsPaidContainer
