import { useFormikContext } from 'formik'
import { Label } from 'reactstrap'
import Anchor from 'Shared/Anchor/Anchor'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import RichText from 'Shared/RichText/RichText'

const RichTextWithFormik = ({ label, labelClasses, name, onClickLabel }) => {
  const { values } = useFormikContext()
  const value = values[name]

  return (
    <>
      {label && (
        <Label className={labelClasses} for={name}>
          {label}

          <Anchor className="edit-rich-text-link" href="edit-item" onClick={onClickLabel}>
            <FontAwesomeIcon className="text-facebook ms-2" icon={['far', 'pen-to-square']} size="lg" />
          </Anchor>
        </Label>
      )}

      <RichText html={value} />
    </>
  )
}

export default RichTextWithFormik
