import { gql } from 'Utils/apolloClient'

const reservationQuery = gql`
  query ReservationQuery($engineName: String, $slug: String!, $uuid: ID!) {
    campground(slug: $slug) {
      reservation(uuid: $uuid) {
        links {
          reservationParentUrl(engine: $engineName)
        }
        reservationsWithParent {
          code
          from
          id
          installmentFirst {
            total {
              display
            }
          }
          isParent
          links {
            detailsUrl(engine: $engineName)
            paymentsUrl(engine: $engineName)
          }
          site {
            id
            name
          }
          state
          to
          uuid
        }
      }
    }
  }`

export default reservationQuery
