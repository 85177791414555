const CheckInNotice = ({ balance }) => {
  const hasBalanceDue = balance.due?.amount > 0

  return (
    <div>
      {hasBalanceDue && (
        <p className="text-md text-cardinal m-0">This reservation has a balance due.</p>
      )}

      <p className="text-md mb-4">Are you sure you want to check in the guest(s)?</p>
    </div>
  )
}

export default CheckInNotice
