import { Button } from 'reactstrap'
import { FormCardElement } from 'Shared/Stripe'
import BillingAddress from 'Shared/BillingAddress'
import CreditCardFormSchema from './CreditCardFormSchema'
import FormCheckbox from 'Shared/Formik/FormCheckbox'
import FormTextInput from 'Shared/Formik/FormTextInput'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import Loading from 'Shared/Loading'
import SuccessErrorAlert from 'Shared/Alert/SuccessErrorAlert'
import './CreditCardForm.scss'

const CreditCardForm = ({ error, handleOnSubmit, onChangeCardElement, processing, tokenErrors }) => {
  const initialFormValues = { address: '', address2: '', cardholderName: '', city: '', defaultPaymentMethod: false,
                              state: '' }
  return (
    <>
      {error && <SuccessErrorAlert error={error} />}

      <FormWithValidations className="payment-method-form" disableRenderProps initialValues={initialFormValues}
                           onSubmit={handleOnSubmit} scrollToError validationSchema={CreditCardFormSchema}>
        <>
          <div className="m-auto mb-2 p-2 pt-1">
            <p className="fs-5 fw-bold">Card Details</p>

            <FormTextInput label="Name On Card" name="cardholderName" disabled={processing} placeholder="Name" />

            <FormCardElement className="mb-4" disabled={processing} label="Card" errors={tokenErrors}
                             labelClassName="fw-normal" onChange={onChangeCardElement} />

            <BillingAddress addressLabel="Address" addressPlaceholder="Address"
                            className="py-4 border-top border-bottom" disabled={processing}
                            layoutColumn={{ first: 7, second: 5 }}
                            stateDropDownProps={{ defaultMessage: 'Select' }} title="Address Details" />

            <FormCheckbox className="mt-2" disabled={processing} id="defaultPaymentMethod"
                          message="Set as Default Payment Method" name="defaultPaymentMethod" />
          </div>

          <div className="d-flex justify-content-end mb-3">
            <Button className="col-12 col-md-3 fw-bold" color="blue-dark" type="submit" disabled={processing}>
              {processing ? <Loading iconClass="text-white" /> : 'Save' }
            </Button>
          </div>
        </>
      </FormWithValidations>
    </>
  )
}

export default CreditCardForm
