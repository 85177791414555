import { SimpleRowItem } from 'Shared/Cart/Components'

const RefundedAmountRows = ({ itemClass, totalRefunded, totalRemaining }) => (
  <>
    <SimpleRowItem classContent="text-danger text-small" className={itemClass} label="Refunded"
                   value={totalRefunded} />

    <SimpleRowItem classContent="text-danger text-small" className={itemClass} label="Remaining Total"
                   value={totalRemaining} />
  </>
)

export default RefundedAmountRows
