import { gql } from 'Utils/apolloClient'

const countriesQuery = gql`
  query statesPerCountry {
    countries {
      name
      states {
        code
        name
      }
    }
  }`

export default countriesQuery
