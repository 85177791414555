import { titleize } from 'Utils/Text'
import { formatToday } from 'Utils/Dates.js'
import CurrencyInput from './TwoColumnsCurrency'
import FormSelect from 'Shared/Formik/FormSelect'
import FormTextArea from 'Shared/Formik/FormTextInput'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'
import CheckRefund from './CheckRefund'
import RefundMethodSelector from './RefundMethodSelector'

const COPY = 'By creating this refund, you acknowledge your responsibility to manually refund this traveler. This process is only for keeping your RoverPass records and reports up to date.'

const paymentData = lastFour => ({
  ach: `(ACH) Bank Account ending in ${lastFour}`,
  card: `Card ending in ${lastFour}`,
  cash: 'Cash',
  check: 'Check',
})

const ModalContentInputs = ({ amount, canReversePayout, checkName, isAdmin, isExternallyPaid, paymentMethod,
                              paymentSource, pricing, resourcesData, values }) => {
  const { camperFeeMaxToRefund, camperProcessingFeeMaxToRefund, grandTotalMax,
          reversePayoutAmountMax } = pricing
  const reversePayout = { total: reversePayoutAmountMax.amount }
  const refundMethodOptions = resourcesData.refundMethodOptions?.map(value => ({ value, label: titleize(value) }))
  const reasonOptions = resourcesData.refundReasons.map(value => ({ value, label: titleize(value) }))
  const targetNames = { camperFee: 'amount.camperFee', camperProcessingFee: 'amount.camperProcessingFee',
                        grandTotal: 'amount.grandTotal', number: 'number', reason: 'reason',
                        refundMethod: 'refundMethod', reversePayout: 'reversePayout.total' }
  const paymentMethodSelected = paymentData(paymentMethod?.lastFour)[paymentSource]

  return (
    <div className="modal-inputs-section p-2 pb-0">
      <div className="d-flex justify-content-between mb-1">
        Available for Refund

        <span>{grandTotalMax.display}</span>
      </div>

      <CurrencyInput debouncedTime={0} id={targetNames.grandTotal} label="Refund Amount" max={amount?.grandTotal}
                     name={targetNames.grandTotal} />

      {isExternallyPaid && (
        <>
          <RefundMethodSelector name={targetNames.refundMethod} options={refundMethodOptions} />

          {values.refundMethod === 'check' && (
              <CheckRefund date={formatToday()} name={targetNames.number} checkName={checkName} />
          )}
        </>
      )}

      <div className="d-flex justify-content-between align-items-center mb-3">
        <span>Payment Method</span>

        <span className="text-gray-700">{paymentMethodSelected}</span>
      </div>

      {isAdmin && (
        <>
          {camperFeeMaxToRefund.amount > 0 && (
            <CurrencyInput debouncedTime={0} id="camperFee" max={camperFeeMaxToRefund.amount}
                           name={targetNames.camperFee}>
              <span className="d-block">Guest Fee Refund</span>

              Max: <span className="fw-normal">{camperFeeMaxToRefund.display}</span>
            </CurrencyInput>
          )}

          {camperProcessingFeeMaxToRefund.amount > 0 && (
            <CurrencyInput debouncedTime={0} id="camperProcessingFee" max={camperProcessingFeeMaxToRefund.amount}
                           name={targetNames.camperProcessingFee}>
              <span className="d-block">Guest Processing Fee Refund</span>

              Max: <span className="fw-normal">{camperProcessingFeeMaxToRefund.display}</span>
            </CurrencyInput>
          )}
        </>
      )}

      <hr className="text-muted" />

      <FormSelect className="row mb-3" defaultMessage="Select a Reason" id="reason" inputWrapperClass="col-md-4"
                  label="Reason" labelClasses="col-md-8 fw-bold mb-0" name={targetNames.reason}>
        <SelectOptions data={reasonOptions} />
      </FormSelect>

      <FormTextArea className="mb-3" label="Add a memo (Optional)" labelClasses="fw-bold" max={500}
                    name="reasonText" placeholder="Type memo here..." type="textarea" rows={1} showErrors />

      {isAdmin && canReversePayout && (
        <CurrencyInput id="reversePayoutTotal" label={`Reverse Transfer Max: ${reversePayoutAmountMax.display}`}
                        max={reversePayout.total} name={targetNames.reversePayout} />
      )}

      {isExternallyPaid && <p className="mt-2 text-muted">{COPY}</p>}
    </div>
  )
}

export default ModalContentInputs
