import RichText from 'Shared/RichText/RichText'
import Utility from './Utility'

const KnowBeforeYourStay = ({ utilities, whatToKnow }) => {
  const { amenitiesCode, gateCode, wifiNetwork, wifiPassword } = utilities || {}
  const utilitiesItems = {
    amenitiesItems: [{ text: amenitiesCode }],
    gateItems: [{ text: gateCode }],
    wifiItems: [{ helperText: 'Network', text: wifiNetwork }, { helperText: 'Password', text: wifiPassword }],
  }

  return (
    <div className="mb-3 not-loading">
      <h5 className="fw-bold mb-3">What To Know Before Your Stay</h5>
      {!!whatToKnow && (<RichText html={whatToKnow} />)}

      {!!gateCode && (
        <Utility icon="door-open" id="gateCode" items={utilitiesItems.gateItems} title="Gate Code" />
      )}

      {(!!wifiNetwork || !!wifiPassword) && (
        <Utility icon="wifi" id="wifi" items={utilitiesItems.wifiItems} title="Wi Fi" />
      )}

      {!!amenitiesCode && (
        <Utility icon="key" id="amenitiesCode" items={utilitiesItems.amenitiesItems} title="Amenities Key" />
      )}
    </div>
  )
}

export default KnowBeforeYourStay
