import Loading from 'Shared/Loading'
import ModalFormContent from 'Shared/RefundFormModal/ModalContent/ModalFormContent'
import RefundError from 'Shared/RefundFormModal/ModalContent/RefundError'
import RefundSuccess from './RefundSuccess'

const ModalContent = ({ isAdmin, onChangeCurrencies, onSubmit, payment, purchaseRefunded, refundError,
                        refundFinished, refundLoading, resourcesData, toggleModal }) => {
  const refundSuccess = purchaseRefunded || refundFinished
  const checkName = payment.reservation?.name || '-'
  const { canReversePayout, id, isExternal, paymentMethod, paymentType: paymentSource, pricing,
          total } = payment || {}

  if (refundLoading) return <Loading className="text-center" />

  if (refundError) return <RefundError error={refundError} toggle={toggleModal} />

  if (refundSuccess) return <RefundSuccess purchaseRefunded={purchaseRefunded} toggle={toggleModal} />

  if (payment && !purchaseRefunded)
    return <ModalFormContent {...{ canReversePayout, checkName, id, isAdmin, onChangeCurrencies, onSubmit,
                              paymentMethod, paymentSource, pricing, resourcesData, total }}
                              isExternallyPaid={isExternal} number={1} paymentsCount={1}
                              toggleFormModal={toggleModal} />
}

export default ModalContent
