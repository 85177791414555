import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import SendToGuestContent from './SendToGuestContent'

const SendToGuestModal = ({ closeModal, error, isCompletePayment, isOpen, link, loading, requestPayment,
                            resTaxDetails, toggle, values, upfrontType }) => {
  const showWarning = !(upfrontType === 'all' || upfrontType === 'invoices')

  return (
    <Modal centered isOpen={isOpen} size="md" toggle={toggle}>
    <ModalHeader toggle={toggle}>Send to Guest</ModalHeader>

    <ModalBody className="p-3">
      <SendToGuestContent {...{ closeModal, error, isCompletePayment, link, loading, requestPayment,
                                resTaxDetails, showWarning, toggle, values }} />
    </ModalBody>
  </Modal>
  )
}

SendToGuestModal.defaultProps = {
  isCompletePayment: false,
  loading: false,
}

SendToGuestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isCompletePayment: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  requestPayment: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Array).isRequired,
}

export default SendToGuestModal
