import { gql } from 'Utils/apolloClient'

const PictureUpdateMutation = gql`
  mutation PictureUpdate($params: PictureUpdateInput!) {
    pictureUpdate(input: $params) {
      success
    }
  }`

export default PictureUpdateMutation
