import { useEffect, useRef } from 'react'

export const useEffectAfterMount = (callback, deps) => {
  const initialRender = useRef(true)

  useEffect(() => {
    let cleanerFunction

    if (initialRender.current) initialRender.current = false
    else cleanerFunction = callback()

    if (cleanerFunction && typeof cleanerFunction === 'function') return cleanerFunction
  }, deps)
}
