import PropTypes from 'prop-types'
import CollapseItem from './CollapseItem'
import CartPayment from './CartPayment'

const CartReservationPayments = ({ breakDown, payments, title, total }) => (
  <CollapseItem title={title} value={total?.display}>
    {payments.map(({ number, processingFeeTotal, taxTotal, total }) => (
      <div key={`payment_${number}`}>
        <CartPayment breakDown={breakDown} classTitle="px-2" processingFeeTotal={processingFeeTotal?.display}
                     taxTotal={taxTotal?.display} titleLabel={`Payment ${number} (Including Invoice Fees)`}
                     titleValue={total?.display} />
      </div>
    ))}
  </CollapseItem>
)

CartReservationPayments.defaultProps = {
  breakDown: false,
  payments: [],
}

CartReservationPayments.propTypes = {
  breakDown: PropTypes.bool,
  payments: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number,
    processingFeeTotal: PropTypes.shape({
      display: PropTypes.string,
    }),
    taxTotal: PropTypes.shape({
      display: PropTypes.string,
    }),
    total: PropTypes.shape({
      display: PropTypes.string,
    }),
  })),
  title: PropTypes.string.isRequired,
  total: PropTypes.shape({
    amount: PropTypes.number,
    display: PropTypes.string,
  }).isRequired,
}

export default CartReservationPayments
