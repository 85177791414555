import { gql } from 'Utils/apolloClient'

const discountsQuery = gql`
  query Discounts($from: ISO8601Date!, $isPos: Boolean, $rateIds: [ID!]!, $slug: String!,
                  $to: ISO8601Date!) {
    campground(from: $from, isPos: $isPos, slug: $slug, to: $to) {
      id
      discountClubsForRates(ids: $rateIds) {
        id
        ratePercent
        name
        isNumberRequired
      }
    }
  }`

export default discountsQuery
