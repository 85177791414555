import { useMemo } from 'react'
import { useQuery } from 'Shared/Hooks/useApolloClient'
import websiteQuery from 'Shared/VerifyWebsite/Queries/WebsiteQuery'
import { buildUrlFromDomain, getVariations } from 'Shared/VerifyWebsite/Utils/Utils'
import { useSecureWebsite } from 'Shared/VerifyWebsite/Hooks/useSecureWebsite'
import { AlertWrapper } from 'Shared/Alert'
import { Button } from 'reactstrap'
import IconStatus from 'Shared/VerifyWebsite/Components/IconStatus/IconStatus'
import Link from 'Shared/Link/Link'
import MsCountDown from 'Shared/VerifyWebsite/Components/MsCountDown/MsCountDown'

const SecureStatus = ({ canSecure, domain, isAdmin }) => {
  const { data, error, loading, refetch } = useQuery(websiteQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { domain },
  })
  const { isSecure } = data?.website || {}
  const { error: errorSecure, isCompleted, isLoading, secureWebsite } = useSecureWebsite(domain)
  const text = useMemo(() => {
    if (loading) return 'Creating the Secure Certificate, please check again in a few seconds.'
    if (error) return 'Invalid Certificate!'

    return 'Secured!'
  }, [error, loading])
  const variations = useMemo(() => getVariations(domain), [domain])
  const url = buildUrlFromDomain(domain)

  const onRefetch = () => {
    if (isLoading) return

    refetch()
  }

  const getSecureBtnText = () => {
    if (isLoading) return 'Securing...'
    if (isCompleted) return 'Completed'

    return 'Secure!'
  }

  return (
    <div className="d-flex flex-column">
      <span className="card-subtitle mb-2 fw-bold">
        <IconStatus isCorrect={isSecure} isError={error} isLoading={loading} />

        {text}

        {!loading && error && <MsCountDown refetch={onRefetch} /> }
      </span>

      {isAdmin && !loading && error && !isSecure && (
        <AlertWrapper className="certificate-error" color="danger">
          <p className="mb-1">ERROR messsage:</p>
          {error.graphQLErrors[0]?.message}
        </AlertWrapper>
      )}

      {!isLoading && errorSecure && !isSecure && (
        <AlertWrapper color="danger">
          {errorSecure}
        </AlertWrapper>
      )}

      {!isLoading && !errorSecure && isCompleted && (
        <AlertWrapper color="success">
          Request to add certificate completed!
        </AlertWrapper>
      )}

      {error && canSecure && (
        <div>
          <Button color="primary" disabled={isLoading || isCompleted} onClick={secureWebsite}>
            {getSecureBtnText()}
          </Button>
        </div>
      )}

      {!loading && isSecure && (
        <>
          <Link className="mb-2" id="url" isExternal target="_blank" to={url}>{url}</Link>

          <span>Other variations that may need extra setup:</span>

          <ul id="variations">
            {variations.map(variation => (
              <li key={variation}>
                <Link isExternal target="_blank" to={variation}>{variation}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default SecureStatus
