import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip as TooltipReactstrap } from 'reactstrap'

const Tooltip = ({ autoHide, children, idTarget, placement, value }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const modifiers = [{ name: 'preventOverflow', options: { boundary: 'window' } }]

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <TooltipReactstrap autohide={autoHide} isOpen={tooltipOpen} modifiers={modifiers} placement={placement}
                       target={idTarget} toggle={toggleTooltip}>
      {children || value}
    </TooltipReactstrap>
  )
}

Tooltip.defaultProps = {
  autoHide: true,
  placement: 'auto',
  value: '',
}

Tooltip.propTypes = {
  autoHide: PropTypes.bool,
  idTarget: PropTypes.string.isRequired,
  placement: PropTypes.string,
  value: PropTypes.string,
}

export default Tooltip
