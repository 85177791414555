import { useState } from 'react'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import DoubleCheckRefundModal from 'AdminRefund/Components/RefundForm/DoubleCheckRefundModal'
import 'Styles/Button.scss'

const RefundPendingButton = ({ number, refundsPending }) => {
  const [showModal, setShowModal] = useState(false)
  const toggle = () => setShowModal(prevState => !prevState)

  return (
    <>
      {showModal && <DoubleCheckRefundModal {...{ number, refundsPending, toggle }} isOpen={showModal} />}

      <Button className="no-line w-100 p-0 l-m-r-half" color="link" onClick={toggle}>
        <FontAwesomeIcon className="me-1" icon="reply" />

        Refund
      </Button>
    </>
  )
}
export default RefundPendingButton
