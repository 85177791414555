import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './Balance.scss'

const Payments = ({ payments }) => {
  const { installmentsCount, installmentsDueCount, installmentsPaidCount, installmentsPendingCount,
          installmentsProcessingCount } = payments

  const classnamesDue = installmentsDueCount > 0 ? 'text-due' : 'text-muted'

  return (
    <div className="payments col-md-5 col-xl-auto">
      <div className="payment-card border rounded shadow-sm p-2 h-100">
        <p className="fw-bold text-uppercase mb-1">
          <FontAwesomeIcon className="me-1 text-muted" icon="file-invoice" />
          Payments
        </p>

        <p className="mb-1 fw-bold">{installmentsPendingCount}
          <small className="fw-lighter text-muted m-1"> Remaining</small>
        </p>

        <div className="d-flex justify-content-between">
          <small className="text-muted">Total: <b>{installmentsCount}</b></small>

          <small className="text-muted mx-3">Paid: <b>{installmentsPaidCount}</b></small>

          {installmentsProcessingCount > 0 && !installmentsDueCount ? (
            <small className="text-muted">Processing: <b>{installmentsProcessingCount}</b></small>
          ) : (
            <small className={classnamesDue}>Due: <b>{installmentsDueCount}</b></small>
          )}
        </div>
      </div>
    </div>
  )
}

export default Payments
