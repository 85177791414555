import { gql } from 'Utils/apolloClient'

const sitesQuery = gql`
  query Sites($isAdmin: Boolean!, $slug: String!) {
    campground(slug: $slug) {
      closeSeasonDates
      id
      sites {
        id
        name
      }
    }
    options {
      externalLinks {
        blackoutDatesRemove(isAdmin: $isAdmin, slug: $slug)
      }
    }
  }`

export default sitesQuery
