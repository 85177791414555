const DANGER = 'danger'
const LIGHT_BLUE = 'light-blue'
const REFUND_CONTENT_STATUS = { color: DANGER, text: 'Will be Refund' }

export default {
  scheduled: {
    color: LIGHT_BLUE,
    text: 'Scheduled',
  },
  sent: {
    color: LIGHT_BLUE,
    text: 'Sent',
  },
  due: {
    color: LIGHT_BLUE,
    text: 'Due',
  },
  overdue: {
    color: LIGHT_BLUE,
    text: 'Past Due',
  },
  paid: {
    color: 'success',
    text: 'Paid',
  },
  voided: {
    color: DANGER,
    text: 'Voided',
  },
  edited: {
    color: LIGHT_BLUE,
    text: 'Updated and Resent',
  },
  to_refund: REFUND_CONTENT_STATUS,
  to_refund_full: REFUND_CONTENT_STATUS,
  to_void: {
    color: DANGER,
    text: 'Will be Voided',
  },
}
