import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Tooltip from 'Shared/Tooltip'

const ReservationDetailItem = ({ amount, amountClass, containerClass, hideColon, icon, idTooltipName, itemName,
                                 itemNameClass, value, valueClass }) => {
  const classes = {
    amount: cnames('flex-shrink-0 ms-1', amountClass),
    container: cnames('not-all-block-loading d-flex mb-1', containerClass),
    name: cnames(itemNameClass),
    value: cnames('ms-auto text-end', valueClass),
  }
  const displayValue = value?.display || value
  const showItemName = hideColon ? itemName : `${itemName}:`

  return (
    <p className={classes.container}>
      <span id={idTooltipName} className={classes.name} title={itemName}>{showItemName}</span>

      {icon && <FontAwesomeIcon className="ms-1 text-salmon" icon={icon} />}

      {idTooltipName && <Tooltip idTarget={idTooltipName} value={itemName} />}

      {amount && <span className={classes.amount}>{`x ${amount}`}</span>}
      <span className={classes.value}>
        {displayValue}
      </span>
    </p>
  )
}

ReservationDetailItem.defaultProps = {
  itemNameClass: 'fw-bold',
}

export default ReservationDetailItem
