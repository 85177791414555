import { Button, ModalBody } from 'reactstrap'
import Modal from 'Shared/ModalCustomClose'

const ConfirmSingleCancelModal = ({ campgroundName, chatUrl, isOpen, onConfirm, toggle }) => (
  <Modal className="confirm-single-cancel-modal salmon-header-modal red-header" isOpen={isOpen} size="lg"
         title="Are you sure you want to cancel?" toggle={toggle}>
    <ModalBody className="text-grey-800">
      <p className="mt-1">
        If you only need to adjust the dates, but would still like to have a reservation at

        <strong className="ms-1">{campgroundName}</strong>,

        please reach out to the campground.
      </p>

      <div className="d-flex justify-content-between mb-1 mt-4 pt-2">
        <a className="btn btn-outline-gray-800" href={chatUrl}>
          No, Request a change instead.
        </a>

        <Button color="red" data-test="cancel-button" onClick={onConfirm}>
          Yes, I want to cancel.
        </Button>
      </div>
    </ModalBody>
  </Modal>
)

export default ConfirmSingleCancelModal
