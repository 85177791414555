import { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import CollapseHeader from './CollapseHeader'
import ReservationBasketItem from './ReservationBasketItem'

const ReservationFees = ({ reservationPricing }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <CollapseHeader className="fees" handleToggle={toggle} isOpen={isOpen} title="Fees"
                      value={reservationPricing?.camperFee?.display} />

      <Collapse isOpen={isOpen}>
        <ReservationBasketItem label="Guest" value={reservationPricing?.camperFee?.display} />
      </Collapse>
    </>
  )
}


ReservationFees.defaultProps = {
  reservation: null,
  reservationPricing: null,
}

ReservationFees.propTypes = {
  reservation: PropTypes.shape({}),
  reservationPricing: PropTypes.shape({}),
}

export default ReservationFees
