const ToUnbanAlertContent = ({ subtitle, title }) => (
  <>
    <p className="fw-bold">{title}</p>

    <p className="mb-0">{subtitle}</p>
  </>
)

ToUnbanAlertContent.defaultProps = {
  title: 'This Camper is Banned',
  subtitle: 'You cannot create new reservations or apply more flags for banned campers.',
}

export default ToUnbanAlertContent
