import 'Shared/timemachine'
import 'react-dates/initialize'
import { getElementDataSet } from 'Utils/Elements'

import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'

const AddBlackoutDates = () => {
  const { engineName, slug: campgroundSlug, timezone, token } = getElementDataSet('blackout-dates-data-container')
  window.roverData = { campgroundSlug, timezone, token }
  const isAdmin = engineName === 'admin'

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper isAdmin={isAdmin} slug={campgroundSlug} />
    </ApolloProvider>
  )
}

export default AddBlackoutDates
