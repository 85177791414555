import { useEffect, useState } from 'react'
import { canBeUnCheck, createInitialSelectedByNigthsBookTogether,
         daysOfWeek, MAX, MIN } from 'Shared/NightlyConfigurationLegacy/Utils/utils'
import CheckDay from './CheckDay'
import 'AdminRateEditor/Components/SitePlans/PricePeriodModal/Form/NightlyConfiguration/CheckDays/CheckDays.scss'

const CheckDays = ({ dailyPrices, nigthsBookTogether, updateNightsBookTogether }) => {
  const [selecteds, setSelecteds] = useState([])

  const handleSelected = (checked, position) => {
    setSelecteds(prev => {
      if (checked) return [...prev, position].sort()
      if (canBeUnCheck(position, selecteds)) return prev.filter(value => value !== position)

      return prev
    })
  }

  useEffect(() => {
    const { startsOn, endsOn } = nigthsBookTogether
    if (startsOn || endsOn)
      setSelecteds(createInitialSelectedByNigthsBookTogether({ startsOn, endsOn }))
  }, [])

  useEffect(() => {
    if (selecteds.length === daysOfWeek.length) updateNightsBookTogether({ endsOn: MAX, startsOn: MIN })
  }, [selecteds])

  return (
    <div className="nightly-configuration--checkdays-group">
      {daysOfWeek.map(day => {
        const { value } = day
        const id = `period[nbt_${value}]`

        return <CheckDay {...{ dailyPrices, handleSelected, id, selecteds, updateNightsBookTogether, ...day }}
                         key={id} />
      })}
    </div>
  )
}

CheckDays.defaultProps = {
  nigthsBookTogether: {},
}

export default CheckDays
