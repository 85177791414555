import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Col, Row } from 'reactstrap'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import TelephoneCodes from './TelephoneCodes.json'
import FormSelect from 'Shared/Formik/FormSelect'
import SelectOptions from 'Shared/SelectOptions/SelectOptions'
import FormTextInput from 'Shared/Formik/FormTextInput'

const TelephoneInput = ({ isFloating, labelClasses, nameScope, sizeInput }) => {
  const classesSelect = cnames({ 'floating-label-height type-select': isFloating })
  const countryCodeLabel = isFloating ? '' : 'Country code'
  const InputComponent = isFloating ? FloatingLabelInput : FormTextInput
  const props = { labelClasses, sizeInput }
  const xsCountryCode = isFloating ? 3 : 4
  const xsPhoneInput = isFloating ? 9 : 8

  return (
    <Row>
      <Col className="pe-2" xs={xsCountryCode}>
        <FormSelect {...props} id="countryCode" inputClasses={classesSelect} label={countryCodeLabel}
                    name={`${nameScope}phone.country`} showDefaultValue={false} showErrors>
          <SelectOptions data={TelephoneCodes} />
        </FormSelect>
      </Col>

      <Col className="ps-0" xs={xsPhoneInput}>
        <InputComponent {...props} className="flex-fill" isRequired label="Phone" max={10}
                        name={`${nameScope}phone.number`} placeholder="Phone" showErrors />
        </Col>
    </Row>
  )
}

TelephoneInput.defaultProps = {
  isFloating: false,
  labelClasses: '',
  nameScope: '',
  sizeInput: '',
}

TelephoneInput.propTypes = {
  isFloating: PropTypes.bool,
  labelClasses: PropTypes.string,
  nameScope: PropTypes.string,
  sizeInput: PropTypes.string,
}

export default TelephoneInput
