import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import FormTextInput from 'Shared/Formik/FormTextInput'

const TOOLTIP = 'Find this number at the top right of the check'

const CheckRefund = ({ checkName, date, name }) => (
  <Row className="bg-light p-3 pb-0 mb-3 rounded-3" id="check-info">
    <Col className="p-0" md={2}>
      <span className="text-muted d-block mb-1">Dated On</span>

      <span>{date}</span>
    </Col>

    <Col className="p-0" md={6}>
      <span className="text-muted d-block mb-1">Pay to the order of</span>

      <span>{checkName}</span>
    </Col>

    <Col className="p-0" md={4}>
      <FormTextInput icon="circle-info" iconClass="text-muted" label="Check Number" labelClasses="mb-1" name={name}
                     placeholder="XXXX" tooltip={TOOLTIP} tooltipId="check-number" />
    </Col>
  </Row>
)

CheckRefund.defaultProps = {
  checkName: '',
  date: '',
  name: '',
}

CheckRefund.propTypes = {
  checkName: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
}

export default CheckRefund
