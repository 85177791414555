import { Row } from 'reactstrap'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'
import SignInModal from 'Shared/ModalSignIn/SignInModal'

const Section = ({ camperId, children, className, name }) => {
  const classes = cnames('section flex-column flex-lg-row flex-fill g-0', name, className)

  return (
    <Row className={classes}>
      {children}
      {!camperId && <SignInModal />}
    </Row>
  )
}

Section.defaultProps = {
  camperId: null,
  name: null,
}

Section.propTypes = {
  camperId: PropTypes.string,
  name: PropTypes.string,
}

const mapStateToProps = state => ({
  camperId: state.camper.id,
})

export default storeConnect({ mapStateToProps })(Section)
