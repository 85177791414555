import { fillArrayWithMinToMax } from 'Utils/Arrays'

// NOTE: this should be the same than PricePeriod::SQL_DOW
const DOW = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 }

export const daysOfWeek = [
  {
    label: 'Monday',
    position: 1,
    value: 'mon',
  }, {
    label: 'Tuesday',
    position: 2,
    value: 'tue',
  }, {
    label: 'Wednesday',
    position: 3,
    value: 'wed',
  }, {
    label: 'Thursday',
    position: 4,
    value: 'thu',
  }, {
    label: 'Friday',
    position: 5,
    value: 'fri',
  }, {
    label: 'Saturday',
    position: 6,
    value: 'sat',
  }, {
    label: 'Sunday',
    position: 0,
    value: 'sun',
  },
]

export const MAX = 'sat'
export const MIN = 'sun'

export const canBeUnCheck = (position, selecteds) => {
  const [firstNeighbor, secondNeighbor] = getNeighbors(position)
  const closesAreDisabled = !selecteds.includes(firstNeighbor) || !selecteds.includes(secondNeighbor)
  const allSelected = selecteds.length === daysOfWeek.length

  return closesAreDisabled || allSelected
}

export const createInitialSelectedByNigthsBookTogether = ({ startsOn, endsOn }) => {
  const starts = DOW[startsOn]
  const ends = DOW[endsOn]
  if (starts === ends) return [starts]
  if (starts < ends) return fillArrayWithMinToMax({ min: starts, max: ends })

  const biggerToMax = fillArrayWithMinToMax({ min: starts, max: DOW[MAX] })
  const minToLower = fillArrayWithMinToMax({ min: DOW[MIN], max: ends })

  return [...biggerToMax, ...minToLower]
}

export const getNeighbors = value => {
  const max = DOW[MAX]
  const min = DOW[MIN]
  if (value === max) return [max - 1, min]
  if (value === min) return [max, min + 1]

  return [value - 1, value + 1]
}

export const isNeighbor = (value, selectedPositionsArr) => {
  if (!selectedPositionsArr.length) return true

  const [lower, bigger] = getNeighbors(value)

  return selectedPositionsArr.includes(lower) || selectedPositionsArr.includes(bigger)
}
