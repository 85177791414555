import { ButtonGroup, Label } from 'reactstrap'
import { FieldArray } from 'formik'
import { moment } from 'Utils/Dates'
import DayButton from './DayButton'
import FormErrorMessage from 'Shared/Formik/FormErrorMessage'

const FormDaysWeek = ({ daysSelected }) => (
  <FieldArray className="mt-5" name="days">
    {({ push, remove }) => {
      const days = moment.weekdaysShort()

      const setDay = day => {
        const index = daysSelected.indexOf(day)
        if (index >= 0)
          remove(index)
        else
          push(day)
      }

      return (
        <div className="mt-5">
          <Label className="main-label" for="days">Nights of the week</Label>

          <ButtonGroup className="w-100">
            {days.map((day, index) => (
              <DayButton dayNumber={index} key={day} isSelected={daysSelected.includes(index)} onClick={setDay}
                         value={day} />
            ))}
          </ButtonGroup>

          <FormErrorMessage name="days" />

          <p className="border-bottom fst-italic mt-2 pb-2 text-muted text-smaller-1">
            If no nights are selected, all nights of the week will be considered.
          </p>
        </div>
      )
    }}
  </FieldArray>
)

export default FormDaysWeek
