import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { Col, Row } from 'reactstrap'

const Utility = ({ icon, id, items, title }) => (
  <Row className="align-items-center gy-3 my-2" id={id}>
    <Col xs={2}>
      <FontAwesomeIcon className="text-theme-primary" icon={icon} size="2x" />
    </Col>

    <Col xs={3}>
      <p className="fw-bold">{title}</p>
    </Col>

    {items.map(({ helperText, text }) => (
      <Col className="align-self-baseline text-break" key={text}>

        {!!helperText && <small className="text-muted">{helperText}</small>}

        <p>{text}</p>

      </Col>
    ))}

    <hr />
  </Row>
)

export default Utility
