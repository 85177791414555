import 'Shared/timemachine'
import 'react-dates/initialize'
import { getElementDataSet } from 'Utils/Elements'
import apolloClient, { ApolloProvider } from 'Utils/apolloClient'
import Wrapper from './Wrapper'

const BalanceTracker = ({ isCalendarView }) => {
  const { engineName, slug: campgroundSlug, reservationId, timezone,
          token } = getElementDataSet('balance-tracker-data-container')
  window.roverData = { ...window?.roverData, campgroundSlug, reservationId, timezone, token }
  const isAdmin = engineName === 'admin'

  return (
    <ApolloProvider client={apolloClient}>
      <Wrapper {...{ isAdmin, isCalendarView, reservationId }} slug={campgroundSlug} />
    </ApolloProvider>
  )
}

export default BalanceTracker
