import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap'
import AlertErrors from 'Shared/Alert/AlertErrors'
import AlertWarning from 'Shared/Alert/AlertWarning/AlertWarning'
import Loading from 'Shared/Loading'
import ReservationDetailItem from 'Shared/ReservationDetailItem'
import SuccesfullyMessage from './SuccesfullyMessage'
import TaxDetails from 'Shared/TaxDetails'

const STG_WARNING_MESSAGE = `Camper will be asked for full payment on this invoice. If you'd like to set up
                             multiple invoices, close this modal and select Multiple Payments.`
const STG_WARNING_TITLE = 'Send to Guest will not work for your pricing configuration.'

const SendToGuestContent = ({ closeModal, error, isCompletePayment, link, loading, requestPayment, showWarning,
                              resTaxDetails, toggle, values }) => {
  if (isCompletePayment) return <SuccesfullyMessage close={toggle} />
  const { isTaxIncluded, tax, taxConfig } = resTaxDetails

  const isTaxItem = itemName => ['Tax', taxConfig?.taxName].includes(itemName)

  return (
    <>
      {error && <AlertErrors className="overflow-hidden" errors={[error]} withScroll />}

      <p>
        This invoice will be sent to the guest to make payment. Please confirm to send this payment request.
      </p>

      <div className="mb-4">
        {values.map(item => (!(isTaxItem(item.name) && isTaxIncluded) && !item.hidden && (
          <ReservationDetailItem {...item} key={`r-i-${item.name}`} hideColon itemName={item.name}
                                 value={item.value} />
        )))}
      </div>

      {isTaxIncluded && (
        <TaxDetails className="mb-3 mb-md-4" hideColon nameClass="fw-bold" tax={tax}
                    taxName={taxConfig?.taxName} />
      )}

      {showWarning && (<AlertWarning link={link} msg={STG_WARNING_MESSAGE} msgLink="Learn More"
                                     title={STG_WARNING_TITLE} />
      )}

      <Row>
        <Col className="pe-1">
          <Button block className="mb-2" color="salmon" onClick={closeModal} size="lg">
            No, go back
          </Button>
        </Col>

        <Col className="ps-1">
          <Button block className="mb-2" color="salmon" disabled={loading} onClick={requestPayment} size="lg">
            {loading ? <Loading iconClass="text-smaller-1 text-white" /> : 'Yes, request payment'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

SendToGuestContent.defaultProps = {
  loading: false,
  showWarning: false,
}

SendToGuestContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showWarning: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
}

export default SendToGuestContent
